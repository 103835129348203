<template>
    <div class="chat-list color-text-alt">
        <template v-for="chat in chatsList" :key="chat.id">
            <div
                v-if="!chat.hide"
                class="chat-list__item"
                :data-test="chat.dataTest"
                @click="chatClick(chat.click)"
            >
                <FeImage :src="chat.image" :alt="chat.alt" />
                <div class="chat-list__wrapper">
                    <span class="caption-2">
                        {{ chat.title }}
                    </span>
                    <span
                        v-if="chat?.value"
                        class="chat-list__link caption-2"
                    >
                        <b>{{ chat.value }}</b>
                    </span>
                </div>
                <div
                    v-if="chat.count"
                    class="chat-list__item-counter"
                >
                    {{ chat.count }}
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { MODAL_CALLBACK } from "@config/modalNames";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import { v4 as uuid } from "uuid";
import iconRastrCall from "@theme/images/icon-call.svg";
import iconRastrCallBack from "@theme/images/icon-callback.svg";
import iconRastrEmail from "@theme/images/icon-email.svg";
import iconRastrChat from "@theme/images/icon-chat.svg";
import bus from "@bus";
import { useEnvironment } from "@store/environment";
import { useI18n } from "vue-i18n";
import { useContacts } from "@store/projectContacts";
import { useUserInfo } from "@store/userInfo";
import { useChats } from "@store/chats";
import { useModalsStore } from "@store/modals";

const i18n = useI18n();
const contactStore = useContacts();
const userInfoStore = useUserInfo();
const chatsStore = useChats();
const modalsStore = useModalsStore();
const { isMockerMode } = storeToRefs(useEnvironment());

const callBackForm = () => import("@components/CallbackForm/CallbackForm.vue");

interface IProps {
    closeHandler?: () => void;
}

const props = defineProps<IProps>();

const emit = defineEmits([ "close" ]);

interface IChatList {
    id: string;
    title: string;
    image: string;
    alt: string;
    count?: string;
    value?: string;
    hide?: boolean;
    dataTest?: string;
    click: () => void;
}

function chatClick(clickHandler: () => void) {
    if (props.closeHandler) {
        props.closeHandler();
    }
    clickHandler();
}
function openChatNewWindow(link: string, target = "_blank") {
    window.open(link, target);
}
function openCall() {
    openChatNewWindow(`tel:${ contactStore.getPhone }`, "_self");
}
function openEmail() {
    openChatNewWindow(`mailto:${ contactStore.getEmail }`);
}
async function openCallback() {
    const module = await callBackForm();

    modalsStore.showModal({
        name: MODAL_CALLBACK,
        component: module.default,
        mobileFriendly: true,
    });
}

const chatsList = computed<IChatList[]>(() => {
    return [
        {
            id: uuid(),
            title: i18n.t("SUPPORT.CHAT"),
            image: iconRastrChat,
            alt: "chat icon",
            count: chatsStore.newMessageCount,
            dataTest: "chat-list--chat",
            click: () => {
                bus.$emit("chat.toggle");
                emit("close");
            },
        },
        {
            id: uuid(),
            title: i18n.t("SUPPORT.CALL"),
            value: contactStore.getPhone,
            image: iconRastrCall,
            alt: "phone icon",
            dataTest: "chat-list--call",
            hide: !contactStore.getPhone,
            click: () => {
                openCall();
                emit("close");
            },
        },
        {
            id: uuid(),
            title: i18n.t("SUPPORT.CALL_BACK"),
            image: iconRastrCallBack,
            alt: "phone icon",
            dataTest: "chat-list--callback",
            hide: !isMockerMode.value && (!userInfoStore.isLogged || !i18n.t("SUPPORT.CALL_BACK")),
            click: () => {
                openCallback();
                emit("close");
            },
        },
        {
            id: uuid(),
            title: i18n.t("SUPPORT.SEND_EMAIL"),
            value: contactStore.getEmail,
            image: iconRastrEmail,
            hide: !isMockerMode.value && !i18n.t("SUPPORT.EMAIL_SHOW_TOGGLE"),
            alt: "mail icon",
            dataTest: "chat-list--email",
            click: () => {
                openEmail();
                emit("close");
            },
        },
    ];
});
</script>

<style lang="scss" src="./style.scss"></style>
