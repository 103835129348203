import { useAuth } from "@controllers/services/auth";
import log from "@front/core/controllers/LoggerController";
import fingerprintHelper from "@front/core/helpers/fingerprintHelper";
import Modal from "@modal";
import type { LoginErrorDTO } from "@api";
import { JsonHttpServerError } from "@api/http/errors";

const modalRecaptcha = () => {
    return import(/* webpackChunkName: "ModalRecaptcha" */ "@modules/Auth/components/ModalRecaptcha/ModalRecaptcha.vue");
};
interface ICaptchaLoginData {
    login: string;
    password: string;
}

function loginAction(
    { login, password }: ICaptchaLoginData,
    captcha: string,
    isShowCaptcha: boolean = false,
) {
    const { login: loginUser } = useAuth();

    const loginData = fingerprintHelper({
        _username: login.trim(),
        _password: password,
    });

    if (captcha) {
        loginData.captcha_key = captcha;
    }

    return new Promise(async (res, rej) => {
        try {
            const step = await loginUser(loginData);
            res(step);
        } catch (error) {
            log.error("LOGIN_ERROR", error);
            if (error instanceof JsonHttpServerError) {
                const errorData = error.error.data as LoginErrorDTO;
                if (errorData && !isShowCaptcha && errorData?.captcha_required) {
                    showCaptcha({ login, password }).then(res).catch(rej);
                    return;
                }
            }
            rej(error);
        }
    });
}

function showCaptcha(loginData: ICaptchaLoginData, isShowCaptcha: boolean = false) {
    return new Promise(async (res, rej) => {
        const module = await modalRecaptcha();

        Modal.show({
            name: "modal-recaptcha",
            component: module.default,
            props: {
                successHandler: (captcha: string) => {
                    loginAction(loginData, captcha, isShowCaptcha)
                        .then(res)
                        .catch(rej);
                    Modal.close("modal-recaptcha");
                },
            },
        });
    });
}

function loginWithCaptcha(
    loginData: ICaptchaLoginData,
    isShowCaptcha: boolean = false,
) {
    if (isShowCaptcha) {
        return showCaptcha(loginData, isShowCaptcha);
    }

    return loginAction(loginData, "", isShowCaptcha);
}

export {
    loginAction,
    showCaptcha,
    loginWithCaptcha,
};
