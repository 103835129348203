// TODO: add correct rule output type (backend errors)
export type RuleOutput = boolean | string | any;
export type InputRuleUnknown<T = unknown> = (value: T) => RuleOutput;
export type InputRuleString = (value: string) => RuleOutput;
export type ServerInputRules = Array<InputRuleString | InputRuleUnknown>;

export class ServerInput {
    private _value: string;
    readonly _rules: ServerInputRules;

    constructor(value: string, rules: ServerInputRules = []) {
        this._value = value;
        this._rules = rules;
    }

    get value(): string {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get rules() {
        return this._rules;
    }
}
