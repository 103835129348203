import BasePromoBannerPrimary from "@components/PromoBanner/BasePromoBanner.vue";
import BasePromoBannerSecondary from "@components/PromoBanner/BasePromoBannerSecondary.vue";

export enum PromoBannerThemes {
    primary = "primary",
    secondary = "secondary",
}

export const componentConfigByTheme = {
    [PromoBannerThemes.primary]: BasePromoBannerPrimary,
    [PromoBannerThemes.secondary]: BasePromoBannerSecondary,
};
