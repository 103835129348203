<template>
    <div class="vip-manager" gap-xs>
        <div v-t="'SUPPORT.VIP_MANAGER.TITLE'" class="caption-1" />
        <div class="vip-manager__manager">
            <FeImage :src="imageManager" alt="manager-avatar" />
            <div>
                <p class="color-text-alt">
                    <b>{{ vipManagerData?.publicName }}</b>
                </p>
                <div v-if="supportWorkHours" class="caption-2">
                    <span>
                        {{ supportWorkHours.weekDays }}
                    </span>
                    <span>
                        {{ supportWorkHours.time }}
                        {{ i18n.t("SUPPORT.VIP_MANAGER.LOCAL_TIME") }}
                    </span>
                </div>
            </div>
        </div>
        <div class="vip-manager__list">
            <template v-for="item in supportList" :key="item.id">
                <FeLink
                    v-if="item.link"
                    :to="item.link"
                    class="vip-manager__item"
                    padding-xs
                >
                    <FeIcon v-if="item?.icon" :icon="item.icon" />
                    <FeImage v-else-if="item?.image" :src="item.image" :alt="item.alt" />
                    <div class="vip-manager__item-title">
                        {{ item.title }}
                    </div>
                </FeLink>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { VipManagerGender } from "@api";
import { useFormatDate } from "@helpers/formatDate";
import { v4 as uuid } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { useUserInfo } from "@store/userInfo";
import { useMultiLang } from "@store/multilang";
import imageManagerMan from "./images/manager-man.png";
import imageManagerWoman from "./images/manager-woman.png";
import iconTelegram from "@theme/images/social/icon-telegram.svg";
import iconWhatApp from "@theme/images/social/icon-whats-app.svg";

const i18n = useI18n();
const { userLanguage } = storeToRefs(useMultiLang());
const { vipManagerData } = storeToRefs(useUserInfo());

const { timezoneSupportHours } = useFormatDate();

interface ISupportList {
    id: string;
    title: string;
    link: string | undefined;
    image?: string;
    icon?: string;
    alt: string
}

const supportList = computed<ISupportList[]>(() => {
    return [
        {
            id: uuid(),
            title: i18n.t("COMMON.WHATS_APP"),
            image: iconWhatApp,
            link: vipManagerData.value?.whatsapp,
            alt: "whatsapp icon",
        },
        {
            id: uuid(),
            title: i18n.t("COMMON.TELEGRAM"),
            image: iconTelegram,
            link: vipManagerData.value?.telegram,
            alt: "telegram icon",
        },
        {
            id: uuid(),
            title: i18n.t("SUPPORT.VIP_MANAGER.EMAIL"),
            icon: FeIconConfig.mail,
            link: vipManagerData.value?.email && `mailto: ${vipManagerData.value.email}`,
            alt: "mail icon",
        },
        {
            id: uuid(),
            title: i18n.t("SUPPORT.VIP_MANAGER.PHONE_CALL"),
            icon: FeIconConfig.phone,
            link: vipManagerData.value?.phoneNumbers && `tel: ${vipManagerData.value.phoneNumbers}`,
            alt: "phone icon",
        },
    ];
});

const imageManager = computed(() => {
    if (vipManagerData.value?.profilePicture) {
        return vipManagerData.value.profilePicture;
    }
    if (vipManagerData.value?.gender === VipManagerGender.FEMALE) {
        return imageManagerWoman;
    }
    return imageManagerMan;
});

function computeDay(weekday: number): string {
    const options: Record<string, string> = { weekday: "short" };

    const date = new Date();
    date.setDate(date.getDate() - date.getDay() + weekday);

    return new Intl.DateTimeFormat(userLanguage.value, options).format(date);
}

const supportWorkHours = computed(() => {
    if (vipManagerData.value) {
        const { dateStart, dateEnd } = vipManagerData.value;
        const { dayStart, dayEnd, timeStart, timeEnd } = timezoneSupportHours(dateStart, dateEnd);

        return {
            weekDays: `${computeDay(dayStart)}-${computeDay(dayEnd)},`,
            time: `${timeStart}-${timeEnd}`,
        };
    }

    return null;
});
</script>

<style scoped lang="scss">
@import "~@theme/styles";
.vip-manager {
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-radius: $border-radius-S;
        background: $color-tertiary-5;
        flex: 1 1 35%;
        align-items: center;
        cursor: pointer;
        color: $color-text-alt;
        text-decoration: none;

        .fe-icon, img {
            width: 1.5rem;
            height: 1.5rem;

            @include media(M) {
                width: 2rem;
                height: 2rem;
            }

        }

        &:hover {
            background: $color-tertiary-2;
        }

        &:active {
            background: $color-tertiary-3;
        }
    }

    &__item-title {
        @include font-size--SmallCaption($font-weight--normal);

        @include media(M) {
            @include font-size--Caption2($font-weight--normal);
        }
    }

    &__manager {
        display: flex;
        gap: 1rem;
        align-items: center;

        img {
            width: 3rem;
            height: 3rem;
        }
    }
}
</style>
