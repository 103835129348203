<script setup lang="ts">
import bus from "@bus";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { v4 as uuid } from "uuid";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { TOAST_TIMER_TIME } from "./config";
import { type IToastData, ToastTheme } from "./types";

interface IIdToastData extends IToastData {
    id: string;
}

const toasts = ref<IIdToastData[]>([]);

onMounted(() => {
    bus.$on("toast.show", show);
    bus.$on("toast.close", close);
});
onBeforeUnmount(() => {
    bus.$off("toast.show", show);
    bus.$off("toast.close", close);
});

function show(data: IToastData) {
    const toast: IIdToastData = {
        ...data,
        id: uuid(),
    };

    setHideTimer(toast);
    toasts.value.push(toast);
}

function close(toast: IToastData) {
    // @ts-expect-error Property id does not exist on type IToastData
    remove(toast.id);
}

function remove(id: string) {
    toasts.value = toasts.value.filter((item) => {
        return item.id !== id;
    });
}

function setHideTimer(toast: IToastData) {
    const time = (typeof toast.time === "number") ? toast.time : TOAST_TIMER_TIME;

    if (time > 0) {
        setTimeout(() => {
            // @ts-expect-error Property id does not exist on type IToastData
            remove(toast.id);
        }, time);
    }
}

function getToastClasses(item: IToastData) {
    return [
        `toast--theme-${item?.theme || ToastTheme.default }`,
    ];
}
</script>

<template>
    <div class="vs-notify">
        <transition-group name="ntf-fade" mode="out-in" tag="div">
            <div
                v-for="toast in toasts"
                :key="toast.id"
                class="toast"
                :class="getToastClasses(toast)"
            >
                <div class="toast__content">
                    <div
                        v-if="toast.title"
                        class="toast__content-title"
                    >
                        <b>{{ toast.title }}</b>
                    </div>
                    <div
                        v-if="toast?.text"
                        class="toast__content-body"
                        :class="{ 'toast__content-body-with-title': Boolean(toast.title) }"
                        v-html="toast.text"
                    />
                </div>
                <div class="toast__close" @click.stop="remove(toast.id)">
                    <FeIcon :icon="FeIconConfig.close" />
                </div>
            </div>
        </transition-group>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles";

$themesBackground: (
    default: $color-layer-alt-1,
    success: $color-primary-2,
    error: $color-error,
    warning: $color-warning
);
$themesTextColor: (
    default: $color-on-dark-back,
    success: $color-on-light-back,
    error: $color-on-light-back,
    warning: $color-on-light-back
);

.vs-notify {
    position: fixed;
    width: 100%;
    z-index: $POPUP_INDEX;
    top: 1rem;
    right: 50%;
    transform: translate(50%, 0);

    @include media(M) {
        right: 2rem;
        transform: translate(0, 0);
        width: 21rem;
        top: 5rem;
    }

    .game ~ & {
        right: auto;
        top: 1rem;
        transform: translate(0, 0);

        @include media(M) {
            left: 1rem;
        }
    }

    .toast {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto 1.5rem;
        align-items: center;

        box-sizing: border-box;
        border-radius: $border-radius-S;
        padding: 1rem;
        margin: 0.5rem 1rem;
        min-height: 4rem;
        text-align: left;
        cursor: pointer;
        background: $color-layer-alt-1;
        color: $color-on-dark-back;
        border: 1px solid $color-tertiary-3;

        @include media(M) {
            margin-left: 0;
            margin-right: 0;
        }

        &:first-child {
            margin-top: 0;
        }

        @each $name, $color in $themesBackground {
            &--theme-#{$name} {
                background: $color;
            }
        }
        @each $name, $color in $themesTextColor {
            &--theme-#{$name} {
                color: $color;
            }
        }


        &__close {
            justify-self: end;
            align-self: start;
            color: $color-text-body;
        }

        &__content {
            &-body {
                &-with-title {
                    font-weight: $font-weight--normal;
                }
            }
        }
    }


    .ntf-left-enter-active,
    .ntf-left-leave-active,
    .ntf-right-enter-active,
    .ntf-right-leave-active,
    .ntf-top-enter-active,
    .ntf-top-leave-active,
    .ntf-bottom-enter-active,
    .ntf-bottom-leave-active {
        transition: all 0.3s;
    }

    .ntf-left-enter-from,
    .ntf-left-leave-to {
        opacity: 0;
        transform: translateX(-300px);
    }

    .ntf-right-enter-from,
    .ntf-right-leave-to {
        opacity: 0;
        transform: translateX(300px);
    }

    .ntf-fade-enter-active,
    .ntf-fade-leave-active {
        transition: opacity 0.5s;
    }

    .ntf-fade-enter-from,
    .ntf-fade-leave-to {
        opacity: 0;
    }

    .ntf-top-enter-from,
    .ntf-top-leave-to {
        opacity: 0;
        transform: translateY(-120px);
    }

    .ntf-bottom-enter-from,
    .ntf-bottom-leave-to {
        opacity: 0;
        transform: translateY(120px);
    }
}
</style>
