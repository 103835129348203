import bus from "@bus";
import { computed, defineComponent, onBeforeUnmount, onMounted, type PropType, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { getFreshchatWidget } from "@front/core/modules/FreshChat/fcWidget";
import { getFreshchatUserTimezone } from "@front/core/modules/FreshChat/helpers";
import type {
    IfcWidget,
    IfcWidgetConfig,
    IfcWidgetUnreadCountEvent,
    IFreshChatParams,
} from "@front/core/modules/FreshChat/types";
import { useContextStore } from "@store/context";
import { FRESHCHAT_HOST } from "@theme/configs/constantsFreshChat";
import { useRoute } from "vue-router";

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<IFreshChatParams>,
            required: true,
        },
    },
    emits: [ "update:restoreId", "update:unreadCount" ],
    setup(props, { emit }) {
        const i18n = useI18n();
        const route = useRoute();
        const { isBot } = storeToRefs(useContextStore());

        let isInit = false;
        let initPromise: Promise<IfcWidget> | undefined = undefined;
        let pendingPromise: Promise<IfcWidget> | undefined = undefined;
        let localRestoreId = props.data.restoreId;
        let widgetStartInit = false;
        let widgetOpened = false;
        let widgetNeedRefresh = false;

        const widgetInitParams = computed<IfcWidgetConfig>(() => {
            return {
                widgetUuid: props.data.widgetUuid,
                host: FRESHCHAT_HOST,
                token: props.data.token,
                restoreId: props.data.restoreId,
                externalId: props.data.externalId,
                email: props.data.email,
                config: {
                    headerProperty: {
                        hideChatButton: true,
                    },
                    content: {
                        headers: {
                            csat_question: i18n.t("SUPPORT.FRESH_CHAT_CSAT_QUESTION"),
                        },
                        actions: {
                            csat_yes: "\u{1F44D}",
                            csat_no: "\u{1F44E}",
                        },
                    },
                },
            };
        });

        async function initWidget(): Promise<IfcWidget> {
            if (props.data.pending) {
                if (!pendingPromise) {
                    // in case we need to wait some data that not loaded yet
                    // because if we start initialize widget and after get new data, widget will be destroyed
                    // and reinitialized again and this can slow down widget loading and chat opening for user
                    pendingPromise = new Promise((resolve) => {
                        const remove = watch(
                            () => props.data.pending,
                            (value) => {
                                if (!value) {
                                    remove();
                                    pendingPromise = undefined;
                                    initWidget().then(resolve);
                                }
                            },
                        );
                    });
                }
                return pendingPromise;
            }
            if (!initPromise) {
                initPromise = new Promise(async (resolve) => {
                    // in case widget was opened and parameters were changed,
                    // we need to reopen widget after initialization (cause widget will be destroyed before init)
                    const needReopen = widgetOpened;

                    if (isInit) {
                        await destroy();
                    }

                    const widget = await getFreshchatWidget();
                    widgetStartInit = true;

                    setupWidgetEvents(widget, resolve, needReopen);
                });
            } else if (widgetStartInit) {
                // in case widget start init we can't cancel this process until it will be finished
                // this flag indicate that we need refresh widget after initialization in case parameters change
                widgetNeedRefresh = true;
            }

            return initPromise;
        }

        function destroy(): Promise<void> {
            if (isInit) {
                return new Promise(async (resolve) => {
                    const widget = await getFreshchatWidget();
                    widget.on("widget:destroyed", () => {
                        widgetOpened = false;
                        isInit = false;
                        resolve();
                    });
                    widget.destroy();
                });
            }
            return Promise.resolve();
        }

        function setupWidgetEvents(widget: IfcWidget, resolve: (widget: IfcWidget) => void, needReopen: boolean): void {
            widget.init({
                ...widgetInitParams.value,
                onLoad: () => handleWidgetLoad(widget, resolve, needReopen),
            });
            let userEmailVerified: "true" | "false" | undefined = undefined;
            if (props.data.userEmailVerified !== undefined) {
                userEmailVerified = props.data.userEmailVerified ? "true" : "false";
            }
            const userTimezone = getFreshchatUserTimezone();
            widget.conversation.setBotVariables({
                usr_domain: window.location.hostname,
                usr_locale: props.data.userLocale,
                usr_timezone: userTimezone,
                usr_vipstatus: props.data.userVIPStatus,
                usr_verifmail: userEmailVerified,
                usr_manager: props.data.userVIPManagerName,
                usr_country: props.data.countryCode,
            });
            widget.user.setProperties({
                cf_chat_timezone: userTimezone,
                cf_chat_session_email: props.data.email,
            });

            widget.on("user:created", (response) => {
                const { restoreId } = response.data || {};
                if (restoreId) {
                    // need to save local restore id in order not to re-init widget after
                    // getting new restore id from updated props
                    localRestoreId = restoreId;
                    if (restoreId !== props.data.restoreId) {
                        emit("update:restoreId", restoreId);
                    }
                }
            });
            widget.on("widget:opened", () => {
                widgetOpened = true;
            });
            widget.on("widget:closed", () => {
                widgetOpened = false;
            });
            widget.on("unreadCount:notify", (response: IfcWidgetUnreadCountEvent) => {
                emit("update:unreadCount", response.count);
            });
        }

        function handleWidgetLoad(widget: IfcWidget, resolve: (widget: IfcWidget) => void, needReopen: boolean): void {
            const callback = (loadWidget: IfcWidget) => {
                resolve(loadWidget);
                if (needReopen) {
                    loadWidget.open();
                }
            };

            isInit = true;
            widgetStartInit = false;
            initPromise = undefined;

            if (widgetNeedRefresh) {
                widgetNeedRefresh = false;
                initWidget().then(callback);
            } else {
                callback(widget);
            }
        }

        async function getWidget(): Promise<IfcWidget> {
            if (isInit) {
                return getFreshchatWidget();
            }
            return initWidget();
        }

        async function close() {
            const widget = await getWidget();
            widget.close();
        }

        async function open() {
            const widget = await getWidget();
            widget.open();
        }

        watch(
            () => props.data,
            (value, oldValue) => {
                if (isBot.value) {
                    return;
                }

                const changeKeys: Array<keyof IFreshChatParams> = [ "token", "externalId", "widgetUuid" ];
                const reInitCheck = changeKeys.some((key) => {
                    return value[key] !== oldValue[key];
                });
                const restoreIdCheck = value.restoreId !== localRestoreId;

                // check is any widget parameters were changed
                if (reInitCheck || restoreIdCheck) {
                    initWidget();
                }
            },
            {
                deep: true,
            },
        );

        onMounted(async () => {
            if (!isBot.value) {
                await initWidget();

                if ([ "#chat" ].includes(route.hash)) {
                    setTimeout(async () => {
                        window.location.hash = "";
                        await open();
                    }, 1000);
                }
            }
        });

        bus.$on("chat.toggle", open);
        onBeforeUnmount(() => {
            bus.$off("chat.toggle", open);
        });

        return () => {
            return null;
        };
    },
});
