import type { IPaginationData, PaginationRequest } from "@controllers/services/pagination";
import { usePaginationService } from "@controllers/services/pagination";
import { useCategoriesStore } from "@store/categories";
import { useMultiLang } from "@store/multilang";
import { SLUGS_WITH_GEO_INJECT } from "@theme/configs/constsGames";
import { computed, ref } from "vue";

export function useSlugGeoInject() {
    const categoriesStore = useCategoriesStore();
    const multiLangStore = useMultiLang();

    const slugGeoInjectSettings = computed(() => {
        return SLUGS_WITH_GEO_INJECT.map((settings) => {
            const originalSlug = settings.slug;
            let countrySlug = null;
            let regionSlug = null;
            if (multiLangStore.userGeo) {
                countrySlug = `${settings.slug}_${multiLangStore.userGeo.toLowerCase()}`;

                if (multiLangStore.userGeoRegion && settings?.regionInjectFor?.includes(multiLangStore.userGeo)) {
                    regionSlug = `${regionSlug}_${multiLangStore.userGeoRegion.toLowerCase()}`;
                }
            }

            return {
                originalSlug,
                countrySlug,
                regionSlug,
            };
        });
    });

    function slugGeoInject(slug: string) {
        const injectSetting = slugGeoInjectSettings.value.find(({ originalSlug }) => originalSlug === slug);
        if (injectSetting && injectSetting.countrySlug) {
            const availableSlugs = categoriesStore.categories.map((category) => {
                return category.slug;
            });

            if (injectSetting.regionSlug && availableSlugs.includes(injectSetting.regionSlug)) {
                return injectSetting.regionSlug;
            }
            if (availableSlugs.includes(injectSetting.countrySlug)) {
                return injectSetting.countrySlug;
            }
        }
        return slug;
    }

    function getOriginalSlug(countryOrRegionSlug: string) {
        const injectSetting = slugGeoInjectSettings.value.find(({ countrySlug, regionSlug }) => {
            return countrySlug === countryOrRegionSlug || regionSlug === countryOrRegionSlug;
        });

        if (injectSetting) {
            return injectSetting.originalSlug;
        }
        return countryOrRegionSlug;
    }

    return {
        slugGeoInject,
        getOriginalSlug,
    };
}

export function usePaginationSlugs<T>(request: PaginationRequest<T, string>) {
    const slugs = ref<Record<string, IPaginationData<T>>>({});

    const paginationService = usePaginationService(request);
    const { slugGeoInject } = useSlugGeoInject();

    async function reloadAll() {
        const promises: Array<Promise<unknown>> = [];
        for (const slug in slugs.value) {
            if (Object.prototype.hasOwnProperty.call(slugs.value, slug)) {
                const data = paginationService.createPaginationData();
                data.pending = true;
                slugs.value[slug] = data;

                promises.push(
                    paginationService.initLoad(data, slug).then((result) => {
                        slugs.value[slug] = {
                            ...result,
                            pending: false,
                        };
                    }),
                );
            }
        }
        await Promise.all(promises);
    }

    function getSlugData(slug: string) {
        const geoSlug = slugGeoInject(slug);
        if (!slugs.value[geoSlug]) {
            return paginationService.createPaginationData();
        }
        return slugs.value[geoSlug];
    }

    function getOrCreateSlugData(slug: string) {
        const geoSlug = slugGeoInject(slug);
        if (!slugs.value[geoSlug]) {
            slugs.value = {
                ...slugs.value,
                [geoSlug]: paginationService.createPaginationData(),
            };
        }
        return slugs.value[geoSlug];
    }

    async function loadNextPage(slug: string) {
        const data = getOrCreateSlugData(slug);
        const geoSlug = slugGeoInject(slug);

        if (!data.pending && !data.isFullLoaded) {
            slugs.value[geoSlug].pending = true;

            const newData = await paginationService.loadNext(data, geoSlug);
            slugs.value[geoSlug] = {
                ...newData,
                pending: false,
            };
        }
    }

    async function loadInitData(slug: string) {
        const data = getOrCreateSlugData(slug);
        const geoSlug = slugGeoInject(slug);

        if (!data.pending && !data.isFullLoaded) {
            slugs.value[geoSlug].pending = true;

            const newData = await paginationService.initLoad(data, geoSlug);
            slugs.value[geoSlug] = {
                ...newData,
                pending: false,
            };
        }
    }

    return {
        slugs,
        reloadAll,
        getSlugData,
        getOrCreateSlugData,
        loadNextPage,
        loadInitData,
    };
}
