import { useUserInfo } from "@store/userInfo";
import { LimitType, useLimitStatusService } from "@controllers/services/limits";
import { useCoolingOffStatus, useShowGameCoolingOffPopup } from "@modules/Limits";
import { useGameSuspendedModal } from "@modules/Game/composables/useGameRestriction";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";

export function useTryRunConditions() {
    const userInfoStore = useUserInfo();
    const { showLossLimitPopup, checkIsLimitReached } = useLimitStatusService(LimitType.LOSS);
    const { checkIsCoolingOff } = useCoolingOffStatus();
    const { showSuspendedModal } = useGameSuspendedModal();
    const { showGameCoolingOffPopup } = useShowGameCoolingOffPopup();
    const router = useRouter();

    async function tryRunConditions() {
        const [ isLimitReached, isCoolingOff ] = await Promise.all([
            checkIsLimitReached(),
            checkIsCoolingOff(),
            userInfoStore.isLoggedAsync(),
        ]);

        const isGamePage = router.currentRoute.value.name === routeNames.gameLivespins;

        if (userInfoStore.isSuspended) {
            showSuspendedModal(isGamePage);
            return false;
        } else if (isCoolingOff) {
            showGameCoolingOffPopup(isGamePage);
            return false;
        } else if (isLimitReached) {
            showLossLimitPopup();
            return false;
        }

        return true;
    }

    return {
        tryRunConditions,
    };
}
