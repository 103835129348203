import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { useEnvironment } from "@store/environment";
import { computed } from "vue";

export function useBonusFeatureService() {
    const envStore = useEnvironment();

    const isBonusesEnabled = computed(() => {
        return getFeatureFlags().enableNewBonuses || envStore.isDev;
    });
    const isLivespinsEnabled = computed(() => {
        return getFeatureFlags().enabledLivespins || envStore.isStage || envStore.isDev;
    });

    return {
        isBonusesEnabled,
        isLivespinsEnabled,
    };
}
