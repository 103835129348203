import { PAGE_TYPE } from "@config/mainPages";
import { getIsLicenseDomain } from "@controllers/services/domains";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { routeNames } from "@router/routeNames";
import config from "@theme/configs/config";
import type { RouteRecordRaw } from "vue-router";

import mainBase from "@layouts/Main/indexMobile.vue";
const EmptyPage = () => import("@modules/GameHall/Empty.vue");
const GameBase = () => import("@layouts/Game/Game.vue");
const Game = () => import("@modules/Game/index.vue");
const LivespinsGamePage = () => import("@pages/Game/Livespins/LivespinsGamePage.vue");

const Login = () => import("@modules/Auth/Login/Login.vue");
const RemindPassword = () => import("@modules/Auth/RemindPassword/index.vue");
const Registration = () => import("@modules/Auth/Registration/index.vue");
const NewPassword = () => import("@modules/Auth/NewPassword/index.vue");
const SetPassword = () => import("@modules/Auth/SetPassword/index.vue");

const PopupBase = () => import("@layouts/Popup/Popup.vue");

const Profile = () => import("@modules/Profile/index.vue");
const LevelSystemCarousel = () => import("@modules/LevelSystemCarousel/components/LevelsCarousel.vue");

const ProfileCabinet = () => import("@modules/Profile").then((module) => module.ProfileCabinet);
const ProfileGifts = () => import("@modules/Profile/ProfileGifts/index.vue");
const ProfileBonuses = () => import("@modules/Profile/ProfileBonuses/ProfileBonuses.vue");
const ProfilePromocode = () => import("@modules/Profile/ProfilePromocode/index.vue");
const ProfileVerification = () => import("@modules/Profile/ProfileVerification/index.vue");
const ProfileLimits = () => import("@modules/Profile/ProfileLimits/ProfileLimits.vue");

const Unsubscribe = () => import("@modules/Unsubscribe/index.vue");

const TournamentPage = () => import("@pages/TournamentPage/index.vue");

const PromoPage = () => import("@pages/PromoPage/index.vue");

// Promotions Page
const PromotionsPage = () => import("@pages/PromotionsPage/index.vue");
// Promotions Page Casino
const PromotionsCasinoPage = () => import("@pages/PromotionsPage/Casino/index.vue");
const PromotionsCasinoCurrent = () => import("@pages/PromotionsPage/Casino/Current.vue");
const PromotionsCasinoActions = () => import("@pages/PromotionsPage/Casino/Actions.vue");
const PromotionsCasinoTournaments = () => import("@pages/PromotionsPage/Casino/Tournaments.vue");
const PromotionsCasinoQuests = () => import("@pages/PromotionsPage/Casino/Quests.vue");
// Promotions Page Live
const PromotionsLivePage = () => import("@pages/PromotionsPage/Live/index.vue");
const PromotionsLiveCurrent = () => import("@pages/PromotionsPage/Live/Current.vue");
const PromotionsLiveActions = () => import("@pages/PromotionsPage/Live/Actions.vue");
const PromotionsLiveTournaments = () => import("@pages/PromotionsPage/Live/Tournaments.vue");
const PromotionsLiveQuests = () => import("@pages/PromotionsPage/Live/Quests.vue");
// Promotions Page Sports
const PromotionsSportPage = () => import("@pages/PromotionsPage/Sport/index.vue");
const PromotionsSportCurrent = () => import("@pages/PromotionsPage/Sport/Current.vue");
const PromotionsSportActions = () => import("@pages/PromotionsPage/Sport/Actions.vue");
const PromotionsSportTournaments = () => import("@pages/PromotionsPage/Sport/Tournaments.vue");
const PromotionsSportQuests = () => import("@pages/PromotionsPage/Sport/Quests.vue");

// Christmas Pages
const SantasGiftShopPromoPage = () => import("@pages/PromoPage/SantasGiftShopPromoPage/index.vue");
const ChristmasPage = () => import("@pages/ChristmasPage/ChristmasPage.vue");
const CalendarPage = () => import("@pages/CalendarPage/CalendarPage.vue");

const ErrorEMP = () => import("@modules/ErrorEMP/index.vue");

const BettingPage = () => import("@pages/BettingPage/BettingPage.vue");
const EsportPage = () => import("@pages/EsportPage/EsportPage.vue");

const BonusPage = () => import("@pages/BonusPage/BonusPage.vue");
const CasinoBonusPage = () => import("@pages/BonusPage/BonusPageCasino.vue");
const SportsBonusPage = () => import("@pages/BonusPage/BonusPageSport.vue");
const LoyaltyProgramPage = () => import("@pages/LoyaltyProgramPage/LoyaltyProgramPage.vue");
const FaqPage = () => import("@pages/FaqPage/FaqPage.vue");
const VerificationPage = () => import("@pages/VerificationPage/VerificationPage.vue");
const VipClubPage = () => import("@pages/VipClubPage/VipClubPage.vue");
const ShareExperiencePage = () => import("@pages/ShareExperiencePage/index.vue");
const StaticPage = () => import("@modules/StaticPage/StaticPage.vue");
// Main Casino
const CasinoPage = () => import("@pages/CasinoPage/index.vue");
const CasinoThemesPage = () => import("@pages/CasinoPage/Themes.vue");
const CasinoProvidersPage = () => import("@pages/CasinoPage/Providers.vue");
const CasinoCategoryPage = () => import("@pages/CasinoPage/Category.vue");
const CasinoThemePage = () => import("@pages/CasinoPage/Theme.vue");
const CasinoProviderPage = () => import("@pages/CasinoPage/Provider.vue");

// Main Live
const LivePage = () => import("@pages/LivePage/index.vue");
const LiveCategories = () => import("@pages/LivePage/Categories.vue");
const LiveProviders = () => import("@pages/LivePage/Providers.vue");
const LiveProviderPage = () => import("@pages/LivePage/Provider.vue");
const LiveCategoryPage = () => import("@pages/LivePage/Category.vue");

const HomePage = () => import("@pages/HomePage/HomePage.vue");

const BenefitsCrypto = () => import("@pages/BenefitsCryptoPage/index.vue");

const LivespinsPage = () => import("@pages/LivespinsPage/LivespinsPage.vue");

const FeatureFlagConfiguration = () => import("@pages/FeatureFlagConfiguration/FeatureFlagConfiguration.vue");

export function createRoutes(hostname?: string): RouteRecordRaw[] {
    const oldProfileGiftsRoute: RouteRecordRaw = {
        path: "gifts",
        component: ProfileGifts,
        name: routeNames.profileGifts,
        meta: { requiresAuth: true },
    };
    const newProfileGiftsRoute: RouteRecordRaw = {
        path: "gifts",
        name: routeNames.profileGifts,
        redirect: { name: routeNames.profileBonuses },
    };
    const profileGiftsRoute = getFeatureFlags().enableNewBonuses ? newProfileGiftsRoute : oldProfileGiftsRoute;

    const profileBonusesRoute: RouteRecordRaw[] = [
        {
            path: "casino",
            name: routeNames.profileBonusesCasino,
            component: ProfileBonuses,
        },
        {
            path: "sport",
            name: routeNames.profileBonusesSport,
            component: ProfileBonuses,
        },
    ];

    if (getFeatureFlags().depositInsuranceBonus) {
        profileBonusesRoute.push({
            path: "insurance",
            name: routeNames.profileBonusesInsurance,
            component: ProfileBonuses,
        });
    } else {
        profileBonusesRoute.push({
            path: "insurance",
            redirect: { name: routeNames.profileBonuses },
        });
    }

    const casinoChildren: RouteRecordRaw[] = [
        {
            path: "",
            name: routeNames.casinoPage,
            component: CasinoPage,
            meta: {
                pageType: PAGE_TYPE.CASINO,
            },
        },
        {
            path: "categories/:slug",
            name: routeNames.casinoCategory,
            component: CasinoCategoryPage,
            props: true,
        },
        {
            path: "theme",
            name: routeNames.casinoThemes,
            component: CasinoThemesPage,
        },
        {
            path: "theme/:slug",
            name: routeNames.casinoTheme,
            component: CasinoThemePage,
            props: true,
        },
        {
            path: "providers",
            component: CasinoProvidersPage,
            name: routeNames.casinoProviders,
        },
        {
            path: "providers/livespins",
            component: LivespinsPage,
            name: routeNames.casinoLivespins,
        },
        {
            path: "providers/:slug",
            component: CasinoProviderPage,
            name: routeNames.casinoProvider,
        },
    ];

    return [
        {
            path: "/",
            name: routeNames.base,
            component: mainBase,
            children: [
                {
                    path: "",
                    name: routeNames.main,
                    component: HomePage,
                    meta: {
                        pageType: PAGE_TYPE.MAIN,
                    },
                },
                {
                    path: "/casino",
                    component: EmptyPage,
                    meta: {
                        emptyRoute: true,
                    },
                    props: true,
                    children: casinoChildren,
                },
                {
                    path: "/live",
                    component: EmptyPage,
                    meta: {
                        emptyRoute: true,
                    },
                    props: true,
                    children: [
                        {
                            path: "",
                            name: routeNames.livePage,
                            component: LivePage,
                            meta: {
                                pageType: PAGE_TYPE.LIVE,
                            },
                        },
                        {
                            path: "categories",
                            component: LiveCategories,
                            name: routeNames.liveCategories,
                        },
                        {
                            path: "categories/:slug",
                            name: routeNames.liveCategory,
                            component: LiveCategoryPage,
                            props: true,
                        },
                        {
                            path: "providers",
                            component: LiveProviders,
                            name: routeNames.liveProviders,
                        },
                        {
                            path: "providers/:slug",
                            component: LiveProviderPage,
                            name: routeNames.liveProvider,
                        },
                    ],
                },
                {
                    path: "/promotions",
                    component: PromotionsPage,
                    meta: {
                        emptyRoute: true,
                    },
                    children: [
                        {
                            path: "",
                            name: routeNames.promotionsDashboard,
                            redirect: { name: routeNames.promotionsCasinoCurrent },
                        },
                        {
                            path: "casino",
                            component: PromotionsCasinoPage,
                            meta: {
                                emptyRoute: true,
                            },
                            children: [
                                {
                                    path: "",
                                    name: routeNames.promotionsCasinoCurrent,
                                    component: PromotionsCasinoCurrent,
                                },
                                {
                                    path: "actions",
                                    name: routeNames.promotionsCasinoActions,
                                    component: PromotionsCasinoActions,
                                },
                                {
                                    path: "tournaments",
                                    name: routeNames.promotionsCasinoTournaments,
                                    component: PromotionsCasinoTournaments,
                                },
                                {
                                    path: "quests",
                                    name: routeNames.promotionsCasinoQuests,
                                    component: PromotionsCasinoQuests,
                                },
                            ],
                        },
                        {
                            path: "live",
                            component: PromotionsLivePage,
                            meta: {
                                emptyRoute: true,
                            },
                            children: [
                                {
                                    path: "",
                                    name: routeNames.promotionsLiveCurrent,
                                    component: PromotionsLiveCurrent,
                                },
                                {
                                    path: "actions",
                                    name: routeNames.promotionsLiveActions,
                                    component: PromotionsLiveActions,
                                },
                                {
                                    path: "tournaments",
                                    name: routeNames.promotionsLiveTournaments,
                                    component: PromotionsLiveTournaments,
                                },
                                {
                                    path: "quests",
                                    name: routeNames.promotionsLiveQuests,
                                    component: PromotionsLiveQuests,
                                },
                            ],
                        },
                        {
                            path: "sport",
                            component: PromotionsSportPage,
                            meta: {
                                emptyRoute: true,
                            },
                            children: [
                                {
                                    path: "",
                                    name: routeNames.promotionsSportCurrent,
                                    component: PromotionsSportCurrent,
                                },
                                {
                                    path: "actions",
                                    name: routeNames.promotionsSportActions,
                                    component: PromotionsSportActions,
                                },
                                {
                                    path: "tournaments",
                                    name: routeNames.promotionsSportTournaments,
                                    component: PromotionsSportTournaments,
                                },
                                {
                                    path: "quests",
                                    name: routeNames.promotionsSportQuests,
                                    component: PromotionsSportQuests,
                                },
                            ],
                        },
                    ],
                },
                // redirects, left from arena
                {
                    path: "arena",
                    children: [
                        {
                            path: "",
                            redirect: { name: routeNames.promotionsCasinoCurrent },
                        },
                        {
                            path: "/promo",
                            redirect: { name: routeNames.promotionsCasinoActions },
                        },
                        {
                            path: "/tournaments",
                            redirect: { name: routeNames.promotionsCasinoTournaments },
                        },
                        {
                            path: "/quests",
                            redirect: { name: routeNames.promotionsCasinoQuests },
                        },
                    ],
                },
                {
                    path: "/promo/:slug",
                    redirect: { name: routeNames.promoPage },
                },
                {
                    path: "/actions/santas_gift_shop",
                    name: routeNames.santasGiftShopPromoPage,
                    component: SantasGiftShopPromoPage,
                    props: {
                        slug: "santas_gift_shop",
                    },
                },
                {
                    path: "/actions/:slug",
                    name: routeNames.promoPage,
                    component: PromoPage,
                },
                {
                    path: "/tournaments/:slug",
                    name: routeNames.tournamentPage,
                    props: true,
                    component: TournamentPage,
                },
                {
                    path: "/users",
                    component: Profile,
                    meta: { requiresAuth: true },
                    children: [
                        {
                            path: "",
                            redirect: { name: routeNames.profileCabinet },
                        },
                        {
                            path: "cabinet",
                            component: ProfileCabinet,
                            name: routeNames.profileCabinet,
                            meta: { requiresAuth: true },
                            props: true,
                        },
                        profileGiftsRoute,
                        {
                            path: "bonuses",
                            component: ProfileBonuses,
                            name: routeNames.profileBonuses,
                            meta: { requiresAuth: true },
                            children: profileBonusesRoute,
                        },
                        {
                            path: "promocode",
                            component: ProfilePromocode,
                            name: routeNames.profilePromocode,
                            meta: { requiresAuth: true },
                        },
                        {
                            path: "verification",
                            component: ProfileVerification,
                            name: routeNames.profileVerification,
                            meta: { requiresAuth: true },
                        },
                        {
                            path: "level",
                            component: LevelSystemCarousel,
                            name: routeNames.profileMyLevel,
                            meta: { requiresAuth: true },
                            props: true,
                        },
                        {
                            path: "limits",
                            component: ProfileLimits,
                            name: routeNames.profileLimits,
                            meta: { requiresAuth: true },
                        },
                    ],
                },
                {
                    path: "unsubscribe",
                    name: routeNames.unsubscribe,
                    component: Unsubscribe,
                },
                {
                    path: "sport",
                    component: BettingPage,
                    name: routeNames.bettingPage,
                    meta: {
                        logoType: "sport",
                        hideCasinoTime: true,
                    },
                },
                {
                    path: "esport",
                    component: EsportPage,
                    name: routeNames.esportPage,
                    meta: {
                        logoType: "sport",
                        hideCasinoTime: true,
                    },
                },
                {
                    path: "bonus",
                    component: BonusPage,
                    children: [
                        {
                            path: "",
                            name: routeNames.casinoBonusPage,
                            component: CasinoBonusPage,
                            meta: {
                                type: "casino",
                            },
                        },
                        {
                            path: "sport",
                            name: routeNames.sportsBonusPage,
                            component: SportsBonusPage,
                            meta: {
                                type: "sport",
                            },
                        },
                    ],
                },
                {
                    path: "vip",
                    name: routeNames.loyaltyProgramPage,
                    component: LoyaltyProgramPage,
                },
                {
                    path: "vip-club",
                    name: routeNames.vipClubPage,
                    component: VipClubPage,
                },
                {
                    path: "faq",
                    name: routeNames.faqPage,
                    component: FaqPage,
                },
                {
                    path: "verification",
                    name: routeNames.verificationPage,
                    component: VerificationPage,
                },
                {
                    path: "benefits-of-crypto",
                    name: routeNames.benefitCrypto,
                    component: BenefitsCrypto,
                },
                {
                    path: "share-your-experience",
                    name: routeNames.shareExperiencePage,
                    component: ShareExperiencePage,
                },
                {
                    path: "terms-and-conditions",
                    name: hostname && getIsLicenseDomain(hostname)
                        ? config.termsConditionsSlug.valid
                        : config.termsConditionsSlug.noValid,
                    component: StaticPage,
                },
                {
                    path: "privacy-policy",
                    name: hostname && getIsLicenseDomain(hostname)
                        ? config.privacyPolicySlug.valid
                        : config.privacyPolicySlug.noValid,
                    component: StaticPage,
                },
                {
                    path: "welcome_to_lapland",
                    name: routeNames.christmasPage,
                    component: ChristmasPage,
                },
                {
                    path: "calendar",
                    name: routeNames.calendarPage,
                    component: CalendarPage,
                },
            ],
        },
        {
            path: "/",
            component: GameBase,
            name: routeNames.gameBase,
            children: [
                {
                    path: "/play/livespins/:sessionId?",
                    component: LivespinsGamePage,
                    meta: {
                        requiresAuth: true,
                        notAllowedFor451: true,
                        demo: false,
                    },
                    name: routeNames.gameLivespins,
                    props: true,
                },
                {
                    path: "/play/:name",
                    component: Game,
                    meta: {
                        requiresAuth: true,
                        notAllowedFor451: true,
                        demo: false,
                    },
                    name: routeNames.gameItem,
                    props: true,
                },
                {
                    path: "/demo/:name",
                    component: Game,
                    meta: {
                        notAllowedFor451: true,
                        demo: true,
                    },
                    name: routeNames.gameItemDemo,
                    props: true,
                },
            ],
        },
        {
            path: "/",
            meta: {
                notAllowedFor451: true,
            },
            component: PopupBase,
            children: [
                {
                    path: "/remind",
                    component: RemindPassword,
                    name: routeNames.remind,
                    meta: {
                        requiresGuest: true,
                        popup: true,
                        notAllowedFor451: true,
                        flow: "login",
                    },
                },
                {
                    path: "/set-password",
                    component: SetPassword,
                    name: routeNames.setPassword,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        popup: true,
                        notAllowedFor451: true,
                        flow: "login",
                    },
                },
                {
                    path: "/new-password/:code",
                    component: NewPassword,
                    name: routeNames.newPassword,
                    meta: {
                        requiresGuest: true,
                        popup: true,
                        metaUrl: "/new-password",
                        notAllowedFor451: true,
                        flow: "login",
                    },
                },
                {
                    path: "/login",
                    component: Login,
                    name: routeNames.login,
                    meta: {
                        notAllowedFor451: true,
                        requiresGuest: true,
                        popup: true,
                        flow: "login",
                    },
                },
                {
                    path: "/registration",
                    component: Registration,
                    name: routeNames.registration,
                    meta: {
                        requiresGuest: true,
                        popup: true,
                        notAllowedFor451: true,
                        flow: "registration",
                    },
                },
            ],
        },
        {
            path: "/ffc",
            component: FeatureFlagConfiguration,
            name: routeNames.featureFlagConfiguration,
        },
        {
            path: "/oops",
            name: routeNames.oopsPage,
            component: ErrorEMP,
        },
    ];
}
