import { defineStore } from "pinia";
import { ref } from "vue";

import { userAPI } from "@api";

export const useFasttrack = defineStore("fasttrack", () => {
    const sid = ref<string | null>();

    async function loadUserSid() {
        const SID = await userAPI.loadFastTrackUserSID();
        sid.value = SID;
        return SID;
    }

    function cleanFasttrackData() {
        sid.value = null;

        if (typeof window !== "undefined") {
            window.sid = null;
            window.fasttrack = null;
            window.fasttrackbrand = null;
            window.FasttrackCrm?.logout();
        }
    }

    return {
        sid,
        loadUserSid,
        cleanFasttrackData,
    };
});
