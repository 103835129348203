import { COUNTRIES } from "@theme/configs/constsLocales";

const CATEGORY_SLUG = {
    slots: "slots",
    classic: "classic",
    buybonus: "buybonus",
    arcade: "arcade",
    highvolatility: "highvolatility",
    lowvolatility: "lowvolatility",
    megaways: "megaways",
    reeloffortunepls: "reeloffortunepls",
    tables: "tables",
    christmas: "christmas",
    top: "top",
    toplive: "toplive",
    newgames: "newgames",
    recommended: "recommended",
    jackpot: "jackpot",
    bonusWagering: "bonus-wagering",
    crash: "crash",
    bingo: "bingo",
    livedealers: "livedealers",

    egypt: "egypt",
    fruits: "fruits",
    top2020: "top_2020",
    top2021: "top_2021",
    books: "books",
};

export const DEFAULT_PAGE_LIMIT = 40;

export const SLUGS_WITH_GEO_INJECT = [
    {
        slug: CATEGORY_SLUG.top,
        regionInjectFor: [
            COUNTRIES.CANADA,
            COUNTRIES.BRASILIA,
        ],
    },
    {
        slug: CATEGORY_SLUG.toplive,
    },
];
