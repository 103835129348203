<script setup lang="ts">
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { iconsSocial } from "@theme/configs/iconsSocial";

interface ISocialItem {
    name: string;
    url: string;
    icon: string;
}
const list: ISocialItem[] = [
    {
        name: "facebook",
        url: "https://www.facebook.com/WinSpiritCasino",
        icon: iconsSocial.facebook,
    },
    {
        name: "instagram",
        url: "https://www.instagram.com/winspiritcasino/",
        icon: iconsSocial.instagram,
    },
    {
        name: "twitter",
        url: "https://twitter.com/WinSpiritCasino",
        icon: iconsSocial.twitter,
    },
    {
        name: "whatsapp",
        url: "https://whatsapp.com/channel/0029Va6N3i64o7qUdy7KrC0b",
        icon: iconsSocial.whatsapp,
    },
];
</script>
<template>
    <div class="footer-social">
        <div class="footer-social__label caption-1">
            <b v-t="'FOOTER.SOCIAL'" />
        </div>
        <div class="footer-social__list">
            <FeLink
                v-for="social in list"
                :key="social.name"
                :to="social.url"
                class="footer-social__item"
                rel="nofollow"
            >
                <FeIcon class="footer-social__item-icon" :icon="social.icon" />
            </FeLink>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles";

.footer-social {
    display: grid;
    gap: 1rem;

    &__list {
        display: grid;
        grid-auto-columns: min-content;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
    }

    &__item-icon {
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        color: $color-text-menu-default;

        &:hover {
            color: $color-text-menu-active;
        }
    }

}

</style>

