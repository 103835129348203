import { GetUserGiftsAvailability, GetUserGiftsSubtype, giftsAPI, type IGiftResourceV2 } from "@api";
import { BonusType } from "@controllers/services/bonuses/types";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { useUserInfo } from "@store/userInfo";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

interface IGiftsByType {
    [BonusType.CASINO]: IGiftResourceV2[];
    [BonusType.SPORT]: IGiftResourceV2[];
    [BonusType.INSURANCE]?: IGiftResourceV2[];
}

const defaultGiftsByType = (): IGiftsByType => ({
    [BonusType.CASINO]: [],
    [BonusType.SPORT]: [],
    [BonusType.INSURANCE]: [],
});

interface IUpdateGiftItem {
    giftId: string;
    bonus: number;
    bonusWagering: number;
}
interface IUpdateGift {
    [BonusType.CASINO]?: IUpdateGiftItem,
    [BonusType.SPORT]?: IUpdateGiftItem,
    [BonusType.INSURANCE]?: IUpdateGiftItem,
}

export const useGiftsStoreV2 = defineStore("giftsV2", () => {
    const userInfoStore = useUserInfo();

    const isInit = ref<boolean>(false);

    const activeGifts = ref<IGiftsByType>(defaultGiftsByType());
    const availableGifts = ref<IGiftsByType>(defaultGiftsByType());
    // will be implemented with pagination
    const historyGifts = ref<IGiftsByType>(defaultGiftsByType());

    const giftsCount = computed(() => {
        const activeCount = Object.values(activeGifts.value).reduce((acc, cur) => acc + cur.length, 0);
        const availableCount = Object.values(availableGifts.value).reduce((acc, cur) => acc + cur.length, 0);
        return activeCount + availableCount;
    });

    function request(subType: GetUserGiftsSubtype, availability: GetUserGiftsAvailability) {
        return giftsAPI.getUserGiftsV2({
            filter: {
                subType,
                availability,
            },
            pagination: {
                pageNumber: 1,
                perPage: 100,
            },
        });
    }

    async function checkLoadingGifts() {
        const isLogged = await userInfoStore.isLoggedAsync();
        return isLogged && !userInfoStore.userInfo.multi_account;
    }

    const loadHistoryGifts = promiseMemoizer(async () => {
        if (!await checkLoadingGifts()) {
            return;
        }

        const promises = [ GetUserGiftsSubtype.SPORT, GetUserGiftsSubtype.CASINO ].map((subtype) => {
            return request(subtype, GetUserGiftsAvailability.HISTORY);
        });
        const [ sport, casino ] = await Promise.all(promises);
        historyGifts.value = {
            [BonusType.CASINO]: casino.items,
            [BonusType.SPORT]: sport.items,
        };
    });

    async function updateActiveGifts(update: IUpdateGift) {
        const [ activeCasinoGift ] = activeGifts.value[BonusType.CASINO];
        const [ activeSportGift ] = activeGifts.value[BonusType.SPORT];

        const casinoUpdate = update[BonusType.CASINO];
        const sportUpdate = update[BonusType.SPORT];

        const compareGiftUpdate = (gift?: IGiftResourceV2, updateItem?: IUpdateGiftItem) => {
            if (!updateItem && !gift) {
                return true;
            }
            return updateItem && gift && updateItem.giftId === gift.id;
        };

        const activeNeedUpdate = !compareGiftUpdate(activeCasinoGift, casinoUpdate);
        const sportNeedUpdate = !compareGiftUpdate(activeCasinoGift, sportUpdate);

        if (activeNeedUpdate || sportNeedUpdate) {
            await loadData();
            return;
        }

        if (casinoUpdate && activeCasinoGift.bonus) {
            activeCasinoGift.bonus = {
                ...activeCasinoGift.bonus,
                value: casinoUpdate.bonus,
                wager: {
                    ...activeCasinoGift.bonus.wager,
                    value: casinoUpdate.bonusWagering,
                },
            };
        }

        if (sportUpdate && activeSportGift.bonus) {
            activeSportGift.bonus = {
                ...activeSportGift.bonus,
                value: sportUpdate.bonus,
                wager: {
                    ...activeSportGift.bonus.wager,
                    value: sportUpdate.bonusWagering,
                },
            };
        }
    }

    const loadData = promiseMemoizer(async () => {
        if (!await checkLoadingGifts()) {
            isInit.value = true;
            return;
        }

        const response = await giftsAPI.getAllUserGiftsV2();
        const casinoActive = response[GetUserGiftsAvailability.ACTIVE].filter((gift) => {
            return gift.subType === GetUserGiftsSubtype.CASINO;
        });
        const sportActive = response[GetUserGiftsAvailability.ACTIVE].filter((gift) => {
            return gift.subType === GetUserGiftsSubtype.SPORT;
        });

        const casinoAvailable = response[GetUserGiftsAvailability.AVAILABLE].filter((gift) => {
            return gift.subType === GetUserGiftsSubtype.CASINO;
        });
        const sportAvailable = response[GetUserGiftsAvailability.AVAILABLE].filter((gift) => {
            return gift.subType === GetUserGiftsSubtype.SPORT;
        });

        activeGifts.value = {
            [BonusType.CASINO]: casinoActive,
            [BonusType.SPORT]: sportActive,
        };

        availableGifts.value = {
            [BonusType.CASINO]: casinoAvailable,
            [BonusType.SPORT]: sportAvailable,
        };

        isInit.value = true;
    });

    async function initData() {
        if (!isInit.value && await userInfoStore.isLoggedAsync()) {
            await loadData();
        }
    }

    function resetData() {
        activeGifts.value = defaultGiftsByType();
        availableGifts.value = defaultGiftsByType();
        historyGifts.value = defaultGiftsByType();
        isInit.value = false;
    }

    return {
        availableGifts,
        activeGifts,
        historyGifts,
        giftsCount,
        isInit,

        loadData,
        initData,
        resetData,
        updateActiveGifts,
        loadHistoryGifts,
    };
});
