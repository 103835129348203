import ABTestController from "@front/core/controllers/ABTestController";

const COINS = 100;

const DEFAULT_VALUES = {
    refillAmount: 500 * COINS,
};

const CURRENCIES_TO_SIGNS = {
    RUB: "₽",
    EUR: "€",
    USD: "$",
    UAH: "грн",
    KZT: "₸",
};

const AB_TEST_POPUP_VARIANTS = {
    route: ABTestController.AB_VARIANTS.VARIANT_A,
    popup: ABTestController.AB_VARIANTS.VARIANT_B,
};

const RATES_RUB = {
    RUB: 1,
    INR: 1,
    EUR: 0.01,
    USD: 0.01,
    AUD: 0.01,
    NZD: 0.01,
    CAD: 0.01,
    KZT: 5,
};

const WITHDRAW_CANCEL_MIN_BALANCE = {
    RUB: 20000,
    INR: 20000,
    EUR: 200,
    USD: 200,
    AUD: 200,
    NZD: 200,
    CAD: 200,
    KZT: 80000,
};


const CASHBOX_DASHBOARD_STEP = "dashboard";
const CASHBOX_DEPOSIT_STEP = "deposit";
const CASHBOX_WITHDRAW_STEP = "withdraw";

export {
    DEFAULT_VALUES,
    COINS,
    CURRENCIES_TO_SIGNS,
    AB_TEST_POPUP_VARIANTS,
    RATES_RUB,
    WITHDRAW_CANCEL_MIN_BALANCE,
    CASHBOX_DASHBOARD_STEP,
    CASHBOX_DEPOSIT_STEP,
    CASHBOX_WITHDRAW_STEP,
};
