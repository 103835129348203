<script setup lang="ts">
import { computed, onMounted, ref, useSlots } from "vue";
import { replaceRecommendDomain } from "@helpers/replaceRecommendDomain";

interface IFeInputProps {
    modelValue: string | number;
    name: string
    placeholder?: string;
    mask?: string | Record<string, unknown>;
    textAlign?: string;
    type?: string;
    error?: boolean;
    disabled?: boolean;
    min?: string | number;
    max?: string | number;
    required?: boolean;
    fontBig?: boolean;
    dataTest?: string;
    hasLeftContent?: boolean;
    pattern?: string;
    autofocus?: boolean;
    step?: number;
    label?: string;
    infoLabel?: string;
    description?: string;
    descriptionCenter?: boolean;
    errorMessages?: string;
    maxlength?: number;
    autocomplete?: string;
    recommendList?: string[];
}
interface IFeInputEmits {
    (event: "update:modelValue", value: string): void
    (event: "change", value: string): void
    (event: "focus", value: FocusEvent): void
    (event: "blur", value: Event): void
    (event: "keydown", value: KeyboardEvent): void
}

const props = withDefaults(defineProps<IFeInputProps>(), {
    mask: "",
    placeholder: "",
    textAlign: "",
    type: "text",
    disabled: false,
    error: false,
    required: false,
    fontBig: false,
    hasLeftContent: false,
    autofocus: false,
    errorMessages: "",
    recommendList: () => [],
});

const emit = defineEmits<IFeInputEmits>();

const slots = useSlots();

const fieldRef = ref<HTMLInputElement | null>(null);
const focused = ref(false);
const showRecommendList = ref(false);

const classes = computed(() => ({
    "has-left-content": props.hasLeftContent || slots.left,
    "has-right-content": slots.right,
    [`fe-inputV2--text-${props.textAlign}`]: props.textAlign,
    "fe-inputV2--focused": focused.value,
    "fe-inputV2--error": props.error,
    "fe-inputV2--font-big": props.fontBig,
    "fe-inputV2--has-recommend": props.recommendList.length && showRecommendList.value,
}));
const idEl = computed(() => `label-${props.name}`);

const clickHandler = () => {
    fieldRef.value?.focus();
};
const inputHandler = (event: Event) => {
    // @ts-expect-error Property `value` does not exist on type `EventTarget`
    return emit("update:modelValue", event.target?.value);
};
const changeHandler = (event: Event) => {
    // @ts-expect-error Property `value` does not exist on type `EventTarget`
    emit("change", event.target?.value);
};
const focusHandler = (event: FocusEvent) => {
    focused.value = true;
    showRecommendList.value = true;
    emit("focus", event);
};
const blurHandler = (event: Event) => {
    focused.value = false;
    emit("blur", event);
};
const selectRecommendItem = (selectItem: string) => {
    const selectRec = replaceRecommendDomain(String(props.modelValue), selectItem);
    emit("change", selectRec);
    emit("update:modelValue", selectRec);
    showRecommendList.value = false;
};
const handleClickOutside = () => {
    showRecommendList.value = false;
};

const keydownEvent = (event: KeyboardEvent) => emit("keydown", event);

onMounted(() => {
    if (props.autofocus) {
        fieldRef.value?.focus();
    }
});

</script>

<template>
    <div v-click-outside="handleClickOutside" :class="{ 'fe-inputV2--disabled': disabled }">
        <label v-if="label" :for="idEl" class="fe-inputV2__label">
            {{ label }}
            <span v-if="required" class="fe-inputV2__label-required">*</span>
            <span v-if="infoLabel">{{ infoLabel }}</span>
        </label>
        <div class="fe-inputV2" :class="classes" @click="clickHandler">
            <div class="fe-inputV2__left">
                <slot name="left" />
            </div>
            <input
                v-bind="$attrs"
                :id="idEl"
                ref="fieldRef"
                v-mask="mask"
                :value="modelValue"
                class="fe-inputV2__input"
                :placeholder="placeholder"
                :type="type"
                :autofocus="autofocus"
                :disabled="disabled"
                :min="min"
                :max="max"
                :required="required"
                :data-test="dataTest"
                :pattern="pattern"
                :step="step"
                :maxlength="maxlength"
                :autocomplete="autocomplete"
                @focus="focusHandler"
                @blur="blurHandler"
                @input="inputHandler"
                @change="changeHandler"
                @keydown="keydownEvent"
            />
            <div v-if="recommendList.length && showRecommendList" class="fe-inputV2__recommend">
                <ul>
                    <li
                        v-for="recommendItem in recommendList"
                        :key="recommendItem"
                        class="caption-1"
                        @click.stop.capture="selectRecommendItem(recommendItem)"
                    >
                        {{ replaceRecommendDomain(String(modelValue)).trim() }}
                        <span>{{ recommendItem.trim() }}</span>
                    </li>
                </ul>
            </div>
            <div class="fe-inputV2__right">
                <slot name="right" />
            </div>

            <transition name="slide">
                <div v-if="errorMessages" class="fe-inputV2__error-message">
                    {{ errorMessages }}
                </div>
            </transition>
        </div>

        <div
            v-if="description && !errorMessages"
            class="fe-inputV2__description"
            :class="{
                'fe-inputV2__description-center': descriptionCenter,
            }"
        >
            {{ description }}
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
