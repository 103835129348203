import {
    BreakPoints,
    Extension,
    Gravity,
    IMG_QUERY_DIMENSION,
    ResizingType,
    type IConfigForTypeImage,
    type IConfigImgCompostela,
    IMG_QUERY_CROP,
} from "@theme/configs/imgResize/imageConfig";

const sizeByBreakPoint: Partial<Record<BreakPoints, IConfigImgCompostela>> = {
    [BreakPoints.BREAK_POINT_XXS]: {
        [IMG_QUERY_CROP]: "S",
        [IMG_QUERY_DIMENSION]: 320,
    },
    [BreakPoints.BREAK_POINT_L]: {
        [IMG_QUERY_CROP]: "S",
        [IMG_QUERY_DIMENSION]: 440,
    },
};

const extension: Extension = Extension.png;
const resizeType: ResizingType = ResizingType.fill;
const gravity: Gravity = Gravity.northwest;

const config: IConfigForTypeImage = {
    sizeByBreakPoint,
    extension,
    gravity,
    resizeType,
};

export default config;
