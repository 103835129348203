import { gamesAPI, type IGameProducerResource } from "@api";
import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { livespinsBaseData } from "@modules/Livespins/types";
import { useUserInfo } from "@store/userInfo";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useProducersStore = defineStore("producers", () => {
    const userInfoStore = useUserInfo();
    const { isLivespinsEnabled } = useBonusFeatureService();

    const producers = ref<IGameProducerResource[]>([]);
    const isLoaded = ref<boolean>(false);

    const initProducers = promiseMemoizer(async () => {
        if (isLoaded.value) {
            return producers.value;
        }
        const isLogged = await userInfoStore.isLoggedAsync();

        const data = await gamesAPI.loadGamesProducers([], isLogged);
        isLoaded.value = true;
        let newData = data;

        if (!isLivespinsEnabled.value) {
            newData = data.filter((producer: IGameProducerResource) => {
                return producer.slug !== livespinsBaseData.slug;
            });
        }
        producers.value = newData;
        return newData;
    });

    function clearProducers() {
        producers.value = [];
        isLoaded.value = false;
    }
    function reloadProducers() {
        clearProducers();
        return initProducers();
    }
    function getFilteredProducersByTags(tags: string[]) {
        return producers.value.filter((producer) => {
            return !tags.some((tag) => !producer.tags.includes(tag));
        });
    }

    return {
        producers,
        isLoaded,

        initProducers,
        clearProducers,
        reloadProducers,
        getFilteredProducersByTags,
    };
});
