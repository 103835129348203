import type { ComponentOptionsMixin } from "vue";
import { loadStartData, promiseAll } from "@mixins/promiseMixin";
import bus from "@bus";

export const serverPrefetchMixin: ComponentOptionsMixin = {
    beforeRouteEnter(to, from, next) {
        next(($vm) => {
            if ($vm.componentMounted) {
                const { serverPrefetch } = $vm.$options;
                if (serverPrefetch) {
                    // присваиваем операцию загрузки к Promise
                    // чтобы в компонентах мы могли делать так `this.dataPromise.then(...)`
                    // для выполнения других задач после готовности данных
                    $vm.dataPromise = serverPrefetch.call($vm);
                }
            }
        });
    },
    beforeRouteUpdate(to, from, next) {
        const { serverPrefetch } = this.$options;
        if (serverPrefetch) {
            serverPrefetch.call({ ...this, $route: to });
        }

        return next();
    },
    data() {
        return { componentMounted: false };
    },
    beforeMount() {
        const { serverPrefetch } = this.$options;
        if (serverPrefetch) {
            // присваиваем операцию загрузки к Promise
            // чтобы в компонентах мы могли делать так `this.dataPromise.then(...)`
            // для выполнения других задач после готовности данных
            this.dataPromise = serverPrefetch.call(this);
        }
    },
    mounted() {
        this.componentMounted = true;
    },
    methods: {
        promiseAll,
        loadStartData,
        formatDate(val: number) {
            const date = new Date(val * 1000);

            let DD: number | string = date.getDate();
            if (DD < 10) {
                DD = `0${DD}`;
            }

            let MM: number | string = date.getMonth() + 1;
            if (MM < 10) {
                MM = `0${MM}`;
            }

            let YY: number | string = date.getFullYear() % 100;
            if (YY < 10) {
                YY = `0${YY}`;
            }

            let hh: number | string = date.getHours();
            if (hh < 10) {
                hh = `0${hh}`;
            }

            let mm: number | string = date.getMinutes();
            if (mm < 10) {
                mm = `0${mm}`;
            }

            let ss: number | string = date.getSeconds();
            if (ss < 10) {
                ss = `0${ss}`;
            }

            return `${DD}.${MM}.${YY}, ${hh}:${mm}:${ss}`;
        },
        popupClose() {
            bus.$emit("popup.close");
        },
    },
};
