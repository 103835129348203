import type { IUserNotificationResource } from "@api";
import { useUserInfo } from "@store/userInfo";
import { useMultiLang } from "@store/multilang";

function restrictedNotice(countryArray: string[]) {
    const userInfoStore = useUserInfo();
    const multiLangStore = useMultiLang();
    const country = userInfoStore.userCountryCode;

    return !countryArray.includes(multiLangStore.userGeo) && !countryArray.includes(country);
}

function filterNoticeArray(noticeArr: IUserNotificationResource[]) {
    return noticeArr.filter((elem) => {
        return restrictedNotice(elem.restricted_countries);
    });
}

export {
    filterNoticeArray,
    restrictedNotice,
};
