import { MODAL_GAME_COOLING_OFF } from "@config/modalNames";
import { useModalsStore } from "@store/modals";

const gameCoolingOffPopup = () => {
    return import("../components/GameCoolingOffPopup.vue");
};

export function useShowGameCoolingOffPopup() {
    const modalsStore = useModalsStore();

    async function showGameCoolingOffPopup(mainPageButton?: boolean) {
        const module = await gameCoolingOffPopup();
        modalsStore.showModal({
            new: true,
            component: module.default,
            disableBackdropClosing: mainPageButton,
            name: MODAL_GAME_COOLING_OFF,
            showFirst: true,
            props: {
                mainPageButton,
            },
        });
    }

    return {
        showGameCoolingOffPopup,
    };
}
