<script setup lang="ts">
import { computed, ref } from "vue";
import type { IModalOld } from "@store/modals";
import { useBodyScrollLock } from "@helpers/bodyScrollLock";

import { FeIconConfig } from "@theme/configs/icons";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { useModalAnalytics } from "@modules/ModalController/analytics";

interface IProps {
    data: IModalOld;
}
const props = defineProps<IProps>();

interface IEmits {
    (event: "close", value: boolean): void;
}
const emit = defineEmits<IEmits>();

useModalAnalytics(() => props.data.analyticsParams);

const modalWrapperRef = ref<HTMLElement | null>(null);
useBodyScrollLock(modalWrapperRef);

const classes = computed(() => {
    const { mobileFriendly = false, fullHeight = false, minHeight = true, alwaysBottom = false } = props.data;

    return {
        "modal__wrapper--mobile-friendly": mobileFriendly,
        "modal__wrapper--full-height": fullHeight,
        "modal__wrapper--min-height": minHeight,
        "modal__wrapper--always-bottom": alwaysBottom,
    };
});

function closeHandler({ immediate } = { immediate: false }) {
    emit("close", immediate);
    if (props.data.callback) {
        props.data.callback();
    }
}
</script>

<template>
    <!-- @vue-expect-error incorrect types -->
    <div
        ref="modalWrapperRef"
        class="modal__wrapper modal__wrapper--scroll"
        :class="classes"
        @click.self.stop.prevent="closeHandler"
    >
        <div class="modal__container">
            <slot :close-handler="closeHandler">
                <div class="modal__body">
                    default body
                </div>
            </slot>
            <slot name="close" :close-handler="closeHandler">
                <!-- @vue-expect-error incorrect types -->
                <div class="modal__close" @click="closeHandler">
                    <FeIcon :icon="FeIconConfig.close" class="modal__close-btn" />
                </div>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
