import { livespinsBaseData } from "@modules/Livespins/types";
import { computed, type Ref } from "vue";
import type { RouteLocationRaw } from "vue-router";
import type { IGameCategoryResource, IGameProducerResource } from "@api/resources/gameResources";
import { PAGE_TYPE, THEME_TAG } from "@config/mainPages";
import { useParserContent } from "@helpers/ParserContent";
import { routeNames } from "@router/routeNames";
import { useCategoriesStore } from "@store/categories";
import { useSeoMetaStore } from "@store/seoMeta";
import { useSlugGeoInject } from "@controllers/services/slugs";

export function useCategoryRouter() {
    const { getOriginalSlug } = useSlugGeoInject();

    function getCategoryRouter(category: IGameCategoryResource): RouteLocationRaw {
        const tagsByName: Record<string, string> = {};

        category.sortableTags.forEach((tag) => {
            tagsByName[tag.name] = tag.id;
        });

        const isCasinoCategory = Boolean(tagsByName[PAGE_TYPE.CASINO]);
        const isLiveCategory = Boolean(tagsByName[PAGE_TYPE.LIVE]);
        const isThemeCategory = isCasinoCategory && tagsByName[THEME_TAG];

        const name =
            (isLiveCategory && routeNames.liveCategory) ||
            (isThemeCategory && routeNames.casinoTheme) ||
            routeNames.casinoCategory;

        return {
            name,
            params: { slug: getOriginalSlug(category.slug) },
        };
    }

    return {
        getCategoryRouter,
    };
}

export function getProviderRouter(provider: IGameProducerResource): RouteLocationRaw {
    const isLiveProvider = provider.tags.includes(PAGE_TYPE.LIVE);
    const isLivespinsProvider = provider.slug === livespinsBaseData.slug;
    let name = routeNames.casinoProvider;

    if (isLiveProvider) {
        name = routeNames.liveProvider;
    } else if (isLivespinsProvider) {
        name = routeNames.casinoLivespins;
    }

    return {
        name,
        params: { slug: provider.slug },
    };
}

type CategoryLike = IGameCategoryResource | IGameProducerResource;
export function useCategorySeo(category: Ref<CategoryLike | undefined>) {
    const { parseContent } = useParserContent();
    const { setSeoMeta } = useSeoMetaStore();

    const seoText = computed(() => {
        const categoryValue = category.value;
        if (categoryValue) {
            return parseContent(categoryValue.description, categoryValue?.localisation?.variables);
        }
        return "";
    });

    function updatePageSeo(path: string) {
        const categoryValue = category.value;
        if (categoryValue) {
            const { description, title, keywords } = categoryValue.meta;
            setSeoMeta({
                url: path,
                meta: {
                    metaTitle: title,
                    metaDescription: description,
                    metaKeywords: keywords,
                },
            });
        }
    }

    return {
        seoText,
        updatePageSeo,
    };
}

export function getCategoryNameBySlug(slug: string): string {
    const categoriesStore = useCategoriesStore();
    const categories = categoriesStore.categories;

    const category = categories.find((categoryObj) => {
        return categoryObj.slug === slug;
    });

    return category?.name ?? "";
}
