import { useModalsStore } from "@store/modals";

const emailVerificationPopupModule = async() => {
    return import("../components/EmailVerificationPopup.vue");
};

export type ShowEmailVerificationPopupParams = {
    title?: string;
}
export function useEmailVerificationPopup() {
    const modalsStore = useModalsStore();

    async function showEmailVerificationPopup(params?: ShowEmailVerificationPopupParams) {
        const moduleComponent = await emailVerificationPopupModule();

        modalsStore.showModal({
            name: "modal-email-sent",
            component: moduleComponent.default,
            props: {
                title: params?.title,
            },
            new: true,
        });
    }

    return {
        showEmailVerificationPopup,
    };
}
