import bus from "@bus";
import { MODAL_CASHBOX_LIST } from "@helpers/CashboxHelper";
import { authAPI } from "@api";
import { routeNames } from "@router/routeNames";
import { useContextStore } from "@store/context";
import { useModalsStore } from "@store/modals";
import { useUserInfo } from "@store/userInfo";
import { useRoute } from "vue-router";
import { onBeforeUnmount, onMounted } from "vue";
import log from "@front/core/controllers/LoggerController";
import { AFFL_TOKEN_KEY, getAfflToken } from "@controllers/services/cookies/afflToken";
import { TimerTrigger, TimerTriggerItem } from "@controllers/triggers/TimerTrigger";
import { DELAYS, COOKIE_KEYS } from "./config";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { RouteTransitionTrigger } from "@controllers/triggers";
import cookie from "@front/core/controllers/CookieController";
import { MODAL_ANDROID_WEBVIEW_ESCAPE, MODAL_IOS_WEBVIEW_ESCAPE } from "@config/modalNames";

const webviewIOSEscapeComponent = () => {
    return import("@modules/Popups/WebviewIOSEscape/index.vue");
};
const webviewAndroidEscapeComponent = () => {
    return import("@modules/Popups/WebviewAndroidEscape/WebviewAndroidEscape.vue");
};

function useUrlAdditionalParams() {
    function getAdditionalUrlParams(isIOS = false) {
        const params = new URLSearchParams({
            utm_source: "facebook",
            utm_medium: "popup",
            utm_campaign: isIOS ? "ios_meta_to_safari" : "android_meta_to_safari",
            utm_content: "link",
        });

        const token = getAfflToken();
        if (token) {
            params.append(AFFL_TOKEN_KEY, token);
        }

        return params;
    }

    return {
        getAdditionalUrlParams,
    };
}

function useAndroidToChromeLink() {
    const userInfoStore = useUserInfo();
    const { getAdditionalUrlParams } = useUrlAdditionalParams();

    function wrapChromeRedirectUrl(url: string) {
        return `intent://${ url }#Intent;scheme=https;package=com.android.chrome;end`;
    }

    async function getAndroidToChromeLink() {
        const { hostname, pathname } = window.location;
        const params = getAdditionalUrlParams(false);

        let url = `${hostname}${pathname}?${params.toString()}`;
        if (await userInfoStore.isLoggedAsync()) {
            try {
                const autologinUrl = await authAPI.getAutologinUrl(`${pathname}?${params.toString()}`);
                const autologinUrlObject = new URL(autologinUrl);
                url = `${autologinUrlObject.hostname}${autologinUrlObject.pathname}${autologinUrlObject.search}`;
            } catch (err) {
                log.error("ANDROID_ESCAPE_PWA_AUTOLOGIN_URL", err);
            }
        }

        return wrapChromeRedirectUrl(url);
    }

    return {
        getAndroidToChromeLink,
    };
}


export function useEscapePopupStrategy() {
    const userInfoStore = useUserInfo();
    const route = useRoute();
    const contextStore = useContextStore();
    const { getAndroidToChromeLink } = useAndroidToChromeLink();
    const modalsStore = useModalsStore();
    const { getAdditionalUrlParams } = useUrlAdditionalParams();

    const timerTrigger = TimerTrigger.use();
    const routeTransitionTrigger = RouteTransitionTrigger.use();

    onMounted(async() => {
        const context = await contextStore.getContext();

        if (!context.isMetaWebview) {
            return;
        }

        const triggerRoutes = [ routeNames.registration, routeNames.login ];
        routeTransitionTrigger.on(showPopup, {
            to: triggerRoutes,
        });
        timerTrigger.on(showPopup, {
            name: COOKIE_KEYS.LAST_TIME_SHOWED,
            time: DELAYS.BETWEEN_SHOWS,
            immediate: true,
        });
        if (typeof route.name === "string" && triggerRoutes.includes(route.name)) {
            showPopup();
        }
        bus.$on("user.register", userRegistrationHandler);
    });
    onBeforeUnmount(() => {
        bus.$off("user.register", userRegistrationHandler);
        routeTransitionTrigger.off(showPopup);
        timerTrigger.off(showPopup);
    });

    function userRegistrationShowPopup() {
        showPopup();
        timerTrigger.off(userRegistrationShowPopup);
    }
    function userRegistrationHandler() {
        timerTrigger.off(userRegistrationShowPopup);
        TimerTriggerItem.clearCookieByName(COOKIE_KEYS.USER_REGISTRATION_TIME);
        timerTrigger.on(userRegistrationShowPopup, {
            name: COOKIE_KEYS.USER_REGISTRATION_TIME,
            replaysCount: 1,
            time: DELAYS.AFTER_REGISTRATION_SHOW,
        });
    }

    const showPopup = promiseMemoizer<void>(() => {
        return new Promise((resolve) => {
            const recursiveShowPopupCondition = () => {
                const modals = modalsStore.modals;
                const cashboxOpen = modals.some((modal) => MODAL_CASHBOX_LIST.includes(modal.name));
                if (!cashboxOpen && route.name !== routeNames.gameItem) {
                    showEscapePopup();
                    resolve();
                } else {
                    // repeat try to show escape popup if open condition failed
                    setTimeout(recursiveShowPopupCondition, DELAYS.BETWEEN_TRY_TO_SHOW_POPUP);
                }
            };
            recursiveShowPopupCondition();
        });
    });

    async function showIOSEscapePopup() {
        const module = await webviewIOSEscapeComponent();

        const params = getAdditionalUrlParams(true);
        const existedParams = (new URL(location.href)).searchParams;
        existedParams.forEach((value, key) => {
            if (!params.has(key)) {
                params.append(key, value);
            }
        });

        let url = `?${params.toString()}`;
        if (await userInfoStore.isLoggedAsync()) {
            try {
                url = await authAPI.getAutologinUrl(url);
            } catch (err) {
                log.error("ESCAPE_PWA_AUTOLOGIN_URL", err);
            }
        }

        modalsStore.showModal({
            name: MODAL_IOS_WEBVIEW_ESCAPE,
            props: {
                url,
            },
            component: module.default,
            new: true,
        });
    }

    async function showAndroidEscapePopup() {
        const module = await webviewAndroidEscapeComponent();
        const url = await getAndroidToChromeLink();

        modalsStore.showModal({
            name: MODAL_ANDROID_WEBVIEW_ESCAPE,
            props: {
                url,
            },
            component: module.default,
            new: true,
        });
    }

    async function showEscapePopup() {
        const context = await contextStore.getContext();
        if (context.isIOS) {
            await showIOSEscapePopup();
        } else {
            await showAndroidEscapePopup();
        }
    }
}

export function useImmediateAndroidEscape() {
    const contextStore = useContextStore();
    const { getAndroidToChromeLink } = useAndroidToChromeLink();

    onMounted(async () => {
        const escapeTryFlag = Boolean(cookie.get(COOKIE_KEYS.ANDROID_WEBVIEW_AUTO_ESCAPE_TRY));
        if (escapeTryFlag) {
            return;
        }

        const context = await contextStore.getContext();

        if (!context.isMetaWebview || context.isIOS) {
            return;
        }

        const redirectUrl = await getAndroidToChromeLink();
        cookie.set(COOKIE_KEYS.ANDROID_WEBVIEW_AUTO_ESCAPE_TRY, "1", {
            expires: DELAYS.COOKIE_FORGET_DELAY,
            path: "/",
        });

        window.location.assign(redirectUrl);
    });
}

export function useWebviewEscapeStrategy() {
    useEscapePopupStrategy();
    useImmediateAndroidEscape();
}
