import type { Pinia } from "pinia";
import bus from "@bus";
import log from "@front/core/controllers/LoggerController";
import { useUserInfo } from "@store/userInfo";
import { useServerStore } from "@store/server";
import { initFirebase } from "./firebase";

export function createServiceWorker(pinia?: Pinia) {
    const userInfoStore = useUserInfo(pinia);
    const serverStore = useServerStore(pinia);
    function getWebPushKey() {
        return serverStore.webPushKey;
    }
    function getUserInfo() {
        return userInfoStore.userInfo;
    }

    function registerFetchWorker() {
        const swProdURL = `/sw.js?${Math.random().toString().replace(".", "")}`;
        const swDevURL = "/dev-sw.js?dev-sw";
        const scriptUrl = `${import.meta.env.MODE === "production" ? swProdURL : swDevURL}`;
        const options: RegistrationOptions = { type: import.meta.env.MODE === "production" ? "classic" : "module" };

        navigator.serviceWorker.register(scriptUrl, options)
            .then(async (sw) => {
                log.info("SW: Registration succeeded.");

                await initFirebase(sw);
            }).catch((error: unknown) => {
                log.error(`SW: Registration failed with ${ error }`);
            });
    }

    function registerPushWorker() {
        const { user_id: userId } = getUserInfo();
        if (!userId) {
            registerFetchWorker();
        }
    }

    function init(isBot?: boolean) {
        if (isBot) {
            return;
        }

        if (getWebPushKey()) {
            registerPushWorker();
        } else if ("serviceWorker" in navigator) {
            registerFetchWorker();
        }

        bus.$on("user.data.received", registerPushWorker);
    }
    function destroy() {
        bus.$off("user.data.received", registerPushWorker);
    }

    return {
        init,
        destroy,
    };
}
