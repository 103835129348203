import cookie from "@front/core/controllers/CookieController";
import { WITHDRAW_CANCEL_MIN_BALANCE } from "@config/cashbox";
import { useCashbox } from "@store/cashbox";
import { useUserBalance } from "@store/userBalance";
import { useModalsStore } from "@store/modals";
import type { BalanceChangedData } from "@api/websockets";

const withdrawCancelModule = () => {
    return import("@modules/Popups/WithdrawCancel/WithdrawCancel.vue");
};

export const withdrawCancelPopup = (data: BalanceChangedData) => {
    const wdCookie = cookie.get("wdCancel");
    const isRoundBet = data.reason === "eapi.roundbet";
    const cashboxStore = useCashbox();
    const userBalanceStore = useUserBalance();
    const modalsStore = useModalsStore();

    if (!wdCookie && isRoundBet) {
        const withdrawCancelMinBalance = (WITHDRAW_CANCEL_MIN_BALANCE as Record<string, number>)[userBalanceStore.userCurrency];
        const isBalanceLessWithdrawCancel = data.balance <= withdrawCancelMinBalance;

        if (isBalanceLessWithdrawCancel) {
            // @ts-expect-error data.changes[0 type of unknown
            const isBalanceLessLastBet = (data.balance + data.changes[0].real) < 0;

            if (isBalanceLessLastBet && cashboxStore.withdrawRequests.length > 0) {
                cookie.set("wdCancel", "true", { expires: 300 });

                withdrawCancelModule().then((module) => {
                    modalsStore.showModal({
                        name: "modal-withdraw-cancel",
                        component: module.default,
                        mobileFriendly: true,
                    });
                });
            }
        }
    }
};
