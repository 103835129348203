<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import FeImage from "@ui-kit/FeImage/index.vue";
import { useCurrentGame, LIVESPINS_LOBBY_ID } from "@store/currentGame";
import {
    LIVESPINS_GAME_FRAME_ID,
    livespinsBaseData,
    useLivespinsGameStart,
    useLivespinsGameStreamTracker,
    useLivespinsStore,
} from "@modules/Livespins";
import GameFrameBase from "./GameFrameBase.vue";
import LivespinsMinimizeImage from "@modules/Livespins/images/livespins-game-minimize.jpg";

const router = useRouter();
const currentGameStore = useCurrentGame();
const livespinsStore = useLivespinsStore();

const currentSessionId = computed(() => {
    return currentGameStore.livespinsSession === LIVESPINS_LOBBY_ID
        ? undefined
        : currentGameStore.livespinsSession;
});

useLivespinsGameStreamTracker();
const { gameUrl } = useLivespinsGameStart(currentSessionId.value);

// must be only ref, not computed, because stream from store can be erased during runtime
const previewImage = ref<string | null>(null);

function closeGame() {
    currentGameStore.clear();
}

function findCurrentStreamThumbnail() {
    if (!currentSessionId.value) {
        return null;
    }
    const currentStream = livespinsStore.streamsLive.find((stream) => {
        return stream.sessionId === currentSessionId.value;
    });
    if (currentStream) {
        return currentStream.thumbnail;
    }
    return null;
}

watch(gameUrl, () => {
    previewImage.value = findCurrentStreamThumbnail();
}, {
    immediate: true,
});
watch(() => livespinsStore.streamsLive, () => {
    if (!previewImage.value) {
        previewImage.value = findCurrentStreamThumbnail();
    }
}, {
    deep: true,
});

function resumePlay() {
    router.push({
        name: routeNames.gameLivespins,
        params: {
            sessionId: currentSessionId.value,
        },
    });
}
</script>

<template>
    <GameFrameBase
        :game-name="livespinsBaseData.title"
        @resume="resumePlay"
        @close="closeGame"
    >
        <template #default="{ minimize }">
            <div
                class="livespins-frame__wrapper"
                :class="{ 'livespins-frame__wrapper-minimize': minimize }"
            >
                <iframe
                    :id="LIVESPINS_GAME_FRAME_ID"
                    class="livespins-frame__iframe"
                    allow="clipboard-read;clipboard-write;autoplay;fullscreen;"
                    data-test="livespins-iframe"
                    :src="gameUrl"
                />
                <template v-if="minimize">
                    <FeImage
                        :src="previewImage || LivespinsMinimizeImage"
                        class="livespins-frame__preview-image"
                        alt="game-preview"
                    />
                </template>
            </div>
        </template>
    </GameFrameBase>
</template>

<style scoped lang="scss">
.livespins-frame {
    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__iframe {
        pointer-events: all;
        width: 100%;
        height: 100%;
        border: none;
    }
    &__preview-image {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
    }
}
</style>
