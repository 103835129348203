import restrictNoFilledUser from "@helpers/restrictNoFilledUser";
import { ref } from "vue";
const SHOW_DATA_FILLING_MODAL = "showDataFillingModal";
const FILLED_USED_DATA = "filledUserData";
const expireTime = 15 * 60 * 1000;

export function useDataFillingForm() {
    const showModal = ref(false);

    function setShowDataFillingModal(leftPeriod?: number) {
        if (typeof localStorage !== "undefined") {
            showModal.value = true;
            const period: number = leftPeriod || expireTime;

            if (!leftPeriod) {
                const expiresTime: Date = new Date(new Date().getTime() + period);

                localStorage.setItem(SHOW_DATA_FILLING_MODAL, JSON.stringify(expiresTime));
            }
        }
    }

    function cleanShowDataFillingModal() {
        if (typeof localStorage !== "undefined") {
            showModal.value = false;
            localStorage.removeItem(SHOW_DATA_FILLING_MODAL);
        }
    }

    function setUserDataToStorage(data: Record<string, unknown>) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(FILLED_USED_DATA, JSON.stringify(data));
        }
    }
    function getUserDataToStorage(): Record<string, unknown> | null {
        if (typeof localStorage !== "undefined") {
            const data: string | null = localStorage.getItem(FILLED_USED_DATA);
            if (data) {
                return JSON.parse(data);
            }
        }
        return null;
    }

    function cleanUserDataToStorage() {
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem(FILLED_USED_DATA);
        }
    }

    async function initUserDataFillingModal() {
        if (typeof localStorage !== "undefined") {
            const isShowModal = localStorage.getItem(SHOW_DATA_FILLING_MODAL);

            if (isShowModal) {
                const expires = JSON.parse(isShowModal);
                const validExpires = new Date(expires).getTime() > new Date().getTime();

                if (!validExpires) {
                    cleanShowDataFillingModal();
                    cleanUserDataToStorage();
                } else {
                    const leftPeriod = new Date(expires).getTime() - new Date().getTime();

                    setShowDataFillingModal(leftPeriod);
                    await restrictNoFilledUser();
                }
            }
        }
    }

    return {
        cleanShowDataFillingModal,
        setShowDataFillingModal,
        setUserDataToStorage,
        getUserDataToStorage,
        cleanUserDataToStorage,

        initUserDataFillingModal,
    };
}
