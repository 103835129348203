<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, computed } from "vue";
import Popup from "@components/Popup/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { routeNames } from "@router/routeNames";
import { iconsNavigation } from "@theme/configs/iconsNavigation";
import { useI18n } from "vue-i18n";
import { useGlobalEventStore } from "@modules/GlobalEvent";

const i18n = useI18n();
const globalEventStore = useGlobalEventStore();

const headerContainer = ref<HTMLElement | null>(null);
const hiddenItems = ref<string[]>([]);

interface INavItem {
    id: string;
    title: string;
    routeName?: string;
    soon?: boolean;
    hide?: boolean;
    seasonal?: boolean;
    externalUrl?: string;
    badge?: string;
    to?:string;
    url?: string;
}

const navList = computed<INavItem[]>(() => {
    return [
        {
            id: routeNames.casinoPage,
            title: i18n.t("HEADER.CASINO"),
            routeName: routeNames.casinoPage,
        },
        {
            id: routeNames.livePage,
            title: i18n.t("HEADER.LIVE"),
            routeName: routeNames.livePage,
        },
        {
            id: routeNames.bettingPage,
            title: i18n.t("HEADER.BETS"),
            routeName: routeNames.bettingPage,
        },
        {
            id: routeNames.esportPage,
            title: i18n.t("HEADER.ESPORT"),
            routeName: routeNames.esportPage,
        },
        {
            id: routeNames.promotionsDashboard,
            title: i18n.t("HEADER.PROMOTIONS"),
            routeName: routeNames.promotionsDashboard,
        },
        {
            id: routeNames.christmasPage,
            title: i18n.t("GLOBAL_EVENT.SANTA_LAPLAND_LINK"),
            routeName: routeNames.christmasPage,
            hide: !globalEventStore.isActive,
            seasonal: true,
        },
        {
            id: routeNames.vipClubPage,
            title: i18n.t("HEADER.VIP"),
            routeName: routeNames.vipClubPage,
        },
        {
            id: routeNames.casinoBonusPage,
            title: i18n.t("HEADER.BONUSES"),
            routeName: routeNames.casinoBonusPage,
        },
    ];
});


const dropDownLinks = computed<INavItem[]>(() => navList.value.filter((item) => hiddenItems.value.includes(item.id)));

const firstSoonLink = computed<INavItem | undefined>(() => dropDownLinks.value.find((item) => item.soon));

let observer: IntersectionObserver | null = null;

function setObserve() {
    if (!headerContainer.value) {
        return;
    }
    const options: IntersectionObserverInit = {
        root: headerContainer.value,
        rootMargin: "0px",
        threshold: 1,
    };

    const callback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            const entriesKey = entry.target.getAttribute("data-key-item");

            if (!entry.isIntersecting) {
                hiddenItems.value.push(entriesKey || "");
            } else {
                hiddenItems.value = hiddenItems.value.filter((id) => id !== entriesKey);
            }
        });
    };

    observer = new IntersectionObserver(callback, options);

    navList.value.forEach(({ id }) => {
        const element = document.querySelector(`.nav-item-${id}`);
        if (element) {
            element.setAttribute("data-key-item", id);
            observer?.observe(element);
        }
    });
}

const itemClasses = (item: INavItem) => {
    return {
        [`nav-item-${ item.id}`]: true,
        "nav-item--seasonal": item.seasonal,
        "item-hidden": hiddenItems.value.includes(item.id),
    };
};
const disconnectObserver = () => {
    observer?.disconnect();
    observer = null;
};

onMounted(setObserve);
onBeforeUnmount(disconnectObserver);

watch(navList, () => {
    disconnectObserver();
    setObserve();
});

</script>
<template>
    <div class="header__nav__wrapper">
        <div
            ref="headerContainer"
            class="header__nav"
            gap-m
        >
            <template v-for="(item, index) in navList" :key="index">
                <FeLink v-if="item.externalUrl" :to="item.externalUrl" class="header__nav-item">
                    {{ item.title }}
                    <span
                        v-if="item.badge"
                        class="header__nav-item__badge"
                        v-text="item.badge"
                    />
                </FeLink>
                <FeLink
                    v-else-if="!item.hide"
                    :key="index + 'else'"
                    :to="item.to || item.url || { name: item.routeName }"
                    class="header__nav-item"
                    :class="itemClasses(item)"
                >
                    {{ item.title }}
                </FeLink>
            </template>

            <Popup
                v-if="dropDownLinks.length"
                activator-fixed
                width-auto
                bottom
            >
                <template #activator>
                    <FeLink class="header__nav-item header__nav-item-more-button">
                        <FeIcon class="header__bottom-nav-icon" :icon="iconsNavigation.more" />
                    </FeLink>
                </template>
                <div class="header__nav__dropdown">
                    <template v-for="(item, index) in dropDownLinks" :key="index">
                        <FeLink
                            v-if="item.externalUrl"
                            :to="item.externalUrl"
                            class="header__nav-item header__nav__dropdown-item"
                        >
                            {{ item.title }}
                            <span
                                v-if="item.badge"
                                class="header__nav-item__badge"
                                v-text="item.badge"
                            />
                        </FeLink>
                        <div v-else-if="item === firstSoonLink" :key="index + '-label'" class="header__nav-item--soon-label">
                            {{ $t("HEADER.SOON") }}
                        </div>
                        <FeLink
                            v-else-if="!item.hide"
                            :key="index + 'else'"
                            class="header__nav-item header__nav__dropdown-item"
                            :to="item.to || item.url || { name: item.routeName }"
                        >
                            {{ item.title }}
                            <span
                                v-if="item.badge"
                                class="header__nav-item__badge"
                                v-text="item.badge"
                            />
                        </FeLink>
                    </template>
                </div>
            </Popup>
        </div>
    </div>
</template>
<style scoped lang="scss">
@import "~@theme/styles";
.header__nav {
    box-sizing: border-box;
    width: 100%;

    display: flex;
    grid-auto-flow: column;
    gap: 0;

    &__wrapper {
        height: 100%;
        max-width: min-content;
        display: none;

        @include media(L) {
            display: flex;
        }
    }

    &-item {
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        transition: color .2s ease;
        cursor: pointer;
        padding: 0;
        margin: 0 1.5rem 0 0;
        white-space: nowrap;

        @include menu-hover;
        @include font-size--Caption1;

        &.nav-item--seasonal {
            @include menu-hover;
            color: $color-secondary-5;
        }

        &.item-hidden {
            visibility: hidden;
            order: 999;
        }

        &--soon {
            opacity: .5;

            &-label {
                opacity: 1;
                font-weight: $font-weight--bold;
            }
        }

        &__badge {
            margin-left: .25rem;
        }
    }

    &__dropdown {
        padding: 0.75rem;
        border-radius: 0 0 $border-radius-S $border-radius-S;
        background: $header--background;

        &-item {
            margin: 0 0 0.75rem 0;
            @include menu-hover;

            &:last-child {
                margin-bottom: 0;
            }

            &.active,
            &:hover {
                &:after {
                    display: none;
                }
            }
        }

    }

    :deep(.fe-popup) {
        display: flex;
        justify-content: center;
    }
}

</style>
