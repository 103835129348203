import { useGiftsStoreV2 } from "@store/giftsV2";
import { computed, onMounted, onUnmounted } from "vue";
import { BonusType } from "@controllers/services/bonuses/types";
import { useConvertToClient } from "@controllers/services/bonuses/dataAdapter";
import { type BalanceChangedData, SocketEvent, websocketEmitter } from "@api/websockets";

export function useActiveBonuses() {
    const giftsStore = useGiftsStoreV2();
    const { convertToClient } = useConvertToClient();

    const activeBonuses = computed(() => {
        const [ casinoGift ] = giftsStore.activeGifts[BonusType.CASINO];
        const [ sportGift ] = giftsStore.activeGifts[BonusType.SPORT];

        return [ casinoGift, sportGift ].filter(Boolean).map(convertToClient);
    });

    return {
        activeBonuses,
    };
}

export function useSyncActiveBonuses() {
    const giftsStore = useGiftsStoreV2();

    function updateActiveBonuses(data: BalanceChangedData) {
        if (data.bonusesWagering) {
            const { sport, casino } = data.bonusesWagering;

            giftsStore.updateActiveGifts({
                [BonusType.CASINO]: casino ? {
                    bonusWagering: casino.sumWagering,
                    bonus: casino.sum,
                    giftId: casino.userGiftId,
                } : undefined,
                [BonusType.SPORT]: sport ? {
                    bonusWagering: sport.sumWagering,
                    bonus: sport.sum,
                    giftId: sport.userGiftId,
                } : undefined,
            });
        }
    }

    onMounted(() => {
        websocketEmitter.on(SocketEvent.BALANCE_CHANGED, updateActiveBonuses);
    });
    onUnmounted(() => {
        websocketEmitter.off(SocketEvent.BALANCE_CHANGED, updateActiveBonuses);
    });
}
