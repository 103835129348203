import { gamesAPI, type IGameCategoryResource } from "@api";
import { defineStore } from "pinia";
import { ref } from "vue";
import { promiseMemoizer } from "@helpers/promiseHelpers";

export const useCategoriesStore = defineStore("categories", () => {
    const categories = ref<IGameCategoryResource[]>([]);
    const isLoaded = ref<boolean>(false);

    const initCategories = promiseMemoizer(async() => {
        if (isLoaded.value) {
            return categories.value;
        }

        const data = await gamesAPI.loadGameCategoryList();
        isLoaded.value = true;
        categories.value = data;
        return data;
    });

    function clearCategories() {
        categories.value = [];
        isLoaded.value = false;
    }
    function reloadCategories() {
        clearCategories();
        return initCategories();
    }

    return {
        categories,
        isLoaded,

        initCategories,
        clearCategories,
        reloadCategories,
    };
});
