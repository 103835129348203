import { defineStore } from "pinia";
import { ref } from "vue";

export const usePWA = defineStore("pwa", () => {
    const deferredPWAPrompt = ref<BeforeInstallPromptEvent | null>(null);
    const showPwaInfo = ref(true);

    const setDeferredPWAPrompt = (event: BeforeInstallPromptEvent) => {
        deferredPWAPrompt.value = event;
    };
    const setShowPwaInfo = (value: boolean) => {
        showPwaInfo.value = value;
    };

    return {
        deferredPWAPrompt,
        showPwaInfo,
        setDeferredPWAPrompt,
        setShowPwaInfo,
    };
});
