import type { Pinia } from "pinia";
import type { EventBus } from "@bus";
import log from "@front/core/controllers/LoggerController";
import { useUserInfo } from "@store/userInfo";
import { isServer } from "@helpers/ssrHelpers";
import { useWebsocketStatusStore } from "@store/websocketStatusStore";

const DEFAULT_TIME_RECONNECT = 1000;
const MAX_TIME_RECONNECT = 60000;
const RECONNECTION_TIME_MULTIPLIER = 2;

let sock: WebSocket | null = null,
    $pinia: Pinia | null = null,
    $bus: EventBus | null = null;

let reconnectTime = DEFAULT_TIME_RECONNECT;

function init(pinia: Pinia, bus: EventBus) {
    if (!bus || !pinia) {
        return;
    }

    $pinia = pinia;
    $bus = bus;
}

function makeid(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result = result + characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function sockStopHandler() {
    sock?.close();
    setTimeout(start, reconnectTime);
    reconnectTime = reconnectTime * RECONNECTION_TIME_MULTIPLIER;
    if (reconnectTime > MAX_TIME_RECONNECT) {
        reconnectTime = DEFAULT_TIME_RECONNECT;
    }
}

function stop() {
    if (sock) {
        sock.removeEventListener("close", sockStopHandler);
        sock.close();
    }
}

async function start() {
    const userInfoStore = useUserInfo($pinia);
    const websocketStatusStore = useWebsocketStatusStore($pinia);

    if (!(await userInfoStore.isLoggedAsync()) || isServer) {
        return;
    }

    stop();
    const host = location.hostname;
    const wsUrl = $pinia?.state.value.server.websocketUrl;
    const server = Math.floor(Math.random() * 100);
    const sessionId = makeid(8);
    sock = new WebSocket(DEV
        ? `ws://${ host }:3000${ wsUrl }/${ server }/${ sessionId }/websocket`
        : `wss://${ host }${ wsUrl }/${ server }/${ sessionId }/websocket`,
    );

    sock.addEventListener("open", () => {
        reconnectTime = DEFAULT_TIME_RECONNECT;
        websocketStatusStore.setConnected(true);

        sock?.send(JSON.stringify([
            JSON.stringify({
                type: "register",
                user: userInfoStore.userInfo.hash,
            }),
        ]));
    });

    sock.addEventListener("close", sockStopHandler);
    sock.addEventListener("close", () => {
        websocketStatusStore.setConnected(false);
    });

    sock.addEventListener("message", ({ data }) => {
        const type = data.slice(0, 1);
        const content = data.slice(1);
        let json = undefined;
        let payload = undefined;

        if (content) {
            try {
                payload = JSON.parse(content);
            } catch (error) {
                log.error("WS_MESSAGE", {
                    payload,
                    error,
                });
            }
        }

        if (typeof payload === "undefined") {
            return;
        }

        switch (type) {
            case "a":
                try {
                    if (payload && Array.isArray(payload)) {
                        payload.forEach((message) => {
                            json = JSON.parse(message);
                            $bus?.$emit(`websocket.${ json.type }`, json);
                        });
                    }
                } catch (error) {
                    log.error("WS_MESSAGE_FOR_TYPE_A", {
                        type,
                        payload,
                        error,
                    });
                }

                break;
            case "m":
            default:
                try {
                    json = JSON.parse(payload);
                    $bus?.$emit(`websocket.${ json.type }`, json);
                } catch (error) {
                    log.error("WS_MESSAGE_FOR_TYPE_M", {
                        type,
                        payload,
                        error,
                    });
                }
        }
    });
}

export default {
    init,
    start,
    stop,
};
