<template>
    <transition-group
        name="slide"
        mode="out-in"
        class="message-container"
        tag="div"
    >
        <ConfirmEMPCard
            v-if="showPopupEMP"
            key="ConfirmEMPCard"
            class="confirm-emp-card"
        />
        <CookiesAccepted
            v-if="showCookiesAccepted"
            key="CookiesAccepted"
        />
    </transition-group>
</template>

<script>
import ConfirmEMPCard from "@modules/ErrorEMP/ConfirmEMPCard.vue";
import CookiesAccepted from "@modules/CookiesAccepted/index.vue";

export default {
    name: "AcceptedMessages",

    components: {
        CookiesAccepted,
        ConfirmEMPCard,
    },

    props: {
        showCookiesAccepted: {
            type: Boolean,
            default: true,
        },
        showPopupEMP: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.message-container {
    position: fixed;
    top: 0;
    z-index: $HEADER_INDEX;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    padding: 0;

    @include media(L) {
        top: unset;
        bottom: 0;
    }

    :deep(.confirm-emp-card) {
        margin-bottom: 3rem;
        order: 1;
    }

    :deep(.cookies-accepted) {
        order: 0;
        margin-bottom: 1.5rem;

        @include media(L) {
            order: 2;
            margin-bottom: 0;
        }
    }


}
</style>
