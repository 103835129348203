<template>
    <div class="chat-button">
        <FeButton
            :class="className"
            :theme="FeButtonConfig.themes.GhostSmall"
            :icon="iconsOutline.support"
            data-test="chat-button"
            @click="$emit('click')"
        />
        <div
            v-if="chatsStore.newMessageCount"
            class="chat-button__counter"
        >
            {{ chatsStore.newMessageCount }}
        </div>
    </div>
</template>

<script setup lang="ts">
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { iconsOutline } from "@theme/configs/iconsOutline";
import { useChats } from "@store/chats";
const chatsStore = useChats();


interface IProps {
    className?: Record<string, unknown>;
}
defineProps<IProps>();

defineEmits([ "click" ]);
</script>

<style scoped lang="scss">
@import "~@theme/styles";
.chat-button {
    height: auto;
    position: relative;

    &__counter {
        @include pin;
        position: absolute;
        right: 0;
        top: 0;
    }
}

</style>
