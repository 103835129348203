<template>
    <div class="border-gradient">
        <div class="border-gradient-content" :class="computedClasses">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Radius, Width, Gradient, InnerBackground } from "./config";

interface IProps {
    width?: Width;
    gradient?: Gradient;
    radius?: Radius;
    innerBackground?: InnerBackground;
}
const props = withDefaults(defineProps<IProps>(), {
    radius: Radius.S,
    width: Width.extraThin,
    gradient: Gradient.primary,
    innerBackground: InnerBackground.alt1,
});

const computedClasses = computed<string[]>(() => {
    const classes = [];

    for (const key in props) {
        if (props[key as keyof IProps]) {
            classes.push(`border-gradient-content--${props[key as keyof IProps]}`);
        }
    }

    return classes;
});
</script>

<style lang="scss" src="./style.scss"></style>
