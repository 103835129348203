export enum Radius {
    L = "L",
    M = "M",
    S = "S",
    XS = "XS",
}

export enum Width {
    extraThin = "extra-thin",
    thin = "thin",
    medium = "medium",
}

export enum Gradient {
    primary = "primary",
    secondary = "secondary",
    tertiary = "tertiary",
}

export enum InnerBackground {
    alt1 = "alt-1",
    alt2 = "alt-2"
}
