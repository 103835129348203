<template>
    <div class="pre-reg-tooltip">
        <transition name="fade">
            <div
                v-if="isShowed"
                class="pre-reg-tooltip__body pre-reg-tooltip__body--international"
                gap-xs
            >
                <PromoBanner
                    show-close-icon
                    @close="isShowed = false"
                />
                <PromoBannerBetting
                    show-close-icon
                    :class="{'order': isBettingRefCode}"
                    @close="isShowedBetting = false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from "pinia";
import cookie from "@front/core/controllers/CookieController";
import PromoBanner from "@components/PromoBanner/CasinoPromoBanner.vue";
import PromoBannerBetting from "@components/PromoBanner/SportPromoBanner.vue";
import { useUserInfo } from "@store/userInfo";
import { useEnvironment } from "@store/environment";

export default {
    name: "PreRegTooltip",
    components: {
        PromoBanner,
        PromoBannerBetting,
    },

    data() {
        return {
            isShowed: false,
            isShowedBetting: false,
        };
    },

    computed: {
        ...mapState(useEnvironment, {
            isMockerMode: "isMockerMode",
        }),
        ...mapState(useUserInfo, {
            isBettingRefCode: "isUserHasBettingRefcode",
        }),
    },

    mounted() {
        if (!this.isMockerMode) {
            setTimeout(() => {
                this.showTooltip();
            }, 5000);
        }
    },

    methods: {
        showTooltip() {
            if (!cookie.get("promo_tooltip")) {
                this.isShowed = true;
                this.isShowedBetting = true;
                cookie.set("promo_tooltip", "true", { expires: 86400 });
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
