<script setup lang="ts">
import { BANNER } from "@config/gtagEvents";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import isExternalUrl from "@helpers/isExternalUrl";
import { useParserContent } from "@helpers/ParserContent";
import { gtag } from "@plugins/gtagPlugin";
import { routeNames } from "@router/routeNames";
import { useGifts } from "@store/gifts";
import FePicture from "@ui-kit/FePicture/FePicture.vue";
import { TypeImage } from "@theme/configs/imgResize";
import { useRouter, useRoute } from "vue-router";
import FeLink from "@ui-kit/FeLink/FeLink.vue";

import type { IBannerData } from "@api";
import config from "@theme/configs/config";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";

interface IProps {
    banner: IBannerData;
    index: number;
}
const props = defineProps<IProps>();
const router = useRouter();
const route = useRoute();
const { parseContent } = useParserContent();
const { activatePromoGift, loadGiftsData } = useGifts();
const layerGradient = computed(() => {
    return {
        background: props.banner?.bgColor || config.defaultGradient,
    };
});
const bannerImage = computed(() => props.banner.compostelaImagePath || props.banner.desktop);
const localisationVars = computed(() => props.banner?.localisation?.variables || {});

const bannerDescription = computed(() => parseContent(props.banner.description, localisationVars.value));

const bannerItemRef = ref<InstanceType<typeof FeLink> | null>(null);
const observer = ref<InstanceType<typeof IntersectionObserver> | null>(null);
const wasSendGA = ref(false);

const isActivatePromoGiftURL = (url: string) => url.startsWith("/promogifts");
const getPromoCode = (url: string) => {
    const urlArr = url.split("/");
    return urlArr[urlArr.length - 1];
};
const routeEvent = async (url: string) => {
    if (isActivatePromoGiftURL(url)) {
        const promoCode = getPromoCode(url);

        await activatePromoGift(promoCode);
        await loadGiftsData();

        const routeName = getFeatureFlags().enableNewBonuses ? routeNames.profileBonuses : routeNames.profileGifts;
        return router.push({ name: routeName });
    }
    router.push(url);
};
const defaultEventOnClick = (event: MouseEvent) => {
    if (route.hash) {
        router.currentRoute.value.hash = "";
    }

    if (props.banner?.button?.url && !isExternalUrl(props.banner?.button?.url)) {
        event.stopPropagation();
        event.preventDefault();
        clickToGtm();
        routeEvent(props.banner.button.url);
    }
};

const clickToGtm = () => {
    gtag("event", BANNER.SELECT, {
        items: [
            {
                creative_slot: props.index,
                location_id: "home_banner_slider",
                promotion_id: props.banner.id,
                promotion_name: props.banner.title,
            },
        ],
    });
};
const sentViewedBannerToGTM = () => {
    gtag("event", BANNER.VIEW, {
        items: [
            {
                creative_slot: props.index,
                location_id: "home_banner_slider",
                promotion_id: props.banner.id,
                promotion_name: props.banner.title,
            },
        ],
    });
};

const setObserve = () => {
    const options = {
        rootMargin: "0px",
        threshold: 1,
    };
    const callback: IntersectionObserverCallback = (entriesArray) => {
        if (entriesArray[0].isIntersecting && !wasSendGA.value) {
            wasSendGA.value = true;
            sentViewedBannerToGTM();
        }
    };

    observer.value = new IntersectionObserver(callback, options);
    if (bannerItemRef.value) {
        observer.value?.observe(bannerItemRef.value.$el);
    }
};

const disconnectObserver = () => {
    if (observer.value) {
        observer.value.disconnect();
        observer.value = null;
    }
};

onMounted(() => {
    setObserve();
});

onBeforeUnmount(() => {
    disconnectObserver();
});
</script>

<template>
    <FeLink
        ref="bannerItemRef"
        class="banner-stories-view"
        :style="layerGradient"
        :to="banner.button.url"
        @click="defaultEventOnClick"
    >
        <div class="banner-stories-view__content padding-s">
            <div class="banner-stories-view__description" v-html="bannerDescription" />
            <div v-if="banner.button.name" class="banner-stories-view__button">
                {{ banner.button.name }}
                <FeIcon :icon="FeIconConfig.arrowNext" />
            </div>
        </div>

        <div class="banner-stories-view__image">
            <FePicture
                v-if="bannerImage"
                :is-lazy="index > 3"
                :fetch-priority="index <= 3 ? 'high' : undefined"
                :src="bannerImage"
                :alt="banner.title"
                :type="TypeImage.BANNER_STORIES"
            />
        </div>
    </FeLink>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.banner-stories-view {
    box-sizing: border-box;
    border-radius: $border-radius-M;
    overflow: hidden;
    height: 11rem;
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    text-decoration: none;
    color: $color-text-body;

    @include media(M) {
        height: 16rem;
    }

    &__content {
        position: relative;
        padding-right: 0;
    }

    &__image {
        &:deep(img) {
            width: 100%;
            height: 11rem;
            object-fit: cover;
            object-position: left center;

            @include media(M) {
                height: 16rem;
            }
        }
    }

    &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        @include font-size--ButtonStyleXS;
        padding: 0.5rem 1rem;
        border-radius: 0 $border-radius-M;
        background: $color-tertiary-3;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: $color-primary-1;
        cursor: pointer;

        .fe-icon {
            height: 1rem;
            width: 1rem;
        }
        @include media(M) {
            padding: 0.5rem 1.5rem;
        }
    }

    &:deep(.banner-title) {
        @include font-size--Header3;

        @include media(M) {
            @include font-size--Header2;
        }
    }

    &:deep(.banner-caption) {
        @include font-size--Caption2($font-weight--normal);

        @include media(M) {
            @include font-size--Caption1($font-weight--normal);
        }
    }
}
</style>
