<script setup lang="ts">
import { computed, watch } from "vue";
import { useCurrentGame } from "@store/currentGame";
import { useCoolingOffStatus } from "@modules/Limits";
import GameFrameDefaultFlow from "./GameFrameDefaultFlow.vue";
import GameFrameLivespinsFlow from "./GameFrameLivespinsFlow.vue";
import { isGamePage } from "@controllers/services/games/pageService";
import { useRouter } from "vue-router";
import { useUserInfo } from "@store/userInfo";
import { useContextStore } from "@store/context";

const router = useRouter();
const currentGameStore = useCurrentGame();
const contextStore = useContextStore();
const userInfoStore = useUserInfo();
const { isCoolingOff } = useCoolingOffStatus();

enum GameFlow {
    DEFAULT = "DEFAULT",
    LIVESPINS = "LIVESPINS",
}

const flow = computed(() => {
    if (currentGameStore.gameData?.id && !isCoolingOff.value) {
        return GameFlow.DEFAULT;
    }
    if (currentGameStore.livespinsSession) {
        return GameFlow.LIVESPINS;
    }
    return null;
});

const frameComponent = computed(() => {
    switch (flow.value) {
        case GameFlow.DEFAULT: return GameFrameDefaultFlow;
        case GameFlow.LIVESPINS: return GameFrameLivespinsFlow;
        default: return null;
    }
});

watch(() => router.currentRoute.value.fullPath, () => {
    if (!isGamePage(router.currentRoute.value)) {
        if (flow.value === GameFlow.LIVESPINS) {
            if (contextStore.isMobile) {
                currentGameStore.clear();
            }
        } else if (!userInfoStore.isLogged || currentGameStore.isDemo || contextStore.isMobile) {
            currentGameStore.clear();
        }
    }
});
</script>

<template>
    <component
        :is="frameComponent"
        v-if="frameComponent"
    />
</template>
