import type { IBannerData } from "@api";
import { useBannersStore } from "@store/banners";
import type { Pinia } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BANNER_STORIES_TAG, BANNER_TAG_BY_PAGE } from "./constants";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import type { Link } from "@unhead/vue";
import { usePictureSource } from "@ui-kit/FePicture/composables/usePictureSource";
import { TypeImage } from "@theme/configs/imgResize";

export class BannersPageService {
    static isShowOnPage(pageName: string) {
        return BANNER_TAG_BY_PAGE.hasOwnProperty(pageName);
    }

    static getDataTagByPage(pageName: string): string[] | undefined {
        return BANNER_TAG_BY_PAGE[pageName];
    }
}

export function useBannersDataService() {
    const route = useRoute();
    const bannersStore = useBannersStore();

    const banners = computed<IBannerData[]>(() => {
        const tag = route.name ? BannersPageService.getDataTagByPage(route.name as string) : undefined;

        if (tag) {
            return bannersStore.getBannersByTags(tag).filter((banner) => !banner.tags.includes(BANNER_STORIES_TAG));
        }
        return [];
    });
    const newBanners = computed<IBannerData[]>(() => {
        const tag = route.name ? BannersPageService.getDataTagByPage(route.name as string) : undefined;

        if (tag) {
            return bannersStore.getBannersByTags(tag).filter((banner) => banner.tags.includes(BANNER_STORIES_TAG));
        }
        return [];
    });

    const bannersPreload = computed(() => {
        if (!getFeatureFlags().homeBannerLikeStories) {
            return [];
        }

        const params: Link = { rel: "preload", as: "image" };
        const imagePreload: Link[] = [];

        newBanners.value.slice(0, 3).forEach((banner) => {
            const { imageSrc, sourceElements } = usePictureSource(
                banner.compostelaImagePath,
                TypeImage.BANNER_STORIES,
                undefined,
            );

            sourceElements.value.reverse().forEach((sourceElement) => {
                imagePreload.push({ ...params, imagesrcset: sourceElement.srcset, media: sourceElement.media });
            });
            imagePreload.push({ ...params, href: imageSrc.value });
        });

        return imagePreload;
    });

    return {
        banners,
        newBanners,
        bannersPreload,
    };
}

export function useBannersFetchService(pinia?: Pinia) {
    const bannersStore = useBannersStore(pinia);

    function loadBanners() {
        return bannersStore.loadBanners();
    }

    async function reloadBanners() {
        bannersStore.clearBannersData();
        await loadBanners();
    }

    return {
        loadBanners,
        reloadBanners,
    };
}
