import bus from "@bus";
import cookie from "@front/core/controllers/CookieController";
import { routeNames } from "@router/routeNames";
import { type Router, useRouter } from "vue-router";
import { USER_REGISTER_COOKIE, REGISTER_DELAY, COOKIE_FORGET_DELAY } from "./config";

type Callback = () => void;
export class RegistrationStrategy {
    private readonly callback: Callback;
    private readonly userRegisterBind: Callback;
    private readonly userLogoutBind: Callback;
    private readonly router: Router;
    private timerId?: ReturnType<typeof setTimeout> | undefined;

    constructor(callback: Callback) {
        this.callback = callback;

        this.userRegisterBind = this.userRegister.bind(this);
        this.userLogoutBind = this.userLogout.bind(this);
        this.router = useRouter();

        this.init();
    }

    stop() {
        bus.$off("user.register", this.userRegisterBind);
        bus.$off("user.logout", this.userLogoutBind);
        this.stopStrategy();
    }

    private async timerCallback() {
        const recursiveShow = async () => {
            if (this.router.currentRoute.value.name === routeNames.gameItem) {
                setTimeout(recursiveShow, 5000);
            } else {
                this.timerId = undefined;
                this.cookieUnset();
                this.callback();
            }
        };
        recursiveShow();
    }

    private stopStrategy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.cookieUnset();
            this.timerId = undefined;
        }
    }

    startStrategy() {
        if (this.timerId) {
            return;
        }

        const registrationTime = parseInt(String(cookie.get(USER_REGISTER_COOKIE)), 10);
        if (!isNaN(registrationTime)) {
            const time = registrationTime - Date.now();
            this.timerId = setTimeout(() => this.timerCallback(), time);
        }
    }

    private init() {
        bus.$on("user.register", this.userRegisterBind);
        bus.$on("user.logout", this.userLogoutBind);
    }

    private cookieSet() {
        cookie.set(USER_REGISTER_COOKIE, String(Date.now() + (REGISTER_DELAY * 1000)), {
            expires: COOKIE_FORGET_DELAY,
            path: "/",
        });
    }
    private cookieUnset() {
        cookie.set(USER_REGISTER_COOKIE, "", {
            expires: -1,
            path: "/",
        });
    }

    private userRegister() {
        this.cookieSet();
    }

    private userLogout() {
        this.cookieUnset();
        this.stopStrategy();
    }
}
