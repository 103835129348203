import type { IToastData } from "@modules/Toast/types";
import Toast from "@plugins/Toast";
import { useNoticeParserContent } from "./parseNotificationService";
import type { IUserNotificationResource } from "@api";

export function useShowNotificationToast() {
    const { parseNotice } = useNoticeParserContent();

    const showNotificationToast = (notice: IUserNotificationResource, toastOverride?: IToastData) => {
        const { title, message } = parseNotice(notice);

        Toast.show({
            ...toastOverride,
            title,
            text: message,
        });
    };

    return {
        showNotificationToast,
    };
}
