<script setup lang="ts">
import type { IBannerData } from "@api";
import { gtag } from "@plugins/gtagPlugin";
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";
import { useRatesStore } from "@store/rates";
import { TypeImage } from "@theme/configs/imgResize";
import { BANNER } from "@config/gtagEvents";
import BannerSlide from "@components/BannerSlide/BannerSlide.vue";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import FeSkeleton from "@ui-kit/FeSkeleton/FeSkeleton.vue";
import AdvantagesCarousel from "@components/HomeBanner/components/AdvantagesCarousel/index.vue";
import { routeNames } from "@router/routeNames";
import { useAdvantagesItemByPage } from "@components/HomeBanner/components/AdvantagesCarousel/resources";
import { useUserInfo } from "@store/userInfo";
import { useBannersDataService } from "@controllers/services/banners";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import BannerStoriesView from "@components/HomeBanner/components/BannerStoriesView.vue";

defineOptions({
    name: "HomeBanner",
});

const route = useRoute();

const userInfoStore = useUserInfo();
const { rates } = storeToRefs(useRatesStore());
const { isMockerMode } = storeToRefs(useEnvironment());

const pageType = computed(() => route.meta?.pageType as string);

const advantages = useAdvantagesItemByPage(pageType, rates);
const { banners, newBanners } = useBannersDataService();

const autoplay = computed(() => {
    if (isMockerMode.value) {
        return;
    }
    if (getFeatureFlags().homeBannerLikeStories) {
        return 15000;
    }
    return 10000;
});

const showAdvantages = computed(() => {
    return !userInfoStore.isLogged || route.name === routeNames.main;
});

function clickToGtm(banner: IBannerData, index: number) {
    gtag("event", BANNER.SELECT, {
        items: [
            {
                creative_slot: index,
                location_id: "home_banner_slider",
                promotion_id: banner.id,
                promotion_name: banner.title,
            },
        ],
    });
}

let promotionsGtmCounter = 0;
function trackActiveBanner(index: number) {
    if (promotionsGtmCounter >= banners.value.length) {
        return;
    }

    const banner = banners.value[index];
    gtag("event", BANNER.VIEW, {
        items: [
            {
                creative_slot: index + 1,
                location_id: "home_banner_slider",
                promotion_id: banner.id,
                promotion_name: banner.title,
            },
        ],
    });

    promotionsGtmCounter = promotionsGtmCounter + 1;
}

const classes = computed(() => {
    return {
        "home-banner": true,
        "home-banner--stories": getFeatureFlags().homeBannerLikeStories,
        "main-layout-width": getFeatureFlags().homeBannerLikeStories,
    };
});

watch(banners, (data) => {
    if (data.length > 0) {
        trackActiveBanner(0);
    }
});
onMounted(() => {
    trackActiveBanner(0);
});
</script>
<template>
    <div :class="classes" gap-s>
        <CarouselScroll
            v-if="banners.length > 0 || newBanners.length > 0"
            class="home-banner__carousel"
            :pagination="!getFeatureFlags().homeBannerLikeStories"
            :autoplay="autoplay"
            :disable-fade-effect="false"
            @change:page="!getFeatureFlags().homeBannerLikeStories ? trackActiveBanner($event) : null"
        >
            <template v-if="getFeatureFlags().homeBannerLikeStories">
                <BannerStoriesView
                    v-for="(banner, index) in newBanners"
                    :key="index"
                    :banner="banner"
                    :index="index + 1"
                />
            </template>

            <template v-else>
                <BannerSlide
                    v-for="(banner, index) in banners"
                    :key="index"
                    :banner="banner"
                    :type-image="TypeImage.BANNER_HOME"
                    @click-banner="clickToGtm(banner, index + 1)"
                />
            </template>
        </CarouselScroll>
        <template v-else>
            <div v-if="getFeatureFlags().homeBannerLikeStories" class="home-banner__skeleton-wrapper gap-xs">
                <FeSkeleton v-for="i in 4" :key="`skeleton_${i}`" class="home-banner__skeleton" />
            </div>

            <FeSkeleton v-else class="home-banner__skeleton" />
        </template>

        <AdvantagesCarousel v-if="showAdvantages" :advantages="advantages" main-layout-width />
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
