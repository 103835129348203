import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import bus from "@bus";
import { CASHBOX_DASHBOARD_STEP, CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import { routeNames } from "@router/routeNames";
import { useGifts } from "@store/gifts";
import { useUserInfo } from "@store/userInfo";
import { nextTick } from "vue";
import { isServer } from "@helpers/ssrHelpers";

export default (router: Router, pinia: Pinia) => {
    const userInfoStore = useUserInfo(pinia);

    router.beforeEach((to, from, next) => {
        const routeCheck = to.hash.startsWith("#cashbox") || to.hash.startsWith(`#${CASHBOX_DEPOSIT_STEP}`);

        if (routeCheck && !isServer) {
            userInfoStore.isLoggedAsync().then((isLogged) => {
                if (!isLogged) {
                    return router.push({ name: routeNames.login });
                }

                const [ cashboxStep, giftId ] = to.hash.split("_");

                const giftsStore = useGifts();
                const gift = giftsStore.getGiftByGiftId(giftId);

                if (gift) {
                    giftsStore.setCurrentGift(gift.id);
                }

                nextTick(() => {
                    let step = CASHBOX_DASHBOARD_STEP;

                    if (cashboxStep.slice(1) === CASHBOX_DEPOSIT_STEP) {
                        step = CASHBOX_DEPOSIT_STEP;
                    }

                    bus.$emit("open-cashbox", { step, location: "promotion" });
                });
                if (from.name === null) {
                    return router.push({ name: routeNames.main });
                }
            });
        }

        return next();
    });
};
