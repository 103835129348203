import { Currency } from "@api/resources/commonResources";

export type AmountMap = {
    [key in Currency]?: number[];
};
const AMOUNT_MAP_BANNER: AmountMap = {
    [Currency.INR]: [ 4000000 ],
    [Currency.EUR]: [ 40000 ],
    [Currency.USD]: [ 40000 ],
    [Currency.AUD]: [ 200000 ],
    [Currency.NZD]: [ 60000 ],
    [Currency.CAD]: [ 200000 ],
    [Currency.BRL]: [ 200000 ],
};

const BETTING_AMOUNT_MAP_BANNER: AmountMap = {
    [Currency.INR]: [ 2000000, 1000000 ],
    [Currency.EUR]: [ 20000, 10000 ],
    [Currency.USD]: [ 20000, 10000 ],
    [Currency.AUD]: [ 30000, 15000 ],
    [Currency.NZD]: [ 30000, 15000 ],
    [Currency.CAD]: [ 30000, 15000 ],
    [Currency.BRL]: [ 100000, 50000 ],
};

export {
    AMOUNT_MAP_BANNER,
    BETTING_AMOUNT_MAP_BANNER,
};
