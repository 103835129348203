<template>
    <FieldValidator
        ref="fieldRef"
        :name="label"
        :value="inputValue"
        :rules="[ ...rules, birthdayMinRule, birthdayMaxRule ]"
        manual-validate
    >
        <template #default="{ valid, message, showError }">
            <FeTooltip :position="positionTooltip" :show-on-hover="false" :show="showError">
                <template #activator>
                    <FeInput
                        v-bind="$attrs"
                        v-model.trim="localValue"
                        type="tel"
                        :name="`birthday-${fieldRef?.fieldName}`"
                        :placeholder="dateController.getPlaceholder()"
                        :mask="dateController.getMask()"
                        :error="!valid"
                        :disabled="disabled"
                        :data-test="dataTest"
                        :max="maxDate"
                        :min="minDate"
                        :label="label"
                        required
                        @focus="onChanHandler"
                        @keydown="keydownEvent"
                    >
                        <template #right>
                            <FeIcon v-if="showCalendar" class="fe-inputV2__calendar" :icon="FeIconConfig.calendar" />
                        </template>
                    </FeInput>
                </template>
                {{ message }}
            </FeTooltip>
        </template>
    </FieldValidator>
</template>

<script setup lang="ts">
import type { IBirthdayNewProps, FieldRef } from "./types";
import { computed, ref, watch } from "vue";
import { useBaseField } from "@ui-kit/FeFields/useBaseField";
import { birthdayMinRule, birthdayMaxRule } from "@mixins/validationRules";
import { DateController } from "@helpers/dateFormatHelper";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import FieldValidator from "@components/FieldValidator/FieldValidator.vue";
import { FeTooltip, FeTooltipPosition } from "@ui-kit/FeTooltip";
import FeInput from "@ui-kit/FeInput/FeInput.vue";

interface IBirthdayNewEmits {
    (event: "focus", value: FocusEvent): void;
    (event: "keydown", value: KeyboardEvent): void
}

const props = withDefaults(defineProps<IBirthdayNewProps>(), {
    positionTooltip: FeTooltipPosition.bottom,
    required: false,
    showCalendar: true,
    country: "",
});
const emit = defineEmits<IBirthdayNewEmits>();

const fieldRef = ref<FieldRef>(null);
const { rules, inputValue } = useBaseField(props, fieldRef.value);

const dateController = ref(new DateController({ country: props.country }));
const localValue = ref(dateController.value.formatFromDefault(props.input.value));

const maxDate = computed(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);

    return today.toISOString().substring(0, 10);
});
const minDate = computed(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 118);

    return today.toISOString().substring(0, 10);
});

const onChanHandler = (event: FocusEvent) => emit("focus", event);
const keydownEvent = (event: KeyboardEvent) => emit("keydown", event);

watch(
    () => localValue.value,
    (value) => {
        fieldRef.value?.validate();
        inputValue.value = dateController.value.formatToDefault(value);
    },
);
watch(
    () => inputValue.value,
    (value) => {
        const formatted = dateController.value.formatFromDefault(value);
        if (formatted && formatted !== localValue.value) {
            localValue.value = formatted;
        }
    },
    { deep: true },
);
</script>

<style lang="scss">
@import "~@theme/styles";

.fe-inputV2__calendar {
    cursor: default;
    color: $color-text-link-default;
}

.fe-inputV2 .fe-inputV2__input[type="date"] {
    min-width: calc(100% - 1rem);
}
</style>
