<template>
    <div
        main-layout-width
        class="footer-contacts caption-1"
        gap-s
    >
        <b class="footer-contacts__title">{{ $t("FOOTER.MENU.CONTACTS") }}</b>
        <div class="footer-contacts__wrapper">
            <div class="footer-contacts__item">
                <FeLink @click="bus.$emit('chat.toggle')">
                    <FeIcon :icon="FeIconConfig.chat" />
                    {{ $t("SUPPORT.LIVE_CHAT") }}
                </FeLink>
            </div>
            <div v-if="getPhone" class="footer-contacts__item">
                <FeLink :to="`tel:${getPhone}`">
                    <FeIcon :icon="FeIconConfig.phone" />
                    {{ getPhone }}
                </FeLink>
            </div>
            <ClientOnly>
                <div v-if="showEmail" class="footer-contacts__item">
                    <FeLink :to="`mailto:${getEmail}`">
                        <FeIcon :icon="FeIconConfig.mail" />
                        {{ getEmail }}
                    </FeLink>
                </div>
            </ClientOnly>
        </div>
    </div>
</template>

<script setup lang="ts">
import bus from "@bus";
import { computed } from "vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import ClientOnly from "@components/ClientOnly";
import { storeToRefs } from "pinia";
import { useContacts } from "@store/projectContacts";
import { useEnvironment } from "@store/environment";

const contactStore = useContacts();
const { getPhone, getEmail } = storeToRefs(contactStore);
const { isMockerMode } = storeToRefs(useEnvironment());

const showEmail = computed(() => {
    return isMockerMode.value || getEmail.value;
});
</script>
<style lang="scss" scoped>
    @import "~@theme/styles";
    .footer-contacts {

        &__wrapper {
            display: grid;
            grid-gap: 1rem;
        }

        &__item {
            display: flex;
            justify-content: space-between;

            a, span {
                font-weight: $font-weight--bold;
                display: grid;
                grid-gap: 0.5rem;
                grid-template-columns: 1.5rem auto;
                @include link("dark", false);

            }
        }
    }
</style>
