<template>
    <div class="enter-btns">
        <FeButton
            class="enter-btns-log"
            :theme="FeButtonConfig.themes.SecondarySmall"
            @click="loginHandler"
        >
            {{ $t("COMMON.LOGIN") }}
        </FeButton>
        <FeButton
            class="enter-btns-reg"
            :theme="FeButtonConfig.themes.PrimarySmall"
            @click="registrationHandler"
        >
            {{ $t("COMMON.REGISTRATION") }}
        </FeButton>
        <div
            v-if="isShownBanners"
            gap-xs
            class="enter-btns__promo-banner"
        >
            <PromoBanner show-close-icon />
            <PromoBannerBetting
                show-close-icon
                :class="{'order': isBettingRefCode}"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { routeNames } from "@router/routeNames";

import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { loginWithCaptcha } from "@helpers/CaptchaForLogin";

import PromoBanner from "@components/PromoBanner/CasinoPromoBanner.vue";
import PromoBannerBetting from "@components/PromoBanner/SportPromoBanner.vue";
import { LOGIN_EVENTS, REGISTRATION_EVENTS } from "@config/gtagEvents";
import DmpController from "@front/core/controllers/MetriksController/DmpController";
import { getLogin } from "@helpers/getLogin";
import { useUserInfo } from "@store/userInfo";
import { useAuth } from "@controllers/services/auth";
import { gtag } from "@plugins/gtagPlugin";
import { isGamePage } from "@controllers/services/games/pageService";

export default {
    name: "EnterBtns",

    components: {
        FeButton,
        PromoBanner,
        PromoBannerBetting,
    },

    props: {
        location: {
            type: String,
            default: "header",
        },
    },

    data() {
        return {
            FeButtonConfig,
            routeNames,
        };
    },
    computed: {
        ...mapState(useUserInfo, {
            refcode: "refcode",
            isBettingRefCode: "isUserHasBettingRefcode",
        }),
        isShownBanners() {
            return this.refcode.isLoaded && !isGamePage(this.$route);
        },
        notUseCredentials() {
            return this.$route.meta?.logoType === "sport";
        },
    },

    methods: {
        registrationHandler() {
            gtag("event", REGISTRATION_EVENTS.CLICK, {
                location: this.location,
            });
            this.$router.push({ name: routeNames.registration, query: this.$route.query });
        },
        async handleCredentials(credential) {
            const { loadFullUserData } = useAuth(this.$pinia);
            const login = getLogin(credential.id);
            await loginWithCaptcha({
                login,
                password: credential.password,
            });
            await loadFullUserData();
            DmpController.emit("auth");
        },

        async redirectToLogin() {
            await this.$router.push({ name: routeNames.login, query: this.$route.query });
        },

        async loginHandler() {
            gtag("event", LOGIN_EVENTS.CLICK, {
                location: this.location,
            });
            if (!this.notUseCredentials && navigator.credentials) {
                try {
                    const credential = await navigator.credentials.get({ password: true });
                    if (credential) {
                        await this.handleCredentials(credential);
                    } else {
                        await this.redirectToLogin();
                    }
                } catch (err) {
                    await this.redirectToLogin();
                }
            } else {
                await this.redirectToLogin();
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
