import type { Pinia } from "pinia";
import { useEnvironment } from "@store/environment";

export function useEnvironmentFetchService(pinia?: Pinia) {
    useEnvironment(pinia);

    function loadEnvironment() {
        return Promise.resolve();
    }

    return {
        loadEnvironment,
    };
}
