import { initializeApp, type FirebaseApp } from "firebase/app";
import { getToken, type Messaging, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";
import { isFrozenUA } from "ua-parser-js/helpers";
import { UAParser as uaParser } from "ua-parser-js";
import log from "@front/core/controllers/LoggerController";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration/featureFlag";
import configAPI from "@api/requests/config";
import firebaseAPI from "@api/requests/firebase";
import logo from "@theme/images/BrandImages/logo.png";
import { useUserInfo } from "@store/userInfo";

const getFirebaseToken = async (messaging: Messaging, vapidKey: string, sw: ServiceWorkerRegistration) => {
    try {
        const uaHints = isFrozenUA(navigator.userAgent)
            ? await uaParser().withClientHints()
            : uaParser(navigator.userAgent);
        const { browser, device, os } = uaHints;
        // eslint-disable-next-line @stylistic/js/max-len
        const currentDevice = `${os.name}/${os.version} | ${device.type ? `${device.type}/` : ""}${device.model} | ${browser.name}/${browser.major || browser.version}`;

        const userTokens = await firebaseAPI.getFirebaseToken(currentDevice);
        const currentToken = await getToken(messaging, { vapidKey, serviceWorkerRegistration: sw });

        if (currentToken) {
            const isTokenExist = userTokens?.data?.filter((item) => item?.token === currentToken).length;
            if (isTokenExist) {
                log.info("GET_FIREBASE_TOKEN: Token already exist");
            } else {
                // Send the token to API endpoint
                await firebaseAPI.setFirebaseToken(currentToken, currentDevice);
            }
        } else {
            log.info("GET_FIREBASE_TOKEN: No registration token available. Request permission to generate one.");
        }
    } catch (error: unknown) {
        log.error("GET_FIREBASE_TOKEN:\nAn error occurred while retrieving token: ", error);
    }
};

const requestPermission = async (messaging: Messaging, vapidKey: string, sw: ServiceWorkerRegistration) => {
    const userInfoStore = useUserInfo();

    const isLogged = await userInfoStore.isLoggedAsync();

    if (isLogged) {
        Notification.requestPermission().then(async (permission) => {
            if (permission === "granted") {
                log.info("SW: Notification permission granted.");

                await getFirebaseToken(messaging, vapidKey, sw);
            }
        });
    }
};

export const initFirebase = async (sw: ServiceWorkerRegistration) => {
    if (!getFeatureFlags().enableFastTrackFirebase) {
        return;
    }

    const config = await configAPI.getFastTrack();

    if (config && Object.keys(config.firebaseConfig).length) {
        const app: FirebaseApp = initializeApp(config.firebaseConfig);
        const messaging = getMessaging(app);
        const vapidKey = config.firebaseVapidKey ?? "";

        sw.active?.postMessage(JSON.stringify(config.firebaseConfig));

        await requestPermission(messaging, vapidKey, sw);

        onMessage(messaging, (payload) => {
            const notificationTitle = payload.notification?.title || payload.data?.title || "";
            const notificationOptions: NotificationOptions = {
                body: payload.notification?.body,
                icon: payload.notification?.image || logo,
                badge: payload.notification?.image,
                data: payload.data,
                silent: false,
            };

            sw.showNotification(notificationTitle, notificationOptions);
        });
    }
};
