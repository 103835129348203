import { useContextStore } from "@store/context";

export default function isPartnerPwa() {
    const contextStore = useContextStore();

    if (contextStore.context?.isIOS) {
        return false;
    }

    if (typeof window !== "undefined") {
        const params = window.location.search || "";

        return window.matchMedia("(display-mode: standalone)").matches && !params.includes("standalone");
    }

    return false;
}
