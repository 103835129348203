import type { Pinia } from "pinia";
import type { Router } from "vue-router";
import type { EventBus } from "@bus";
import { routeNames } from "@router/routeNames";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { useUserStatusService } from "@controllers/services/user";
import { useUserInfo } from "@store/userInfo";
import { useAuth } from "@controllers/services/auth";

export default function redirectsHook(router: Router, bus: EventBus, pinia: Pinia) {
    router.beforeEach((to, from, next) => {
        if (to.hash === "#remind-password") {
            return next({ name: routeNames.remind });
        }

        if (to.hash === "#enter-new-password") {
            return next({ name: routeNames.setPassword });
        }

        if (to.hash === "#tab_gifts") {
            return next({ name: getFeatureFlags().enableNewBonuses ? routeNames.profileBonuses : routeNames.profileGifts });
        }

        if (from.name && [ "#chat" ].includes(to.hash)) {
            setTimeout(() => {
                window.location.hash = "";
            }, 1000);

            bus.$emit("chat.toggle");
        }

        if ([ "#install-pwa" ].includes(to.hash)) {
            setTimeout(() => {
                window.location.hash = "";
                bus.$emit("pwa.install");
            }, 1500);
        }

        if (getFeatureFlags().depositInsuranceBonus && to.name === routeNames.profileBonusesInsurance) {
            const userInfoStore = useUserInfo(pinia);
            const { loadFullUserData } = useAuth(pinia);
            userInfoStore.isLoggedAsync().then(async (isLogged) => {
                if (isLogged) {
                    if (!userInfoStore.userStatusData.userId) {
                        await loadFullUserData();
                    }

                    const { userVipStatus } = useUserStatusService(pinia);

                    if (!userVipStatus.value) {
                        await router.push({ name: routeNames.profileBonuses });
                    }
                }
            });
        }

        return next();
    });
};
