import type { Pinia } from "pinia";
import { createRouter as _createRouter, createMemoryHistory, createWebHistory, type Router } from "vue-router";
import { createRoutes } from "@router/routes";
import { LOCALES } from "@theme/configs/constsLocales";
import { generateStaticRoutes } from "@router/StaticPageRouterController";
import { scrollBehavior } from "@router/helpers";
import { isServer } from "@helpers/ssrHelpers";

const router: Record<"instance", Router | null> = { instance: null };

export default router;

export function createRouter(pinia?: Pinia, routerData?: Record<string, unknown>, base?: string, hostname?: string) {
    let baseUrl = base;
    if (!baseUrl && !isServer) {
        const localeFromURL = String(window.location.pathname).split("/")[1];
        baseUrl = (Object.values(LOCALES).includes(localeFromURL) && `/${localeFromURL}/`) || "/";
    }

    let domain = hostname;
    if (!hostname && !isServer) {
        domain = window.location.hostname;
    }

    const historyUrl = routerData?.guest ? "/guest/" : baseUrl ?? "/";
    const history = isServer ? createMemoryHistory(historyUrl) : createWebHistory(historyUrl);
    const routes = createRoutes(domain);

    router.instance = _createRouter({ history, routes, scrollBehavior });

    generateStaticRoutes(routerData, router.instance, pinia);

    router.instance.onError((err) => {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify({ label: "VUE_ROUTER", message: err.message, level_name: "ERROR" }));
    });

    return router.instance;
}
