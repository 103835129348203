import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import type { I18nGlobal } from "@i18n/types";
import Toast from "@plugins/Toast";
import { useUserInfo } from "@store/userInfo";

export default (router: Router, pinia: Pinia, i18n: I18nGlobal) => {
    router.beforeEach((to, _from, next) => {
        const userInfoStore = useUserInfo(pinia);
        if (!userInfoStore.isGuest) {
            return next();
        }

        if (to.meta && Boolean(to.meta.notAllowedFor451)) {
            if (location.pathname.replace("/guest", "") === to.fullPath) {
                return next({ name: "Main" });
            }

            const MESSAGES_NOTIFICATION: Record<string, string> = {
                Registration: i18n.t("TOAST.GUEST_REGISTRATION_MESSAGE"),
                Login: i18n.t("TOAST.GUEST_LOGIN_MESSAGE"),
            };

            Toast.show({
                text: to.name ? MESSAGES_NOTIFICATION[to.name as string] : "",
            });

            return next(false);
        }

        return next();
    });
};
