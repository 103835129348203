<script setup lang="ts">
import { v4 as uuid } from "uuid";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import risingStar from "./images/rising-star-23.svg";
import bestCasino from "./images/best-casino-23.svg";
import guruAwards from "./images/guru-awards-24.svg";

interface IAwardItem {
    id: string;
    src: string;
    alt: string;
    link?: string;
    rel?: string;
}

const awards: IAwardItem[] = [
    {
        id: uuid(),
        src: risingStar,
        alt: "rising-star",
    },
    {
        id: uuid(),
        src: bestCasino,
        alt: "world's-best",
        link: "https://worldcasinoawards.com/award/world-best-online-casino/2023",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        src: guruAwards,
        alt: "guru-awards",
        link: "https://casino.guru/awards",
    },
];
</script>
<template>
    <div
        class="footer-awards"
        main-layout-width
        grid-full-width
        gap-s
    >
        <h2 v-t="'FOOTER.AWARDS.TITLE'" class="text--align-center" />
        <div class="footer-awards__items text--align-center" gap-s>
            <template v-for="award in awards" :key="award.id">
                <FeLink v-if="award.link" :to="award.link" :rel="award.rel">
                    <FeImage
                        :src="award.src"
                        :alt="award.alt"
                        width="144"
                        height="96"
                        class="footer-awards__image"
                    />
                </FeLink>
                <FeImage
                    v-else
                    :src="award.src"
                    :alt="award.alt"
                    width="144"
                    height="96"
                    class="footer-awards__image"
                />
            </template>
        </div>
    </div>
</template>
<style scoped lang="scss">
@import "~@theme/styles";

.footer-awards {
    margin: 0 auto;
    padding-bottom: 1.5rem;

    @include media(M) {
        padding-bottom: 2rem;
    }

    &__items {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    &__image {
        width: 6rem;
        height: 4rem;

        @include media(M) {
            height: 6rem;
            width: 9rem;
        }
    }
}
</style>
