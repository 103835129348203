import { AbstractTrigger } from "@controllers/triggers/AbstractTrigger";
import { type Callback } from "@controllers/triggers/types";
import { type Router, useRouter } from "vue-router";

export interface IRouteTransitionConfig {
    from?: string[];
    to?: string[];
    localStorageKey?: string;
}
export type RouteTransitionCondition = IRouteTransitionConfig | IRouteTransitionConfig[];

export class RouteTransitionTrigger extends AbstractTrigger<RouteTransitionCondition> {
    private stopRouterHook: (() => void) | undefined;
    private router: Router;
    protected static instance?: RouteTransitionTrigger;

    protected constructor(router: Router) {
        super();
        this.router = router;
    }

    static use(): RouteTransitionTrigger {
        const router = useRouter();
        if (!this.instance) {
            this.instance = new RouteTransitionTrigger(router);
        }
        return this.instance;
    }

    init() {
        this.stopRouterHook = this.router.afterEach((toRoute, fromRoute) => {
            this.items.forEach((item) => {
                const {
                    callback,
                    condition,
                } = item;

                if (!condition) {
                    return callback();
                }

                const fromName = fromRoute.name;
                const toName = toRoute.name;

                const conditionList = Array.isArray(condition) ? condition : [ condition ];

                conditionList.forEach(({ to, from, localStorageKey }) => {
                    const fromCheck = !from || from.some((name) => fromName === name);
                    const toCheck = !to || to.some((name) => toName === name);
                    const localStorageSaveCheck = !from || from.some((name) => toName === name);

                    if (localStorageKey) {
                        if (localStorageSaveCheck) {
                            window.localStorage.setItem(localStorageKey, "1");
                        } else {
                            window.localStorage.removeItem(localStorageKey);
                        }
                    }

                    if (fromCheck && toCheck) {
                        callback();
                    }
                });
            });
        });
    }
    public on(callback: Callback, condition?: RouteTransitionCondition) {
        if (condition) {
            const currentRouteName = this.router.currentRoute.value.name;
            const conditionList = Array.isArray(condition) ? condition : [ condition ];
            conditionList.forEach(({ localStorageKey, to, from }) => {
                if (localStorageKey) {
                    const toCheck = !to || to.some((name) => currentRouteName === name);
                    const localStorageSaveCheck = !from || from.some((name) => currentRouteName === name);
                    const localStorageFlag = Boolean(Number(window.localStorage.getItem(localStorageKey)));

                    if (toCheck && localStorageFlag) {
                        callback();
                        window.localStorage.removeItem(localStorageKey);
                    }

                    if (localStorageSaveCheck) {
                        window.localStorage.setItem(localStorageKey, "1");
                    } else {
                        window.localStorage.removeItem(localStorageKey);
                    }
                }
            });
        }
        super.on(callback, condition);
    }
    public off(callback: Callback) {
        const items = this.items.filter((item) => {
            return item.callback !== callback;
        });
        items.forEach(({ condition }) => {
            if (condition) {
                const conditionList = Array.isArray(condition) ? condition : [ condition ];
                conditionList.forEach(({ localStorageKey }) => {
                    if (localStorageKey) {
                        window.localStorage.removeItem(localStorageKey);
                    }
                });
            }
        });

        super.off(callback);
    }

    stop() {
        if (this.stopRouterHook) {
            this.stopRouterHook();
            this.stopRouterHook = undefined;
        }
    }
}
