import { publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";

interface FirebaseTokenResponse {
    id: string
    device: string
    token: string
}
type FirebaseResponseData = {
    data: FirebaseTokenResponse[]
}

export default {
    async setFirebaseToken(token: string, device: string) {
        try {
            await publicApiV1<FirebaseResponseData>({
                url: "/firebase/token/create",
                type: () => "Firebase.V1.PublicSecured.Token.Create",
                secured: true,
                data: {
                    data: { device, token },
                },
            });
        } catch (error: unknown) {
            log.error("SET_FIREBASE_TOKEN", error);
        }
    },
    async getFirebaseToken(device: string) {
        try {
            // Check the return type when task https://upstars.atlassian.net/browse/CP-2047 solves
            const { data } = await publicApiV1<FirebaseTokenResponse>({
                url: "/firebase/token",
                type: () => "Firebase.V1.PublicSecured.Token.Get",
                secured: true,
                data: {
                    filter: { device },
                },
            });

            return { data: [ data ] };
        } catch (error: unknown) {
            log.error("GET_FIREBASE_TOKEN", error);
        }
    },
};
