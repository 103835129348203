<script lang="ts" setup>
import FeButton, { themes } from "@ui-kit/FeButton/index.vue";
import { iconsOutline } from "@theme/configs/iconsOutline";
import bus from "@bus";
import { useChats } from "@store/chats";
import { storeToRefs } from "pinia";

const { isShowSupportChatBadge } = storeToRefs(useChats());

function openChat() {
    bus.$emit("chat.toggle");
}
</script>

<template>
    <div v-if="isShowSupportChatBadge" class="support-chat-badge">
        <FeButton
            class="support-chat-badge__button"
            :icon="iconsOutline.support"
            :theme="themes.SecondaryMedium"
            @click="openChat"
        />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.support-chat-badge {
    position: fixed;
    bottom: 6rem;
    right: 0;
    z-index: $POPUP_INDEX;

    @include media(M) {
        bottom: 3rem;
    }

    &__button {
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 1rem 0 0 1rem;
    }
}
</style>
