<script lang="ts" setup>
import { COINS } from "@config/cashbox";
import { BONUS_KIND_BONUS_DETAIL_MAP } from "@controllers/services/bonuses/config";
import { BonusDetailType, type IBonusDetailWagering } from "@controllers/services/bonuses/detailTypes";
import type { IBonusData } from "@controllers/services/bonuses/types";
import { useCurrencyView } from "@controllers/services/currency-view";
import { useParserContent } from "@helpers/ParserContent";
import { routeNames } from "@router/routeNames";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";
import { computed } from "vue";
import BonusStatusBadge from "../components/BonusStatusBadge.vue";
import BonusTypeBadge from "../components/BonusTypeBadge.vue";
import { useI18n } from "vue-i18n";

import { useRouter } from "vue-router";
const router = useRouter();

const { currencyView } = useCurrencyView();
const { parseContent } = useParserContent();
const i18n = useI18n();

interface IProps {
    data: IBonusData;
}
const props = defineProps<IProps>();

const bonusText = computed<string | undefined>(() => {
    const { kind, details } = props.data;
    const key = BONUS_KIND_BONUS_DETAIL_MAP[kind].cardShortView;
    const bonusDetail = key ? details[key] : undefined;
    if (bonusDetail && bonusDetail.type === BonusDetailType.CASH) {
        return currencyView(bonusDetail.amount, bonusDetail.currency);
    }
});

const wagering = computed<IBonusDetailWagering | undefined>(() => {
    const { details } = props.data;
    const wageringDetail = details.wagering;
    if (wageringDetail && wageringDetail.type === BonusDetailType.WAGERING && wageringDetail.max > 0) {
        return wageringDetail;
    }
});

const wageringText = computed(() => {
    if (wagering.value) {
        const { max, value, currency } = wagering.value;
        const maxFormatted = max / COINS;
        const valueFormatted = value / COINS;

        return `${i18n.t("GIFT.WAGERING")}: ${valueFormatted} / ${maxFormatted} ${currency}`;
    }
    return "";
});

const bonusTitle = computed(() => {
    return parseContent(props.data.title, props.data.localisationVariables);
});
function clickHandler() {
    router.push({ name: routeNames.profileBonuses });
}
</script>

<template>
    <section class="bonus-item-short" @click="clickHandler">
        <div>
            <div class="bonus-item-short__header">
                <BonusTypeBadge :type="data.type" />
                <BonusStatusBadge :data="data" />
            </div>
            <div class="bonus-item-short__title">
                <div class="bonus-item-short__title-label">
                    {{ bonusTitle }}
                </div>
                <div class="bonus-item-short__title-bonus">
                    {{ bonusText }}
                </div>
            </div>
            <div class="bonus-item-short__wagering">
                <FeProgressBar
                    v-if="wagering"
                    :to="wagering.max"
                    :progress="wagering.value"
                    big
                >
                    <template #inside>
                        <div class="text--align-center">
                            {{ wageringText }}
                        </div>
                    </template>
                </FeProgressBar>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.bonus-item-short {
    background: $color-gradient-2;
    cursor: pointer;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    &__title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        &-label {
          color: $color-text-body;
          @include font-size--Caption1($font-weight--normal);
        }
        &-bonus {
          @include font-size--Caption1($font-weight--bold);
        }
    }
    &__wagering {
        min-height: 1rem;
    }
}
</style>
