import getUserDataModalStep from "@helpers/getUserDataModalStep";
import openUserData from "@helpers/openUserData";

export default async function restrictNoFilledUser(): Promise<boolean> {
    const step = getUserDataModalStep();

    if (step) {
        return new Promise((resolve) => {
            openUserData({
                props: {
                    step,
                    completeCallback(status: boolean) {
                        resolve(status);
                    },
                },
            });
        });
    }

    return Promise.resolve(true);
}
