import { COINS } from "@config/cashbox";
import { PAGE_TYPE } from "@config/mainPages";
import { useCurrencyView } from "@controllers/services/currency-view";
import { useI18n } from "vue-i18n";
import { useUserBalance } from "@store/userBalance";
import { useMultiLang } from "@store/multilang";
import { COUNTRIES } from "@theme/configs/constsLocales";
import { v4 as uuid } from "uuid";
import { computed, type Ref } from "vue";

import bankBR from "./images/bank-br.png";
import bankCA from "./images/bank-ca.png";
import bankDE from "./images/bank-de.png";
import bank from "./images/bank.png";
import maxWin from "./images/max-win.png";
import support from "./images/support.png";
import time from "./images/time.png";
import top from "./images/top.png";
import crypto from "./images/crypto.png";

export interface IAdvantage {
    id: string|number;
    image: string;
    title: string;
    text: string;
    badges?: string[];
}

export function useAdvantagesItemByPage(pageType: Ref<string>, rates: Ref<Record<string, number>>): Ref<IAdvantage[]> {
    const i18n = useI18n();
    const userBalanceStore = useUserBalance();
    const multiLangStore = useMultiLang();
    const { currencyView } = useCurrencyView();

    const DEFAULT_SUM = 37_900;
    const DEFAULT_RATE = 1;

    return computed(() => {
        const ratesSum = rates.value[userBalanceStore.userCurrency] || DEFAULT_RATE;
        const rateUserSum = Number((DEFAULT_SUM * ratesSum).toFixed(0));

        const minMaxTitle = currencyView(rateUserSum * COINS);

        let bankImage = bank;
        let topTitle = "ADVANTAGES.TOP_TITLE";
        let topText = "ADVANTAGES.TOP_TEXT";

        switch (multiLangStore.userGeo) {
            case COUNTRIES.GERMANY:
                bankImage = bankDE;
                break;
            case COUNTRIES.CANADA:
                bankImage = bankCA;
                topTitle = "ADVANTAGES.TOP_TITLE_CA";
                topText = "ADVANTAGES.TOP_TEXT_CA";
                break;
            case COUNTRIES.BRASILIA:
                bankImage = bankBR;
                topTitle = "ADVANTAGES.TOP_TITLE_BR";
                topText = "ADVANTAGES.TOP_TEXT_BR";
                break;
            case COUNTRIES.AUSTRALIA:
                topTitle = "ADVANTAGES.TOP_TITLE_AU";
                topText = "ADVANTAGES.TOP_TEXT_AU";
        }

        const bankItem = {
            id: uuid(),
            image: bankImage,
            title: i18n.t("ADVANTAGES.BANK_TITLE"),
            text: i18n.t("ADVANTAGES.BANK_TEXT"),
        };
        const timeItem = {
            id: uuid(),
            image: time,
            title: i18n.t("ADVANTAGES.TIME_TITLE"),
            text: i18n.t("ADVANTAGES.TIME_TEXT"),
        };
        const supportItem = {
            id: uuid(),
            image: support,
            title: i18n.t("ADVANTAGES.SUPPORT_TITLE"),
            text: i18n.t("ADVANTAGES.SUPPORT_TEXT"),
        };
        const topItem = {
            id: uuid(),
            image: top,
            title: i18n.t(topTitle) as string,
            text: i18n.t(topText) as string,
            badges: [ i18n.t("ADVANTAGES.BADGE_1") ],
        };
        const maxWinItem = {
            id: uuid(),
            image: maxWin,
            title: i18n.t(String(minMaxTitle) || ""),
            text: i18n.t("ADVANTAGES.MAX_WIN_TEXT"),
            badges: [ i18n.t("ADVANTAGES.BADGE_1") ],
        };
        const cryptoItem = {
            id: uuid(),
            image: crypto,
            title: i18n.t("ADVANTAGES.CRYPTO_TITLE"),
            text: i18n.t("ADVANTAGES.CRYPTO_TEXT"),
        };

        const defaultList: IAdvantage[] = [
            bankItem,
            timeItem,
            supportItem,
            maxWinItem,
            cryptoItem,
        ];

        const configByPage: Record<string, IAdvantage[]> = {
            default: defaultList,
            [PAGE_TYPE.MAIN]: [
                ...defaultList,
                topItem,
            ],
            [PAGE_TYPE.CASINO]: [
                ...defaultList,
                topItem,
            ],
        };
        return configByPage[pageType.value] || configByPage.default;
    });
}
