<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import { useCurrentGame } from "@store/currentGame";
import { useUserInfo } from "@store/userInfo";
import { useObserveGameLoadTime } from "@controllers/services/performance/gameLoadTime";
import performanceService from "@controllers/services/performance";
import { PerformanceKey } from "@controllers/services/performance/config";
import GamePagePreview from "@components/GamePagePreview/GamePagePreview.vue";
import GameFrameBase from "./GameFrameBase.vue";

const router = useRouter();
const userInfoStore = useUserInfo();
const currentGameStore = useCurrentGame();
const { observeGameLoadTime } = useObserveGameLoadTime();

const loading = ref(true);

const isGameHasDemo = computed(() => {
    return Boolean(currentGameStore.gameData?.hasDemoMode);
});
const isGamePreview = computed(() => {
    if (userInfoStore.isLogged || !currentGameStore.gameData?.id) {
        return false;
    }

    return !isGameHasDemo.value;
});

const allowFullscreen = computed(() => {
    const excludedProviders: unknown[] = [
        "1spin4win",
        "1x2gaming",
        "alg",
        "amatic",
        "apparat",
        "aristocrat",
        "belatra",
        "betsoft",
        "bgaming",
        "booming",
        "booming_games",
        "el_casino",
        "ela_gaming",
        "endorphina",
        "evolution_",
        "evolution_gaming",
        "gameart",
        "habanero",
        "habanero_",
        "igrosoft",
        "imagine_live",
        "isoftbet",
        "kalamba",
        "netent",
        "novomatic",
        "novomatic_",
        "novomatic__",
        "nucleus",
        "onetouch",
        "pariplay",
        "platipus",
        "playngo",
        "popiplay",
        "pragmatic",
        "pragmaticplay",
        "pragmaticplay_",
        "pragmaticplaylive",
        "pragmaticplaylive_",
        "quickspin",
        "quickspin_",
        "rival_games",
        "skywind",
        "spinomenal",
        "tomhorn",
        "vivogaming",
        "voltent",
        "wazdan",
        "zillion",
    ];
    return !excludedProviders.includes(currentGameStore?.gameData?.gameProviderSlug);
});
const withReferrerPolicy = computed(() => {
    const providers: unknown[] = [
        "evolution_",
        "pragmaticplay_",
        "quickspin_",
        "habanero_",
    ];

    return providers.includes(currentGameStore?.gameData?.gameProviderSlug);
});

function frameLoad() {
    const time = performanceService.finishMeasure(PerformanceKey.GAME_START);
    if (time && currentGameStore.gameData) {
        observeGameLoadTime(currentGameStore.gameData, time);
    }
    loading.value = false;
}

function resumePlay() {
    if (currentGameStore.gameData) {
        router.push({ name: routeNames.gameItem, params: { name: currentGameStore.gameData.slug } });
    }
}
function closeGame() {
    currentGameStore.clear();
}
function messageEventHandler(event: MessageEvent) {
    if (event.data.type === "rgs-backToHome") {
        window.location.href = event.data.mainDomain;
    }
}

onMounted(() => {
    window.addEventListener("message", messageEventHandler);
});
onBeforeUnmount(() => {
    window.removeEventListener("message", messageEventHandler);
});

watch(
    () => currentGameStore.gameLoading,
    (value) => {
        if (value) {
            loading.value = true;
        }
    },
);
</script>

<template>
    <GameFrameBase
        :game-name="currentGameStore.gameData?.name"
        :preview="isGamePreview"
        :loading="loading"
        @close="closeGame"
        @resume="resumePlay"
    >
        <template v-if="!isGamePreview">
            <iframe
                v-if="allowFullscreen"
                class="game-frame__iframe"
                frameborder="0"
                allowFullScreen="true"
                webkitAllowFullScreen="true"
                :referrerpolicy="withReferrerPolicy ? 'origin' : undefined"
                :src="currentGameStore.gameUrl || undefined"
                @load="frameLoad"
            />
            <iframe
                v-else
                class="game-frame__iframe"
                frameborder="0"
                :src="currentGameStore.gameUrl || undefined"
                @load="frameLoad"
            />
        </template>
        <GamePagePreview v-else />
    </GameFrameBase>
</template>

<style scoped>
.game-frame__iframe {
    pointer-events: all;
    width: 100%;
    height: 100%;
}
</style>
