<script setup lang="ts">
import type { ServerInputRules } from "@mixins/ServerInput";
import { computed, onBeforeUnmount, onMounted, ref, watch, inject } from "vue";
import { useI18n } from "vue-i18n";
import { FORM_VALIDATOR_INJECTION_KEY } from "@components/FormValidator/injectionKeys";

interface IFieldValidatorProps {
    errorComponent?: Record<string, unknown>;
    manualValidate?: boolean;
    rules?: ServerInputRules;
    value: string;
    name?: string;
}

const formValidator = inject(FORM_VALIDATOR_INJECTION_KEY);

const props = withDefaults(defineProps<IFieldValidatorProps>(), {
    manualValidate: false,
    rules: () => [],
});

const i18n = useI18n();

const showError = ref(false);
const valid = ref(true);
const errorMessage = ref("");
const currentError = computed(() => {
    for (let index = 0; index < props.rules.length; index++) {
        const rule = props.rules[index];
        const _valid = typeof rule === "function" ? rule(props.value) : rule;
        if (typeof _valid === "string" || _valid?.reason) {
            return _valid;
        }
    }
    return false;
});

const clearError = () => {
    showError.value = false;
    valid.value = true;
};
const hideError = () => {
    showError.value = false;
};
const validate = () => {
    if (typeof currentError.value === "object" && currentError.value.reason) {
        const limit = currentError.value.reasonTemplateParameters?.["{{ limit }}"];
        const params: Record<string, string> = {};
        if (limit) {
            params.limit = limit;
        }
        errorMessage.value = i18n.t(`VALIDATION_BACK.${currentError.value.reason}`, params);
    } else if (currentError.value && currentError.value.includes("_")) {
        errorMessage.value = i18n.t(`VALIDATION_BACK.${currentError.value}`);
    } else if (currentError.value) {
        errorMessage.value = currentError.value;
    }
    showError.value = Boolean(currentError.value);
    valid.value = typeof currentError.value !== "string";
    return valid.value;
};

watch(() => props.value, () => {
    showError.value = false;
    valid.value = true;
    if (!props.manualValidate) {
        validate();
    }
});
watch(() => currentError.value, () => {
    if (!props.manualValidate) {
        validate();
    }
});

onMounted(() => {
    if (formValidator) {
        formValidator.connect({
            fieldName: props.name ? String(props.name) : null,
            errorMessage: errorMessage.value,
            clearError,
            validate,
        });
    }
});

onBeforeUnmount(() => {
    if (formValidator) {
        formValidator.disconnect({
            fieldName: props.name ? String(props.name) : null,
            errorMessage: errorMessage.value,
            clearError,
            validate,
        });
    }
});

defineExpose({ validate, clearError, fieldName: props.name ? String(props.name) : null });
</script>

<template>
    <div class="field">
        <slot
            :valid="valid"
            :message="errorMessage"
            :show-error="showError"
            :hide-error="hideError"
            :clear-error="clearError"
        />
    </div>
</template>

<style>
.field {
    width: 100%;
}
</style>
