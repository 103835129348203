export enum FeTooltipThemes {
    primary = "primary",
    secondary = "secondary",
    success = "success",
    alert = "alert",
    danger = "danger",
}

export enum FeTooltipPosition {
    top = "top",
    left = "left",
    right = "right",
    bottom = "bottom",
    under = "under",
}
