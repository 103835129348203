export const DELAYS = {
    // 15 minutes in ms - time to show popup after registration
    AFTER_REGISTRATION_SHOW: 1000 * 60 * 5,
    // 72 hours in ms - popup appears in this interval
    BETWEEN_SHOWS: 1000 * 60 * 60 * 24,
    // 2 minutes in ms - delay between trying to show popup after trigger
    // (in case it can be blocked by other conditions)
    BETWEEN_TRY_TO_SHOW_POPUP: 1000 * 60 * 2,
    // 31 day in seconds - time to forget long term cookies in strategy
    COOKIE_FORGET_DELAY: 60 * 60 * 24 * 31,
};

export const COOKIE_KEYS = {
    USER_REGISTRATION_TIME: "ios-meta-escape-scope.user-register",
    LAST_TIME_SHOWED: "ios-meta-escape-scope.last-time-popup-showed",
    ANDROID_WEBVIEW_AUTO_ESCAPE_TRY: "android-meta-escape-scope.auto-escape-try",
};
