import type { RouteLocationRaw } from "vue-router";

export enum BonusDetailKey {
    BONUS = "bonus", // many variations of value, can be an array of values (in case of group gift), percent, cash, fs
    COINS = "coins", // number
    XP = "xp", // number
    SPINS = "spins", // number
    GAME = "game", // name with link
    BET = "bet", // cash
    MAX_BONUS = "maxBonus", // cash
    WAGERING = "wagering", // two number [current / max]
    WAGER = "wager", // multiplier
    MIN_DEPOSIT = "minDeposit", // cash
    CASHBACK = "cashback", // cash
    RECEIVED_CASHBACK = "receivedCashback", // cash amount
    RECEIVED_BONUS = "receivedBonus", // cash amount
    MAX_WIN = "maxWin", // can be cash or multiplier,
    CASH = "cash", // cash,
    MIN_COEFFICIENT = "minCoefficient", // number
    BET_TYPE = "betType", // text
    MIN_NUMBER_EVENTS = "minNumberEvents", // number
    WIN_LIMIT = "winLimit"
}

export enum BonusDetailType {
    CASH = "cash",
    PERCENT = "percent",
    MULTIPLIER = "multiplier",
    FS = "fs",
    XP = "xp",
    NUMBER = "number",
    WAGERING = "wagering",
    LINK = "link",
    TEXT = "text",
}

export interface IBonusDetailCash {
    type: BonusDetailType.CASH;
    currency: string;
    amount: number;
}
export interface IBonusDetailPercent {
    type: BonusDetailType.PERCENT;
    amount: number;
}
export interface IBonusDetailMultiplier {
    type: BonusDetailType.MULTIPLIER;
    amount: number;
}
export interface IBonusDetailFS {
    type: BonusDetailType.FS;
    amount: number;
}
export interface IBonusDetailNumber {
    type: BonusDetailType.NUMBER;
    amount: number;
}
export interface IBonusDetailWagering {
    type: BonusDetailType.WAGERING;
    value: number;
    max: number;
    currency: string;
}
export interface IBonusDetailLink {
    type: BonusDetailType.LINK;
    name: string;
    link: RouteLocationRaw;
}
export interface IBonusDetailXP {
    type: BonusDetailType.XP;
    amount: number;
}
export interface IBonusDetailText {
    type: BonusDetailType.TEXT;
    i18nKey?: boolean;
    text: string;
    localisationVariables?: Record<string, any>;
}


export type IBonusDetail = IBonusDetailCash
    | IBonusDetailPercent
    | IBonusDetailMultiplier
    | IBonusDetailFS
    | IBonusDetailXP
    | IBonusDetailWagering
    | IBonusDetailLink
    | IBonusDetailText
    | IBonusDetailNumber;
