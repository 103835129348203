<script setup lang="ts">
import { useCountriesData } from "@store/countriesData";
import type { ICountriesFieldProps, FieldRef } from "./types";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { countryRule } from "@mixins/validationRules";
import { useBaseField } from "@ui-kit/FeFields/useBaseField";
import Flag from "@components/CountryFlag/index.vue";
import { FeTooltip, FeTooltipPosition } from "@ui-kit/FeTooltip";
import FieldValidator from "@components/FieldValidator/FieldValidator.vue";

defineOptions({
    name: "CountriesField",
});

const props = withDefaults(defineProps<ICountriesFieldProps>(), {
    dialCodeMode: false,
    positionTooltip: FeTooltipPosition.bottom,
    required: false,
});

const i18n = useI18n();

const fieldRef = ref<FieldRef>(null);
const { rules, inputValue } = useBaseField(props, fieldRef.value);

const phoneCodeStore = useCountriesData();

const countriesPrepared = computed(() => {
    return phoneCodeStore.getCountries.map((country) => {
        const name = country.name.replace(/\(.*?\)/g, "");
        const code = country.code.toUpperCase();

        return { ...country, name, code };
    });
});
const countryRules = computed(() => [ ...rules.value, countryRule ]);
const isAvailableCountry = computed(() => {
    if (inputValue.value) {
        return countriesPrepared.value.find((country) => country.code === inputValue.value);
    }
});

const selected = (event: Event) => {
    // @ts-expect-error Property `value` does not exist on type `EventTarget`
    inputValue.value = event.target?.value ?? "";
};
</script>

<template>
    <FieldValidator
        v-if="phoneCodeStore.getCountries.length > 1"
        ref="fieldRef"
        :name="label"
        :value="inputValue"
        :rules="countryRules"
        manual-validate
    >
        <template #default="{ message, showError }">
            <FeTooltip
                :position="positionTooltip"
                :show-on-hover="false"
                :show="showError"
            >
                <template #activator>
                    <div
                        class="select-data"
                        :class="{ 'select-data--with-label': label, 'disabled': disabled }"
                    >
                        <label
                            v-if="label"
                            class="fe-inputV2__label"
                        >
                            {{ label }}
                            <span v-if="required" class="fe-inputV2__label-required">*</span>
                        </label>
                        <Flag
                            v-if="isAvailableCountry"
                            :code="inputValue.toLowerCase()"
                            :disabled="disabled"
                            class="select-data__flag"
                        />
                        <div
                            v-if="isAvailableCountry && dialCodeMode"
                            class="select-data__dial-code"
                        >
                            {{ "+" + isAvailableCountry?.dialCode }}
                        </div>
                        <select
                            v-model="inputValue"
                            class="country-select"
                            data-test="country-select"
                            :disabled="disabled"
                            @change="selected($event)"
                        >
                            <option :value="(isAvailableCountry) ? '' : inputValue" disabled>
                                {{ i18n.t("REGISTRATION.COUNTRY") }}
                            </option>
                            <option
                                v-for="item in countriesPrepared"
                                :key="item.code"
                                :value="item.code"
                            >
                                {{ dialCodeMode ? `${item.name} (+${item.dialCode})` : item.name }}
                            </option>
                        </select>
                    </div>
                </template>
                {{ message }}
            </FeTooltip>
        </template>
    </FieldValidator>
</template>

<style lang="scss">
@import "~@theme/styles";

.select-data {
    &__flag {
        position: absolute;
        left: 1rem;
        top: calc(50% + 0.25rem);
        z-index: 1;
        overflow: hidden;
        pointer-events: none;

        & + .country-select {
            padding-left: 3rem;
            padding-right: 1.5rem;
        }
    }

    &__dial-code {
        position: absolute;
        left: 3rem;
        top: 50%;
        z-index: 1;
        pointer-events: none;
        @include font-size--Caption1($font-weight--normal);
        color: $color-text-alt;


        & + select {
            text-indent: -10000rem;
        }
    }

    &.disabled {
        @include font-color--disabled;
    }
}
</style>
