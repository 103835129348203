import { SelfExclusionStatus } from "@api";
import { useLimitsStore } from "@store/limits";
import { useUserInfo } from "@store/userInfo";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { computed } from "vue";

export function useCoolingOffStatus() {
    const limitsStore = useLimitsStore();
    const userInfoStore = useUserInfo();

    const isCoolingOff = computed(() => {
        if (getFeatureFlags().enableSelfExclusion) {
            return userInfoStore.userInfo.selfExclusionStatus === SelfExclusionStatus.COOLING_OFF_ACTIVE;
        }
        return false;
    });

    const coolingOffDate = computed(() => {
        const date = limitsStore?.selfExclusionLimit?.tillDate;
        if (date) {
            return new Date(date);
        }
    });

    async function checkIsCoolingOff() {
        if (getFeatureFlags().enableSelfExclusion) {
            await Promise.all([
                userInfoStore.isLoggedAsync(),
                limitsStore.loadSelfExclusionLimit(),
            ]);

            return isCoolingOff.value;
        }
        return false;
    }

    return {
        isCoolingOff,
        coolingOffDate,
        checkIsCoolingOff,
    };
}
