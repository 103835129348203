<script setup lang="ts">
import type { IBaseFieldProps, FieldRef } from "./types";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useBaseField } from "@ui-kit/FeFields/useBaseField";
import Flag from "@components/CountryFlag/index.vue";
import { useMultiLang } from "@store/multilang";
import { useUserInfo } from "@store/userInfo";

const props = defineProps<IBaseFieldProps>();

const i18n = useI18n();

const fieldRef = ref<FieldRef>(null);
const { inputValue } = useBaseField(props, fieldRef.value);
const multiLangStore = useMultiLang();
const userInfoStore = useUserInfo();

const languageForSelect = computed(() => {
    return multiLangStore.locales.filter((locale: Record<string, unknown>) => locale.code);
});
const countryByLocale = computed(() => {
    const exceptions = [ "en", "uk" ];

    if (exceptions.includes(inputValue.value)) {
        return "gb";
    } else if (inputValue.value.includes("-")) {
        return inputValue.value.split("-")[1].toLowerCase();
    }

    return inputValue.value;
});

const change = async () => {
    if (userInfoStore.isLogged) {
        await userInfoStore.sendUserData({ localization: inputValue.value });
    }
    multiLangStore.updateUserLocale(inputValue.value);
};
</script>

<template>
    <div class="select-data select-lang">
        <Flag v-if="inputValue" :code="countryByLocale" class="select-lang__flag" />
        <select
            v-model.trim="inputValue"
            class="select-data-field"
            @change="change"
        >
            <option
                v-for="item in languageForSelect"
                :key="item.code"
                :value="item.code"
                style="text-transform: uppercase"
            >
                {{ i18n.t(`PROFILE.LANGUAGE_${ item.name.toUpperCase() }`) }}
            </option>
        </select>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles";

.select-lang {
    position: relative;

    &__flag {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .select-data-field {
        @include font-size--Caption2($font-weight--bold);
        padding-left: 3rem;
        padding-right: 2rem;
        background: $color-tertiary-3;
        color: $color-text-body;

        option {
            text-transform: uppercase;
        }
    }
}
</style>
