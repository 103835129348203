import { VerificationsStatus } from "@api";
import bus from "@bus";
import { VerificationCardType } from "@controllers/services/user/verificationService";
import Toast from "@plugins/Toast";
import { useUserInfo } from "@store/userInfo";
import { onBeforeUnmount, onMounted } from "vue";

import { useI18n } from "vue-i18n";

export function useKycStrategies() {
    const i18n = useI18n();
    const userInfoStore = useUserInfo();

    interface IVerificationTypeMap {
        [key: string]: Record<string, string>;
    }

    const VerificationTitle: IVerificationTypeMap = {
        [VerificationCardType.PAYMENT]: {
            [VerificationsStatus.APPROVED]: i18n.t("VERIFICATION.NOTICE.PAYMENT_TITLE_APPROVED"),
            default: i18n.t("VERIFICATION.NOTICE.PAYMENT_TITLE_RECHECK"),
        },
        [VerificationCardType.IDENTITY]: {
            [VerificationsStatus.APPROVED]: i18n.t("VERIFICATION.NOTICE.IDENTITY_TITLE_APPROVED"),
            default: i18n.t("VERIFICATION.NOTICE.IDENTITY_TITLE_RECHECK"),
        },
    };

    const VerificationText: Record<string, string> = {
        [VerificationsStatus.RECHECK]: i18n.t("VERIFICATION.NOTICE.TEXT_RECHECK"),
        [VerificationsStatus.REJECTED]: i18n.t("VERIFICATION.NOTICE.TEXT_REJECT"),
        default: "",
    };

    function noticeTitle(status: string, vType: VerificationCardType): string {
        return VerificationTitle[vType]?.[status] || VerificationTitle[vType].default;
    }

    function noticeText(status: VerificationsStatus): string {
        return VerificationText[status] || VerificationText.default;
    }

    function statusChanged(data: unknown, verificationType: VerificationCardType) {
        const status: VerificationsStatus | undefined = (data as Record<string, VerificationsStatus>)?.status;

        if (status && status !== VerificationsStatus.PENDING) {
            Toast.show({
                title: noticeTitle(status, verificationType),
                text: noticeText(status),
            });
        }
        userInfoStore.loadUserProfile({ reload: true });
    }

    onMounted(() => {
        bus.$on("websocket.kycVerification.paymentMethod.statusUpdated",
            (data: unknown) => statusChanged(data, VerificationCardType.PAYMENT));
        bus.$on("websocket.kycVerification.identity.statusUpdated",
            (data: unknown) => statusChanged(data, VerificationCardType.IDENTITY));
    });

    onBeforeUnmount(() => {
        bus.$off("websocket.kycVerification.paymentMethod.statusUpdated",
            (data: unknown) => statusChanged(data, VerificationCardType.PAYMENT));
        bus.$off("websocket.kycVerification.identity.statusUpdated",
            (data: unknown) => statusChanged(data, VerificationCardType.IDENTITY));
    });
}
