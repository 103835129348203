<template>
    <div
        class="footer-payments"
        main-layout-width
        grid-full-width
        gap-s
    >
        <h2 class="text--align-center">
            {{ $t("FOOTER.PAYMENTS") }}
        </h2>
        <CarouselScroll class="footer-payments__carousel">
            <template v-for="payment in payments" :key="payment">
                <PaymentLogo :payment="payment" @click="handlePaymentClick" />
            </template>
        </CarouselScroll>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import { CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import { paymentsByCountries } from "@components/PaymentLogo/paymentsInfo";
import PaymentLogo from "@components/PaymentLogo/index.vue";
import { useMultiLang } from "@store/multilang";
import { useUserInfo } from "@store/userInfo";
import bus from "@bus";

const router = useRouter();
const userInfoStore = useUserInfo();
const multiLangStore = useMultiLang();

const handlePaymentClick = async () => {
    const isLogged = await userInfoStore.isLoggedAsync();

    if (!isLogged) {
        return router.push({ name: routeNames.login });
    }


    bus.$emit("open-cashbox", { step: CASHBOX_DEPOSIT_STEP, location: "footer" });
};

const payments = computed(() => {
    const paymentsValue = paymentsByCountries[userInfoStore.isLogged ? userInfoStore.userCountryCode : multiLangStore.userGeo];

    if (paymentsValue) {
        return paymentsValue;
    }

    return paymentsByCountries.default;
});

</script>
<style lang="scss">
@import "~@theme/styles";

.footer-payments {
    padding: 1.5rem 0;
    margin: 0 auto;

    @include media(M) {
        padding: 2rem 0;
    }

    &__carousel {
        --carousel-item-width: 6rem;
        --carousel-gap: 1rem;
    }
    .payments-logo {
        cursor: pointer;
    }
}
</style>
