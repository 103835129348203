export function blockScroll() {
    document.documentElement.style.overflow = "hidden";
}
export function unblockScroll() {
    document.documentElement.style.overflow = "";
}

export function scrollToElement(
    element: HTMLElement,
    offset = 200,
    minTopOffset = 100,
    minBottomOffset = 100,
) {
    const rect = element.getBoundingClientRect();
    const isElementInView = (
        rect.top >= minTopOffset &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - minBottomOffset &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );

    window.scrollTo({
        top: window.scrollY,
        behavior: "auto",
    });
    if (!isElementInView) {
        const scrollPosition = window.scrollY + rect.top - offset;
        window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
        });

        // scroll inertia fix
        setTimeout(() => {
            window.scrollTo({
                top: scrollPosition,
                behavior: "smooth",
            });
        }, 500);
    }
}
