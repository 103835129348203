<script setup lang="ts">
import { type IUserNotificationResource, NotificationStatus } from "@api";
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";
import { useContextStore } from "@store/context";
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { routeNames } from "@router/routeNames";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import EnterBtns from "@components/EnterBtns/index.vue";
import UserBalance from "@components/UserBalance/index.vue";
import UserBalanceNew from "@components/UserBalance/UserBalanceNew.vue";
import FeNotice from "@ui-kit/FeNotice/index.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import { useBodyScrollLock } from "@helpers/bodyScrollLock";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { iconsOutline } from "@theme/configs/iconsOutline";
import { iconsNavigation } from "@theme/configs/iconsNavigation";
import FeAccordion from "@ui-kit/FeAccordion/index.vue";
import ChatList from "@components/ChatList/index.vue";
import isExternalUrl from "@helpers/isExternalUrl";
import FeScroll from "@ui-kit/FeScroll/FeScroll.vue";
import LocalTime from "@components/LocalTime/LocalTime.vue";
import { CASHBOX_DASHBOARD_STEP } from "@config/cashbox";
import bus from "@bus";
import CustomBadge from "@ui-kit/CustomBadge/CustomBadge.vue";
import { CustomBadgeThemes } from "@ui-kit/CustomBadge/config";
import VipManager from "@components/VipManager/VipManager.vue";
import { useUserStatusService } from "@controllers/services/user";
import { useChats } from "@store/chats";
import { useUserNotice } from "@store/userNotice";
import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import { useUserInfo } from "@store/userInfo";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { useGlobalEventStore, GlobalEventIcon } from "@modules/GlobalEvent";

const { userVipStatus } = useUserStatusService();
const chatsStore = useChats();
const userNoticeStore = useUserNotice();
const userInfoStore = useUserInfo();
const { isDev } = storeToRefs(useEnvironment());
const i18n = useI18n();
const { isBonusesEnabled } = useBonusFeatureService();
const globalEventStore = useGlobalEventStore();

interface IMenuItem {
    title: string;
    icon: string;
    routeName: string;
    hide?: boolean;
    new?: boolean;
    seasonal?: boolean;
    dataTest?: string;
    soon?: boolean;
}

const sidebarRef = ref<HTMLElement | null>(null);
const open = ref(false);

useBodyScrollLock(sidebarRef, open);

const classes = computed(() => ({
    "sidebar--open": open.value,
    "sidebar--login": userInfoStore.isLogged,
}));

const { isMobile } = storeToRefs(useContextStore());

const noticeNewCounter = computed<number>(() => {
    return notice.value.filter((el) => el.status === NotificationStatus.NEW).length;
});

const sidebarLinksSoon = computed<IMenuItem[]>(() => {
    return sidebarLinks.value.filter((link) => link.soon);
});

const sidebarLinks = computed<IMenuItem[]>(() => {
    return [
        {
            title: i18n.t("HEADER.BETS"),
            icon: iconsNavigation.bet,
            routeName: routeNames.bettingPage,
        },
        {
            title: i18n.t("HEADER.ESPORT"),
            icon: iconsNavigation.eSport,
            routeName: routeNames.esportPage,
        },
        {
            title: i18n.t("HEADER.PROMOTIONS"),
            icon: iconsNavigation.arena,
            routeName: routeNames.promotionsDashboard,
        },
        {
            title: i18n.t("HEADER.BONUSES"),
            icon: iconsNavigation.bonuses,
            routeName: routeNames.casinoBonusPage,
        },
        {
            title: i18n.t("GLOBAL_EVENT.SANTA_LAPLAND_LINK"),
            icon: GlobalEventIcon,
            seasonal: true,
            hide: !globalEventStore.isActive,
            dataTest: "sidebar-global-event",
            routeName: routeNames.christmasPage,
        },
        {
            title: i18n.t("SIDEBAR.VIP_CLUB"),
            icon: iconsNavigation.vip,
            routeName: routeNames.vipClubPage,
            new: !globalEventStore.isActive,
        },
        {
            title: i18n.t("HEADER.PROFILE"),
            icon: iconsNavigation.profile,
            hide: !userInfoStore.isLogged,
            routeName: routeNames.profile,
        },
        {
            title: i18n.t("HEADER.FAQ"),
            icon: iconsNavigation.faq,
            routeName: routeNames.faqPage,
        },
    ];
});

const notice = computed<IUserNotificationResource[]>(() => {
    const list = [ ...userNoticeStore.userNotice ];
    // @ts-expect-error Property next does not exist on type
    return list.sort((current) => current.status !== NotificationStatus.NEW || current.next);
});

function closeSideBar(direction: MouseEvent | string | undefined) {
    if ((typeof direction !== "string" && open.value) || direction === "right") {
        bus.$emit("sidebar.close");
    }
}

function noticeOpenBlank(button: { link: string; name: string; }) {
    return button?.link ? isExternalUrl(button.link) : undefined;
}

function sidebarOverlay(visible: boolean) {
    if (typeof document === "undefined") {
        return;
    }
    const app = document.getElementById("app");
    if (app) {
        if (visible) {
            app.classList.add("sidebar__overlay");
        } else {
            app.classList.remove("sidebar__overlay");
        }
    }
}

function openCashbox() {
    bus.$emit("open-cashbox", { location: "sidebar", step: CASHBOX_DASHBOARD_STEP });
}

const showVipManager = computed(() => {
    return (userVipStatus || isDev) && userInfoStore.vipManagerData?.id;
});

onMounted(() => {
    bus.$on("sidebar.open", () => {
        open.value = true;
        sidebarOverlay(true);
    });

    bus.$on("sidebar.close", () => {
        open.value = false;
        sidebarOverlay(false);
    });
});

</script>

<template>
    <div
        id="sidebar"
        ref="sidebarRef"
        v-click-outside="closeSideBar"
        v-touch:swipe.stop="closeSideBar"
        class="sidebar"
        :class="classes"
        @click="closeSideBar"
    >
        <div class="sidebar__header" padding-s>
            <div class="sidebar__header-title">
                {{ i18n.t("SIDEBAR.HEADER_TITLE_MORE") }}
            </div>
            <FeIcon :icon="FeIconConfig.close" />
        </div>
        <FeScroll>
            <EnterBtns
                v-if="!userInfoStore.isLogged"
                v-show="userInfoStore.userInfoLoaded"
                location="sidebar"
                gap-s
            />
            <template v-if="userInfoStore.isLogged">
                <UserBalanceNew
                    v-if="isBonusesEnabled"
                    class="sidebar__user-balance"
                >
                    <template #right>
                        <FeButton
                            :theme="FeButtonConfig.themes.PrimarySmall"
                            @click="openCashbox"
                        >
                            {{ i18n.t("PROFILE.BALANCE_CASHBOX") }}
                        </FeButton>
                    </template>
                </UserBalanceNew>
                <UserBalance
                    v-else
                    class="sidebar__user-balance"
                >
                    <FeButton
                        :theme="FeButtonConfig.themes.PrimarySmall"
                        @click="openCashbox"
                    >
                        {{ i18n.t("PROFILE.BALANCE_CASHBOX") }}
                    </FeButton>
                </UserBalance>
            </template>

            <div class="sidebar__menu" @click.stop>
                <div v-if="userInfoStore.isLogged" class="sidebar__new-menu">
                    <div v-show="noticeNewCounter" v-touch:swipe.stop>
                        {{ i18n.t("SIDEBAR.NOTICE_TITLE") }}
                        <span class="sidebar__new-menu-counter">
                            {{ noticeNewCounter }}
                        </span>
                        <FeScroll>
                            <div class="sidebar__new-menu-notice-list">
                                <FeNotice
                                    v-for="(item, index) in notice"
                                    :key="index"
                                    :notice="item"
                                    new-style
                                    :blank="noticeOpenBlank(item.button)"
                                />
                            </div>
                        </FeScroll>
                    </div>
                </div>
                <div class="sidebar__menu-links" @click="bus.$emit('sidebar.close')">
                    <template v-for="(link, index) of sidebarLinks" :key="index">
                        <FeLink
                            v-if="!link.soon && !link.hide"
                            :to="{ name: link.routeName }"
                            class="sidebar__menu-link"
                            :data-test="link.dataTest"
                            :class="[
                                link.routeName ? `sidebar__menu-link--${link.routeName}` : '',
                                { 'sidebar__menu-link--seasonal': link.seasonal },
                            ]"
                        >
                            <FeIcon class="sidebar__menu-link-image" :icon="link.icon" />
                            <div class="sidebar__menu-link-wrapper">
                                <span>{{ link.title }}</span>
                                <CustomBadge
                                    v-if="link.new"
                                    :theme="CustomBadgeThemes.additional"
                                    uppercase
                                >
                                    {{ i18n.t("COMMON.NEW") }}
                                </CustomBadge>
                                <CustomBadge
                                    v-if="link.seasonal"
                                    :theme="CustomBadgeThemes.secondary"
                                >
                                    {{ i18n.t("SIDEBAR.SEASONAL") }}
                                </CustomBadge>
                            </div>
                        </FeLink>
                        <FeAccordion
                            v-if="link.routeName === routeNames.faqPage"
                            :key="'support' + index"
                        >
                            <template #activator="{toggleHandler, showContent}">
                                <div
                                    class="sidebar__menu-link sidebar__menu-link--support"
                                    :class="{'active': showContent}"
                                    data-test="chat-button"
                                    @click.stop="toggleHandler"
                                >
                                    <FeIcon class="sidebar__menu-link-image" :icon="iconsOutline.support" />
                                    {{ i18n.t("HEADER.SUPPORT") }}
                                    <div
                                        v-if="chatsStore.newMessageCount"
                                        class="sidebar__counter"
                                    >
                                        {{ chatsStore.newMessageCount }}
                                    </div>
                                    <FeIcon
                                        class="sidebar__menu-link-arrow"
                                        :icon="FeIconConfig.dropdownDown"
                                    />
                                </div>
                            </template>

                            <ChatList
                                key="chat-list"
                                class="sidebar__chat-list"
                            />
                        </FeAccordion>
                    </template>
                    <div class="sidebar__vip-manager">
                        <VipManager v-if="showVipManager" />
                    </div>
                    <div v-if="sidebarLinksSoon.length" class="sidebar__menu-link-title">
                        {{ i18n.t("SIDEBAR.SOON") }}
                    </div>
                    <div
                        v-for="(link, index) of sidebarLinksSoon"
                        :key="index"
                        class="sidebar__menu-link sidebar__menu-link--soon"
                    >
                        <FeImage class="sidebar__menu-link-image" :src="link.icon" alt="soon icon" />
                        {{ i18n.t(link.title) }}
                    </div>
                </div>
            </div>
        </FeScroll>
        <LocalTime v-if="isMobile" />
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
