import { MODAL_CASHBOX_LIST } from "@helpers/CashboxHelper";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { routeNames } from "@router/routeNames";
import { useContextStore } from "@store/context";
import { useModalsStore } from "@store/modals";
import { onMounted, watch, type WatchStopHandle, onBeforeUnmount } from "vue";
import { useUserInfo } from "@store/userInfo";
import { useEnvironment } from "@store/environment";

import bus from "@bus";
import { type RouteRecordName, useRoute } from "vue-router";
import { getSurveyWidgetAsync, ISurveyWidgetType } from "./script";
import { SURVEY_COLLECTOR_ID, SURVEY_ID, TRY_TO_SHOW_DELAY, SURVEY_SHOW_DELAY } from "./config";

export function useFreshchatSurvey() {
    const userInfoStore = useUserInfo();
    const contextStore = useContextStore();
    const modalsStore = useModalsStore();
    const envStore = useEnvironment();
    const route = useRoute();

    let stopWatch: undefined | WatchStopHandle = undefined;
    let isSurveyShowed = false;
    const showSurvey = promiseMemoizer(async() => {
        if (isSurveyShowed) {
            return;
        }

        const [ widget, context ] = await Promise.all([
            getSurveyWidgetAsync(),
            contextStore.getContext(),
        ]);
        if (!await userInfoStore.isLoggedAsync()) {
            return;
        }

        widget.init({
            type: context.isMobile ? ISurveyWidgetType.COMPACT : ISurveyWidgetType.DEFAULT,
            include_unsubscribe: true,
            delay: 0,
            survey_id: SURVEY_ID,
            survey_collector_id: SURVEY_COLLECTOR_ID,
            contact: {
                work_email: userInfoStore.userEmail,
                first_name: userInfoStore.userInfo?.firstname || "",
                last_name: userInfoStore.userInfo?.lastname || "",
            },
        });
        isSurveyShowed = true;
        stopAndUnwatch();
    });

    const tryToShowSurvey = promiseMemoizer<void>(() => {
        return new Promise((resolve) => {
            const recursiveCondition = () => {
                if (route.name === routeNames.gameItem) {
                    resolve();
                    return;
                }

                const modals = modalsStore.modals;
                const cashboxOpen = modals.some((modal) => MODAL_CASHBOX_LIST.includes(modal.name));

                if (!cashboxOpen) {
                    showSurvey();
                    resolve();
                } else {
                    setTimeout(recursiveCondition, TRY_TO_SHOW_DELAY);
                }
            };
            recursiveCondition();
        });
    });

    let timerId: ReturnType<typeof setTimeout> | undefined = undefined;

    function startDelayTimer() {
        if (!timerId && !isSurveyShowed) {
            timerId = setTimeout(() => {
                tryToShowSurvey();
                timerId = undefined;
            }, SURVEY_SHOW_DELAY);
        }
    }
    function stopDelayTimer() {
        if (timerId) {
            clearTimeout(timerId);
            timerId = undefined;
        }
    }

    function routeChangeHandler(name?: RouteRecordName | null) {
        if (name === routeNames.gameItem) {
            stopDelayTimer();
        } else {
            startDelayTimer();
        }
    }

    function initSurvey() {
        stopWatch = watch(() => route.name, (name) => {
            if (!envStore.isMockerMode) {
                routeChangeHandler(name);
            }
        }, {
            immediate: true,
        });
    }

    function stopAndUnwatch() {
        if (stopWatch) {
            stopWatch();
        }
        stopDelayTimer();
    }

    onMounted(() => {
        bus.$on("user.login", initSurvey);
        bus.$on("user.logout", stopAndUnwatch);
    });
    onBeforeUnmount(() => {
        stopAndUnwatch();
        bus.$off("user.login", initSurvey);
        bus.$off("user.logout", stopAndUnwatch);
    });
}
