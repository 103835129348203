import { gamesAPI } from "@api";
import bus from "@bus";
import { LimitType, useLimitStatusService } from "@controllers/services/limits";
import { useLivespinsStore } from "@modules/Livespins";
import { useTryRunConditions } from "@modules/Livespins/composables/useTryRunConditions";
import { LIVESPINS_GAME_SLUG } from "@modules/Livespins/configs";
import { useGetLivespinsSdk } from "@modules/Livespins/helpers/sdk";
import { routeNames } from "@router/routeNames";
import { useContextStore } from "@store/context";
import { storeToRefs } from "pinia";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export function useLivespinsGameStart(initialSessionId?: string) {
    const livespinsStore = useLivespinsStore();
    const router = useRouter();
    const { showLossLimitPopup } = useLimitStatusService(LimitType.LOSS);
    const { tryRunConditions } = useTryRunConditions();

    const { getLivespinsSdk } = useGetLivespinsSdk();

    const { isMobile } = storeToRefs(useContextStore());

    const gameUrl = ref("");
    async function gameInit() {
        let { authToken } = livespinsStore;
        if (!authToken) {
            const platform = isMobile.value ? "mobile" : "desktop";
            authToken = await gamesAPI.loadLivespinsGameAuthToken(platform, LIVESPINS_GAME_SLUG);
            livespinsStore.setAuthToken(authToken);
        }
        const gamePath = router.resolve({
            name: routeNames.gameLivespins,
            params: {
                sessionId: ":sessionId",
            },
        }).href;

        const backURL = `${window.location.origin}/exit_iframe`;
        const shareURL = window.location.origin + gamePath;

        const sdk = await getLivespinsSdk();
        if (initialSessionId) {
            gameUrl.value = sdk.convert.toPlayURL({
                authToken,
                backURL,
                shareURL,
                sessionId: initialSessionId,
                streamEndRedirect: true,
            });
        } else {
            gameUrl.value = sdk.convert.toLobbyURL({
                authToken,
                backURL,
                shareURL,
                streamEndRedirect: true,
            });
        }
    }

    function setExitUrl() {
        const { fullPath } = router.resolve({
            name: routeNames.casinoPage,
        });
        window.localStorage.setItem("exit_url", encodeURIComponent(window.location.origin + fullPath));
    }

    onMounted(() => {
        setExitUrl();
        tryRunConditions();
        gameInit();
        bus.$on("websocket.loss.limit.reached", showLossLimitPopup);
    });
    onBeforeUnmount(() => {
        bus.$off("websocket.loss.limit.reached", showLossLimitPopup);
    });

    return {
        gameUrl,
    };
}
