import type { IVipManagerResource } from "@api";
import type { QUnitType, OpUnitType } from "dayjs";
import dayjs from "dayjs";
import utsPlugin from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import { useI18n } from "vue-i18n";

type DateProp = dayjs.ConfigType

dayjs.extend(utsPlugin);
dayjs.extend(weekday);
dayjs.extend(timezone);

export const useFormatDate = () => {
    const i18n = useI18n();

    const formatDate = (date: DateProp, format = "YYYY/MM/DD HH:mm") => {
        return dayjs(date).format(format);
    };

    const formatDateL = (date: DateProp, format = "YYYY-MM-DD, HH:mm:ss") => {
        return dayjs(date).format(format);
    };

    const formatDateUS = (date: DateProp, format = "YYYY/MM/DD hh:mm A") => {
        return dayjs(date).format(format);
    };

    const dayMonthYear = (date: DateProp, format = "YYYY/MM/DD") => {
        return dayjs(date).format(format);
    };

    const timeUTC = (date?: DateProp) => {
        const dateOfWeek = i18n.rt(i18n.t(`CALENDAR.WEEK_DAYS.${[ dayjs(date).utc().day() ]}`));
        return `${dateOfWeek}, ${dayjs(date).utc().format("MMM, D, HH:mm")}`;
    };

    const diffDates = (first: DateProp, lastDate = Date.now(), format: QUnitType | OpUnitType) => {
        const date1 = dayjs(first);
        const date2 = dayjs(lastDate);
        return date2.diff(date1, format);
    };

    const timezoneTime = (date: DateProp) => {
        return dayjs.utc(date).tz(dayjs.tz.guess()).format("HH:mm");
    };

    const timezoneSupportHours = (dateStart: IVipManagerResource["dateStart"], dateEnd: IVipManagerResource["dateEnd"]) => {
        const shiftWeekDay = (day: number) => (day + 1) % 7;

        const startDay = dayjs.utc().startOf("week").add(shiftWeekDay(dateStart.weekday), "day");
        const startTime = dayjs(dateStart.date);
        const start = startDay.set("hours", startTime.hour()).set("minutes", startTime.minute()).set("seconds", startTime.second());

        const endDay = dayjs.utc().startOf("week").add(shiftWeekDay(dateEnd.weekday), "day");
        const endTime = dayjs(dateEnd.date);
        const end = endDay.set("hours", endTime.hour()).set("minutes", endTime.minute()).set("seconds", endTime.second());

        return {
            dayStart: dayjs.utc(start).tz(dayjs.tz.guess()).day(),
            dayEnd: dayjs.utc(end).tz(dayjs.tz.guess()).day(),
            timeStart: timezoneTime(start),
            timeEnd: timezoneTime(end),
        };
    };

    return {
        formatDate,
        formatDateL,
        formatDateUS,
        dayMonthYear,
        timeUTC,
        diffDates,
        timezoneTime,
        timezoneSupportHours,
    };
};
