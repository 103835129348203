<script setup lang="ts">
import { v4 as uuid } from "uuid";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import askgamblers from "./images/askgamblers.svg";
import guru from "./images/guru.svg";
import trustPilot from "./images/trustPilot.svg";
import lcb from "./images/lcb.svg";
import crashOfSlots from "./images/crash-of-slots.svg";
import canadaCasino from "./images/canada-casino.svg";
import gamblersConnect from "./images/gamblers-connect.svg";

const views = [
    {
        id: uuid(),
        image: askgamblers,
        link: "https://www.askgamblers.com/online-casinos/reviews/winspirit-casino",
        alt: "askgamblers-icon",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        image: canadaCasino,
        link: "https://casinosincanada.com/casinos/winspirit/#rtp_blocks",
        alt: "canada-casino-icon",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        image: trustPilot,
        link: "https://www.trustpilot.com/review/winspirit.com",
        alt: "trustpilot-icon",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        image: guru,
        link: "https://casinoguru-en.com/winspirit-casino-review",
        alt: "casino-guru-icon",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        image: lcb,
        link: "https://lcb.org/",
        alt: "lcb-icon",
        rel: "noindex nofollow",
    },
    {
        id: uuid(),
        image: crashOfSlots,
        link: "https://clashofslots.com",
        alt: "crash-of-slots-icon",
        rel: "",
    },
    {
        id: uuid(),
        image: gamblersConnect,
        link: "https://gamblersconnect.com/casino/winspirit-casino-review",
        alt: "gamblers-connect-icon",
        rel: "noindex nofollow",
    },
];
</script>

<template>
    <div
        class="overviews"
        main-layout-width
        grid-full-width
        gap-s
    >
        <h2 class="text--align-center">
            {{ $t("FOOTER.OVERVIEWS.TITLE") }}
        </h2>
        <CarouselScroll class="overviews__carousel">
            <FeLink
                v-for="view in views"
                :key="view.id"
                :to="view.link"
                :rel="view.rel"
                target="_blank"
            >
                <FeImage
                    :src="view.image"
                    :alt="view.alt"
                    width="96"
                    height="48"
                />
            </FeLink>
        </CarouselScroll>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles";

.overviews {
    margin: 0 auto;
    padding-bottom: 1.5rem;

    @include media(M) {
        padding-bottom: 2rem;
    }

    &__carousel {
        --carousel-item-width: 6rem;
        --carousel-gap: 1rem;

        @include media(S) {
            .carousel-scroll__list {
                display: flex;
                justify-content: center;
            }

            .carousel-scroll__item {
                width: var(--carousel-item-width);
            }
        }
    }
}
</style>
