<template class="chat-dropdown">
    <Popup
        ref="popupChats"
        activator-fixed
        left
        bottom
        width-auto
    >
        <template #activator="{ isShow }">
            <ChatButton
                class="chat-dropdown__activator"
                :class-name="{ 'active': isShow }"
            />
        </template>
        <template #default="{ hideHandler: closeDropdownBalance }">
            <div class="chat-dropdown__list" padding-s gap-xs>
                <template v-if="showVipManager">
                    <VipManager />
                    <hr>
                </template>
                <div gap-xs>
                    <p class="color-text-alt text--align-center">
                        {{ $t("HEADER.CHAT_TITLE") }}
                    </p>
                    <ChatList :close-handler="closeDropdownBalance" />
                </div>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useEnvironment } from "@store/environment";
import { useUserStatusService } from "@controllers/services/user";
import { useUserInfo } from "@store/userInfo";
import Popup from "@components/Popup/index.vue";
import ChatList from "@components/ChatList/index.vue";
import ChatButton from "@components/ChatButton/index.vue";
import VipManager from "@components/VipManager/VipManager.vue";

const userInfoStore = useUserInfo();
const { isDev } = storeToRefs(useEnvironment());
const { userVipStatus } = useUserStatusService();

const showVipManager = computed(() => {
    return (userVipStatus || isDev) && userInfoStore.vipManagerData?.id;
});
</script>
<style lang="scss" scoped>
@import "~@theme/styles";
.chat-dropdown {
    &__list {
        background-color: $color-layer-alt-2;
        border-radius: 0 0 $border-radius-M $border-radius-M;
        position: relative;
        left: 3.5rem;
        top: 0.75rem;
        width: 100vw;
        box-shadow: 0 3px 3px $color-tertiary-4;
        z-index: 10;

        @include media(M) {
            left: 3rem;
            width: 23rem;
        }

    }
}
</style>
