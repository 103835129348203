import type { IGameRecentResource, IGameResource } from "@api";
import { CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import { MODAL_GAME_NOTE, MODAL_GAME_SUSPENDED } from "@config/modalNames";
import { useUserBalanceServices } from "@controllers/services/user";
import { openCashbox } from "@helpers/CashboxHelper";
import { routeNames } from "@router/routeNames";
import { useModalsStore } from "@store/modals";
import config from "@theme/configs/config";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import iconWager from "@theme/images/badgeIcons/icon-wager-base.svg";

const gameNoteModal = () => import("@modules/Game/components/GameNoteModal/GameNoteModal.vue");
const gameSuspendedModal = () => import("@modules/Game/components/SuspendedModal/SuspendedModal.vue");

export function useGameRestriction(game: IGameResource | IGameRecentResource, openGame: () => void) {
    const modalsStore = useModalsStore();
    const router = useRouter();
    const i18n = useI18n();
    const { commonBonusBalance, realBalance } = useUserBalanceServices();

    const isGameRestriction = computed(() => {
        // user have real balance
        if (realBalance.value >= 0 && commonBonusBalance.value === 0) {
            return false;
        }
        // game just for real balance
        if (realBalance.value > 0 && commonBonusBalance.value > 0 && !game.isActiveBonusBalance) {
            return false;
        }
        // game with active bonus balance and Bonus wagering percent < 100
        if (game.isActiveBonusBalance && game.sumWageringMultiplier >= 100) {
            return false;
        }
        return true;
    });

    function closeButtonHandler() {
        modalsStore.closeModal(MODAL_GAME_NOTE);

        router.push({
            name: routeNames.casinoCategory,
            params: {
                slug: config.gameSlugs.bonusWagering,
            },
        });
    }

    async function actionButtonHandler() {
        modalsStore.closeModal(MODAL_GAME_NOTE);

        if (game.isActiveBonusBalance) {
            if (openGame) {
                await openGame();
            }
            return;
        }
        return await openCashbox({ step: CASHBOX_DEPOSIT_STEP, location: MODAL_GAME_NOTE });
    }

    function getPropsModal() {
        let title = i18n.t("GAME_NOTE_MODAL.TITLE_REAL_ONLY");
        let description = i18n.t("GAME_NOTE_MODAL.DESCRIPTION_REAL_ONLY");
        const gameData = {
            image: game.preview,
            name: game.name,
        };
        let balance: Record<string, number> | null = {
            real: realBalance.value,
            bonus: commonBonusBalance.value,
        };
        const actionButton = {
            text: i18n.t("GAME_NOTE_MODAL.BUTTON_TEXT_REAL_ONLY"),
            actionButton: actionButtonHandler,
            closeButton: closeButtonHandler,
        };

        if (game.isActiveBonusBalance) {
            title = i18n.t("GAME_NOTE_MODAL.TITLE_ACTIVE_BONUS", { wager: game.sumWageringMultiplier });
            description = i18n.t("GAME_NOTE_MODAL.DESCRIPTION_ACTIVE_BONUS", { imgSrc: iconWager });
            actionButton.text = i18n.t("GAME_NOTE_MODAL.BUTTON_TEXT_ACTIVE_BONUS");
            balance = null;
        }
        return {
            title,
            description,
            game: gameData,
            balance,
            actionButton,
        };
    }

    async function showRestrictionModal() {
        const propsModal = getPropsModal();
        const noteModal = await gameNoteModal();

        modalsStore.showModal({
            new: true,
            component: noteModal.default,
            name: MODAL_GAME_NOTE,
            props: propsModal,
        });
    }

    return {
        isGameRestriction,
        showRestrictionModal,
    };
}

export function useGameSuspendedModal() {
    const modalsStore = useModalsStore();

    async function showSuspendedModal(mainPageButton?: boolean) {
        const suspendedModal = await gameSuspendedModal();

        modalsStore.showModal({
            new: true,
            component: suspendedModal.default,
            disableBackdropClosing: mainPageButton,
            name: MODAL_GAME_SUSPENDED,
            showFirst: true,
            props: {
                mainPageButton,
            },
        });
    }

    return {
        showSuspendedModal,
    };
}
