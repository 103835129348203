import { useDataFillingForm } from "@modules/Auth/RegistrationSteps/dataFillingForm";
import { Step } from "@modules/Auth/RegistrationSteps/constants";
import { useModalsStore } from "@store/modals";
import { useUserStrategies } from "@store/userStrategies";

const registrationSteps = () => import("@modules/Auth/RegistrationSteps/RegistrationSteps.vue");
const registrationStepsBrazil = () => import("@modules/Auth/RegistrationSteps/BrazilFlow.vue");

export default async function openUserData({
    props,
    showFirst = false,
}: {
    props: Record<string, unknown>;
    showFirst?: boolean;
}) {
    const { showModal } = useModalsStore();
    const userStrategiesStore = useUserStrategies();
    const { setShowDataFillingModal } = useDataFillingForm();
    const isStepBrazil = userStrategiesStore.isBrazilFlow && (!props?.step || props.step === Step.BRAZIL);

    if (showFirst) {
        setShowDataFillingModal();
    }

    const moduleComponent = await (isStepBrazil ? registrationStepsBrazil() : registrationSteps());

    showModal({
        name: "registration-steps",
        component: moduleComponent.default,
        new: true,
        showFirst,
        props,
    });
}
