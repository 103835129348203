<script setup lang="ts">
import type { NavigationFailure } from "vue-router";
import { routeNames } from "@router/routeNames";
import FeImage from "@ui-kit/FeImage/index.vue";
import imageLogo from "@theme/images/BrandImages/logo.png";
import imageLogoRetina from "@theme/images/BrandImages/logo@2x.png";
import textLogo from "@theme/images/BrandImages/text-casino.svg";
import manifest from "@theme/manifest.json";

interface ILogoProps {
    full?: boolean
    closeHandler?(): void;
}

const props = withDefaults(defineProps<ILogoProps>(), {
    full: false,
});

const title = manifest.name;

function clickLogo(event: MouseEvent, navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>) {
    if (props.closeHandler) {
        event.preventDefault();
        props.closeHandler();
    } else {
        navigate(event);
    }
}
</script>

<template>
    <router-link
        v-slot="{ navigate, href }"
        custom
        :to="{ name: routeNames.main }"
    >
        <a
            v-bind="$attrs"
            :href="href"
            class="logo"
            :title="title"
            @click="clickLogo($event, navigate)"
        >
            <FeImage
                class="logo__img"
                :src="imageLogo"
                :src-retina="imageLogoRetina"
                :alt="title"
            />
            <FeImage
                v-if="full"
                class="logo__text"
                :src="textLogo"
                :alt="title"
            />
        </a>
    </router-link>
</template>

<style lang="scss" src="./style.scss"></style>
