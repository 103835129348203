import type { IGameResource } from "@api";
import { useContextStore } from "@store/context";
import { defineStore } from "pinia";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

export const LIVESPINS_LOBBY_ID = "LIVESPINS_LOBBY" as const;

export const useCurrentGame = defineStore("currentGame", () => {
    const { isMobile } = storeToRefs(useContextStore());

    const gameData = ref<IGameResource | null>(null);
    const livespinsSession = ref<string | typeof LIVESPINS_LOBBY_ID>();

    function setLivespinsSession(session: string | typeof LIVESPINS_LOBBY_ID) {
        clearGame();
        livespinsSession.value = session;
    }
    function clearLivespinsSession() {
        livespinsSession.value = undefined;
    }
    function clearGame() {
        gameData.value = null;
    }

    function setGame(game: IGameResource) {
        clearLivespinsSession();
        gameData.value = game;
    }

    function clear() {
        clearGame();
        clearLivespinsSession();
    }

    const gameUrl = computed<string | null>(() => {
        if (gameData.value?.id) {
            const demoPathPart = isDemo.value ? "/demo" : "";
            const platform = isMobile.value ? "mobile" : "desktop";

            return `/games${demoPathPart}/start/${platform}/${gameData.value.slug}?exit_url=exit_iframe`;
        }
        return null;
    });

    const gameLoading = ref(false);
    function setGameLoading(value: boolean) {
        gameLoading.value = value;
    }

    const isDemo = ref(false);
    function setGameDemo(boolean: boolean) {
        isDemo.value = boolean;
    }

    return {
        livespinsSession,
        setLivespinsSession,
        clearLivespinsSession,

        gameData,
        setGame,
        clearGame,
        gameUrl,
        gameLoading,
        setGameLoading,
        isDemo,
        setGameDemo,

        clear,
    };
});
