import { defineStore } from "pinia";
import { v4 as uuid } from "uuid";
import { computed, ref } from "vue";
import type { OnboardScenario } from "../types";

type CurrentOnboardScenario = {
    id: string;
    scenario: OnboardScenario;
    currentStep: number;
    isActive: boolean;
    timeout?: ReturnType<typeof setTimeout>;
};

export const useOnboardStore = defineStore("onboardStore", () => {
    const currentScenario = ref<CurrentOnboardScenario>();

    function initScenario(scenario: OnboardScenario) {
        clearScenario();
        currentScenario.value = {
            scenario,
            currentStep: 0,
            id: uuid(),
            isActive: false,
        };
    }

    function activateScenario() {
        const currentScenarioValue = currentScenario.value;
        if (currentScenarioValue) {
            currentScenario.value = {
                ...currentScenarioValue,
                isActive: true,
            };
        }
    }

    function setScenarioTimeout(timeout?: ReturnType<typeof setTimeout>) {
        const currentScenarioValue = currentScenario.value;
        if (currentScenarioValue) {
            clearTimeout(currentScenarioValue.timeout);
            currentScenario.value = {
                ...currentScenarioValue,
                timeout,
            };
        }
    }

    function nextStep() {
        const currentScenarioValue = currentScenario.value;
        if (currentScenarioValue) {
            const step = currentScenarioValue.currentStep + 1;
            if (step >= currentScenarioValue.scenario.items.length) {
                clearScenario();
            } else {
                currentScenario.value = {
                    ...currentScenarioValue,
                    currentStep: step,
                };
            }
        }
    }

    function clearScenario() {
        clearTimeout(currentScenario.value?.timeout);
        currentScenario.value = undefined;
    }

    const currentScenarioItem = computed(() => {
        const currentScenarioValue = currentScenario.value;
        if (currentScenarioValue) {
            return currentScenarioValue.scenario.items[currentScenarioValue.currentStep];
        }
    });

    return {
        currentScenario,
        currentScenarioItem,

        initScenario,
        activateScenario,
        setScenarioTimeout,
        nextStep,
        clearScenario,
    };
});
