// 72 hours in seconds - time between pwa can be displayed
export const TIME_DELAY_BETWEEN_PWA_SHOW = 60 * 60 * 72;
// maximum pwa shows count
export const MAX_SHOW_PWA_COUNT = 3;
// 31 day in seconds - time to forget long term cookies in strategy
export const COOKIE_FORGET_DELAY = 60 * 60 * 24 * 31;
// 15 minutes in seconds - time after registration without showing pwa
export const REGISTER_DELAY = 60 * 15;
// 15 seconds to check balance after withdrawal
export const CASHBOX_WITHDRAWAL_CLOSE_DELAY = 15;
// 1 in user currency (in coins) - for condition to show pwa
export const MIN_USER_BALANCE = 100;

export const DONT_SHOW_PWA_AGAIN_COOKIE = "dont-show-pwa-again";
export const LAST_TIME_SHOW_PWA_COOKIE = "last-time-show-pwa";
export const COUNT_SHOW_PWA_COOKIE = "count-show-pwa";
export const USER_REGISTER_COOKIE = "user-register-pwa-scope";
