import isPwaMode from "@helpers/isPwaMode";
import isPartnerPwa from "@helpers/isPartnerPwa";
import { useGifts } from "@store/gifts";
import cookie from "@front/core/controllers/CookieController";
import Modal from "@modal";

const COOKIE_PWA_LOGGED = "pwa-logged";
const TEN_YEARS_IN_SECONDS = 315360000;

const pwaBonus = () => import("@modules/Popups/PWABonus/index.vue");

export default async function showPwaBonus(route) {
    if (cookie.get(COOKIE_PWA_LOGGED)) {
        return;
    }

    if (isPartnerPwa() || !isPwaMode(route.query?.mode)) {
        return;
    }

    const giftsStore = useGifts();

    await giftsStore.activatePromoGift("pwa_gift");
    giftsStore.loadGiftsData();

    pwaBonus().then((module) => {
        Modal.show({
            name: "pwa-first-login-bonus",
            component: module.default,
            new: true,
        });
    });
    cookie.set(COOKIE_PWA_LOGGED, "true", { expires: TEN_YEARS_IN_SECONDS });
}
