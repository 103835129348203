import { computed, ref } from "vue";
import { defineStore } from "pinia";

export const useWebsocketStatusStore = defineStore("websocketStatus", () => {
    const isConnected = ref(false);

    function setConnected(value: boolean) {
        isConnected.value = value;
    }

    return {
        isConnected: computed(() => isConnected.value),
        setConnected,
    };
});
