import type { IAchievementResource, IPromoDataResource, IPromoPrizeResource, IUserPromoResource } from "@api";
import type { IGameRowParams } from "@components/GameRowNew/types";
import { MODAL_PROMO_RULES } from "@config/modalNames";
import { useCurrencyView } from "@controllers/services/currency-view";
import { PROMO_COINS, PROMOTION_STATUS } from "@controllers/services/promotions";
import { useParserContent } from "@helpers/ParserContent";
import { isServer } from "@helpers/ssrHelpers";
import { useAchievements } from "@modules/Quests";
import { filterActiveAccessSoon } from "@modules/Quests/helpers/sortAchievements";
import { routeNames } from "@router/routeNames";
import { useGamesStore } from "@store/games";
import { useModalsStore } from "@store/modals";
import { usePromo } from "@store/promo";
import { useUserBalance } from "@store/userBalance";
import { mainDomain } from "@theme/configs/configDomains";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { gameCategoryTag } from "../config";

const promoRulesModal = () => import("@pages/PromoPage/components/PromoRulesModal.vue");

export function usePromoPage() {
    const promoAchievements = useAchievements();
    const promoStore = usePromo();
    const userBalanceStore = useUserBalance();
    const i18n = useI18n();
    const modalsStore = useModalsStore();
    const gamesStore = useGamesStore();

    const { parseContent } = useParserContent();
    const { currencyView } = useCurrencyView();

    const currentPromo = computed<IPromoDataResource | undefined>(() => {
        return promoStore.currentPromoData;
    });
    const activePromo = computed<IPromoDataResource | undefined>(() => {
        return promoStore.activePromoData;
    });

    const promoQuests = computed<IAchievementResource[]>(() => {
        let list: IAchievementResource[] = [];

        if (currentPromo.value) {
            list = filterActiveAccessSoon(promoAchievements.userAchievementByPromoId(currentPromo.value.id));
        }
        return list;
    });

    const hostName = computed(() => (isServer ? mainDomain : location.hostname));

    const promoRules = computed(() => {
        if (currentPromo.value) {
            const parsedContent = currentPromo.value.rules.replace(/%domain%/g, hostName.value);
            return parseContent(parsedContent, currentPromo.value?.localisation?.variables || {});
        }
        return "";
    });

    const exchangeRate = computed(() => {
        const rates = currentPromo.value?.pointPrices;
        return rates ? currencyView(rates[userBalanceStore.userCurrency]) : 0;
    });

    const isFinished = computed(() => {
        return currentPromo.value?.status === PROMOTION_STATUS.PAST;
    });

    const isFuture = computed(() => {
        return currentPromo.value?.status === PROMOTION_STATUS.FUTURE;
    });

    const isActive = computed(() => {
        return activePromo.value?.status === PROMOTION_STATUS.ACTIVE;
    });

    const activePromoCoinsUserBalance = computed<number>(() => {
        const activePromoValue = activePromo.value;

        if (activePromoValue?.id) {
            const action = promoStore.userPromo?.find((uPromo: IUserPromoResource) => {
                return uPromo.id === activePromoValue.id;
            });

            if (action) {
                return Math.floor(action.points / PROMO_COINS);
            }
        }
        return 0;
    });

    const currentPromoCoinsUserBalance = computed<number>(() => {
        if (currentPromo.value?.id) {
            const action = promoStore.userPromo?.find((uPromo: IUserPromoResource) => {
                return uPromo.id === currentPromo.value?.id;
            });

            if (action) {
                return Math.floor(action.points / PROMO_COINS);
            }
        }
        return 0;
    });

    const currentPromoDescription = computed(() => {
        return parseContent(currentPromo.value?.steps ?? "", currentPromo.value?.localisation?.variables || {});
    });

    async function openRulesModal() {
        const module = await promoRulesModal();

        modalsStore.showModal({
            new: true,
            name: MODAL_PROMO_RULES,
            component: module.default,
            props: {
                description: currentPromoDescription,
            },
        });
    }

    async function participateInPromo(promo: IPromoDataResource): Promise<void> {
        if (promo?.id) {
            await promoStore.participateInPromotions(promo.id);
        }
    }

    const gameRowParams = computed<IGameRowParams>(() => {
        return {
            games: gamesStore.getSlugData(gameCategoryTag).items,
            path: {
                name: routeNames.casinoCategory,
                params: { slug: gameCategoryTag },
            },
            title: i18n.t("PROMO.PROMO_GAMES"),
        };
    });

    const isMembershipInCurrentPromo = computed(() => {
        return promoStore.userPromo?.find((promo: IUserPromoResource) => {
            return promo.id === currentPromo.value?.id && promo.membership;
        });
    });
    const isCurrentPromoStatusActive = computed(() => {
        return currentPromo.value?.status === PROMOTION_STATUS.ACTIVE;
    });

    function isEnoughCoins(cPrize: IPromoPrizeResource) {
        const uCoins: number = currentPromo.value?.id ?
            Number(currentPromoCoinsUserBalance.value) :
            Number(activePromoCoinsUserBalance.value);

        return uCoins >= cPrize.price / PROMO_COINS;
    }

    return {
        promoQuests,
        currentPromo,
        activePromo,
        promoRules,
        exchangeRate,
        currentPromoDescription,
        isFinished,
        isFuture,
        isActive,
        activePromoCoinsUserBalance,
        currentPromoCoinsUserBalance,
        isMembershipInCurrentPromo,
        isCurrentPromoStatusActive,
        openRulesModal,
        participateInPromo,

        gameRowParams,
        isEnoughCoins,
    };
}
