import type { IBaseFieldProps, FieldRef } from "./types";
import { computed } from "vue";

export const useBaseField = <T extends IBaseFieldProps>(props: T, fieldRef: FieldRef) => {
    const inputValue = computed({
        get: () => props.input.value,
        set: (value: string) => (props.input.value = value),
    });
    const rules = computed(() => props.input.rules);

    const validate = () => fieldRef?.validate();

    return { inputValue, rules, validate };
};
