import Modal from "@modal";
import { CASHBOX_EVENTS } from "@config/gtagEvents";
import { gtag } from "@plugins/gtagPlugin";
import { CASHBOX_DASHBOARD_STEP, CASHBOX_DEPOSIT_STEP, CASHBOX_WITHDRAW_STEP } from "@config/cashbox";
import { useGifts } from "@store/gifts";
import { useCashbox } from "@store/cashbox";
import { useUserBalance } from "@store/userBalance";
import restrictNoFilledUser from "@helpers/restrictNoFilledUser";

import * as modalNames from "@config/modalNames";
import {
    MODAL_CASHBOX,
    MODAL_CASHBOX_DEPOSIT,
    MODAL_CASHBOX_SERVICE_WORKS,
    MODAL_CASHBOX_WITHDRAWAL,
    MODAL_CASHBOX_WITHDRAWAL_MESSAGES,
    MODAL_CASHBOX_WITHDRAWAL_REQUESTS,
} from "@config/modalNames";
import { useUserBalanceServices } from "@controllers/services/user";
import { useCashboxServiceWorks } from "@controllers/services/cashbox/serviceWorks";

const cashboxDashboard = () => import("@modules/Cashbox/CashboxDashboard/index.vue");
const cashboxStepDeposit = () => import("@modules/Cashbox/CashboxStepDeposit/index.vue");
const cashboxStepWithdraw = () => import("@modules/Cashbox/CashboxStepWithdraw/index.vue");
const cashboxWithdrawRequests = () => import("@modules/Cashbox/CashboxStepWithdrawRequests/index.vue");
const cashboxWithdrawMessages = () => import("@modules/Cashbox/CashboxStepWithdrawMessages/index.vue");
const cashboxServiceWorks = () => import("@modules/Cashbox/CashboxServiceWorks/CashboxServiceWorks.vue");

const showCashboxDashboard = () => {
    cashboxDashboard().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX,
            component: module.default,
            disableBackdropClosing: true,
            new: true,
        });
        gtag("event", CASHBOX_EVENTS.DASHBOARD.OPEN);
    });
};

const showCashboxStepDeposit = () => {
    cashboxStepDeposit().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX_DEPOSIT,
            component: module.default,
            disableBackdropClosing: true,
            new: true,
        });
        gtag("event", CASHBOX_EVENTS.DEPOSIT.OPEN);
    });
};

const showCashboxStepWithdraw = () => {
    cashboxStepWithdraw().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX_WITHDRAWAL,
            component: module.default,
            disableBackdropClosing: true,
            new: true,
        });
    });
    gtag("event", CASHBOX_EVENTS.WITHDRAW.OPEN);
};

const showCashboxWithdrawRequests = () => {
    cashboxWithdrawRequests().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX_WITHDRAWAL_REQUESTS,
            component: module.default,
            disableBackdropClosing: true,
            new: true,
        });
    });
};

const showCashboxWithdrawMessages = () => {
    cashboxWithdrawMessages().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX_WITHDRAWAL_MESSAGES,
            component: module.default,
            disableBackdropClosing: true,
            new: true,
        });
    });
};

const showCashboxServiceWorks = () => {
    cashboxServiceWorks().then((module) => {
        Modal.show({
            name: modalNames.MODAL_CASHBOX_SERVICE_WORKS,
            component: module.default,
            new: true,
        });
    });
};

const openCashbox = async(config) => {
    const { location = "", step } = config;

    const checkUser = await restrictNoFilledUser();

    if (!checkUser) {
        return;
    }
    const giftStore = useGifts();
    const cashboxStore = useCashbox();
    const userBalanceStore = useUserBalance();
    const { isWithdrawalBlocked } = useUserBalanceServices();
    const { isServiceWorks } = useCashboxServiceWorks();

    if (await isServiceWorks()) {
        return showCashboxServiceWorks();
    }

    userBalanceStore.loadWinbackData();

    let eventName = CASHBOX_EVENTS.DEPOSIT.CLICK;

    switch (step) {
        case CASHBOX_DASHBOARD_STEP:
            eventName = CASHBOX_EVENTS.DASHBOARD.CLICK;

            showCashboxDashboard();
            break;
        case CASHBOX_DEPOSIT_STEP:
            if (cashboxStore.withdrawRequests.length > 0) {
                showCashboxWithdrawRequests();
                break;
            }
            showCashboxStepDeposit();
            break;
        case CASHBOX_WITHDRAW_STEP:
            eventName = CASHBOX_EVENTS.WITHDRAW.CLICK;

            if (isWithdrawalBlocked.value || giftStore.payoutGiftCount > 0) {
                showCashboxWithdrawMessages();
                break;
            }
            showCashboxStepWithdraw();
            break;
        default:
            showCashboxStepDeposit();
    }

    if (location) {
        gtag("event", eventName, { location });
    }
};

export const MODAL_CASHBOX_LIST = [
    MODAL_CASHBOX,
    MODAL_CASHBOX_DEPOSIT,
    MODAL_CASHBOX_WITHDRAWAL,
    MODAL_CASHBOX_WITHDRAWAL_MESSAGES,
    MODAL_CASHBOX_WITHDRAWAL_REQUESTS,
    MODAL_CASHBOX_SERVICE_WORKS,
];

export {
    showCashboxDashboard,
    showCashboxStepWithdraw,
    showCashboxStepDeposit,
    showCashboxWithdrawRequests,
    showCashboxWithdrawMessages,
    openCashbox,
};
