<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { isServer } from "@helpers/ssrHelpers";
import { debounce } from "@helpers/debounce";
import { useContextStore } from "@store/context";
import { addEventListenerUtil, getScrollPosition } from "./utils";
import { FeIconConfig } from "@theme/configs/icons";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { useUserInfo } from "@store/userInfo";
import GDPRCountries from "@modules/CookiesAccepted/GDPRCountries";
import cookie from "@front/core/controllers/CookieController";
import { useMultiLang } from "@store/multilang";

let topPosition = 0;
let stopScrollHandler: ReturnType<typeof addEventListenerUtil> | null = null;
const SCREEN_COUNT = 3;

const { isMobile } = storeToRefs(useContextStore());
const { userGeo } = storeToRefs(useMultiLang());
const { isLoggedAsync } = useUserInfo();

const isVisible = ref(false);
const isLogged = ref(false);

const isCookiePopup = computed(() => {
    const COOKIES_KEY = "cookies_accepted";
    return GDPRCountries.includes(userGeo.value) && !cookie.get(COOKIES_KEY);
});

const styles = computed(() => {
    if (isServer) {
        return;
    }

    if (isMobile.value) {
        if (isLogged.value) {
            return { "--bottom": "4.5rem" };
        }
        return { "--bottom": "8rem" };
    }

    if (isCookiePopup.value && !isMobile.value) {
        return { "--bottom": "5rem" };
    }
});

const scrollHandler = () => {
    topPosition = getScrollPosition("top");

    isVisible.value = topPosition > window.innerHeight * SCREEN_COUNT;
};

const scrollToTop = () => {
    if (isServer) {
        return;
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

onMounted(async () => {
    const handler = debounce(scrollHandler, 50);
    stopScrollHandler = addEventListenerUtil({ type: "scroll", handler });
    isLogged.value = await isLoggedAsync();
});

onBeforeUnmount(() => {
    if (stopScrollHandler) {
        stopScrollHandler();
    }
});
</script>

<template>
    <FeButton
        class="back-to-top"
        :class="{ 'is-hidden': !isVisible }"
        :style="styles"
        :theme="FeButtonConfig.themes.SecondaryMedium"
        @click="scrollToTop"
    >
        <FeIcon :icon="FeIconConfig.arrowUp" />
    </FeButton>
</template>

<style lang="scss">
@import "~@theme/styles";

.back-to-top {
    padding: 0.5rem;
    position: fixed;
    bottom: var(--bottom, 2rem);
    right: 0.5rem;
    z-index: 15;

    @include media(M) {
        right: 2rem;
    }

    &.is-hidden {
        opacity: 0;
        z-index: 0;
    }

    .fe-icon {
        display: flex;
    }
}
</style>
