import { onMounted, watch } from "vue";
import { useUserInfo } from "@store/userInfo";
import { useGamesRecentStore } from "../store/useGamesRecentStore";

export function useGamesRecentController() {
    const userInfoStore = useUserInfo();
    const gamesRecentStore = useGamesRecentStore();

    onMounted(async () => {
        const isLogged = await userInfoStore.isLoggedAsync();
        if (isLogged) {
            await gamesRecentStore.reload();
        }
        gamesRecentStore.setInitPending(false);
    });

    watch(() => userInfoStore.isLogged, (value) => {
        if (!gamesRecentStore.initPending) {
            if (value) {
                gamesRecentStore.reload();
            } else {
                gamesRecentStore.clear();
            }
        }
    });
}
