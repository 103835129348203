export function debounce(func: () => void, ms = 200) {
    let timer: ReturnType<typeof setTimeout> | null = null;

    return (...args: []) => {
        const onComplete = () => {
            // @ts-expect-error this is type of any
            // eslint-disable-next-line no-invalid-this
            func.apply(this, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };
}
