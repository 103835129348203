import { useModalsStore } from "@store/modals";

const commonErrorPopup = () => {
    return import("@modules/Popups/CommonError/CommonErrorPopup.vue");
};

export function useCommonErrorPopup() {
    const modalsStore = useModalsStore();

    async function showCommonErrorPopup() {
        const module = await commonErrorPopup();

        modalsStore.showModal({
            name: "common-error",
            component: module.default,
            new: true,
            showFirst: true,
        });
    }

    return {
        showCommonErrorPopup,
    };
}
