<template>
    <div v-show="modalsStore.isModalShown" class="modal">
        <transition-group tag="div" :name="isMobile ? 'modal-mobile' : 'modal'">
            <template v-for="(modal, index) in modalsStore.modals" :key="modal.name">
                <ModalWrapper
                    v-if="modal.new"
                    v-show="index === 0"
                    :data="modal"
                    :close="close"
                />
                <Modal
                    v-else
                    v-show="index === 0"
                    :key="modal.name"
                    :data="modal"
                    @close="(immediate) => { close(modal.name, immediate) }"
                >
                    <template #default="{ closeHandler }">
                        <component
                            :is="modal.component"
                            v-bind="modal.props"
                            :close-handler="closeHandler"
                        />
                    </template>
                </Modal>
            </template>
        </transition-group>
    </div>
</template>

<script setup lang="ts">
import { useContextStore } from "@store/context";
import { storeToRefs } from "pinia";
import { useModalsStore } from "@store/modals";

import Modal from "@modules/Modal/index.vue";
import ModalWrapper from "@components/ModalWrapper.vue";

const modalsStore = useModalsStore();
const { isMobile } = storeToRefs(useContextStore());


function close(name: string, immediate = false) {
    modalsStore.closeModal(name, immediate);
}
</script>

<style lang="scss" src="./style.scss"></style>
