<script lang="ts" setup>
import { iconsOutline } from "@theme/configs/iconsOutline";
import { routeNames } from "@router/routeNames";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { useGifts } from "@store/gifts";
import { useGiftsStoreV2 } from "@store/giftsV2";
import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import { useRouter } from "vue-router";
import { computed } from "vue";

const router = useRouter();
const giftsStore = useGifts();
const giftsStoreV2 = useGiftsStoreV2();
const { isBonusesEnabled } = useBonusFeatureService();

function buttonClick() {
    router.push({
        name: isBonusesEnabled.value ? routeNames.profileBonuses : routeNames.profileGifts,
    });
}

const count = computed(() => {
    if (isBonusesEnabled.value) {
        return giftsStoreV2.giftsCount;
    }
    return giftsStore.getGiftCounter;
});
</script>

<template>
    <div class="gift-button">
        <FeButton
            :theme="FeButtonConfig.themes.GhostSmall"
            :icon="iconsOutline.giftBox"
            @click="buttonClick"
        />
        <div
            v-if="count"
            class="gift-button__counter"
        >
            {{ count }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "~@theme/styles";

.gift-button {
  display: flex;
  position: relative;

  &__counter {
    @include pin;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
