import { computed } from "vue";
import { LIMITS_BY_COUNTRY_EXCLUDE } from "@config/limits";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { useEnvironment } from "@store/environment";
import { useUserInfo } from "@store/userInfo";

export function useLimitsFeatureService() {
    const envStore = useEnvironment();
    const userInfoStore = useUserInfo();

    const isLimitsEnabled = computed(() => {
        const { isLogged, userCountryCode } = userInfoStore;
        const limitByCountry = !isLogged || !LIMITS_BY_COUNTRY_EXCLUDE.includes(userCountryCode);
        const limitByFeature = getFeatureFlags().enableLimits || envStore.isDev;

        return limitByCountry && limitByFeature;
    });

    return {
        isLimitsEnabled,
    };
}
