import { COUNTRIES } from "@theme/configs/constsLocales";
import type { StyleValue } from "vue";

const PAYMENTS = {
    Pix: "Pix",
    Interac: "Interac",
    Boleto: "Boleto",
    DepositExpress: "DepositExpress",
    Lottery: "Lottery",
    OnlineBankTransfer: "Online-bank-transfer",
    MuchBetter: "MuchBetter",
    Bitcoin: "Bitcoin",
    Ethereum: "Ethereum",
    BitcoinCash: "BitcoinCash",
    XRP: "XRP",
    Dai: "Dai",
    Litecoin: "Litecoin",
    Tether: "Tether",
    USDCoin: "USD-Coin",
    Visa: "Visa",
    Mastercard: "Mastercard",
    Inpay: "Inpay",
    Flexepin: "Flexepin",
    SmartCashew: "Smart-Cashew",
    Maestro: "Maestro",
    Neosurf: "Neosurf",
    IDebit: "IDebit",
    InstaDebit: "InstaDebit",
    DogeCoin: "Doge-Coin",
    TRX: "TRX",
    Binance: "Binance",
    MiFinity: "Mi-Finity",
    PayDo: "PayDo",
    eZeeWallet: "eZeeWallet",
    Poli: "Poli",
    BankUberweisung: "BankUberweisung",
    Sofort: "Sofort",
    BPWallet: "BP_Wallet",
    ApplePay: "ApplePay",
    Node: "Node",
} as const;

type Payment = typeof PAYMENTS[keyof typeof PAYMENTS];

const positionInSprite: Payment[] = [
    PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Maestro, PAYMENTS.Node, PAYMENTS.SmartCashew, PAYMENTS.Neosurf,
    PAYMENTS.Interac, PAYMENTS.IDebit, PAYMENTS.InstaDebit, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash,
    PAYMENTS.XRP, PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.USDCoin, PAYMENTS.DogeCoin, PAYMENTS.TRX, PAYMENTS.Tether,
    PAYMENTS.Binance, PAYMENTS.Flexepin, PAYMENTS.MuchBetter, PAYMENTS.Pix, PAYMENTS.Boleto, PAYMENTS.DepositExpress,
    PAYMENTS.Lottery, PAYMENTS.OnlineBankTransfer, PAYMENTS.MiFinity, PAYMENTS.PayDo, PAYMENTS.eZeeWallet,
    PAYMENTS.Poli, PAYMENTS.BankUberweisung, PAYMENTS.Sofort, PAYMENTS.BPWallet, PAYMENTS.Inpay, PAYMENTS.ApplePay,
];

const paymentsByCountries: Record<string, Payment[]> = {
    default: [
        PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Inpay, PAYMENTS.MuchBetter, PAYMENTS.MiFinity, PAYMENTS.eZeeWallet,
        PAYMENTS.SmartCashew, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash, PAYMENTS.XRP, PAYMENTS.Dai,
        PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin, PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.BRASILIA]: [
        PAYMENTS.Pix, PAYMENTS.Boleto, PAYMENTS.DepositExpress, PAYMENTS.Lottery, PAYMENTS.OnlineBankTransfer,
        PAYMENTS.MuchBetter, PAYMENTS.eZeeWallet, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash,
        PAYMENTS.XRP, PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin,
        PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.CANADA]: [
        PAYMENTS.Interac, PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Inpay, PAYMENTS.Flexepin,
        PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash, PAYMENTS.XRP,
        PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin,
        PAYMENTS.DogeCoin, PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.AUSTRALIA]: [
        PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Inpay, PAYMENTS.PayDo, PAYMENTS.eZeeWallet,
        PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash,
        PAYMENTS.XRP, PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin,
        PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.NEW_ZEALAND]: [
        PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Inpay, PAYMENTS.Flexepin, PAYMENTS.MuchBetter, PAYMENTS.MiFinity,
        PAYMENTS.SmartCashew, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash, PAYMENTS.XRP, PAYMENTS.Dai,
        PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin, PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.GERMANY]: [
        PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Node, PAYMENTS.Inpay, PAYMENTS.MuchBetter, PAYMENTS.MiFinity,
        PAYMENTS.eZeeWallet, PAYMENTS.SmartCashew, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash,
        PAYMENTS.XRP, PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin,
        PAYMENTS.TRX, PAYMENTS.Binance,
    ],
    [COUNTRIES.AUSTRIA]: [
        PAYMENTS.Visa, PAYMENTS.Mastercard, PAYMENTS.Node, PAYMENTS.Inpay, PAYMENTS.MuchBetter, PAYMENTS.MiFinity,
        PAYMENTS.eZeeWallet, PAYMENTS.SmartCashew, PAYMENTS.Bitcoin, PAYMENTS.Ethereum, PAYMENTS.BitcoinCash,
        PAYMENTS.XRP, PAYMENTS.Dai, PAYMENTS.Litecoin, PAYMENTS.Tether, PAYMENTS.USDCoin, PAYMENTS.DogeCoin,
        PAYMENTS.TRX, PAYMENTS.Binance,
    ],
};

function imageStyles(payment: Payment): StyleValue {
    const index = positionInSprite.indexOf(payment) || 0;
    return {
        objectPosition: `-${index * 6}rem 0`,
    };
}

export {
    PAYMENTS,
    type Payment,
    positionInSprite,
    paymentsByCountries,
    imageStyles,
};
