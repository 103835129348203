import type { IGiftCustomFieldsV2Rate, IGiftResourceV2 } from "@api";
import {
    GiftResourceBonusStatus,
    GiftResourceStatus,
    GiftResourceSubtype,
    GiftResourceType,
    IGiftCustomFieldsV2BetType,
} from "@api";
import { Currency } from "@api/resources/commonResources";
import { routeNames } from "@router/routeNames";
import { useUserBalance } from "@store/userBalance";
import { BonusDetailKey, BonusDetailType } from "./detailTypes";
import { BonusKind, BonusStatus, BonusType, GiftStatus, type IBonusData } from "./types";

export function getGiftKind(gift: IGiftResourceV2): BonusKind {
    switch (gift.type) {
        case GiftResourceType.CASHBACK: return BonusKind.CASHBACK;
        case GiftResourceType.XP: return BonusKind.XP;
        case GiftResourceType.GROUP: return BonusKind.GROUP;
        case GiftResourceType.ACTION_CURRENCY: return BonusKind.ACTION_CURRENCY;
        case GiftResourceType.DEPOSIT_BONUS: return BonusKind.DEPOSIT_BONUS;
        case GiftResourceType.CUSTOM_GIFT: return BonusKind.CUSTOM_GIFT;
        case GiftResourceType.BETTING: {
            return gift.customFields?.subtype === GiftResourceSubtype.FREE_BET
                ? BonusKind.BETTING_FREEBET
                : BonusKind.BETTING_WAGERING;
        }
        case GiftResourceType.CASH: return BonusKind.CASH;
        case GiftResourceType.UNIVERSAL_GAMING: {
            return gift.customFields?.subtype === GiftResourceSubtype.GIFT_SPIN
                ? BonusKind.UNIVERSAL_GAMING_FS
                : BonusKind.UNIVERSAL_GAMING_BONUS;
        }
    }

    // fallback
    return BonusKind.DEPOSIT_BONUS;
}

export function getGiftDetails(gift: IGiftResourceV2, currency: Currency): IBonusData["details"] {
    const giftKind = getGiftKind(gift);

    const details: IBonusData["details"] = {};
    const { customFields } = gift;

    // basic details (available almost for all kind of gifts)
    if (gift.bonus && gift.bonus.wager) {
        details[BonusDetailKey.WAGERING] = {
            type: BonusDetailType.WAGERING,
            currency,
            value: gift.bonus.wager.value,
            max: gift.bonus.wager.limit,
        };
    }
    if (gift.restrictions.depositLimit) {
        details[BonusDetailKey.MIN_DEPOSIT] = {
            type: BonusDetailType.CASH,
            currency,
            amount: gift.restrictions.depositLimit,
        };
    }
    const wagerField = customFields?.multiplier || customFields?.wager;
    if (wagerField) {
        details[BonusDetailKey.WAGER] = {
            type: BonusDetailType.MULTIPLIER,
            amount: Math.trunc(wagerField),
        };
    }
    if (customFields?.winLimit) {
        if (customFields.winLimitPercent) {
            details[BonusDetailKey.MAX_WIN] = {
                type: BonusDetailType.MULTIPLIER,
                amount: customFields.winLimit / 100,
            };
        } else {
            details[BonusDetailKey.MAX_WIN] = {
                type: BonusDetailType.CASH,
                currency,
                amount: customFields.winLimit,
            };
        }
    }
    if (customFields?.sumLimit) {
        details[BonusDetailKey.MAX_BONUS] = {
            type: BonusDetailType.CASH,
            currency,
            amount: customFields.sumLimit,
        };
    }

    switch (giftKind) {
        case BonusKind.CASH: {
            if (customFields?.sum) {
                details[BonusDetailKey.CASH] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: customFields.sum,
                };
            }
            break;
        }
        case BonusKind.ACTION_CURRENCY: {
            if (customFields?.sum) {
                details[BonusDetailKey.COINS] = {
                    type: BonusDetailType.NUMBER,
                    amount: customFields.sum,
                };
            }
            break;
        }
        case BonusKind.XP: {
            if (customFields?.amount) {
                details[BonusDetailKey.XP] = {
                    type: BonusDetailType.XP,
                    amount: customFields.amount,
                };
            }
            break;
        }
        case BonusKind.CASHBACK: {
            if (gift.bonus) {
                details[BonusDetailKey.CASHBACK] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.value,
                };
                details[BonusDetailKey.RECEIVED_CASHBACK] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.initial,
                };
            } else if (customFields?.cashbackValue) {
                details[BonusDetailKey.CASHBACK] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: customFields.cashbackValue,
                };
            }
            break;
        }
        case BonusKind.DEPOSIT_BONUS: {
            if (gift.bonus) {
                details[BonusDetailKey.BONUS] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.value,
                };
                details[BonusDetailKey.RECEIVED_BONUS] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.initial,
                };
            } else if (customFields?.sum) {
                if (customFields.sumsAsPercent) {
                    details[BonusDetailKey.BONUS] = {
                        type: BonusDetailType.PERCENT,
                        amount: customFields.sum,
                    };
                } else {
                    details[BonusDetailKey.BONUS] = {
                        type: BonusDetailType.CASH,
                        currency,
                        amount: customFields.sum,
                    };
                }
            }
            break;
        }
        case BonusKind.CUSTOM_GIFT: {
            details[BonusDetailKey.BONUS] = {
                type: BonusDetailType.TEXT,
                text: gift.shortDescription,
                localisationVariables: gift?.localisation?.variables || {},
            };
            break;
        }
        case BonusKind.GROUP: {
            details[BonusDetailKey.BONUS] = {
                type: BonusDetailType.TEXT,
                text: gift.shortDescription,
                localisationVariables: gift?.localisation?.variables || {},
            };
            if (customFields?.giftDetails) {
                const itemWithWager = customFields.giftDetails.find((item) => Boolean(item.wager));
                if (itemWithWager && itemWithWager.wager) {
                    details[BonusDetailKey.WAGER] = {
                        type: BonusDetailType.MULTIPLIER,
                        amount: itemWithWager.wager,
                    };
                }
            }
            break;
        }
        case BonusKind.UNIVERSAL_GAMING_BONUS:
        case BonusKind.UNIVERSAL_GAMING_FS: {
            const meta = customFields?.meta;
            if (meta) {
                if (meta.rounds) {
                    details[BonusDetailKey.SPINS] = {
                        type: BonusDetailType.FS,
                        amount: meta.rounds,
                    };
                }
                let rates: undefined | IGiftCustomFieldsV2Rate[] = undefined;
                if (meta.rates) {
                    rates = meta.rates;
                }
                if (meta.meta && meta.meta.showRatesEnabled && meta.meta.showRates) {
                    rates = meta.meta.showRates;
                }
                if (rates) {
                    const rate = rates.find((_rate) => _rate.currency === currency);
                    if (rate) {
                        details[BonusDetailKey.BET] = {
                            type: BonusDetailType.CASH,
                            currency,
                            amount: rate.bet,
                        };
                    }
                }
            }
            if (customFields?.gameSlug && customFields?.gameTitle) {
                details[BonusDetailKey.GAME] = {
                    type: BonusDetailType.LINK,
                    link: { name: routeNames.gameItem, params: { name: customFields.gameSlug } },
                    name: customFields.gameTitle,
                };
            }
            if (gift.bonus) {
                details[BonusDetailKey.BONUS] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.value,
                };
                details[BonusDetailKey.RECEIVED_BONUS] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.initial,
                };
            }
            break;
        }
        case BonusKind.BETTING_WAGERING:
        case BonusKind.BETTING_FREEBET: {
            if (gift.bonus) {
                details[BonusDetailKey.BONUS] = {
                    type: BonusDetailType.CASH,
                    currency,
                    amount: gift.bonus.value,
                };
            } else if (customFields?.sum) {
                if (customFields.sumsAsPercent) {
                    details[BonusDetailKey.BONUS] = {
                        type: BonusDetailType.PERCENT,
                        amount: customFields.sum,
                    };
                } else {
                    details[BonusDetailKey.BONUS] = {
                        type: BonusDetailType.CASH,
                        currency,
                        amount: customFields.sum,
                    };
                }
            }
            if (customFields?.minNumberOfSelections) {
                details[BonusDetailKey.MIN_NUMBER_EVENTS] = {
                    type: BonusDetailType.NUMBER,
                    amount: Math.trunc(customFields.minNumberOfSelections),
                };
            }
            if (customFields?.allowedBetType) {
                let key: string | undefined = undefined;
                switch (customFields.allowedBetType) {
                    case IGiftCustomFieldsV2BetType.EXPRESS_AND_SINGLE: key = "MULTI_SINGLE"; break;
                    case IGiftCustomFieldsV2BetType.EXPRESS: key = "MULTI"; break;
                    case IGiftCustomFieldsV2BetType.SINGLE: key = "SINGLE"; break;
                }
                details[BonusDetailKey.BET_TYPE] = {
                    type: BonusDetailType.TEXT,
                    i18nKey: true,
                    text: `GIFT.BET_TYPES.${key}`,
                };
            }
            if (customFields?.minWageringOdd) {
                details[BonusDetailKey.MIN_COEFFICIENT] = {
                    type: BonusDetailType.NUMBER,
                    amount: customFields.minWageringOdd,
                };
            }
            break;
        }
    }

    return details;
}

export function getGiftType(gift: IGiftResourceV2): BonusType {
    return gift.type === GiftResourceType.BETTING
        ? BonusType.SPORT
        : BonusType.CASINO;
}

export function getGiftStatus(gift: IGiftResourceV2): GiftStatus {
    switch (gift.status) {
        case GiftResourceStatus.RECEIVED: return GiftStatus.RECEIVED;
        case GiftResourceStatus.ACTIVATED: return GiftStatus.ACTIVATED;
        case GiftResourceStatus.EXPIRED: return GiftStatus.EXPIRED;
        case GiftResourceStatus.CANCELED: return GiftStatus.CANCELED;
        case GiftResourceStatus.USED: return GiftStatus.USED;
        case GiftResourceStatus.REMOVED: return GiftStatus.REMOVED;
    }
}

export function getGiftBonusStatus(gift: IGiftResourceV2): BonusStatus | undefined {
    const type = getGiftType(gift);
    const giftStatus = getGiftStatus(gift);

    if (type === BonusType.SPORT && giftStatus === GiftStatus.ACTIVATED) {
        return gift.customFields?.isQueued ? BonusStatus.WAITING : BonusStatus.ACTIVE;
    }

    if (gift.bonus) {
        switch (gift.bonus.status) {
            case GiftResourceBonusStatus.NEW:
            case GiftResourceBonusStatus.WAIT_ACTIVATION:
            case GiftResourceBonusStatus.WAITING: return BonusStatus.WAITING;

            case GiftResourceBonusStatus.ACTIVATED:
            case GiftResourceBonusStatus.ACTIVE: return BonusStatus.ACTIVE;

            case GiftResourceBonusStatus.FINISHED:
            case GiftResourceBonusStatus.WAGER_DONE: return BonusStatus.WAGER_DONE;

            case GiftResourceBonusStatus.CANCELED: return BonusStatus.CANCELED;
            case GiftResourceBonusStatus.EXPIRED: return BonusStatus.EXPIRED;
            case GiftResourceBonusStatus.LOST: return BonusStatus.LOST;
        }
    }
}

export function checkGiftNoDeposit(gift: IGiftResourceV2): boolean {
    return !gift.restrictions.depositLimit;
}

export function convertToClient(gift: IGiftResourceV2, currency: Currency, order = 0): IBonusData {
    return {
        title: gift.title,
        id: gift.id,
        bonusId: gift.bonus?.id,
        image: gift.image,
        description: gift.description,
        kind: getGiftKind(gift),
        type: getGiftType(gift),
        localisationVariables: gift?.localisation?.variables || {},
        order,
        bonus: gift.bonus?.value,
        wagering: gift.bonus?.wager,
        details: getGiftDetails(gift, currency),
        giftStatus: getGiftStatus(gift),
        bonusStatus: getGiftBonusStatus(gift),
        noDeposit: checkGiftNoDeposit(gift),
        gameSlug: gift.customFields?.gameSlug,
        needEmailVerification: Boolean(gift?.restrictions?.needsValidEmail),
        expires: gift.expiredAt ? Date.parse(gift.expiredAt) : undefined,
    };
}

export function useConvertToClient() {
    const userBalanceStore = useUserBalance();

    return {
        convertToClient: (gift: IGiftResourceV2, order = 0) => {
            return convertToClient(gift, userBalanceStore.userCurrency, order);
        },
    };
}
