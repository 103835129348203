import { isAddressDataSaved, isBrazilDataSaved, isBrazilFlow, isPersonalDataSaved } from "@helpers/checkUserDataSaved";
import { Step } from "@modules/Auth/RegistrationSteps/constants";

export default function getUserDataModalStep(): string | boolean {
    if (isBrazilFlow()) {
        return !isBrazilDataSaved() ? Step.BRAZIL : "";
    }
    if (!isPersonalDataSaved()) {
        return Step.PERSONAL;
    }
    if (!isAddressDataSaved()) {
        return Step.ADDRESS;
    }
    return false;
}
