<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useContextStore } from "@store/context";
import { isGamePage } from "@controllers/services/games/pageService";
import { FeIconConfig } from "@theme/configs/icons";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeWSLoader from "@ui-kit/FeWSLoader/index.vue";

const MOBILE_Z_INDEX = "10000";

type Props = {
    loading?: boolean;
    gameName?: string;
    preview?: boolean;
};
type Emits = {
    (event: "resume"): void;
    (event: "close"): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const router = useRouter();
const contextStore = useContextStore();

const isMinimize = computed(() => {
    return !isGamePage(router.currentRoute.value);
});

const classes = computed(() => {
    return {
        "game-frame--minimized": isMinimize.value,
        "game-frame--loading": props.loading,
        "game-page-preview": props.preview,
    };
});
const styles = computed(() => {
    const stylesObj: Record<string, string> = {};

    if (contextStore.isMobile && isGamePage(router.currentRoute.value)) {
        stylesObj.zIndex = MOBILE_Z_INDEX;
    }

    return stylesObj;
});
</script>

<template>
    <div
        class="game-frame"
        :class="classes"
        :style="styles"
    >
        <div class="game-frame__wrapper">
            <slot :minimize="isMinimize" />
            <div v-if="loading" class="game-frame__loader">
                <FeWSLoader :size="isMinimize ? 4 : 8" />
            </div>
        </div>
        <div v-if="isMinimize" class="game-frame__shell" draggable="false">
            <div class="game-frame__shell-cover" @click="emit('resume')">
                <FeIcon :icon="FeIconConfig.fullscreen" class="game-frame__shell-cover-icon" />
            </div>

            <div class="game-frame__shell-wrapper">
                <div class="game-frame__shell-name">
                    {{ gameName || "" }}
                </div>

                <div class="game-frame__shell-button" @click="emit('close')">
                    <FeIcon :icon="FeIconConfig.close" class="game-frame__shell-button-icon" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles";

$minimize-width: 16rem;
$minimize-height: 9rem;

.game-frame {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    &:not(.game-page-preview) {
        background: $color-black;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    @include media(L) {
        left: calc(23rem + 2rem);
        position: absolute;
        top: 6rem;
        height: calc(100vh - (#{6rem} + 5rem));
        width: calc(100vw - 29rem);
        right: auto;
    }

    &--has-game{
        pointer-events: all;
    }

    &--minimized {
        border-radius: 1rem;
        overflow: hidden;
        display: block;
        position: fixed;
        top: auto;
        left: auto;
        right: 1rem;
        width: $minimize-width;
        height: auto;
        bottom: 6rem;
        z-index: $HEADER_INDEX;

        transform: translate(0, 150%);
        animation-name: show-frame;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 1s;
        animation-iteration-count: 1;
        animation-fill-mode:forwards;

        @include media(M) {
            bottom: 3rem;
        }

        .game-frame__wrapper {
            width: $minimize-width;
            height: $minimize-height;
        }
    }

    &__shell {
        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.5rem;
            padding: 1rem;
            width: 100%;
            box-sizing: border-box;
        }

        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &-name {
            @include font-size--Caption1;
            @include ellipsis;
            user-select: none;
            width: 100%;
        }

        &-cover {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: $minimize-width;
            height: $minimize-height;
            background: $color-tertiary-5;
            color: $color-on-dark-back;
            opacity: 0;
            transition: opacity 0.2s;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    &--minimized &__shell {
        color: $color-text-alt;
        background: $color-layer-alt-2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 100%;
        pointer-events: all;
    }

    &__shell-cover:hover {
        opacity: 1;
    }

    &__iframe {
        pointer-events: all;
        width: 100%;
        height: 100%;
    }

    &--minimized &__iframe {
        width: 100%;
        height: 100%;
    }

    &__loader {
        position: absolute;
        top: 0;
        background: $color-black;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--loading &__iframe {
        visibility: hidden;
    }
}

.game-page-preview {
    @media (max-width: $M) {
        top: 0;
        height: 100%;
    }
}
@keyframes show-frame {
    0% {
        transform: translate(0, 150%);
    }
    50% {
        transform: translate(0, 75%);
    }
    100% {
        transform: translate(0,0);
    }
}
</style>
