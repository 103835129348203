<template>
    <div
        class="trust-block"
        main-layout-width
        grid-full-width
        gap-s
    >
        <div class="trust-block__list">
            <ClientOnly>
                <!-- FP-2361 temporary hidden <FooterLicence v-if="!isSpecialDomain" class="trust-block__list-item" />-->
                <GCBCertificate class="trust-block__list-item" />
            </ClientOnly>
            <template v-for="item in config.trust" :key="item.index">
                <template v-if="showBlock(item)">
                    <FeLink
                        v-if="isTrustLink(item)"
                        :to="item.to"
                        class="trust-block__list-item"
                    >
                        <FeImage :src="item.icon" alt="trust-icon" class="trust-block__list-item" />
                    </FeLink>
                    <FeImage
                        v-else
                        :key="item.index"
                        class="trust-block__list-item"
                        :src="item.icon"
                        alt="trust-icon"
                    />
                </template>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import FooterLicence from "@modules/Footer/components/FooterLicence.vue";
import config from "@theme/configs/config";
import FeImage from "@ui-kit/FeImage/index.vue";
import ClientOnly from "@components/ClientOnly";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { useDomainConfig } from "@controllers/services/domains";
import GCBCertificate from "./GCBCertificate.vue";

const { isSpecialDomain, isLicenseDomain } = useDomainConfig();

function showBlock(item: Record<string, unknown>) {
    return !(item.hideToSpecialDomain && isSpecialDomain.value);
}

function isTrustLink(item: Record<string, unknown>) {
    return item.blank && isLicenseDomain.value;
}
</script>
<style lang="scss">
@import "~@theme/styles";

.trust-block {
    margin: 0 auto;
    border-top: 1px solid $color-tertiary-3;

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;
        justify-content: center;
        margin: 1.5rem 0;

        @include media(M) {
            gap: 1.5rem;
        }

        &-item {
            width: 6rem;
            height: 3rem;
            overflow: hidden;
        }

        & img {
            width: 6rem;
            object-fit: contain;
            max-height: 3rem;
        }
    }
}
</style>
