export enum LimitType {
    DEPOSIT = "deposit",
    LOSS = "loss",
    SELF_EXCLUSION = "self-exclusion",
}

export enum LimitSubtype {
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
}

export enum LimitStatus {
    DISABLED = "disabled",
    PROLONGATION_DISABLE = "prolongation_disabled",
    PROLONGATION_UPDATED = "prolongation_updated",
    WAITING_ACTIVATION = "waiting_activation",
    ACTIVE = "active",
    WAITING_DISABLE = "waiting_disable",
    WAITING_CHANGES = "waiting_changes",
}

export const LIMITS_SUBTYPE_ORDER: Record<LimitSubtype, number> = {
    [LimitSubtype.DAILY]: 1,
    [LimitSubtype.WEEKLY]: 2,
    [LimitSubtype.MONTHLY]: 3,
};
