import { useVerificationService } from "@controllers/services/user/verificationService";
import HeaderEmailVerify from "@modules/Header/components/HeaderEmailVerify.vue";
import HeaderKYCVerify from "@modules/Header/components/HeaderKYCVerify.vue";
import { useUserInfo } from "@store/userInfo";
import { computed } from "vue";

export function useHeaderNotice() {
    const userInfoStore = useUserInfo();
    const { cards, checkVerifyPassed } = useVerificationService();

    const showHeaderNotice = computed(() => {
        if (!userInfoStore.isLogged || !userInfoStore.userInfo.id) {
            return false;
        }

        return !userInfoStore.isVerifiedEmail || !checkVerifyPassed(cards.value);
    });

    const noticeComponent = computed(() => {
        return !userInfoStore.isVerifiedEmail ? HeaderEmailVerify : HeaderKYCVerify;
    });

    return {
        showHeaderNotice,
        noticeComponent,
    };
}
