import type { CreateAppOptions } from "@src/types/common";
import { createApp, createSSRApp } from "vue";
import { createHead } from "@unhead/vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import WS from "@controllers/WebsocketController";
import { createRouter } from "@router";
import guestRouterHook from "@router/hooks/GuestHook";
import cashboxPopupRouterHook from "@router/hooks/CashboxPopupHook";
import redirectsHook from "@router/hooks/RedirectsHook";
import languageHook from "@router/hooks/LanguageHook";
import metaHook from "@router/hooks/metaHook";
import requestGuestHook from "@router/hooks/RequestGuestHook";
import requestAuthHook from "@router/hooks/RequestAuthHook";
import { serverPrefetchMixin } from "@mixins/serverPrefetch";
import { pinia } from "@store/pinia";
import { createI18n } from "@i18n";
import bus from "@bus";
import Modal from "@modal";
import Toast from "@plugins/Toast";
import { installGMP } from "@plugins/gtagPlugin";
import Pagination from "@plugins/Pagination";
import App from "@layouts/App/App.vue";

dayjs.extend(customParseFormat);

export const errorHandler = (levelName: string, label: string, message: string, context: Record<string, string>) => {
    // eslint-disable-next-line no-console
    console.error(JSON.stringify({ label, message, level_name: levelName, context }));
};

export async function generateApp(options: CreateAppOptions) {
    const { baseUrl, piniaInitialState, routerData } = options;

    if (piniaInitialState) {
        pinia.state.value = piniaInitialState;
    }

    WS.init(pinia, bus);
    const router = createRouter(pinia, routerData, baseUrl);
    const i18n = createI18n(pinia);
    Modal.init(pinia);
    Pagination.init();
    Toast.init(bus);

    installGMP(pinia);
    languageHook(router, pinia, i18n.global);
    guestRouterHook(router, pinia, i18n.global);
    cashboxPopupRouterHook(router, pinia);
    redirectsHook(router, bus, pinia);
    metaHook(router, pinia);
    requestGuestHook(router, pinia);
    requestAuthHook(router, pinia);

    const head = createHead();
    const app = import.meta.env.SSR ? createSSRApp(App) : createApp(App);

    if (!DEV) {
        app.config.errorHandler = (error, _vm, trace) => {
            const { message } = error as Record<string, string>;
            errorHandler("ERROR", "GLOBAL_VUE_ERROR_HANDLER", message, { trace });
        };
        app.config.warnHandler = (message, _vm, trace) => {
            errorHandler("WARNING", "GLOBAL_VUE_WARN_HANDLER", message, { trace });
        };
    }

    if (!app.config.globalProperties.$mixinIsInit) {
        app.config.globalProperties.$mixinIsInit = true;
        app.mixin(serverPrefetchMixin);
    }

    // mock for global property this.$isServer
    // remove it when $isServer is no longer used
    app.config.globalProperties.$isServer = import.meta.env.SSR;

    app.use(i18n);
    app.use(pinia);
    app.use(router);
    app.use(head);

    return {
        app,
        bus,
        head,
        router,
        pinia,
        WS,
    };
}
