<script setup lang="ts">
import { ref } from "vue";
import { FeIconConfig } from "@theme/configs/icons";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { useHeaderNotice } from "@modules/Header/composables/useHeaderNotice";

const { showHeaderNotice, noticeComponent } = useHeaderNotice();
const isShowNotice = ref<boolean>(true);

function hideNotice() {
    isShowNotice.value = false;
}
</script>

<template>
    <div
        v-if="showHeaderNotice && isShowNotice"
        class="header-notice"
    >
        <div class="header-notice__text caption-2">
            <component :is="noticeComponent" />
        </div>
        <FeIcon
            :icon="FeIconConfig.close"
            class="header-notice__close"
            @click="hideNotice"
        />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.header-notice {
    background: $color-layer-alt-1;
    color: $color-text-alt;
    position: relative;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(M) {
        padding: 1rem;
    }

    &__text {
        @include media(M) {
            margin-left: auto;
        }
    }

    &__close {
        margin-left: auto;
        width: 1rem;
        height: 1rem;
        display: flex;
        padding-left: 1rem;

        @include media(M) {
            padding: 0;
        }
    }

    :deep(.fe-link) {
        font-weight: $font-weight--bold;
        white-space: nowrap;
        @include link("dark", false);
    }
}
</style>
