export enum FooterMenuType {
    INFO = "info",
    RULES = "rules",
    PAYMENTS = "payments"
}
interface IStringDictionary {
    [key: string]: FooterMenuType;
}

export const slugConfig: IStringDictionary = {
    "bonus": FooterMenuType.INFO,
    "faq": FooterMenuType.INFO,
    "verification": FooterMenuType.INFO,
    "blog": FooterMenuType.INFO,
    "news": FooterMenuType.INFO,
    "terms-and-conditions": FooterMenuType.RULES,
    "bonus-terms-and-conditions": FooterMenuType.RULES,
    "kyc": FooterMenuType.RULES,
    "privacy-policy": FooterMenuType.RULES,
    "sports-betting-rules": FooterMenuType.RULES,
    "sports-bonus-terms-and-conditions": FooterMenuType.RULES,
    "payment-policy": FooterMenuType.PAYMENTS,
    "refund": FooterMenuType.PAYMENTS,
    "aml-policy": FooterMenuType.PAYMENTS,

    // for develop
    "s4tr3ing": FooterMenuType.PAYMENTS,
    "333334433": FooterMenuType.PAYMENTS,
    "SlugStaticPage5": FooterMenuType.PAYMENTS,
    "hello": FooterMenuType.RULES,
    "New-LeagueTest": FooterMenuType.RULES,
    "test": FooterMenuType.RULES,
};
