import { type IUserConsentDTO, userAPI } from "@api";
import { registrationTypes } from "@api/resources/commonResources";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUserConsents = defineStore("userConsents", () => {
    const userConsents = ref<IUserConsentDTO[]>([]);
    const consentsEmail = ref<string>("");

    async function loadUserConsents(token?: string) {
        const response = await userAPI.loadUserConsents(token);
        if (response?.consents) {
            userConsents.value = response.consents;
        }
        if (response?.info?.email) {
            consentsEmail.value = response.info.email;
        }
    }

    async function updateUserConsents(consents: IUserConsentDTO[], token?: string) {
        const response = await userAPI.updateUserConsents(consents, token);

        if (response?.consents) {
            userConsents.value = response.consents;
        }
        return response;
    }

    function cleanUserConsents() {
        userConsents.value = [];
        consentsEmail.value = "";
    }

    const getUserConsentsEmail = computed(() => {
        return userConsents.value.find((consent: IUserConsentDTO) => {
            return consent.type === registrationTypes.EMAIL;
        })?.optedIn;
    });

    return {
        userConsents,
        consentsEmail,
        loadUserConsents,
        updateUserConsents,
        cleanUserConsents,
        getUserConsentsEmail,
    };
});
