<script setup lang="ts">
import type { IModalNew } from "@store/modals";
import { computed, provide, ref } from "vue";
import { useBodyScrollLock } from "@helpers/bodyScrollLock";
import { CLOSE_MODAL_INJECTION_KEY } from "@modules/ModalController/injectionKeys";
import { useModalAnalytics } from "@modules/ModalController/analytics";

interface IProps {
  data: IModalNew;
  close(name: string): void;
}
const props = defineProps<IProps>();

useModalAnalytics(() => props.data.analyticsParams);

const modalWrapperRef = ref(null);
useBodyScrollLock(modalWrapperRef);

const modalProps = computed(() => {
    return props.data.props || {};
});

provide(CLOSE_MODAL_INJECTION_KEY, closeHandler);

function closeBackdropHandler() {
    if (!props.data.disableBackdropClosing) {
        closeHandler();
    }
}

function closeHandler() {
    const { name, callback } = props.data;

    props.close(name);

    if (callback) {
        callback();
    }
}
</script>

<template>
    <div ref="modalWrapperRef" class="modal-wrapper" @click.self="closeBackdropHandler">
        <component :is="data.component" v-bind="modalProps" />
    </div>
</template>

<style>
.modal-wrapper {
    min-height: 100%;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
}
</style>
