import { useDomainConfig } from "@controllers/services/domains";

import { useI18n } from "vue-i18n";
import { defineStore } from "pinia";
import { computed } from "vue";

export const useContacts = defineStore("projectContacts", () => {
    const i18n = useI18n();
    const { isLicenseDomain } = useDomainConfig();

    const getEmail = computed<string>(() => {
        return isLicenseDomain.value ?
            i18n.t("FOOTER.EMAIL.VALID") :
            i18n.t("FOOTER.EMAIL.NO_VALID");
    });

    const getPhone = computed<string>(() => {
        return isLicenseDomain.value ?
            i18n.t("FOOTER.PHONE.VALID") :
            i18n.t("FOOTER.PHONE.NO_VALID");
    });

    const getEmailForStaticPage = computed<string>(() => {
        return isLicenseDomain.value ?
            i18n.tm("FOOTER.EMAIL.VALID") :
            i18n.tm("FOOTER.EMAIL.NO_VALID");
    });

    return {
        getEmail,
        getPhone,
        getEmailForStaticPage,
    };
});
