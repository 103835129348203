// Nuxt client-only component
// https://nuxt.com/docs/api/components/client-only
// https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/components/client-only.ts
import { createElementBlock, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
    name: "ClientOnly",
    inheritAttrs: false,
    // eslint-disable-next-line vue/require-prop-types
    props: [ "fallback", "placeholder", "placeholderTag", "fallbackTag" ],
    setup(_props, { slots, attrs }) {
        const mounted = ref(false);
        onMounted(() => {
            mounted.value = true;
        });
        return (props: any) => {
            if (mounted.value) {
                return slots.default?.();
            }
            const slot = slots.fallback || slots.placeholder;
            if (slot) {
                return slot();
            }
            const fallbackStr = props.fallback || props.placeholder || "";
            const fallbackTag = props.fallbackTag || props.placeholderTag || "span";
            return createElementBlock(fallbackTag, attrs, fallbackStr);
        };
    },
});
