import log from "@front/core/controllers/LoggerController";
import { authAPI } from "@api";
import config from "@theme/configs/config";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { useContextStore } from "@store/context";
import { useModalsStore } from "@store/modals";
import { getPWANextShowDelay, isPWADisabledByCookie, proceedPWAShowCookie } from "../helpers/cookie";

const pwaEscape = () => {
    return import("@modules/Popups/pwa/PWAEscape/index.vue");
};
const pwaInstructions = () => {
    return import("@modules/Popups/pwa/PWAInstruction/index.vue");
};

export function usePushPWA() {
    const modalsStore = useModalsStore();
    const contextStore = useContextStore();

    async function showPWAEscape(isApp: boolean, isIos: boolean, trigger?: string) {
        let link = isIos ? config.pwaPromoLink : config.pwaInstallLink;
        try {
            link = await authAPI.getAutologinUrl(link);
        } catch (err) {
            log.error("ESCAPE_PWA_AUTOLOGIN_URL", err);
            link = `https://${window.location.hostname}${link}`;
        }

        const module = await pwaEscape();
        modalsStore.showModal({
            component: module.default,
            name: "pwa-escape",
            props: {
                isApp,
                isIos,
                link,
                trigger,
            },
            new: true,
        });
    }

    async function showPWAInstructions(isPush: boolean, isIos: boolean, trigger?: string) {
        const module = await pwaInstructions();
        modalsStore.showModal({
            component: module.default,
            name: "pwa-instructions",
            props: {
                isPush,
                isIos,
                trigger,
            },
            new: true,
        });
    }

    async function showPWA(isPush = false, trigger?: string) {
        const context = await contextStore.getContext();

        const acceptableBrowser = (context.isIOS && context.browser?.toLowerCase().includes("safari")) ||
            (!context.isIOS && context.browser?.toLowerCase().includes("chrome"));

        if (context.isApp || !acceptableBrowser) {
            showPWAEscape(Boolean(context.isApp), Boolean(context.isIOS), trigger);
        } else {
            showPWAInstructions(isPush, Boolean(context.isIOS), trigger);
        }
    }

    // @ts-expect-error Types of parameters trigger and args are incompatible.
    const pushPWA = promiseMemoizer(async(trigger?: string) => {
        if (!isPWADisabledByCookie() && getPWANextShowDelay() === 0) {
            proceedPWAShowCookie();
            await showPWA(true, trigger);
            return true;
        }
        return false;
    });

    return {
        pushPWA,
        showPWA,
    };
}
