<template>
    <div
        class="inline-notification"
        :class="classes"
        gap-column-xs
    >
        <div class="inline-notification__container" gap-column-xs>
            <slot name="icon">
                <FeIcon v-if="icon" class="inline-notification__icon" :icon="icon" />
            </slot>
            <div
                class="inline-notification__content"
                gap-xs
            >
                <div class="inline-notification__message">
                    <slot>
                        <h3 v-if="title" class="inline-notification__title">
                            {{ title }}
                        </h3>
                        <span v-if="message">{{ message }}</span>
                    </slot>
                </div>
                <div
                    v-if="$slots.actions"
                    class="inline-notification__actions"
                    gap-column-xs
                >
                    <slot name="actions" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { colorsList } from "@ui-kit/lib.js";

/**
 * @typedef {String} Size
 * */

/**
 * @cons
 * @typedef {Object} SizeList
 * @enum {Size}
 * */
const sizeList = {
    small: "small",
    medium: "medium",
};

/**
 * @typedef {Object} Theme
 * @property {Color} color
 * @property {Size} size
 * */

/**
 * @cons
 * @typedef {Object} ThemesList
 * @enum {Theme}
 * */
export const themes = {
    PrimaryMedium: {
        color: colorsList.primary,
        size: sizeList.medium,
    },
    PrimarySmall: {
        color: colorsList.primary,
        size: sizeList.small,
    },

    SuccessMedium: {
        color: colorsList.success,
        size: sizeList.medium,
    },
    SuccessSmall: {
        color: colorsList.success,
        size: sizeList.small,
    },

    ErrorMedium: {
        color: colorsList.error,
        size: sizeList.medium,
    },
    ErrorSmall: {
        color: colorsList.error,
        size: sizeList.small,
    },
};

export default {
    name: "InlineNotification",

    components: {
        FeIcon,
    },

    props: {
        icon: {
            type: String,
            default: "",
        },

        title: {
            type: String,
            default: "",
        },

        message: {
            type: String,
            default: "",
        },

        theme: {
            type: Object,
            default() {
                return themes.PrimaryMedium;
            },
        },
    },

    computed: {

        /**
         * List of classes for button
         *
         * @returns {Object}
         * */
        classes() {
            return {
                [`inline-notification--${ this.theme.color }`]: this.theme.color,
                [`inline-notification--${ this.theme.size }`]: this.theme.size,
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";


.inline-notification {
    & &__content {
        @include media(XS) {
            grid-auto-flow: column;
            align-items: center;
        }
    }
    & &__container {
        align-items: center;
    }
}

.inline-notification {
    $this: &;

    @include font-size--Body;
    background: $color-gradient-2;
    border-radius: var(--border-radius-xs);
    width: 100%;
    box-sizing: border-box;

    &__container {
        margin: 0 auto;
        justify-content: flex-start;
        display: flex;
        width: fit-content;
        grid-template-columns: repeat(auto-fill, minmax(0, auto));

        @include media(M) {
            grid-template-columns: auto;
        }

    }

    &__icon {
        width: 2rem;
        height: 2rem;
    }

    &__title {
        margin-bottom: 0.5rem;
    }

    &__content {
        width: 100%;
        grid-auto-flow: column;
        align-items: center;

    }

    &__actions {
        align-items: center;

        & > * {
            width: 100%;
            min-width: max-content;
        }
    }

    &--medium {
        padding: 1rem;
    }

    &--small {
        @include font-size--Caption2($weight: $font-weight--normal);
        padding: 0.5rem;
        text-align: center;

        #{$this}__message {
            text-align: center;
        }

        #{$this}__content {
            justify-content: center;
        }

        #{$this}__icon {
            width: 1rem;
            height: 1rem;

            & + #{$this}__content {
                justify-content: start;

                #{$this}__message {
                    text-align: left;
                }
            }
        }
    }

    &--error {
        background: var(--color-error);
    }

    &--success {
        background: var(--color-secondary-3);
    }

}
</style>
