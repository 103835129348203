export enum CustomBadgeThemes {
    primary = "primary",
    secondary = "secondary",
    additional = "additional",
    tertiary = "tertiary",
    tertiary3 = "tertiary3",
    tertiary5 = "tertiary5",
}

export enum CustomBadgeSize {
    default = "default",
    medium = "md",
    small = "sm",
}
