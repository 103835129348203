import { DEFAULT_VALUES } from "@config/cashbox";
import { filterPayments } from "@controllers/services/cashbox/filterPayments";
import { useUserBalance } from "@store/userBalance";
import { defineStore } from "pinia";
import { ref } from "vue";

import type {
    IBetResource,
    IGeneralLimitResource,
    IPaymentMethodResource,
    IPayoutItemResource,
    ISumRangeResource,
} from "@api";
import { cashboxAPI } from "@api";
import { promiseMemoizer } from "@helpers/promiseHelpers";

export const useCashbox = defineStore("cashbox", () => {
    const userBalanceStore = useUserBalance();

    const paymentsSystems = ref<IPaymentMethodResource[]>([]);
    const depositManagerEnabled = ref<boolean>(false);
    async function loadPaymentsData(): Promise<void> {
        const { data, meta } = await cashboxAPI.loadMethodsIn();
        depositManagerEnabled.value = meta.depositManagerEnabled;
        paymentsSystems.value = filterPayments(data);
    }

    const payoutSystems = ref<IPaymentMethodResource[]>([]);
    async function loadPayoutsData(): Promise<void> {
        const { data } = await cashboxAPI.loadMethodsOut();
        payoutSystems.value = data;
    }

    const withdrawalDefaultsAmount = ref<number>(0);
    async function loadWithdrawalDefaultAmount() {
        if (!withdrawalDefaultsAmount.value) {
            const data = await cashboxAPI.loadWithdrawalDefaultAmount();
            withdrawalDefaultsAmount.value =
                data.find((elem: { amount: number; currency: string }) => {
                    return elem.currency === userBalanceStore.userCurrency;
                })?.amount || 0;
        }
    }

    function loadWithdrawData() {
        return Promise.all([ loadPayoutsData(), loadWithdrawalDefaultAmount() ]);
    }

    const withdrawRequests = ref<IPayoutItemResource[]>([]);
    async function loadWithdrawRequests() {
        const data = await cashboxAPI.loadWithdrawalRequests();
        withdrawRequests.value = data.reverse();
    }

    async function removeWithdrawRequestById(id: string) {
        const status = await cashboxAPI.cancelWithdrawRequest(id);

        if (status) {
            withdrawRequests.value = withdrawRequests.value.filter((item) => {
                return item.id !== id;
            });
        }
    }

    function addWithdrawRequests(data: IPayoutItemResource) {
        withdrawRequests.value.push(data);
    }

    const sumRange = ref<ISumRangeResource[]>([]);
    async function loadSumRange(): Promise<void> {
        if (!sumRange.value.length) {
            const data = await cashboxAPI.loadSumRange();
            if (data.length > 0) {
                sumRange.value = data.filter((el) => {
                    return el.currency === userBalanceStore.userCurrency;
                });
            }
        }
    }

    const generalLimit = ref<IGeneralLimitResource>();
    async function loadGeneralLimit(): Promise<void> {
        if (generalLimit.value?.currency !== userBalanceStore.userCurrency) {
            const data = await cashboxAPI.loadGeneralLimit();
            if (data.length > 0) {
                generalLimit.value = data.find((el) => {
                    return el.currency === userBalanceStore.userCurrency;
                });
            }
        }
    }

    const userDepositNumbers = ref<number>(0);
    const loadUserDepositNumbers = promiseMemoizer(async () => {
        const { data } = await cashboxAPI.loadUserTransactionNumbers();
        const depositNumber = data?.depositNumbers || 0;
        userDepositNumbers.value = depositNumber;

        return depositNumber;
    });

    function cleanUserDepositNumbers() {
        userDepositNumbers.value = 0;
    }

    const lastBet = ref<IBetResource | null>(null);
    async function loadLastBet(): Promise<void> {
        lastBet.value = await cashboxAPI.loadLastBet();
    }

    const depositPayment = ref<IPaymentMethodResource | null>(null);
    function setDepositPayment(payment: IPaymentMethodResource): void {
        depositPayment.value = payment;
    }

    const depositValue = ref<number>(DEFAULT_VALUES.refillAmount);
    function setDepositValue(value: number): void {
        depositValue.value = Number(value);
    }

    return {
        paymentsSystems,
        loadPaymentsData,

        payoutSystems,
        withdrawalDefaultsAmount,
        loadWithdrawData,
        withdrawRequests,
        loadWithdrawRequests,
        removeWithdrawRequestById,
        addWithdrawRequests,

        setDepositPayment,
        depositPayment,

        depositManagerEnabled,

        loadLastBet,
        lastBet,

        setDepositValue,
        depositValue,

        loadGeneralLimit,
        userDepositNumbers,
        loadUserDepositNumbers,
        cleanUserDepositNumbers,
        generalLimit,

        loadSumRange,
        sumRange,
    };
});
