<template>
    <div class="confirm-emp__container">
        <FeCard
            v-if="displayEmpMessage"
            :theme="FeCardConfig.themes.White"
            :padding="FeCardConfig.PaddingSizes.paddingS"
            class="confirm-emp"
            gap-row-xs
        >
            <FeImage :src="image" alt="attention icon" />
            <p>{{ $t("EMP_MESSAGE.TEXT") }}</p>

            <div class="confirm-emp__actions" gap-row-s>
                <FeButton @click="handleApprove">
                    {{ $t("EMP_MESSAGE.BUTTON_APPROVE") }}
                </FeButton>
                <FeLink :to="{name: routeNames.oopsPage}">
                    {{ $t("EMP_MESSAGE.BUTTON_DECLINE") }}
                </FeLink>
            </div>
        </FeCard>
    </div>
</template>

<script>
import { mapState } from "pinia";
import FeCard, * as FeCardConfig from "@ui-kit/FeCard/index.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import iconAttention from "@theme/images/attention.png";
import iconAttentionDark from "@theme/images/attention-dark.png";
import cookie from "@front/core/controllers/CookieController";
import { routeNames } from "@router/routeNames";
import { useUserInfo } from "@store/userInfo";
import { useMultiLang } from "@store/multilang";
import { useThemeStore } from "@store/theme";

const approveEmpKey = "EMP_user_approved";

export default {
    name: "PopupConfirmEMP",

    components: {
        FeImage,
        FeCard,
        FeButton,
        FeLink,
    },

    data() {
        return {
            FeCardConfig,
            iconAttention,
            FeButtonConfig,
            routeNames,
            showEmpCard: false,
        };
    },

    computed: {
        ...mapState(useThemeStore, {
            isDark: "isThemeDark",
        }),
        ...mapState(useMultiLang, {
            userGeo: "userGeo",
        }),
        ...mapState(useUserInfo, {
            isLogged: "isLogged",
        }),
        isOopsPage() {
            return this.$route.name === routeNames.oopsPage;
        },

        displayEmpMessage() {
            return !this.isOopsPage && this.showEmpCard && !this.isLogged && this.userGeo === "AU";
        },
        image() {
            return this.isDark ? iconAttentionDark : iconAttention;
        },
    },
    created() {
        setTimeout(() => {
            this.showEmpCard = !cookie.get(approveEmpKey);
        }, 0);
    },

    methods: {
        handleApprove() {
            cookie.set(approveEmpKey, true, { expires: 31536e6 });
            this.showEmpCard = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";


.confirm-emp {
    box-shadow: 0px 3px 3px $color-tertiary-4;

    &__actions a {
        @include font-size--Body($font-weight--bold);
    }
    &__container {
        position: fixed;
        top: 50%;
        transform: translate(0px, -50%);
    }
}
.emp-error-page {
    &__logo {
        position: absolute;
        top: 0;
        width: 100vw;
        text-align: center;
        display: flex;
        justify-content: center;
        background: $color-layer-alt-2;
        align-items: center;
        height: 4rem;
    }

    h1 {
        @include font-size--LargeTitle;
        margin: 0 0 1.5rem 0;

        @include media(M) {
            margin: 0 0 2rem 0;
        }
    }

    &__link {
        margin: 0 0 1.5rem 0;

        @include media(M) {
            margin: 0 0 2rem 0;
        }
    }
}

.confirm-emp {
    p {
        margin: 0;
    }

    &__message {
        padding-bottom: 0.5rem;
    }

    &__container {
        width: calc( 100% - 2rem);
        max-width: 27rem;
        text-align: center;
    }

    &__actions {
        a {
            display: inline-block;
            width: max-content;
            margin: 0 auto;
            color: var(--color-text-link-default);
            text-decoration: none;
        }
    }

    img {
        display: none;
        width: 6rem;

        @include media(M) {
            display: block;
            margin: 0 auto;
        }
    }

}
</style>
