import { PerformanceKey } from "@controllers/services/performance/config";

interface ICounter {
    start: number;
    callback?(time: number): void;
}
const counters: Partial<Record<PerformanceKey, ICounter>> = {};

function startMeasure(name: PerformanceKey, callback?: (time: number) => void) {
    counters[name] = {
        start: Date.now(),
        callback,
    };
}
function finishMeasure(name: PerformanceKey): number | undefined {
    const counter = counters[name];

    if (counter !== undefined) {
        const time = Date.now() - counter.start;

        if (counter.callback) {
            counter.callback(time);
        }
        delete counters[name];

        return time;
    }

    return undefined;
}

export default {
    startMeasure,
    finishMeasure,
};
