<template>
    <div class="fe-popup" :class="popupClasses">
        <div ref="activator" class="fe-popup__activator" @click.stop="activatorToggleHandler">
            <slot
                name="activator"
                :is-show="contentShow"
                :show-handler="showHandler"
                :hide-handler="hideHandler"
                :toggle-handler="toggleHandler"
            >
                <button>Popup</button>
            </slot>
        </div>
        <div
            ref="wrapper"
            class="fe-popup__wrapper"
        >
            <div
                ref="content"
                v-click-outside="hideHandler"
                class="fe-popup__content"
                :class="contentClasses"
                :style="contentStyles"
            >
                <transition name="fe-popup-transition-fade">
                    <slot v-if="contentShow" :hide-handler="hideHandler" />
                </transition>
            </div>
            <transition name="fe-popup-transition-fade">
                <div
                    v-if="useBackdrop && contentShow"
                    ref="backdrop"
                    class="fe-popup__backdrop"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import { debounce } from "@helpers/debounce";

export default {
    name: "Popup",

    components: {},

    props: {
        top: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        bottom: {
            type: Boolean,
            default: false,
        },
        offsetX: {
            type: Boolean,
            default: false,
        },
        offsetY: {
            type: Boolean,
            default: false,
        },
        target: {
            type: Object,
        },
        activatorFixed: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        useBackdrop: {
            type: Boolean,
            default: false,
        },
        widthAuto: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            contentShow: false,
            detached: false,
            debounceResize: debounce(this.updateDimensions, 150),
            activator: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: 0,
                height: 0,
            },
            content: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: 0,
                height: 0,
            },
        };
    },

    computed: {
        popupClasses() {
            return {
                "fe-popup--show-content": this.contentShow,
                "fe-popup--detachable": !this.activatorFixed,
            };
        },
        contentClasses() {
            return {
                "fe-popup__content--show": this.contentShow,
                "fe-popup__content--full-width": this.fullWidth,
                "fe-popup__content--width-auto": this.widthAuto,
            };
        },
        getTopPosition() {
            let top = 0;

            if (this.top) {
                top = top + (this.activator.height - this.content.height);
            }

            if (!this.offsetY) {
                top = top + (this.top ? -this.activator.height : this.activator.height);

                /**
                 * Если указанны горизонатльные коордитнаты и не указанны вертикальные,
                 * то устаналивается вертикальное выравнивание по центру
                 */
                if (!this.top && !this.bottom && (this.left || this.right)) {
                    top = top - this.activator.height / 2 - this.content.height / 2;
                }
            }

            if (!this.activatorFixed) {
                top = top + this.activator.top + this.getOffsetTop();

                /**
                 * Контент не должен уходить за верхнюю границу
                 */
                if (top < 0) {
                    top = 0;
                }

                /**
                 * Контент не должен уходить за нижнюю границу
                 */
                if (!this.$isServer && top + this.content.height > document.documentElement.offsetHeight) {
                    top = document.documentElement.offsetHeight - this.content.height;
                }
            }

            return top;
        },
        getLeftPosition() {
            let left = 0;

            if (this.left) {
                left = left + (this.activator.width - this.content.width);
            }

            if (!this.offsetX) {
                left = left + (this.left ? -this.activator.width : this.activator.width);

                /**
                 * Если не указанны горизонатльные коордитнаты,
                 * то устаналивается горизонтальное выравнивание по центру
                 */
                if (!this.left && !this.right) {
                    left = left - this.activator.width / 2 - this.content.width / 2;
                }
            }

            if (!this.activatorFixed) {
                left = left + this.activator.left + this.getOffsetLeft();

                /**
                 * Контент не должен уходить за левую границу
                 */
                if (left < 0) {
                    left = 0;
                }

                /**
                 * Контент не должен уходить за правую границу
                 */
                if (!this.$isServer && left + this.content.width > document.documentElement.clientWidth) {
                    left = document.documentElement.clientWidth - this.content.width;
                }
            }

            return left;
        },
        contentStyles() {
            return {
                transform: `translate3d(${ this.getLeftPosition }px, ${ this.getTopPosition }px, 0)`,
                width: this.widthAuto ? "auto" : `${ this.activator.width }px`,
            };
        },
    },
    watch: {
        "$route"() {
            this.hideHandler();
        },
    },

    mounted() {
        this.detach();
    },

    beforeUnmount() {
        if (this.detached && this.$refs.wrapper) {
            this.$refs.wrapper.parentNode.removeChild(this.$refs.wrapper);
        }
        window.removeEventListener("resize", this.debounceResize);
    },

    methods: {
        detach() {
            if (!this.activatorFixed && !this.detached) {
                const target = this.target || document.getElementById("app");
                target.appendChild(this.$refs.wrapper);
                this.detached = true;
            }
        },
        activatorToggleHandler() {
            if (!this.contentShow) {
                this.showHandler();
            } else {
                this.hideHandler();
            }
        },
        showHandler() {
            if (typeof window === "undefined") {
                return;
            }

            this.contentShow = true;
            window.addEventListener("resize", this.debounceResize);
            this.updateDimensions();
        },
        hideHandler() {
            this.contentShow = false;
            window.removeEventListener("resize", this.debounceResize);
        },
        toggleHandler() {
            if (this.contentShow) {
                this.hideHandler();
            } else {
                this.showHandler();
            }
        },
        measure(el) {
            return el.getBoundingClientRect();
        },
        updateDimensions() {
            if (!this.contentShow) {
                return;
            }
            this.updateSize(this.measure(this.$refs.activator), this.activator);
            this.sneakPeek(() => {
                this.updateSize(this.measure(this.$refs.content), this.content);
            });
        },
        sneakPeek(cb) {
            requestAnimationFrame(() => {
                if (this.contentShow) {
                    return cb();
                }
                this.$nextTick(() => {
                    cb();
                });
            });
        },
        updateSize({ top, left, right, bottom, height, width }, variable) {
            variable.top = top;
            variable.left = left;
            variable.right = right;
            variable.bottom = bottom;
            variable.width = width;
            variable.height = height;
        },
        getOffsetLeft() {
            if (this.$isServer) {
                return 0;
            }
            return window.pageXOffset || document.documentElement.scrollLeft;
        },
        getOffsetTop() {
            if (this.$isServer) {
                return 0;
            }
            return window.pageYOffset || document.documentElement.scrollTop;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
