<template>
    <div class="producers-block" main-layout-width gap-s>
        <h2 class="text--align-center">
            {{ $t("FOOTER.PARTNERS") }}
        </h2>
        <CarouselScroll class="producers-block__carousel">
            <FeLink
                v-for="item in producersStore.producers"
                :key="item.id"
                class="producers-block__link"
                :to="getProviderRouter(item)"
            >
                <ProducerImage :producer="item.slug" />
            </FeLink>
        </CarouselScroll>
    </div>
</template>

<script setup lang="ts">
import type { IGameProducerResource } from "@api";
import { onMounted, onServerPrefetch, ref } from "vue";
import ProducerImage from "@components/ProducerImage/ProducerImage.vue";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { getProviderRouter } from "@controllers/services/categories";
import { useProducersStore } from "@store/producers";

const producersStore = useProducersStore();
const data = ref<IGameProducerResource[] | null>(null);

onServerPrefetch(async() => {
    data.value = await producersStore.initProducers();
});

onMounted(async() => {
    if (!data.value) {
        data.value = await producersStore.initProducers();
    }
});

</script>

<style lang="scss">
@import "~@theme/styles";
.producers-block {
    margin: 0 auto;
    padding-bottom: 1.5rem;

    @include media(M) {
        padding-bottom: 2rem;
    }

    &__carousel {
        --carousel-item-width: 10rem;
        --carousel-gap: 0;
    }
}
</style>
