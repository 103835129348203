<template>
    <FeLink :to="linkto" class="user-pic-name">
        <FeImage
            v-if="image"
            class="user-pic-name__pic"
            :src="image"
            alt="user-level-icon"
        />
    </FeLink>
</template>

<script>
import FeImage from "@ui-kit/FeImage/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";

export default {
    name: "UserPicName",

    components: {
        FeImage,
        FeLink,
    },

    props: {
        image: {
            type: String,
        },
        linkto: {
            type: Object,
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
