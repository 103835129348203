<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { v4 as uuid } from "uuid";
import bus from "@bus";
import { useUserInfo } from "@store/userInfo";
import { useEnvironment } from "@store/environment";

const userInfoStore = useUserInfo();
const { isProduction } = storeToRefs(useEnvironment());

const PIXEL_URL = "https://ck-grd.com/pixel.png?project=thor";
const pixelURLs = ref<string[]>([]);
let savedUserId: string | null | undefined = null;

async function updatePixelURL() {
    const url = new URL(PIXEL_URL);
    await userInfoStore.isLoggedAsync();

    const userId = userInfoStore?.userInfo?.user_id as (string | undefined);
    if (userId === savedUserId) {
        return;
    }
    savedUserId = userId;

    if (userId) {
        url.searchParams.set("user_id", userId);
    }
    url.searchParams.set("uid", uuid() + Date.now());

    pixelURLs.value = [ url.toString() ];
}

onMounted(async () => {
    if (!isProduction.value) {
        return;
    }

    await updatePixelURL();
    bus.$on("user.login", updatePixelURL);
    bus.$on("user.logout", updatePixelURL);
});
onBeforeUnmount(() => {
    bus.$off("user.login", updatePixelURL);
    bus.$off("user.logout", updatePixelURL);
});
</script>

<template>
    <div class="pixel-analytics">
        <img
            v-for="url in pixelURLs"
            :key="url"
            :src="url"
            alt="pixel-image"
        />
    </div>
</template>

<style scoped lang="scss">
.pixel-analytics {
    position: fixed;
    right: 100%;
    top: 100%;
}
</style>
