<template>
    <div
        main-layout-width
        class="footer__copyright text--align-center"
        :class="{ 'footer__copyright--loggout': !userInfoStore.isLogged }"
    >
        <div
            v-if="isLicenseDomain"
            grid-center-position
            gap-xs
            class="footer__copyright-wrapper"
        >
            <small data-test="text" v-html="$t('FOOTER.CONTACTS')" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { useUserInfo } from "@store/userInfo";
const userInfoStore = useUserInfo();
import { useDomainConfig } from "@controllers/services/domains";
const { isLicenseDomain } = useDomainConfig();

</script>

<style scoped lang="scss">
@import "~@theme/styles";
.footer__copyright {
    color: $color-text-body;
    margin: 0 auto;
    padding: 0 0 6rem;

    &--loggout {
        padding: 0 0 10rem;

        @include media(L) {
            padding: 0 0 2rem;
        }
    }


}
</style>
