export enum PAGE_TYPE {
    MAIN = "mainHome",
    CASINO = "mainCasino",
    LIVE = "mainLive",
    SPORT = "mainSport",
}

export const THEME_TAG = "theme";

export enum PAGE_SEO_TAGS {
    MAIN = "main-page-seo",
    CASINO = "casino-page-seo",
    LIVE = "live-page-seo",
}

export enum TOP_LEADERS_TAGS {
    CASINO = "casino",
    LIVE = "live",
}
