import type { Pinia } from "pinia";
import { useBannersFetchService } from "@controllers/services/banners";
import { useEnvironmentFetchService } from "@controllers/services/environment";
import { useMultiLangFetchService } from "@controllers/services/multilang";
import { useProducersStore } from "@store/producers";

export function usePrefetchData(pinia?: Pinia) {
    const { loadBanners } = useBannersFetchService(pinia);
    const { loadEnvironment } = useEnvironmentFetchService(pinia);
    const { loadMultiLang } = useMultiLangFetchService(pinia);
    const { initProducers } = useProducersStore(pinia);

    return () => {
        return Promise.all([
            loadEnvironment(),
            loadMultiLang(),
            loadBanners(),
            initProducers(),
        ]);
    };
}
