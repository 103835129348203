import dayjs from "dayjs";
import { DEFAULT_TIME_DAYJS_FORMAT } from "@config/dateFormats";
import { DateController } from "@helpers/dateFormatHelper";
import acceptLanguageParser from "accept-language-parser";
import { COINS } from "@config/cashbox";
import { useI18n } from "vue-i18n";
import { useUserBalance } from "@store/userBalance";
import { useUserInfo } from "@store/userInfo";
import { useMultiLang } from "@store/multilang";
import { useParserContent } from "@helpers/ParserContent";
import type { IUserNotificationResource } from "@api";

export interface IParsedContent {
    message: string;
    title?: string;
}

export function useNoticeParserContent() {
    const userBalanceStore = useUserBalance();
    const userInfoStore = useUserInfo();
    const i18n = useI18n();
    const multiLangStore = useMultiLang();
    const { parseContent } = useParserContent();

    const parseNotice = (noticeObj: IUserNotificationResource): IParsedContent => {
        let params: Record<string, unknown> = {
            currency: userBalanceStore.userCurrency,
        };
        const localisationVars = noticeObj.localisation?.variables || {};

        if (noticeObj.code) {
            const vars = noticeObj?.vars;
            const phaseNames = vars?.phaseNames;

            // to solve only one case with localisation only for one type of notification
            // must have some general solution from both team to have variables with different locales
            // FP-1345
            if (phaseNames && Object.keys(phaseNames).length) {
                vars.phaseName = mostSuitableLocale(phaseNames, multiLangStore.userLocale);
            }

            if (vars) {
                const amountNumber = Number(vars.amount);
                const amount = String(!isNaN(amountNumber) ? amountNumber / COINS : 0);

                params = {
                    ...params,
                    ...vars,
                    amount,
                };
            }

            if (typeof vars?.date === "string") {
                const dateController = new DateController({
                    country: userInfoStore.userInfo.chosen_country,
                });

                const parsedDate = new Date(vars.date);

                const date = dateController.formatFromDate(parsedDate);
                const time = dayjs(parsedDate).format(DEFAULT_TIME_DAYJS_FORMAT);

                params = {
                    ...params,
                    date,
                    time,
                };
            }

            const text = i18n.t(`NOTIFICATION_BACK.${noticeObj.code}`, params);
            const message = parseContent(text, localisationVars);
            const titleKey = `NOTIFICATION_BACK_TITLE.${noticeObj.code}`;
            const title = i18n.te(titleKey) ? i18n.t(titleKey, params) : undefined;

            return {
                message,
                title,
            };
        }
        return {
            message: parseContent(noticeObj.msg, localisationVars),
        };
    };

    return {
        parseNotice,
    };
}

function mostSuitableLocale(localesObj: Record<string, string>, locale: string) {
    const localePhase = localesObj[locale];

    if (!localePhase) {
        const code = acceptLanguageParser.pick(
            Object.keys(localesObj),
            locale,
            { loose: true },
        );
        return (code && localesObj?.[code]) || localesObj.en;
    }

    return localePhase;
}
