<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";
import { useThemeStore } from "@store/theme";

interface IProps {
    producer: string
    type?: "theme-light" | "theme-dark" | "theme-gray"
}

defineProps<IProps>();

const { version } = storeToRefs(useEnvironment());
const { theme } = storeToRefs(useThemeStore());
</script>

<template>
    <div class="producer-image">
        <img
            class="producer-image__image"
            :class="`type--${ type ?? theme }`"
            :data-class="producer"
            :src="`/img/sprites/products-sprite-${ type || theme }.svg${version ? `?${version}` : ''}`"
            :alt="producer"
            loading="lazy"
        >
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
