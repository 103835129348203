import { SelfExclusionActivatePeriod } from "@api";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { SELF_EXCLUSION_DURATIONS } from "../config";

const TRANSLATE_MAP = {
    [SelfExclusionActivatePeriod.DAY]: "DAY",
    [SelfExclusionActivatePeriod.MONTH]: "MONTH",
    [SelfExclusionActivatePeriod.YEAR]: "YEAR",
    [SelfExclusionActivatePeriod.FOREVER]: "FOREVER",
};

export function useSelfExclusionDurations() {
    const i18n = useI18n();

    const selfExclusionDurations = computed(() => {
        return SELF_EXCLUSION_DURATIONS.map((duration) => {
            const i18nKey = `LIMITS.SELF_EXCLUSION.DURATION_TYPES.${TRANSLATE_MAP[duration.type]}`;
            return {
                label: i18n.t(i18nKey, duration.value || 0),
                value: [ duration.value || 1, duration.type ].join("_"),
                duration,
            };
        });
    });

    function getDurationByKey(key: string) {
        const duration = selfExclusionDurations.value.find(({ value }) => {
            return value === key;
        });

        return duration?.duration;
    }

    return {
        selfExclusionDurations,
        getDurationByKey,
    };
}
