import { Currency } from "@api/resources/commonResources";
import { COINS } from "@config/cashbox";
import { useUserBalance } from "@store/userBalance";
import { storeToRefs } from "pinia";
import { unref } from "vue";

/**
 * @deprecated
 *
 * This method is deprecated. Use `useCurrencyView` instead.
 *
 * @param {number} value - The value to be formatted.
 * @param {string} [currency] - The currency string. If not provided, the user's currency from the userBalanceStore will be used.
 * @param {boolean} [toCeil=false] - If true, the value will be rounded up to the nearest whole number.
 * @param {boolean} [isRound=true] - If true, the value will be rounded based on the currency type.
 * If false, no rounding will be performed.
 *
 * @returns {string} - The formatted value with the currency symbol.
 */
export function currencyView(value: number, currency?: string, toCeil = false, isRound = true): string {
    const userBalanceStore = useUserBalance();
    const { userCurrency } = storeToRefs(userBalanceStore);
    const roundPatter: RegExp | string = isRound || currency === Currency.INR ? /\,.{2}/ : "";

    const currencyValue = currency || userCurrency.value;
    const valueNumber = toCeil ? Math.ceil(value / COINS) : value / COINS;

    if (currencyValue === Currency.INR) {
        return `${valueNumber.toLocaleString("ru-RU").replace(roundPatter, "")} ${currencyValue}`;
    }
    return `${valueNumber.toLocaleString(undefined, { maximumSignificantDigits: 8 })} ${currencyValue}`;
}

export function useCurrencyView() {
    const userBalanceStore = useUserBalance();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    function currencyView(value: number, currency?: string, toCeil = false, isRound = true) {
        const userCurrency = unref(userBalanceStore.userCurrency);
        const roundPatter: RegExp | string = isRound || currency === Currency.INR ? /\,.{2}/ : "";

        const currencyValue = currency || userCurrency;
        const valueNumber = toCeil ? Math.ceil(value / COINS) : value / COINS;

        if (currencyValue === Currency.INR) {
            return `${valueNumber.toLocaleString("ru-RU").replace(roundPatter, "")} ${currencyValue}`;
        }
        return `${valueNumber.toLocaleString(undefined, { maximumSignificantDigits: 8 })} ${currencyValue}`;
    }

    return {
        currencyView,
    };
}
