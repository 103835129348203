import { gamesAPI } from "@api";
import { usePaginationSlugs } from "@controllers/services/slugs";
import { useUserInfo } from "@store/userInfo";
import { defineStore } from "pinia";

export const useGamesStore = defineStore("games", () => {
    const userInfoStore = useUserInfo();

    const request = async (page: number, perPage: number, slug: string) => {
        return gamesAPI.loadGamesByCategory(await userInfoStore.isLoggedAsync(), slug, page, perPage);
    };

    const { slugs, getSlugData, loadNextPage, loadInitData, reloadAll } = usePaginationSlugs(request);

    const producerGamesRequest = async (page: number, perPage: number, slug: string) => {
        return gamesAPI.loadGamesByProducer(await userInfoStore.isLoggedAsync(), slug, page, perPage);
    };
    const pageGamesRequest = async (page: number, perPage: number, slug: string) => {
        return gamesAPI.loadGamesByPage(await userInfoStore.isLoggedAsync(), slug, page, perPage);
    };
    const tournamentGamesRequest = async (page: number, perPage: number, slug: string) => {
        return gamesAPI.loadGamesByTournament(await userInfoStore.isLoggedAsync(), slug, undefined, page, perPage);
    };
    const {
        slugs: pageGamesSlugs,
        reloadAll: reloadAllPageGames,
        getSlugData: getPageGamesSlugData,
        loadNextPage: loadPageGamesNextPage,
        loadInitData: loadPageGamesInitData,
    } = usePaginationSlugs(pageGamesRequest);

    const {
        slugs: tournamentGamesSlugs,
        reloadAll: reloadAllTournamentGames,
        getSlugData: getTournamentGamesSlugData,
        loadNextPage: loadTournamentGamesNextPage,
        loadInitData: loadTournamentGamesInitData,
    } = usePaginationSlugs(tournamentGamesRequest);

    const {
        slugs: producerGamesSlugs,
        reloadAll: reloadAllProducerGames,
        getSlugData: getProducerGamesSlugData,
        loadNextPage: loadProducerGamesNextPage,
        loadInitData: loadProducerGamesInitData,
    } = usePaginationSlugs(producerGamesRequest);

    return {
        slugs,
        reloadAll,
        getSlugData,
        loadNextPage,
        loadInitData,

        producerGamesSlugs,
        reloadAllProducerGames,
        getProducerGamesSlugData,
        loadProducerGamesNextPage,
        loadProducerGamesInitData,

        pageGamesSlugs,
        reloadAllPageGames,
        getPageGamesSlugData,
        loadPageGamesNextPage,
        loadPageGamesInitData,

        tournamentGamesSlugs,
        reloadAllTournamentGames,
        getTournamentGamesSlugData,
        loadTournamentGamesNextPage,
        loadTournamentGamesInitData,
    };
});
