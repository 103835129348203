<script lang="ts" setup>
import { BonusStatus, GiftStatus, type IBonusData } from "@controllers/services/bonuses/types";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import StatusBadge from "@components/StatusBadge/StatusBadge.vue";

const i18n = useI18n();

interface IProps {
    data: IBonusData;
}
const props = defineProps<IProps>();

interface IEmits {
    (e: "timerEnd"): void;
}
const emit = defineEmits<IEmits>();

const statusBadgeConfig = computed(() => {
    const { giftStatus, bonusStatus, expires } = props.data;

    switch (true) {
        case bonusStatus === BonusStatus.EXPIRED:
        case giftStatus === GiftStatus.EXPIRED: return {
            label: i18n.t("STATUS.EXPIRED"),
            danger: true,
        };

        case bonusStatus === BonusStatus.LOST: return {
            label: i18n.t("STATUS.LOST"),
            danger: true,
        };

        case bonusStatus === BonusStatus.CANCELED:
        case giftStatus === GiftStatus.CANCELED: return {
            label: i18n.t("STATUS.CANCELED"),
        };

        case bonusStatus === BonusStatus.WAGER_DONE: return {
            label: i18n.t("STATUS.WAGER_DONE"),
            success: true,
        };

        case giftStatus === GiftStatus.USED: return {
            label: i18n.t("STATUS.USED"),
            success: true,
        };
    }

    return {
        expiresEnd: expires,
    };
});
</script>

<template>
    <StatusBadge v-bind="statusBadgeConfig" @timer-end="emit('timerEnd')" />
</template>
