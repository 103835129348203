import bus from "@bus";
import { onBeforeUnmount, onMounted } from "vue";
import { useWebsocketsStore } from "./store";
import { websocketEmitter } from "./emitter";
import { type SocketEventMap, type SocketEventsNames, SocketEvent } from "./types";

const config: Array<[string, SocketEvent]> = [
    [ "websocket.balance.changed", SocketEvent.BALANCE_CHANGED ],
];

export function useSocketInitService() {
    const websocketsStore = useWebsocketsStore();
    const handlers: Record<string, (data: SocketEventMap[SocketEvent]) => void> = {};

    const createTimestampHandler = <K extends SocketEventsNames>(event: K) => {
        return (data: SocketEventMap[K]) => {
            const result = websocketsStore.verifyEventTimestamp(event, data.timestamp);
            if (result) {
                websocketEmitter.emit(event, data);
            }
        };
    };

    onMounted(() => {
        config.forEach(([ websocketEvent, websocketEmitterEvent ]) => {
            const handler = createTimestampHandler(websocketEmitterEvent);
            bus.$on(websocketEvent, handler);
            handlers[websocketEvent] = handler;
        });
    });
    onBeforeUnmount(() => {
        Object.entries(handlers).forEach(([ event, handler ]) => {
            bus.$off(event, handler);
        });
    });
}
