<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import Timer from "../Timer/Timer.vue";

const i18n = useI18n();
interface IProps {
    expiresEnd?: number | Date;
    dotSuccess?: boolean;
    timerDots?: boolean;
    timerText?: string[];
    label?: string;
    danger?: boolean;
    success?: boolean;
    outlined?: boolean;
}
const props = defineProps<IProps>();

interface IEmits {
    (e: "timerEnd"): void;
}
const emit = defineEmits<IEmits>();

const timerTextValue = computed(() => {
    if (props.timerText) {
        return props.timerText;
    }
    return [
        i18n.t("COMMON.DAY"),
        " ",
        " ",
        " ",
    ];
});

const statusText = computed(() => {
    if (props.label) {
        return props.label;
    }
    if (props.expiresEnd) {
        return `${i18n.t("STATUS.EXPIRES")}:`;
    }
    return "";
});

</script>

<template>
    <div
        :class="[
            'status-badge',
            { 'status-badge-outlined': outlined },
        ]"
    >
        <div
            v-if="expiresEnd"
            :class="[
                'status-badge__timer-dot',
                { 'status-badge__timer-dot--success': dotSuccess },
            ]"
        />
        <div
            class="status-badge__text"
            :class="{
                'status-badge__text-danger': danger,
                'status-badge__text-success': success,
            }"
        >
            {{ statusText }}
        </div>
        <Timer
            v-if="expiresEnd"
            class="status-badge__timer"
            :end="expiresEnd"
            :dots="timerDots"
            :text="timerTextValue"
            @end="emit('timerEnd')"
        />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.status-badge {
    display: inline-flex;
    padding: 0.25rem 0.5rem;
    align-items: center;
    background: $color-layer-alt-3;
    border-radius: $border-radius-M;

    &-outlined {
        background: transparent;
        border: 1px solid $color-tertiary-2;
    }

    &__timer-dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: $color-error;
        margin-right: 0.25rem;
    }
    &__timer-dot--success {
        background: $color-successfully;
    }

    &__text {
        @include font-size--Caption2($font-weight--normal);
        color: $color-text-body;
        text-wrap: nowrap;

        &-danger {
            color: $color-error;
        }
        &-success {
            color: $color-successfully;
        }
    }
}

.status-badge__timer {
    margin-left: 0.25rem;
    @include font-size--Caption2;
    color: $color-text-alt;

    :deep(.timer__dots.day) {
        display: none;
    }
    :deep(.timer__item.day) {
        margin-right: 0.25rem;
    }
}
</style>
