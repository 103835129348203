<script lang="ts" setup>
import { computed } from "vue";
import { useOnboard } from "../composables/useOnboard";
import { useOnboardStore } from "../store/onboardStore";
import OnboardItem from "./OnboardItem.vue";
import bus from "@bus";

const onboardStore = useOnboardStore();
useOnboard();

const isShow = computed(() => {
    return onboardStore.currentScenario?.isActive && onboardStore.currentScenarioItem;
});

function outsideClick() {
    bus.$emit("onboard-outside-click");
}
</script>

<template>
    <Teleport to="body">
        <Transition name="overlay-fade">
            <div v-if="isShow" class="onboard-overlay" @click.stop="outsideClick" />
        </Transition>
        <div v-if="isShow" class="onboard-item-container">
            <OnboardItem
                v-if="onboardStore.currentScenario && onboardStore.currentScenarioItem"
                :key="`${onboardStore.currentScenario?.id}_${onboardStore.currentScenario?.currentStep}`"
                :data="onboardStore.currentScenarioItem"
                :step="onboardStore.currentScenario.currentStep"
                :steps-count="onboardStore.currentScenario.scenario.items.length"
            />
        </div>
    </Teleport>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.onboard-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $POPUP_INDEX;
    @include background-overlay;
}

.onboard-item-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: $POPUP_INDEX;
}
</style>

<style lang="scss">
@import "~@theme/styles";

.overlay-fade-enter-active,
.overlay-fade-leave-active {
    transition: opacity 0.5s;
}

.overlay-fade-enter-from,
.overlay-fade-leave-to {
    opacity: 0;
}

// extra classes for scenarios
.onboarding-sidebar-element {
    background: $color-layer-alt-2;
    margin-left: -1rem;
    margin-top: -1rem;
}
</style>
