import { onBeforeUnmount, onMounted, nextTick, type Ref, watch } from "vue";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade";
import { isServer } from "@helpers/ssrHelpers";

const allowTouchMove = (el: HTMLElement | null) => {
    while (el && el !== document.body) {
        if (el.getAttribute("body-scroll-lock-ignore") !== null) {
            return true;
        }

        // eslint-disable-next-line no-param-reassign
        el = el.parentElement;
    }
};

export function useBodyScrollLock(element: Ref<HTMLElement | null>, isVisible?: Ref<boolean>) {
    if (isVisible) {
        watch(
            () => isVisible.value,
            (value) => {
                if (!isServer) {
                    if (value) {
                        nextTick(() => {
                            if (element.value) {
                                disableBodyScroll(element.value, {
                                    reserveScrollBarGap: true,
                                    // @ts-expect-error Type is incompatible.
                                    allowTouchMove,
                                });
                            }
                        });
                        return;
                    }

                    clearAllBodyScrollLocks();
                }
            },
        );
    } else {
        onMounted(() => {
            nextTick(() => {
                if (element.value) {
                    disableBodyScroll(element.value, {
                        reserveScrollBarGap: true,
                        // @ts-expect-error Type is incompatible.
                        allowTouchMove,
                    });
                }
            });
        });

        onBeforeUnmount(() => {
            clearAllBodyScrollLocks();
        });
    }
}
