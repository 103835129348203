<script setup lang="ts">
import type { ITextFieldProps, FieldRef } from "./types";
import { onMounted, ref } from "vue";
import { useBaseField } from "@ui-kit/FeFields/useBaseField";
import FieldValidator from "@components/FieldValidator/FieldValidator.vue";
import { FeTooltip, FeTooltipPosition } from "@ui-kit/FeTooltip";
import FeInput from "@ui-kit/FeInput/FeInput.vue";

interface ITextFieldEmits {
    (event: "focus", value: FocusEvent): void
    (event: "input", value: string): void
    (event: "keydown", value: KeyboardEvent): void
}

defineOptions({
    name: "TextField",
});

const props = withDefaults(defineProps<ITextFieldProps>(), {
    autofocus: false,
    required: false,
    positionTooltip: FeTooltipPosition.bottom,
});
const emit = defineEmits<ITextFieldEmits>();

const fieldRef = ref<FieldRef>(null);
const { rules, inputValue } = useBaseField(props, fieldRef.value);

const onFocusHandler = (event: FocusEvent) => emit("focus", event);
const inputChange = (value: string) => emit("input", value);
const keydownEvent = (event: KeyboardEvent) => emit("keydown", event);

onMounted(() => {
    inputChange(inputValue.value);
});
</script>

<template>
    <FieldValidator
        ref="fieldRef"
        :name="label"
        :value="inputValue"
        :rules="rules"
        manual-validate
    >
        <template #default="{ valid, message, showError }">
            <FeTooltip
                :show-on-hover="false"
                :show="showError"
                :position="positionTooltip"
            >
                <template #activator>
                    <FeInput
                        v-bind="$attrs"
                        v-model.trim="inputValue"
                        class="input__input"
                        type="text"
                        :name="`text-${fieldRef?.fieldName}`"
                        :error="!valid"
                        :disabled="disabled"
                        :data-test="dataTest"
                        :autofocus="autofocus"
                        :placeholder="placeholder"
                        :label="label"
                        :required="required"
                        @update:model-value="inputChange"
                        @focus="onFocusHandler"
                        @keydown="keydownEvent"
                    >
                        <template #right>
                            <slot name="right" :has-error="showError" />
                        </template>
                    </FeInput>
                </template>
                {{ message }}
            </FeTooltip>
        </template>
    </FieldValidator>
</template>
