<template>
    <div class="preview-game-demo__wrapper">
        <Logo full class="preview-game-demo__logo" />
        <div class="preview-game-demo">
            <img
                v-if="currentGameStore.gameData"
                :src="currentGameStore.gameData.preview"
                :alt="currentGameStore.gameData.name"
                class="preview-game-demo__image"
            >
            <h3 class="preview-game-demo__text">
                {{ $t("GAMEPAGE.ONLY_REALPLAY_MESSAGE") }}
            </h3>
            <div class="preview-game-demo__buttons">
                <EnterBtns
                    location="games"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import EnterBtns from "@components/EnterBtns/index.vue";
import Logo from "@components/Logo/Logo.vue";
import { useCurrentGame } from "@store/currentGame";
const currentGameStore = useCurrentGame();

</script>

<style lang="scss">
@import "~@theme/styles";

.preview-game-demo {
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &__logo {
        margin: 0 auto;
        display: block;

        @include media(M) {
            display: none;
        }
    }

    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
    }

    &__image {
        height: 13rem;
        border-radius: $border-radius-S;
        margin-bottom: 1.5rem;

        @include media(M) {
            height: 17rem;
        }
    }

    &__text {
        max-width: 28rem;
        text-align: center;
        color: $color-text-alt;
        margin-bottom: 1.5rem;
    }

    &__buttons {
        transform: scale(1.3333);
    }
}
</style>
