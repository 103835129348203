import type { Callback, ITriggerItem, ITrigger } from "./types";

export abstract class AbstractTrigger<T = unknown, C extends Callback = Callback> implements ITrigger<T, C> {
    protected items: ITriggerItem<T, C>[];

    protected constructor() {
        this.items = [];
    }

    protected abstract init(): void;
    protected abstract stop(): void;

    public on(callback: C, condition?: T) {
        if (this.items.length === 0) {
            this.init();
        }
        this.items.push({
            condition,
            callback,
        });
    }
    public off(callback: C) {
        this.items = this.items.filter((item) => {
            return item.callback !== callback;
        });
        if (this.items.length === 0) {
            this.stop();
        }
    }
    public offAll() {
        this.items = [];
        this.stop();
    }
}

