<template>
    <div class="bookmark-menu">
        <div class="bookmark-menu__header">
            {{ $t("BOOKMARK_MENU.MAIN_HEADER") }}
        </div>
        <div class="bookmark-menu__close">
            <FeIcon :icon="FeIconConfig.close" @click="closeHandler" />
        </div>
        <div class="bookmark-menu__btns">
            <FeButton class="bookmark-menu__btn" :theme="FeButtonConfig.themes.TertiarySmall" @click="goBack">
                {{ $t("GAME_ITEM.QUIT") }}
            </FeButton>
            <FeButton
                v-if="isRunDemoGame"
                class="bookmark-menu__btn"
                :theme="FeButtonConfig.themes.PrimarySmall"
                @click="playForReal"
            >
                {{ $t("GAME_ITEM.PLAY_REAL") }}
            </FeButton>
            <FeButton
                v-else
                class="bookmark-menu__btn"
                :theme="FeButtonConfig.themes.PrimarySmall"
                @click="openCashboxEvent"
            >
                {{ $t("GAME_ITEM.CASHBOX") }}
            </FeButton>
        </div>
    </div>
</template>

<script>
import { routeNames } from "@router/routeNames";
import { mapState } from "pinia";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import bus from "@bus";
import { useCurrentGame } from "@store/currentGame";

export default {
    name: "BookmarkMenu",

    components: {
        FeButton,
        FeIcon,
    },

    props: {
        closeHandler: {
            type: Function,
        },
        goBackCallback: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            FeButtonConfig,
            FeIconConfig,
        };
    },
    computed: {
        ...mapState(useCurrentGame, {
            game: "gameData",
            isRunDemoGame: "isDemo",
        }),
    },

    watch: {
        $route() {
            this.closeHandler();
        },
    },

    methods: {
        playForReal() {
            this.$router.push({
                name: routeNames.gameItem,
                params: { name: this.game.slug },
            });
            this.closeHandler();
        },

        openCashboxEvent() {
            this.closeHandler();
            bus.$emit("open-cashbox", { location: "games", step: CASHBOX_DEPOSIT_STEP });
        },

        goBack() {
            this.closeHandler();
            this.goBackCallback();
        },
    },
};
</script>

<style lang="scss">
@import "~@theme/styles";

.bookmark-menu {
    padding: 1rem;
    box-sizing: border-box;

    & + .modal__close {
        display: none;
    }

    @include media(S) {
        min-width: 30rem;
    }

    &__header {
        text-align: center;
        color: $color-text-alt;
        @include font-size--Body($font-weight--bold);
        margin-bottom: 1rem;
    }

    &__btns {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, minmax( max-content, 1fr));
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 5;
    }
}
</style>
