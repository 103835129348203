import type { Pinia } from "pinia";
import { Currency } from "@api/resources/commonResources";
import { useServerStore } from "@store/server";
import { DEFAULT_CURRENCY_BY_GEO } from "@theme/configs/constsCurrencies";

function getCurrencyByGeo(geo: string, pinia?: Pinia): Currency {
    const serverStore = useServerStore(pinia);
    return DEFAULT_CURRENCY_BY_GEO[geo] || serverStore.defaultCurrency;
}

export {
    getCurrencyByGeo,
};
