import { defineStore } from "pinia";
import { computed, ref } from "vue";
import config from "@theme/configs/config";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";

export const useThemeStore = defineStore("theme", () => {
    const theme = ref(config.theme);

    const isThemeDark = computed(() => {
        return theme.value === "theme-dark";
    });

    const setTheme = (value: string) => {
        if (getFeatureFlags().disableThemeSwitcher) {
            return;
        }

        theme.value = value;

        if (typeof document !== "undefined") {
            document.body.classList.remove("theme-light", "theme-dark");
            document.body.classList.add(value);
        }
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("userTheme", value);
        }
    };

    return { theme, isThemeDark, setTheme };
});
