import { defineStore } from "pinia";
import { ref } from "vue";

export const useGlobalEventStore = defineStore("global-event", () => {
    const isActive = ref(false);

    function setIsActive(value: boolean) {
        isActive.value = value;
    }

    return {
        setIsActive,
        isActive,
    };
});
