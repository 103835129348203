import {
    BreakPoints,
    Extension,
    Gravity,
    IMG_QUERY_CROP,
    ResizingType,
    type IConfigForTypeImage,
    type IConfigImgCompostela,
} from "@theme/configs/imgResize/imageConfig";

const sizeByBreakPoint: Partial<Record<BreakPoints, IConfigImgCompostela>> = {
    [BreakPoints.BREAK_POINT_XXS]: {
        [IMG_QUERY_CROP]: "XS",
    },
    [BreakPoints.BREAK_POINT_S]: {
        [IMG_QUERY_CROP]: "S",
    },
    [BreakPoints.BREAK_POINT_M]: {
        [IMG_QUERY_CROP]: "M",
    },
    [BreakPoints.BREAK_POINT_L]: {
        [IMG_QUERY_CROP]: "L",
    },
};

const extension: Extension = Extension.png;
const resizeType: ResizingType = ResizingType.fill;
const gravity: Gravity = Gravity.northwest;

const config: IConfigForTypeImage = {
    sizeByBreakPoint,
    extension,
    gravity,
    resizeType,
};

export default config;
