import cookie from "@front/core/controllers/CookieController";
import {
    DONT_SHOW_PWA_AGAIN_COOKIE,
    LAST_TIME_SHOW_PWA_COOKIE,
    COUNT_SHOW_PWA_COOKIE,
    MAX_SHOW_PWA_COUNT,
    COOKIE_FORGET_DELAY,
    TIME_DELAY_BETWEEN_PWA_SHOW,
} from "../config";

function getPWAShowsNumber() {
    return Number(cookie.get(COUNT_SHOW_PWA_COOKIE)) || 0;
}

function addPWAShowsNumber() {
    const count = getPWAShowsNumber();
    cookie.set(COUNT_SHOW_PWA_COOKIE, String(count + 1), {
        expires: COOKIE_FORGET_DELAY,
        path: "/",
    });
}

function getPWADontShowAgain() {
    return Boolean(cookie.get(DONT_SHOW_PWA_AGAIN_COOKIE));
}

export function setPWADontShowAgain() {
    cookie.set(DONT_SHOW_PWA_AGAIN_COOKIE, "1", {
        expires: COOKIE_FORGET_DELAY,
        path: "/",
    });
}

export function getPWANextShowDelay() {
    const lastTimeShowPWA = Number(cookie.get(LAST_TIME_SHOW_PWA_COOKIE));

    if (!isNaN(lastTimeShowPWA)) {
        const delay = (lastTimeShowPWA + (TIME_DELAY_BETWEEN_PWA_SHOW * 1000)) - Date.now();

        return delay > 0 ? delay : 0;
    }
    return 0;
}

function setPWALastTimeShow() {
    cookie.set(LAST_TIME_SHOW_PWA_COOKIE, String(Date.now()), {
        expires: TIME_DELAY_BETWEEN_PWA_SHOW,
        path: "/",
    });
}

export function proceedPWAShowCookie() {
    setPWALastTimeShow();
    addPWAShowsNumber();
}

export function isPWADisabledByCookie() {
    return [
        getPWAShowsNumber() >= MAX_SHOW_PWA_COUNT,
        getPWADontShowAgain(),
    ].some(Boolean);
}
