import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import { routeNames } from "@router/routeNames";
import { useUserInfo } from "@store/userInfo";
import { isServer } from "@helpers/ssrHelpers";

export default function requestAuthHook(router: Router, pinia: Pinia) {
    const userInfoStore = useUserInfo(pinia);

    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some((record) => {
            return record.meta.requiresAuth;
        });
        if (requiresAuth && !isServer) {
            if (!userInfoStore.isLogged) {
                userInfoStore.isLoggedAsync().then((boolean) => {
                    if (!boolean) {
                        router.push({
                            name: routeNames.login,
                            query: { redirect: to.fullPath },
                        });
                    }
                });
            }
        }

        return next();
    });
}
