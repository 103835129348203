type AddListener<T> = {
    target?: (Window & typeof globalThis) | Document | Element;
    options?: boolean | AddEventListenerOptions;
    handler: (...args: any) => void;
    type: T;
};

type RemoveListener = () => void;

export const addEventListenerUtil = <T extends string>({
    target = window,
    type,
    handler,
    options,
}: AddListener<T>): RemoveListener => {
    if (!type || !handler) {
        throw Error("provide type and/or handler");
    }

    target.addEventListener(type, handler, options);

    return () => {
        target.removeEventListener(type, handler, options);
    };
};

export const getScrollPosition = (position: "top" | "left") => {
    const { body, documentElement } = document;

    if (position === "top") {
        return window.scrollY || documentElement.scrollTop || body.scrollTop;
    }

    if (position === "left") {
        return window.scrollX || documentElement.scrollLeft || body.scrollLeft;
    }

    return 0;
};
