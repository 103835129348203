<script setup lang="ts">
import type { IBannerData } from "@api";
import { TypeImage } from "@theme/configs/imgResize";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import isExternalUrl from "@helpers/isExternalUrl";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { useParserContent } from "@helpers/ParserContent";
import { useGifts } from "@store/gifts";
import config from "@theme/configs/config";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import FePicture from "@ui-kit/FePicture/FePicture.vue";

interface IProps {
    banner: IBannerData;
    typeImage?: TypeImage;
    useFeImage?: boolean;
}
interface IEmits {
    (event: "clickBanner", banner: IBannerData): void;
}

const props = defineProps<IProps>();
const emit = defineEmits<IEmits>();

const router = useRouter();

const { parseContent } = useParserContent();
const { activatePromoGift, loadGiftsData } = useGifts();

const localisationVars = computed(() => props.banner?.localisation?.variables || {});
const bannerImage = computed(() => props.banner.compostelaImagePath || props.banner.desktop);
const bannerButtonName = computed(() => parseContent(props.banner.button.name, localisationVars.value));
const bannerDescription = computed(() => parseContent(props.banner.description, localisationVars.value));
const bannerSlideClasses = computed(() => {
    return {
        "banner-slide": true,
        // @ts-expect-error Property status does not exist on type IBannerData
        "banner-slide--past": props.banner.status === "past",
    };
});
const layerGradient = computed(() => {
    return {
        background: props.banner?.bgColor || config.defaultGradient,
    };
});

const isActivatePromoGiftURL = (url: string) => url.startsWith("/promogifts");
const getPromoCode = (url: string) => {
    const urlArr = url.split("/");
    return urlArr[urlArr.length - 1];
};
const routeEvent = async (url: string) => {
    if (isActivatePromoGiftURL(url)) {
        const promoCode = getPromoCode(url);

        await activatePromoGift(promoCode);
        await loadGiftsData();

        const routeName = getFeatureFlags().enableNewBonuses ? routeNames.profileBonuses : routeNames.profileGifts;
        return router.push({ name: routeName });
    }
    router.push(url);
};
const defaultEventOnClick = (event: MouseEvent) => {
    if (props.banner?.button?.url && !isExternalUrl(props.banner?.button?.url)) {
        event.stopPropagation();
        event.preventDefault();
        routeEvent(props.banner.button.url);
    }
    emit("clickBanner", props.banner);
};
</script>

<template>
    <div :class="bannerSlideClasses" :style="layerGradient">
        <div class="banner-slide-wrapper" @click="defaultEventOnClick">
            <template v-if="useFeImage">
                <FeImage :src="bannerImage" class="banner-slide__img" :alt="banner.title" />
            </template>
            <template v-else>
                <FePicture
                    v-if="bannerImage && typeImage"
                    :src="bannerImage"
                    :alt="banner.title"
                    :type="typeImage"
                />
            </template>
            <div class="banner-slide-wrapper-body">
                <div class="banner-slide__content" gap-xs>
                    <div class="banner-slide__labels-wrapper">
                        <div class="banner-slide__labels" gap-xs>
                            <slot name="status" />
                            <template v-for="label of banner.labels" :key="label">
                                <div class="banner-slide__label caption-2">
                                    {{ label }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="banner.text" class="banner-slide-title" v-html="banner.text" />
                    <div v-if="banner.description" class="banner-slide-description" v-html="bannerDescription" />
                    <slot name="text" />
                </div>

                <div class="banner-slide__control">
                    <slot name="button">
                        <template v-if="banner.button.url && banner.button.name">
                            <FeLink :to="banner.button.url" @click="defaultEventOnClick">
                                <FeButton :theme="FeButtonConfig.themes.PrimaryMedium">
                                    {{ bannerButtonName }}
                                </FeButton>
                            </FeLink>
                        </template>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>
