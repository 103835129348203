<template>
    <div class="user-balance color-text-alt" gap-row-s @click.stop>
        <div class="user-balance__common">
            <div
                :class="{
                    'user-balance__common-value-row': !Boolean($slots.right),
                }"
            >
                <div class="caption-1-bold color-text-alt">
                    {{ balanceTitle }}
                    <Tooltip
                        v-if="commonBonusBalance"
                        v-click-outside="hideTooltip"
                        bottom
                        :show-on-hover="true"
                        :show="isShowTooltip"
                        :width="200"
                    >
                        <template #activator>
                            <FeIcon
                                class="user-balance__tooltip-info"
                                :icon="FeIconConfig.info1"
                                @click="toggleTooltip"
                            />
                        </template>
                        <div v-html="tooltipText" />
                    </Tooltip>
                </div>
                <div class="color-text-additional h3">
                    {{ currencyView(commonBalance) }}
                </div>
            </div>
            <slot name="right" />
        </div>
        <div v-if="commonBonusBalance" class="gap-xs">
            <div class="user-balance__detailed">
                <div class=" caption-2 color-text-body">
                    {{ $t("PROFILE.BALANCE_BONUS_TITLE") }}
                </div>
                <div class="caption-1-bold">
                    {{ currencyView(commonBonusBalance) }}
                </div>
            </div>
            <div class="user-balance__detailed">
                <div class=" caption-2 color-text-body">
                    {{ $t("PROFILE.BALANCE_REAL_TITLE") }}
                </div>
                <div class=" caption-1-bold">
                    {{ currencyView(realBalance) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { FeIconConfig } from "@theme/configs/icons";
import { useCurrencyView } from "@controllers/services/currency-view";
import Tooltip from "@components/Tooltip/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { useUserBalanceServices } from "@controllers/services/user";
import { computed, ref } from "vue";
import config from "@theme/configs/config";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const { currencyView } = useCurrencyView();

const {
    commonBalance,
    commonBonusBalance,
    realBalance,
} = useUserBalanceServices();

const isShowTooltip = ref<boolean>(false);
function toggleTooltip() {
    isShowTooltip.value = !isShowTooltip.value;
}
function hideTooltip() {
    isShowTooltip.value = false;
}

const tooltipText = computed(() => {
    return i18n.t("PROFILE.BALANCE_TOOLTIP_TEXT", { link: config.links.bonusTermsPage });
});

const balanceTitle = computed(() => {
    return commonBonusBalance.value ? i18n.t("PROFILE.BALANCE_COMMON_TITLE") : i18n.t("PROFILE.BALANCE_TITLE");
});

</script>

<style scoped lang="scss">
@import "~@theme/styles";
.user-balance {

    &__common {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-size--Header3;

        &-value-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
    &__common #{&}__value {
        @include font-size--Header3;
        color: $color-additional-1;
    }

    &__detailed {
        display: flex;
        justify-content: space-between;
    }

    &__tooltip-info {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        color: $color-text-link-default;

        svg {
            width: 1.75rem;
        }
    }
}
</style>
