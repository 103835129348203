import Emitter from "tiny-emitter";
import type { SocketEventMap, SocketEventsNames } from "./types";

const emitter = new Emitter.TinyEmitter();

function emit<K extends SocketEventsNames>(event: K, data: SocketEventMap[K]) {
    emitter.emit(event, data);
}
function on<K extends SocketEventsNames>(event: K, callback: (data: SocketEventMap[K]) => void) {
    emitter.on(event, callback);
}
function off<K extends SocketEventsNames>(event: K, callback: (data: SocketEventMap[K]) => void) {
    emitter.off(event, callback);
}

export const websocketEmitter = {
    emit,
    on,
    off,
};
