import { onBeforeUnmount, onMounted } from "vue";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { EVENT_START, EVENT_END } from "../config";
import { useGlobalEventStore } from "../store/globalEventStore";

const MAX_DIFF = 604800000; // 7 days in ms

export function useGlobalEventStatusController() {
    const globalEventStore = useGlobalEventStore();
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;

    function checkIsActive() {
        if (!getFeatureFlags().enableGlobalEvent) {
            return;
        }

        clearTimeout(timer);
        const currentDate = Date.now();
        if (currentDate < Number(EVENT_START)) {
            const diff = Number(EVENT_START) - currentDate;
            if (diff < MAX_DIFF) {
                timer = setTimeout(() => {
                    checkIsActive();
                }, diff + 1000);
            }
        } else if (currentDate < Number(EVENT_END)) {
            globalEventStore.setIsActive(true);
            const diff = Number(EVENT_END) - currentDate;
            if (diff < MAX_DIFF) {
                timer = setTimeout(() => {
                    globalEventStore.setIsActive(false);
                }, diff);
            }
        }
    }
    onMounted(() => {
        checkIsActive();
    });
    onBeforeUnmount(() => {
        clearTimeout(timer);
    });
}
