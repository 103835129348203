<script setup lang="ts">
import { PromoBannerThemes, componentConfigByTheme } from "@components/PromoBanner/config";
import { BonusType } from "@controllers/services/bonuses/types";
import { computed } from "vue";
import image from "./images/welcome-badge.png";
import imageRetina from "./images/welcome-badge@2x.png";
import { useI18n } from "vue-i18n";
import { AMOUNT_MAP_BANNER } from "@config/promoBanner";
import { usePromoBanner } from "@controllers/services/promo-banner";

interface IProps {
    theme?: PromoBannerThemes;
}

const props = withDefaults(defineProps<IProps>(), {
    theme: PromoBannerThemes.primary,
});

const i18n = useI18n();

const { bannerAmounts } = usePromoBanner();

const data = computed(() => {
    return {
        image,
        imageRetina,
        title: i18n.t("MODAL_THANKS.WELCOME_BONUS_TITLE", bannerAmounts(AMOUNT_MAP_BANNER)),
        caption: i18n.t("MODAL_THANKS.WELCOME_BONUS_TEXT"),
        type: BonusType.CASINO,
    };
});

const theme = computed(() => {
    if (props.theme === PromoBannerThemes.secondary) {
        return PromoBannerThemes.secondary;
    }
    return PromoBannerThemes.primary;
});
</script>

<template>
    <component :is="componentConfigByTheme[theme]" :data="data">
        <slot />
    </component>
</template>
