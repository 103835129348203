import { useSeoMetaStore } from "@store/seoMeta";
import type { Pinia } from "pinia";
import type { Router } from "vue-router";

export default function metaHook(router: Router, pinia: Pinia) {
    router.beforeEach((to, from, next) => {
        const { setNoIndex } = useSeoMetaStore(pinia);
        setNoIndex(to.path.split("/").length > 4);
        next();
    });
}
