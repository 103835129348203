import { usePWA } from "@store/pwa";
import { useModalsStore } from "@store/modals";
import { MODAL_PWA_INSTRUCTION } from "@config/modalNames";

const pwaInstruction = () => import("@modules/Popups/PWAInstruction/index.vue");

export default async function installPWA() {
    const pwaStore = usePWA();
    const { showModal } = useModalsStore();

    if (pwaStore.deferredPWAPrompt) {
        pwaStore.deferredPWAPrompt.prompt();

        pwaStore.deferredPWAPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                pwaStore.setShowPwaInfo(false);
            }
        });
    } else {
        const module = await pwaInstruction();

        showModal({
            name: MODAL_PWA_INSTRUCTION,
            component: module.default,
            new: true,
        });
    }
}
