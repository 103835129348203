import type {
    ILivespinsClient,
    ILivespinsSDK,
    ILivespinsStaticConfigurationParameters,
    ILivespinsVariableConfigurationParameters,
} from "@modules/Livespins/types";
import { configAPI } from "@api";
import { useMultiLang } from "@store/multilang";
import { LIVESPINS_SCRIPT_SRC } from "@modules/Livespins/configs";

type Livespins = {
    sdk(options: ILivespinsStaticConfigurationParameters & ILivespinsVariableConfigurationParameters): ILivespinsSDK;
}
declare global {
    const livespins: Livespins;
}

const LOADED_EVENT = "LIVESPINS_LOADED";

let livespinsSdk: ILivespinsSDK | null = null;
let livespinsSdkLoadPromise: Promise<ILivespinsSDK> | null = null;

let livespinsClient: ILivespinsClient | null = null;
let livespinsClientLoadPromise: Promise<ILivespinsClient> | null = null;

export function useGetLivespinsSdk() {
    const multiLangStore = useMultiLang();

    function getLivespinsSdk(): Promise<ILivespinsSDK> {
        if (livespinsSdk) {
            return Promise.resolve(livespinsSdk);
        }
        if (!livespinsSdkLoadPromise) {
            livespinsSdkLoadPromise = new Promise<ILivespinsSDK>(async (resolve, reject) => {
                const script = document.createElement("script");
                script.src = LIVESPINS_SCRIPT_SRC;
                script.async = true;
                document.addEventListener(LOADED_EVENT, async () => {
                    const { tenant, serverConfig } = await configAPI.loadLivespinsData();

                    if (!tenant) {
                        reject(new Error("LIVESPINS_LOAD_FAILED: Livespins load failed, tenant is empty"));
                    }

                    livespinsSdk = livespins.sdk({
                        language: multiLangStore.userLanguage,
                        tenant,
                        // tenant: "Mock",
                        serverConfig,
                    });

                    resolve(livespinsSdk);
                });

                document.body.appendChild(script);
            });
        }
        return livespinsSdkLoadPromise;
    }

    return {
        getLivespinsSdk,
    };
}
export function useGetLivespinsClient() {
    const { getLivespinsSdk } = useGetLivespinsSdk();

    function getLivespinsClient(): Promise<ILivespinsClient> {
        if (livespinsClient) {
            return Promise.resolve(livespinsClient);
        }
        if (!livespinsClientLoadPromise) {
            livespinsClientLoadPromise = new Promise(async (resolve, reject) => {
                try {
                    const sdk = await getLivespinsSdk();

                    // eslint-disable-next-line new-cap
                    livespinsClient = await sdk.Client();
                    resolve(livespinsClient);
                } catch (error) {
                    reject(error);
                }
            });
        }
        return livespinsClientLoadPromise;
    }

    return {
        getLivespinsClient,
    };
}
