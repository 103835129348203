import { useContextStore } from "@store/context";
import { useModalsStore } from "@store/modals";
import { useUserInfo } from "@store/userInfo";
import { storeToRefs } from "pinia";
import { DEFAULT_RUN_SCENARIO_TIMEOUT, RUN_SCENARIO_RETRY_DELAY } from "../config/config";
import { findElement } from "../helpers/domHelpers";
import { checkScenarioShown, setScenarioShown } from "../helpers/localStorageHelpers";
import { useOnboardStore } from "../store/onboardStore";
import { AuthType, PlatformType, type OnboardScenario, OnboardScenarioConditionType } from "../types";

function useOnboardScenarioConditionsCheck() {
    function checkScenarioConditions(scenario: OnboardScenario) {
        const { conditions = [] } = scenario;

        if (conditions.length > 0) {
            const conditionFail = conditions.some((condition) => {
                switch (condition.type) {
                    case OnboardScenarioConditionType.NOT_SAW_STORIES: {
                        return condition.stories.some((name) => {
                            return checkScenarioShown(name);
                        });
                    }
                    case OnboardScenarioConditionType.SAW_STORIES: {
                        return condition.stories.some((name) => {
                            return !checkScenarioShown(name);
                        });
                    }
                }
            });

            return !conditionFail;
        }
        return true;
    }

    return {
        checkScenarioConditions,
    };
}

export function useOnboardScenarioRunner() {
    const onboardStore = useOnboardStore();
    const userInfoStore = useUserInfo();
    const modalsStore = useModalsStore();
    const { isMobile } = storeToRefs(useContextStore());
    const { checkScenarioConditions } = useOnboardScenarioConditionsCheck();

    function tryToActivateScenario(scenario: OnboardScenario, timeout: number) {
        if (modalsStore.isModalShown) {
            onboardStore.clearScenario();
            return;
        }

        const element = findElement(scenario.items[0].element);
        if (!element) {
            if (timeout > 0) {
                const timeoutId = setTimeout(() => {
                    tryToActivateScenario(scenario, timeout - RUN_SCENARIO_RETRY_DELAY);
                }, RUN_SCENARIO_RETRY_DELAY);
                onboardStore.setScenarioTimeout(timeoutId);
            } else {
                onboardStore.clearScenario();
            }
        } else {
            setScenarioShown(scenario.name);
            onboardStore.activateScenario();
        }
    }

    async function tryRunScenario(scenario: OnboardScenario) {
        const {
            name,
            delay,
            disabled = false,
            timeout = DEFAULT_RUN_SCENARIO_TIMEOUT,
            authType = AuthType.ALL,
            platformType = PlatformType.ALL,
        } = scenario;

        const isDisabled = typeof disabled === "function" ? disabled() : disabled;

        // conditions to run scenario
        if (checkScenarioShown(name)) {
            return;
        }
        if (isDisabled) {
            return;
        }
        if (authType !== AuthType.ALL) {
            const isLogged = await userInfoStore.isLoggedAsync();
            const authTypeIsLogged = authType === AuthType.AUTH_ONLY;
            if (isLogged !== authTypeIsLogged) {
                return;
            }
        }
        if (platformType !== PlatformType.ALL) {
            const platformTypeIsMobile = platformType === PlatformType.MOBILE_ONLY;
            if (isMobile.value !== platformTypeIsMobile) {
                return;
            }
        }
        if (!checkScenarioConditions(scenario)) {
            return;
        }

        onboardStore.initScenario(scenario);

        if (delay) {
            const timeoutId = setTimeout(() => {
                tryToActivateScenario(scenario, timeout);
            }, delay);
            onboardStore.setScenarioTimeout(timeoutId);
        } else {
            tryToActivateScenario(scenario, timeout);
        }
    }

    function clearScenario(scenario: OnboardScenario) {
        if (onboardStore.currentScenario && onboardStore.currentScenario.scenario.name === scenario.name) {
            onboardStore.clearScenario();
        }
    }

    return {
        tryRunScenario,
        clearScenario,
    };
}
