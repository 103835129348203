import cookie from "@front/core/controllers/CookieController";
import type { ITrigger, Callback } from "@controllers/triggers/types";

const TIME_COOKIE_PREFIX = "timer-trigger-start-time.";
const REPLAYS_COOKIE_PREFIX = "timer-trigger-replays.";
// 31 days in seconds - time to forget long term cookies, such a replays count
const COOKIE_FORGET_DELAY = 60 * 60 * 24 * 31;

interface ICookieParams {
    startTime?: number;
    replays?: number;
}

export interface ITimerConfig {
    // for cookie identification, must be unique
    name: string;
    // in ms
    time: number;
    // call callback on timer initialization
    immediate?: boolean;
    // delay replays count, undefined means infinity times
    replaysCount?: number;
}

export class TimerTrigger implements ITrigger<ITimerConfig> {
    protected static instance: TimerTrigger;
    private timers: Map<Callback, TimerTriggerItem>;

    protected constructor() {
        this.timers = new Map();
    }

    public static use() {
        if (!this.instance) {
            this.instance = new TimerTrigger();
        }
        return this.instance;
    }

    on(callback: Callback, condition: ITimerConfig) {
        const timer = new TimerTriggerItem(callback, condition);
        this.timers.set(callback, timer);
    }

    off(callback: Callback) {
        const timer = this.timers.get(callback);
        if (timer) {
            timer.stop();

            this.timers.delete(callback);
        }
    }

    offAll() {
        this.timers.forEach((timer) => {
            timer.stop();
        });
        this.timers.clear();
    }
}

export class TimerTriggerItem {
    private timerId?: ReturnType<typeof setTimeout>;
    private readonly config: ITimerConfig;
    private readonly callback: Callback;
    private replays: number;
    private startTime: number;

    public static getCookieKeys(name: string) {
        return {
            startTime: TIME_COOKIE_PREFIX + name,
            replays: REPLAYS_COOKIE_PREFIX + name,
        };
    }

    public static clearCookieByName(name: string) {
        const keys = this.getCookieKeys(name);

        cookie.set(keys.replays, "", {
            path: "/",
            expires: 1,
        });
        cookie.set(keys.startTime, "", {
            path: "/",
            expires: 1,
        });
    }

    public static setCookieByName(name: string, params: ICookieParams) {
        const keys = this.getCookieKeys(name);
        const { replays, startTime } = params;

        cookie.set(keys.startTime, String(startTime), {
            expires: COOKIE_FORGET_DELAY,
            path: "/",
        });

        cookie.set(keys.replays, String(replays), {
            expires: COOKIE_FORGET_DELAY,
            path: "/",
        });
    }

    public static initCookieByName(name: string) {
        this.setCookieByName(name, {
            startTime: Date.now(),
            replays: 0,
        });
    }

    constructor(callback: Callback, config: ITimerConfig) {
        this.callback = callback;
        this.config = config;

        const { replays, startTime } = this.init();
        this.replays = replays;
        this.startTime = startTime;

        this.setCookieParams();
        this.tryRunTimer();
    }

    private init() {
        const cookieData = this.getCookieParams();
        const { immediate, time } = this.config;

        const isImmediateCall = cookieData.replays === undefined && immediate;
        const isImmediateReplay = cookieData.replays !== undefined && cookieData.startTime === undefined;

        if (isImmediateCall) {
            this.callback();
        }

        const defaultStartTime = isImmediateReplay ? Date.now() - time : Date.now();

        return {
            replays: cookieData.replays || 0,
            startTime: cookieData.startTime || defaultStartTime,
        };
    }

    private tryRunTimer() {
        const { replaysCount, time } = this.config;

        if (replaysCount === undefined || this.replays < replaysCount) {
            this.timerId = setTimeout(() => {
                this.callback();
                this.replays = this.replays + 1;
                this.startTime = Date.now();

                this.setCookieParams();
                this.timerId = undefined;

                this.tryRunTimer();
            }, this.startTime + time - Date.now());
        }
    }

    public stop() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }

    private getCookieParams(): ICookieParams {
        const keys = TimerTriggerItem.getCookieKeys(this.config.name);

        // in case time or replays values are "", any non number values will return NaN
        const time = parseInt(String(cookie.get(keys.startTime)), 10);
        const replays = parseInt(String(cookie.get(keys.replays)), 10);

        return {
            replays: !isNaN(replays) ? replays : undefined,
            startTime: !isNaN(time) ? time : undefined,
        };
    }

    private setCookieParams() {
        TimerTriggerItem.setCookieByName(this.config.name, {
            startTime: this.startTime,
            replays: this.replays,
        });
    }
}
