import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import { useLivespinsStore } from "@modules/Livespins";
import { useGetLivespinsClient } from "@modules/Livespins/helpers/sdk";
import type { ILivespinsClient } from "@modules/Livespins/types";
import { onMounted } from "vue";

export function useLivespinsInit() {
    const { getLivespinsClient } = useGetLivespinsClient();
    const livespinsStore = useLivespinsStore();
    const { isLivespinsEnabled } = useBonusFeatureService();

    function setStreamsData(client: ILivespinsClient) {
        const live = client.getStreams() || [];
        const scheduled = client.getScheduledStreams() || [];

        livespinsStore.setStreams([ live, scheduled ]);
    }

    onMounted(async () => {
        if (isLivespinsEnabled.value) {
            const client = await getLivespinsClient();

            client.onUpdate(() => {
                setStreamsData(client);
            });
            setStreamsData(client);
        }
    });
}
