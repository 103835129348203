<template>
    <BorderGradientWrapper
        :width="Width.thin"
        :radius="Radius.S"
        :gradient="type === BonusType.CASINO ? Gradient.primary : Gradient.secondary"
        :inner-background="insideSection ? InnerBackground.alt2 : InnerBackground.alt1"
    >
        <div class="bonus-type-badge">
            {{ typeText }}
        </div>
    </BorderGradientWrapper>
</template>

<script lang="ts" setup>
import { BonusType } from "@controllers/services/bonuses/types";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import BorderGradientWrapper from "@components/BorderGradientWrapper/BorderGradientWrapper.vue";
import { Gradient, InnerBackground, Radius, Width } from "@components/BorderGradientWrapper/config";

const i18n = useI18n();

interface IProps {
    type: BonusType;
    insideSection?: boolean;
}
const props = defineProps<IProps>();

const typeText = computed(() => {
    switch (props.type) {
        case BonusType.CASINO: return i18n.t("GIFT.TYPE.CASINO");
        case BonusType.SPORT: return i18n.t("GIFT.TYPE.SPORT");
    }
});
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.bonus-type-badge {
    padding: 0.25rem 0.5rem;
    @include font-size--Caption2;
}
</style>
