import { PAGE_TYPE } from "@config/mainPages";
import { routeNames } from "@router/routeNames";

export const BANNER_TAG_BY_PAGE = {
    [routeNames.main]: [ PAGE_TYPE.MAIN ],
    [routeNames.casinoPage]: [ PAGE_TYPE.CASINO ],
    [routeNames.livePage]: [ PAGE_TYPE.LIVE ],
};

export const BANNER_STORIES_TAG = "turbotest";
