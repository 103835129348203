import { routeNames } from "@router/routeNames";
import type { Ref } from "vue";
import type { RouteLocationNormalized } from "vue-router";

export default function activeMenu(
    item: Record<string, unknown>,
    isActive: boolean,
    currentRoute: Ref<RouteLocationNormalized>,
    hiddenItems: unknown[] = [],
) {
    if (hiddenItems.includes(item.title)) {
        return [ "item-hidden" ];
    }
    let active = isActive;

    if (item.routeName === routeNames.main) {
        active = currentRoute.value.fullPath === "/";
    } else if (item.routeName === routeNames.casinoPage) {
        active = currentRoute.value.fullPath.startsWith("/casino");
    } else if (item.routeName === routeNames.livePage) {
        active = currentRoute.value.fullPath.startsWith("/live");
    }

    return { active };
}
