<template>
    <div class="footer" :style="contentVisibility">
        <div
            class="footer-block"
            :class="{'is-desktop': !isMobile}"
        >
            <div
                class="footer-block__wrapper"
                main-layout-width
            >
                <FooterMenu>
                    <section>
                        <FooterContacts />
                        <FooterSocial />
                        <LanguageSelect :input="lang" class="footer__lang" />
                    </section>
                </FooterMenu>
                <hr v-if="!isMobile">
                <PartnerBlock />
            </div>
        </div>
        <FooterPayments />
        <ProducersBlock />
        <Overviews />
        <FooterAwards />
        <TrustBlock />
        <FooterCopyright />
    </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import FooterMenu from "@modules/Footer/components/FooterMenu/index.vue";
import FooterPayments from "@modules/Footer/components/FooterPayments/index.vue";
import FooterContacts from "./components/FooterContacts/index.vue";
import ProducersBlock from "@modules/Footer/components/ProducersBlock/index.vue";
import TrustBlock from "@modules/Footer/components/TrustBlock/TrustBlock.vue";
import LanguageSelect from "@ui-kit/FeFields/LanguageSelect.vue";
import { ServerInput } from "@mixins/ServerInput";
import FooterSocial from "@modules/Footer/components/FooterSocial/FooterSocial.vue";
import FooterCopyright from "@modules/Footer/components/FooterCopyright/FooterCopyright.vue";
import PartnerBlock from "@modules/Footer/components/PartnerBlock/index.vue";
import Overviews from "@modules/Footer/components/Overviews/index.vue";
import FooterAwards from "@modules/Footer/components/FooterAwards/FooterAwards.vue";
import { useMultiLang } from "@store/multilang";
import contentVisibilityAuto from "@helpers/contentVisibilityAuto";
import { useContextStore } from "@store/context";
import { storeToRefs } from "pinia";

const { userLocale } = storeToRefs(useMultiLang());
const { isMobile } = storeToRefs(useContextStore());

const lang = ref(new ServerInput(userLocale.value, []));
const contentVisibility = computed(() => contentVisibilityAuto());

</script>

<style lang="scss" src="./style.scss" scoped></style>
