import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ONBOARD_SCENARIOS } from "../../config/scenarios";
import { useOnboardScenarioRunner } from "../useOnboardRunner";
import { useModalsStore } from "@store/modals";
import { type OnboardRouteTrigger, type OnboardScenario, TriggerType } from "@modules/Onboard/types";

export function useOnboardByRouting() {
    const router = useRouter();
    const route = useRoute();
    const modalsStore = useModalsStore();
    const { tryRunScenario } = useOnboardScenarioRunner();

    const routeTriggerScenarios = ONBOARD_SCENARIOS.filter((scenario) => {
        return scenario.trigger.type === TriggerType.ROUTE;
    }) as OnboardScenario<OnboardRouteTrigger>[];

    function checkTrigger() {
        const routeScenarios = routeTriggerScenarios.filter((scenario) => {
            const resolved = router.resolve(scenario.trigger.route);
            return resolved.name === route.name;
        });
        routeScenarios.forEach((scenario) => {
            tryRunScenario(scenario);
        });
    }

    watch(() => route.fullPath, checkTrigger, {
        immediate: true,
    });
    watch(() => modalsStore.isModalShown, checkTrigger);
}
