import type { IPaymentMethodResource } from "@api";
import { useUserInfo } from "@store/userInfo";
import { storeToRefs } from "pinia";

export function filterPayments(payments: IPaymentMethodResource[]): IPaymentMethodResource[] {
    const { userInfo } = storeToRefs(useUserInfo());
    const { isVerified, isAntiFraudVerified } = userInfo.value.verification;

    return payments.filter((payment: IPaymentMethodResource) => {
        if (payment.kycVerificationRequired) {
            return isVerified || isAntiFraudVerified;
        }

        return true;
    });
}
