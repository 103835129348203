import { BonusDetailKey } from "./detailTypes";
import { BonusKind, BonusStatus } from "./types";

export const BONUS_DETAIL_KEY_TRANSLATE_MAP: Record<BonusDetailKey, string> = {
    [BonusDetailKey.BONUS]: "BONUS",
    [BonusDetailKey.MAX_BONUS]: "MAX_BONUS",
    [BonusDetailKey.BET]: "BET",
    [BonusDetailKey.RECEIVED_BONUS]: "RECEIVED_BONUS",
    [BonusDetailKey.GAME]: "GAME",
    [BonusDetailKey.CASHBACK]: "CASHBACK",
    [BonusDetailKey.RECEIVED_CASHBACK]: "RECEIVED_CASHBACK",
    [BonusDetailKey.WAGERING]: "WAGERING",
    [BonusDetailKey.WAGER]: "WAGER",
    [BonusDetailKey.MAX_WIN]: "MAX_WIN",
    [BonusDetailKey.SPINS]: "SPINS",
    [BonusDetailKey.MIN_DEPOSIT]: "MIN_DEPOSIT",
    [BonusDetailKey.XP]: "XP",
    [BonusDetailKey.COINS]: "COINS",
    [BonusDetailKey.CASH]: "CASH",
    [BonusDetailKey.MIN_COEFFICIENT]: "MIN_COEFFICIENT",
    [BonusDetailKey.BET_TYPE]: "BET_TYPE",
    [BonusDetailKey.MIN_NUMBER_EVENTS]: "MIN_NUMBER_EVENTS",
    [BonusDetailKey.WIN_LIMIT]: "WIN_LIMIT",
};

export const BONUS_KINDS_WITHOUT_ACTIVE_STATE: BonusKind[] = [
    BonusKind.CASH,
    BonusKind.XP,
    BonusKind.ACTION_CURRENCY,
    BonusKind.GROUP,
];

export const ACTIVE_BONUS_STATUSES: BonusStatus[] = [
    BonusStatus.WAITING,
    BonusStatus.ACTIVE,
];

export interface BONUS_DETAIL_MAP {
    cardView: {
        available?: BonusDetailKey[],
        activeGift?: BonusDetailKey[],
        active?: BonusDetailKey[],
        history?: BonusDetailKey[],
    },
    cardShortView?: BonusDetailKey,
    detailsView: {
        available?: BonusDetailKey[],
        activeGift?: BonusDetailKey[],
        active?: BonusDetailKey[],
    },
}

export const BONUS_KIND_BONUS_DETAIL_MAP: Record<BonusKind, BONUS_DETAIL_MAP> = {
    [BonusKind.CASHBACK]: {
        cardShortView: BonusDetailKey.CASHBACK,
        cardView: {
            available: [
                BonusDetailKey.CASHBACK,
                BonusDetailKey.MIN_DEPOSIT,
                BonusDetailKey.WAGER,
            ],
            active: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.CASHBACK,
            ],
            history: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.CASHBACK,
                BonusDetailKey.MAX_WIN,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.CASHBACK,
                BonusDetailKey.WAGER,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            active: [
                BonusDetailKey.CASHBACK,
                BonusDetailKey.WAGERING,
                BonusDetailKey.RECEIVED_CASHBACK,
                BonusDetailKey.WAGER,
                BonusDetailKey.MAX_WIN,
            ],
        },
    },
    [BonusKind.DEPOSIT_BONUS]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MIN_DEPOSIT,
                BonusDetailKey.WAGER,
            ],
            active: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
            ],
            history: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_WIN,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_BONUS,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            active: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WAGERING,
                BonusDetailKey.RECEIVED_BONUS,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
            ],
        },
    },
    [BonusKind.GROUP]: {
        cardView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WAGER,
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
    },
    [BonusKind.CUSTOM_GIFT]: {
        cardView: {
            available: [
                BonusDetailKey.BONUS,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
            ],
        },
    },
    [BonusKind.UNIVERSAL_GAMING_FS]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.MIN_DEPOSIT,
                BonusDetailKey.WAGER,
            ],
            activeGift: [
                BonusDetailKey.WAGER,
            ],
            active: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
            ],
            history: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_WIN,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.SPINS,
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.GAME,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            activeGift: [
                BonusDetailKey.SPINS,
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.GAME,
            ],
            active: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WAGERING,
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
            ],
        },
    },
    [BonusKind.UNIVERSAL_GAMING_BONUS]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.MIN_DEPOSIT,
                BonusDetailKey.WAGER,
            ],
            active: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
            ],
            history: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_WIN,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.GAME,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            activeGift: [
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.GAME,
            ],
            active: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WAGERING,
                BonusDetailKey.RECEIVED_BONUS,
                BonusDetailKey.BET,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
            ],
        },
    },
    [BonusKind.CASH]: {
        cardView: {},
        detailsView: {
            available: [
                BonusDetailKey.CASH,
            ],
        },
    },
    [BonusKind.ACTION_CURRENCY]: {
        cardView: {
            available: [
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.COINS,
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
    },
    [BonusKind.XP]: {
        cardView: {
            available: [
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.XP,
                BonusDetailKey.MIN_DEPOSIT,
            ],
        },
    },
    [BonusKind.BETTING_FREEBET]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            active: [
                BonusDetailKey.BONUS,
            ],
            history: [
                BonusDetailKey.BONUS,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_BONUS,
                BonusDetailKey.MIN_COEFFICIENT,
                BonusDetailKey.BET_TYPE,
                BonusDetailKey.MIN_NUMBER_EVENTS,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            active: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MIN_COEFFICIENT,
                BonusDetailKey.BET_TYPE,
                BonusDetailKey.MIN_NUMBER_EVENTS,
            ],
        },
    },
    [BonusKind.BETTING_WAGERING]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MIN_DEPOSIT,
                BonusDetailKey.WAGER,
            ],
            active: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
            ],
            history: [
                BonusDetailKey.WAGERING,
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_WIN,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.MAX_BONUS,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.MIN_COEFFICIENT,
                BonusDetailKey.BET_TYPE,
                BonusDetailKey.MIN_NUMBER_EVENTS,
                BonusDetailKey.MIN_DEPOSIT,
            ],
            active: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WAGERING,
                BonusDetailKey.MAX_WIN,
                BonusDetailKey.WAGER,
                BonusDetailKey.MIN_COEFFICIENT,
                BonusDetailKey.BET_TYPE,
                BonusDetailKey.MIN_NUMBER_EVENTS,
            ],
        },
    },
    [BonusKind.INSURANCE]: {
        cardShortView: BonusDetailKey.BONUS,
        cardView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WIN_LIMIT,
                BonusDetailKey.WAGER,
            ],
        },
        detailsView: {
            available: [
                BonusDetailKey.BONUS,
                BonusDetailKey.WIN_LIMIT,
                BonusDetailKey.WAGER,
            ],
        },
    },
};
