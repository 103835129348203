export enum ToastTheme {
    default = "default",
    success = "success",
    error = "error",
    warning = "warning",
}

export interface IToastData {
    time?: number;
    theme?: ToastTheme;
    title?: string;
    text?: string;
}
