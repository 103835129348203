import type { IToastData } from "@modules/Toast/types";

interface IBus {
    $emit(event: string, ...args: unknown[]): void;
}

let bus: IBus | undefined = undefined;

export default {
    init($bus: IBus) {
        bus = $bus;
    },

    show(options: IToastData) {
        bus?.$emit("toast.show", options);
    },

    close(id: string) {
        bus?.$emit("toast.close", id);
    },
};
