import type { Pinia } from "pinia";
import type { IChangePasswordDTO, ILoginDTO, IRegisterDTO } from "@api";
import { authAPI } from "@api";
import bus from "@bus";
import { useBannersFetchService } from "@controllers/services/banners";
import WS from "@controllers/WebsocketController";
import { FullStory } from "@front/core/controllers/MetriksController/FullStory";
import { promiseAll } from "@mixins/promiseMixin";
import { useFasttrack } from "@modules/FastTrack/fasttrackStore";
import { useAchievements } from "@modules/Quests";
import { useCashbox } from "@store/cashbox";
import { useChats } from "@store/chats";
import { useCurrentGame } from "@store/currentGame";
import { useGamesStore } from "@store/games";
import { useGifts } from "@store/gifts";
import { useGiftsStoreV2 } from "@store/giftsV2";
import { useLevels } from "@store/levels";
import { useProducersStore } from "@store/producers";
import { usePromo } from "@store/promo";
import { useTournaments } from "@store/tournaments";
import { useUserBalance } from "@store/userBalance";
import { useUserConsents } from "@store/userConsents";
import { useUserInfo } from "@store/userInfo";
import { useUserNotice } from "@store/userNotice";
import { useUserStrategies } from "@store/userStrategies";
import { useServerStore } from "@store/server";
import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import DmpController from "@front/core/controllers/MetriksController/DmpController";
import { useLivespinsStore } from "@modules/Livespins";

export interface ILoadBaseUserDataParams {
    isInit?: boolean;
    localeRedirectUrl?: string;
}

export function useAuth(pinia?: Pinia) {
    const giftsStore = useGifts(pinia);
    const giftsStoreV2 = useGiftsStoreV2(pinia);
    const promoStore = usePromo(pinia);
    const achievementsStore = useAchievements(pinia);
    const fasttrackStore = useFasttrack(pinia);
    const levelsStore = useLevels(pinia);
    const cashboxStore = useCashbox(pinia);
    const userInfoStore = useUserInfo(pinia);
    const promoAchievements = useAchievements(pinia);
    const userNoticeStore = useUserNotice(pinia);
    const userBalanceStore = useUserBalance(pinia);
    const chatsStore = useChats(pinia);
    const userConsentsStore = useUserConsents(pinia);
    const userStrategiesStore = useUserStrategies(pinia);
    const tournamentsStore = useTournaments(pinia);
    const producersStore = useProducersStore(pinia);
    const gamesStore = useGamesStore(pinia);
    const currentGameStore = useCurrentGame(pinia);
    const serverStore = useServerStore(pinia);
    const livespinsStore = useLivespinsStore(pinia);
    const { isBonusesEnabled } = useBonusFeatureService();

    const { reloadBanners } = useBannersFetchService(pinia);

    async function login(loginData: ILoginDTO) {
        return await authAPI.login(loginData);
    }
    async function registration(registrationData: IRegisterDTO) {
        const data = await authAPI.register(registrationData);

        if (!data.success) {
            return Promise.reject(data);
        }
        return data;
    }
    function setNewPassword(changePasswordData: IChangePasswordDTO) {
        return authAPI.changePassword(changePasswordData);
    }

    function cleanUserData() {
        userInfoStore.cleanUserInfo();
        userInfoStore.cleanUserStatusData();
        userConsentsStore.cleanUserConsents();
        userBalanceStore.cleanUserBalances();
        userStrategiesStore.cleanUserStrategies();
        userInfoStore.setUserFeatures([]);
        achievementsStore.cleanPersonalizedAchievements();
        currentGameStore.clear();
        levelsStore.cleanLevelData();
        giftsStore.cleanGiftsData();
        giftsStoreV2.resetData();
        promoStore.cleanUserPromoData();
        promoStore.loadActivePromo();
        cashboxStore.cleanUserDepositNumbers();
        fasttrackStore.cleanFasttrackData();
        userNoticeStore.cleanUserNotification();
        tournamentsStore.cleanUserTournamentList();
        livespinsStore.clearAuthToken();
        reloadBanners();
        producersStore.reloadProducers();
        gamesStore.reloadAllProducerGames();
        gamesStore.reloadAll();
        gamesStore.reloadAllTournamentGames();
        gamesStore.reloadAllPageGames();
        gamesStore.reloadAll();
        chatsStore.cleanRestoreId();
        WS.stop();

        bus.$emit("user.logout");

        if (DmpController && DmpController.enabled) {
            DmpController.resetUser();
        }

        if (navigator.credentials) {
            navigator.credentials.preventSilentAccess();
        }
    }

    async function logout() {
        const data = await authAPI.logout();
        cleanUserData();
        return data;
    }

    async function loadBaseUserData(params?: ILoadBaseUserDataParams) {
        const {
            isInit = false,
            localeRedirectUrl,
        } = params || {};

        const promises: Array<Promise<unknown>> = [
            userInfoStore.loadUserProfile({
                localeRedirectUrl,
            }).then((data) => {
                bus.$emit("user.login", data);
                return chatsStore.loadFcRestoreId();
            }),
            userConsentsStore.loadUserConsents(),
            userBalanceStore.loadUserBalance(),
            userBalanceStore.loadWinbackData(),
            levelsStore.loadUserLevelData(),
            userNoticeStore.loadUserNotification(),
            userInfoStore.loadContactsOnVerification(),
            userInfoStore.loadUserFeatures(),
            userStrategiesStore.loadUserStrategies(),
            tournamentsStore.loadUserTournamentList(),
            promoStore.loadPromoList(),
            cashboxStore.loadWithdrawRequests(),
            cashboxStore.loadUserDepositNumbers(),
            promoAchievements.loadPersonalizedAchievements(),
            userInfoStore.loadUserStatusData(),
        ];

        // just reload, without waiting for it
        gamesStore.reloadAllProducerGames();
        gamesStore.reloadAllTournamentGames();
        gamesStore.reloadAllPageGames();
        gamesStore.reloadAll();
        producersStore.reloadProducers();

        if (!isInit) {
            promises.push(reloadBanners());
        }

        return promiseAll(promises).then(() => {
            const { fullStory } = serverStore.metricsConfig;

            giftsStore.loadGiftsData();
            if (isBonusesEnabled.value) {
                giftsStoreV2.initData();
            }

            WS.start();

            if (fullStory?.project_id) {
                FullStory.identify(userInfoStore, fullStory.project_id as string);
            }
        });
    }
    async function loadFullUserData(params?: ILoadBaseUserDataParams) {
        return promiseAll([
            loadBaseUserData(params),
            levelsStore.loadLevelsData(),
            promoStore.loadUserPromoData(),
            promoStore.loadActivePromo(),
        ]);
    }

    return {
        login,
        registration,
        setNewPassword,
        logout,
        cleanUserData,

        loadBaseUserData,
        loadFullUserData,
    };
}
