import { defineStore } from "pinia";
import { ref } from "vue";
import { bannersAPI, type IBannerData } from "@api";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { useUserInfo } from "@store/userInfo";

export const useBannersStore = defineStore("banners", () => {
    const data = ref<IBannerData[] | undefined>();

    const userInfoStore = useUserInfo();

    const loadBanners = promiseMemoizer(async() => {
        const isLogged = await userInfoStore.isLoggedAsync();

        data.value = await bannersAPI.loadBannersDataByTag(isLogged);
    });

    function clearBannersData() {
        data.value = [];
    }

    function getBannersByTags(tags: string[]): IBannerData[] {
        if (!tags.length) {
            return data.value ?? [];
        }

        return data.value?.filter((banner) => {
            return tags.some((tag) => banner.tags.includes(tag));
        }) ?? [];
    }

    return {
        data,
        loadBanners,
        getBannersByTags,
        clearBannersData,
    };
});
