import { wait } from "@front/core/helpers/functionsHelper";
import type { RouterScrollBehavior } from "vue-router";

export async function tryScrollToAnchor(hash: string, timeout = 5000, delay = 200) {
    if (timeout <= 0) {
        return;
    }
    const el = document.querySelector(hash);
    if (timeout > 0 && el) {
        await wait(delay);
        el.scrollIntoView({ behavior: "smooth" });
        return;
    }
    await wait(delay);
    tryScrollToAnchor(hash, timeout - delay);
}

export const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        tryScrollToAnchor(to.hash);
        return;
    }
    if (savedPosition) {
        return savedPosition;
    }

    return { left: 0, top: 0 };
};
