<template>
    <div class="footer-menu" :class="{ 'is-desktop': !isMobile }" gap-xs>
        <slot />
        <section v-for="(category, index) in menuCategory" :key="index" class="caption-1">
            <FeAccordion :is-open="!isMobile">
                <template #activator="{ toggleHandler, showContent }">
                    <div
                        class="footer-menu__title"
                        :class="{ 'is-active': showContent }"
                        @click="isMobile ? toggleHandler() : null"
                    >
                        <b>{{ category.name }}</b>
                        <FeIcon v-if="isMobile" class="footer-menu__arrow" :icon="FeIconConfig.dropdownDown" />
                    </div>
                </template>
                <template v-for="item in category.pages" :key="item.id">
                    <FeLink
                        v-if="item.target === '_blank'"
                        :to="blankLink(item)"
                        class="footer-menu__item"
                        target="_blank"
                    >
                        {{ item.title }}
                    </FeLink>
                    <FeLink v-else :to="item.url" class="footer-menu__item">
                        {{ item.title }}
                    </FeLink>
                </template>
            </FeAccordion>
        </section>
    </div>
</template>

<script setup lang="ts">
import { useContextStore } from "@store/context";
import { computed } from "vue";
import { COUNTRY_BY_HOST } from "@theme/configs/constsLocales";
import FeAccordion from "@ui-kit/FeAccordion/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { FooterMenuType, slugConfig } from "@modules/Footer/components/FooterMenu/slugConfig";
import { useI18n } from "vue-i18n";
import { useVisibleStaticPages, type IVisibleStaticPagesItemResource } from "@controllers/services/static-pages";
import { storeToRefs } from "pinia";

const i18n = useI18n();

const { isMobile } = storeToRefs(useContextStore());

interface IMenuCategoryItem {
    name: string;
    pages: IVisibleStaticPagesItemResource[];
}
type IMenuCategory = Record<FooterMenuType, IMenuCategoryItem>;

const { visibleStaticPages } = useVisibleStaticPages();

function blankLink(item: IVisibleStaticPagesItemResource): string {
    const hostname = location.hostname;
    let path = hostname + item.url;

    if (item.slug === "blog" && COUNTRY_BY_HOST[hostname]) {
        path = `${COUNTRY_BY_HOST[hostname].toLowerCase()}.${hostname}`;
    }
    return `https://${path}`;
}

const menuCategory = computed<IMenuCategory>(() => {
    const menu = {} as IMenuCategory;

    Object.values(FooterMenuType).forEach((type) => {
        menu[type] = {
            name: i18n.t(`FOOTER.MENU.${type.toUpperCase()}`),
            pages: [],
        };
    });

    visibleStaticPages.value.forEach((page) => {
        const category = (page.slug && slugConfig[page.slug]) || FooterMenuType.INFO;

        menu[category].pages.push(page);
    });
    return menu as IMenuCategory;
});
</script>
<style lang="scss" src="./style.scss"></style>
