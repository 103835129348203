import { type IUserNotificationResource, NotificationStatus, userAPI } from "@api";
import { filterNoticeArray } from "@helpers/filterNotice";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUserNotice = defineStore("userNotice", () => {
    const userNotice = ref<IUserNotificationResource[]>([]);
    async function loadUserNotification() {
        const data = await userAPI.loadUserNotification();
        userNotice.value = filterNoticeArray(data);
    }
    async function deleteUserNotification(id: string) {
        const data = await userAPI.readUserNotification(id);
        userNotice.value = userNotice.value.filter((item) => {
            return item.id !== data.id;
        });
    }
    function addUserNotification(notice: IUserNotificationResource) {
        userNotice.value.push(notice);
    }
    function cleanUserNotification() {
        userNotice.value = [];
    }
    const countNewNotification = computed<number>(() => {
        return userNotice.value.filter((el: IUserNotificationResource) => {
            return el.status === NotificationStatus.NEW;
        }).length;
    });

    return {
        userNotice,
        loadUserNotification,
        deleteUserNotification,
        addUserNotification,
        cleanUserNotification,
        countNewNotification,
    };
});
