<template>
    <div class="user-balance" gap-row-s @click.stop>
        <div class="user-balance__common">
            <div>
                <div class="user-balance__title">
                    {{ $t(balanceTitle) }}
                    <Tooltip
                        v-if="commonBonusBalance"
                        v-click-outside="hideTooltip"
                        bottom
                        :show-on-hover="true"
                        :show="isShowTooltip"
                        :width="200"
                    >
                        <template #activator>
                            <FeIcon
                                class="user-balance__tooltip-info"
                                :icon="FeIconConfig.info1"
                                @click="toggleTooltip"
                            />
                        </template>
                        <div v-html="tooltipText" />
                    </Tooltip>
                </div>
                <div class="user-balance__value">
                    {{ currencyView(commonBalance) }}
                </div>
            </div>
            <slot />
        </div>
        <div v-if="commonBonusBalance" class="user-balance__detailed">
            <div class="user-balance__detailed-real">
                <div class="user-balance__label">
                    {{ $t("PROFILE.BALANCE_REAL_TITLE") }}
                </div>
                <div class="user-balance__value">
                    {{ currencyView(realBalance) }}
                </div>
            </div>
            <div class="user-balance__detailed-bonus">
                <div class="user-balance__label">
                    {{ $t("PROFILE.BALANCE_BONUS_TITLE") }}
                </div>
                <div class="user-balance__value">
                    {{ currencyView(commonBonusBalance) }}
                </div>
            </div>
        </div>
        <FeProgressBar
            v-if="bonusBalance"
            :progress="percentageWagering"
            class="user-balance__progressbar"
        >
            <template #top>
                <div class="user-balance__progressbar-description">
                    <div class="user-balance__text">
                        {{ $t("PROFILE.BALANCE_BONUS_SLOT_TITLE") }}
                    </div>
                    <div class="user-balance__progressbar-wagering">
                        <span class="user-balance__amount">{{ currencyView(bonusBalance) }}</span>
                    </div>
                </div>
            </template>
            <div>
                <div class="user-balance__progressbar-wagered">
                    {{ $t("PROFILE.BALANCE_BONUS_SLOT_DESCRIPTION") }}
                    <span class="user-balance__progressbar-wagered-amount">
                        {{ currencyView(userWagered) }}
                        {{ $t("PROFILE.BALANCE_OF") }}
                        {{ currencyView(totalWagering) }}
                    </span>
                </div>
            </div>
        </FeProgressBar>
        <FeProgressBar
            v-if="isShowSportBonus"
            :progress="bettingPercentageWagering"
            class="user-balance__progressbar"
        >
            <template #top>
                <div class="user-balance__progressbar-description">
                    <div class="user-balance__text">
                        {{ $t("PROFILE.BALANCE_BONUS_BETTING_TITLE") }}
                    </div>
                    <div class="user-balance__progressbar-wagering">
                        <span class="user-balance__amount">{{ currencyView(bettingBonusBalance) }}</span>
                    </div>
                </div>
            </template>

            <div>
                <div class="user-balance__progressbar-wagered">
                    {{ $t("PROFILE.BALANCE_BONUS_BETTING_DESCRIPTION") }}
                    <span class="user-balance__progressbar-wagered-amount">
                        {{ currencyView(userBettingWagered) }}
                        {{ $t("PROFILE.BALANCE_OF") }}
                        {{ currencyView(userBettingTotalWagering) }}
                    </span>
                </div>
            </div>
        </FeProgressBar>
        <div v-if="freeBet" class="user-balance__freebet">
            <div class="user-balance__text">
                {{ $t("PROFILE.BALANCE_BONUS_FREEBET") }}
            </div>
            <div class="user-balance__amount">
                {{ currencyView(freeBet) }}
            </div>
        </div>
        <slot name="bottom-info" />
    </div>
</template>
<script setup lang="ts">
import { currencyView } from "@controllers/services/currency-view";

import { computed, ref } from "vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import Tooltip from "@components/Tooltip/index.vue";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";
import config from "@theme/configs/config";
import { useUserBalanceServices } from "@controllers/services/user";

import { useI18n } from "vue-i18n";
const i18n = useI18n();

const PERCENTS = 100;
const isShowTooltip = ref<boolean>(false);

function toggleTooltip() {
    isShowTooltip.value = !isShowTooltip.value;
}
function hideTooltip() {
    isShowTooltip.value = false;
}

const {
    commonBalance,
    bonusBalance,
    commonBonusBalance,
    realBalance,
    userBettingWagering,
    bettingBonusBalance,
    userBettingWagered,
    userBettingTotalWagering,
    userWagered,
    totalWagering,
    freeBet,

} = useUserBalanceServices();

const tooltipText = i18n.t("PROFILE.BALANCE_TOOLTIP_TEXT", { link: config.links.bonusTermsPage });

const balanceTitle = computed(() => {
    return commonBonusBalance.value ? "PROFILE.BALANCE_COMMON_TITLE" : "PROFILE.BALANCE_TITLE";
});

const isShowSportBonus = computed(() => {
    return userBettingWagering.value || bettingBonusBalance.value;
});

const bettingPercentageWagering = computed(() => {
    return Math.floor((userBettingWagered.value / userBettingTotalWagering.value) * PERCENTS);
});

const percentageWagering = computed(() => {
    return Math.floor((userWagered.value / totalWagering.value) * PERCENTS);
});

</script>
<style scoped lang="scss">
@import "~@theme/styles";
.user-balance {
    text-align: left;
    @include font-size--Caption2;

    &__common {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-size--Header3;
        color: $color-additional-1;
    }

    &__value {
        color: $color-text-alt;
        @include font-size--Caption1;
    }
    &__common #{&}__value {
        @include font-size--Header3;
        color: $color-additional-1;
    }

    &__title,
    &__label {
        @include font-size--Caption2($font-weight--normal);
        color: $color-text-body;
    }

    &__detailed {
        display: flex;
        flex-wrap: wrap;

        & > div {
            flex: 1 50%;
            box-sizing: border-box;
        }

        &-real {
            border-right: 0;
        }

        &-bonus {
            padding-left: 0;
        }
    }

    &__tooltip-info {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        color: $color-text-link-default;

        svg {
            width: 1.75rem;
        }
    }

    &__progressbar {
        box-sizing: border-box;
        color: $color-text-alt;
        @include font-size--Caption2;

        :deep(.progressbar__main) {
            margin: 0.25rem 0;
        }

        &-description {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-weight: $font-weight--normal;
        }
        &-wagering {
            @include font-size--Caption2($font-weight--bold);
            color: $color-text-alt;
        }
        &-wagered {
            @include font-size--SmallCaption($font-weight--normal);
            text-align: center;
            color: $color-text-body;

            &-amount {
                color: $color-text-alt;
                font-weight: $font-weight--bold;
            }
        }
    }

    &__text {
        font-weight: $font-weight--normal;
    }

    &__freebet {
        display: flex;
        justify-content: space-between;
        color: $color-text-alt;
        align-items: center;
    }
}
</style>
