import { computed, onBeforeUnmount, onMounted } from "vue";
import config from "@theme/configs/config";
import { useI18n } from "vue-i18n";

export function useIdleTitle() {
    let savedTitle = "";
    let idleTitles: string[] = [];
    let startDelayTimer: ReturnType<typeof setTimeout> | undefined = undefined;
    let repeatTimer: ReturnType<typeof setInterval> | undefined = undefined;
    let isStarted = false;
    let idleTitleIndex = 0;

    function changeIdleTitle() {
        document.title = idleTitles[idleTitleIndex];
        idleTitleIndex = (idleTitleIndex + 1) % idleTitles.length;
    }

    function start(titles: string[]) {
        if (!isStarted && titles.length > 1) {
            isStarted = true;
            idleTitles = [ ...titles ];
            savedTitle = document.title;

            startDelayTimer = setTimeout(() => {
                changeIdleTitle();
                repeatTimer = setInterval(changeIdleTitle, config.idlePageTitle.changeDelay);
            }, config.idlePageTitle.idleStartDelay);
        }
    }

    function stop() {
        if (isStarted) {
            isStarted = false;
            clearTimeout(startDelayTimer);
            clearInterval(repeatTimer);

            document.title = savedTitle;
        }
    }

    return {
        start,
        stop,
    };
}

export function useIdleTitleStrategy () {
    const i18n = useI18n();
    const idleTitle = useIdleTitle();

    const titles = computed<string[]>(() => {
        const list: string[] = [];

        if (i18n.te("IDLE_PAGE_TITLE_1") && i18n.t("IDLE_PAGE_TITLE_1").trim().length > 0) {
            list.push(i18n.t("IDLE_PAGE_TITLE_1"));
        }
        if (i18n.te("IDLE_PAGE_TITLE_2") && i18n.t("IDLE_PAGE_TITLE_2").trim().length > 0) {
            list.push(i18n.t("IDLE_PAGE_TITLE_2"));
        }

        return list;
    });

    function visibilityChangeHandler() {
        if (document.visibilityState === "hidden") {
            idleTitle.start(titles.value);
        } else {
            idleTitle.stop();
        }
    }
    onMounted(() => {
        if (config.idlePageTitle.enabled && titles.value.length > 1) {
            document.addEventListener("visibilitychange", visibilityChangeHandler, false);
        }
    });
    onBeforeUnmount(() => {
        document.removeEventListener("visibilitychange", visibilityChangeHandler, false);
        idleTitle.stop();
    });
}
