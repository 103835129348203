export enum BreakPoints {
    BREAK_POINT_XXS = 320,
    BREAK_POINT_XS = 360,
    BREAK_POINT_S = 480,
    BREAK_POINT_M = 768,
    BREAK_POINT_L = 1024,
    BREAK_POINT_XL = 1440,
}

export interface IConfigImg {
    width: number;
    height: number;
}

export interface IResizeImgQueryParams extends IConfigImg {
    dpr?: number;
}

export interface IConfigPreparing extends IResizeImgQueryParams {
    url: string;
}

export interface IConfigImgCompostela {
    dimension?: string | number;
    crop?: string;
    dpr?: number;
    provider?: string;
    gradients?: string;
    badges?: string;
}

export enum Extension {
    png = "png",
    jpg = "jpg",
    jpeg = "jpeg",
    gif = "gif",
}

export enum Gravity {
    center = "ce",
    northwest = "nowe", // top-left corner
}

export enum ResizingType {
    fill = "fill",
    fit = "fit",
}

export type ConfigImgType = IConfigImg | IConfigImgCompostela;

export interface IConfigForTypeImage {
    sizeByBreakPoint: Partial<Record<BreakPoints, ConfigImgType>>;
    extension: Extension;
    gravity: Gravity;
    resizeType: ResizingType;
}

export const IMG_QUERY_WIDTH = "width";
export const IMG_QUERY_HEIGHT = "height";
export const IMG_QUERY_DPR = "dpr";
export const IMG_QUERY_CROP = "crop";
export const IMG_QUERY_DIMENSION = "dimension";
