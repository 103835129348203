import { BonusKind, BonusType, GiftStatus, type IBonusData } from "@controllers/services/bonuses/types";
import { UserVipStatus } from "@controllers/services/user";
import iconDepositInsurance from "@theme/images/icon_deposit-insurance.png";
import { BonusDetailType } from "@controllers/services/bonuses/detailTypes";
import { Currency } from "@api/resources/commonResources";

type AmountMap = {
    [key in UserVipStatus]?: Record<Currency, number>
}
type BonusOptions = {
    currency: Currency;
    title: string;
    description: string;
};

const BONUS_ID = "insurance-bonus";
const BONUS_KIND = BonusKind.INSURANCE;
const BONUS_TYPE = BonusType.INSURANCE;
const BONUS_STATUS = GiftStatus.RECEIVED;

const amountMap: AmountMap = {
    [UserVipStatus.BRONZE]: {
        [Currency.INR]: 100000000,
        [Currency.EUR]: 1000000,
        [Currency.USD]: 1000000,
        [Currency.AUD]: 1500000,
        [Currency.NZD]: 1500000,
        [Currency.CAD]: 1500000,
        [Currency.BRL]: 5000000,
    },
    [UserVipStatus.SILVER]: {
        [Currency.INR]: 100000000,
        [Currency.EUR]: 1000000,
        [Currency.USD]: 1000000,
        [Currency.AUD]: 1500000,
        [Currency.NZD]: 1500000,
        [Currency.CAD]: 1500000,
        [Currency.BRL]: 5000000,
    },
    [UserVipStatus.GOLD]: {
        [Currency.INR]: 100000000,
        [Currency.EUR]: 1000000,
        [Currency.USD]: 1000000,
        [Currency.AUD]: 1500000,
        [Currency.NZD]: 1500000,
        [Currency.CAD]: 1500000,
        [Currency.BRL]: 5000000,
    },
};

export const IS_DEPOSIT_INSURANCE_REQUESTED = "isDepositInsuranceRequested";
export const IS_DEPOSIT_INSURANCE_ERROR = "isDepositInsuranceError";
export const DEPOSIT_INSURANCE_TIMESTAMP = "depositInsuranceTimestamp";

export const getDepositInsuranceBonus = (status: UserVipStatus, options: BonusOptions) => {
    const { currency, description, title } = options;
    const depositInsurance: Record<UserVipStatus, IBonusData> = {
        [UserVipStatus.BRONZE]: {
            title,
            id: `${BONUS_ID}_${UserVipStatus.BRONZE}`,
            image: iconDepositInsurance,
            kind: BONUS_KIND,
            type: BONUS_TYPE,
            giftStatus: BONUS_STATUS,
            needEmailVerification: false,
            localisationVariables: {},
            description,
            details: {
                bonus: { type: BonusDetailType.PERCENT, amount: 10 },
                wager: { type: BonusDetailType.MULTIPLIER, amount: 20 },
                winLimit: { type: BonusDetailType.CASH, currency, amount: amountMap[UserVipStatus.BRONZE]?.[currency] ?? 0 },
            },
        },
        [UserVipStatus.SILVER]: {
            title,
            id: `${BONUS_ID}_${UserVipStatus.SILVER}`,
            image: iconDepositInsurance,
            kind: BONUS_KIND,
            type: BONUS_TYPE,
            giftStatus: BONUS_STATUS,
            needEmailVerification: false,
            localisationVariables: {},
            description,
            details: {
                bonus: { type: BonusDetailType.PERCENT, amount: 10 },
                wager: { type: BonusDetailType.MULTIPLIER, amount: 15 },
                winLimit: { type: BonusDetailType.CASH, currency, amount: amountMap[UserVipStatus.SILVER]?.[currency] ?? 0 },
            },
        },
        [UserVipStatus.GOLD]: {
            title,
            id: `${BONUS_ID}_${UserVipStatus.GOLD}`,
            image: iconDepositInsurance,
            kind: BONUS_KIND,
            type: BONUS_TYPE,
            giftStatus: BONUS_STATUS,
            needEmailVerification: false,
            localisationVariables: {},
            description,
            details: {
                bonus: { type: BonusDetailType.PERCENT, amount: 10 },
                wager: { type: BonusDetailType.MULTIPLIER, amount: 10 },
                winLimit: { type: BonusDetailType.CASH, currency, amount: amountMap[UserVipStatus.GOLD]?.[currency] ?? 0 },
            },
        },
        [UserVipStatus.PLATINUM]: {
            title,
            id: `${BONUS_ID}_${UserVipStatus.PLATINUM}`,
            image: iconDepositInsurance,
            kind: BONUS_KIND,
            type: BONUS_TYPE,
            giftStatus: BONUS_STATUS,
            needEmailVerification: false,
            localisationVariables: {},
            description,
            details: {
                bonus: { type: BonusDetailType.PERCENT, amount: 15 },
                wager: { type: BonusDetailType.MULTIPLIER, amount: 0.01 },
            },
        },
        [UserVipStatus.DIAMOND]: {
            title,
            id: `${BONUS_ID}_${UserVipStatus.DIAMOND}`,
            image: iconDepositInsurance,
            kind: BONUS_KIND,
            type: BONUS_TYPE,
            giftStatus: BONUS_STATUS,
            needEmailVerification: false,
            localisationVariables: {},
            description,
            details: {
                bonus: { type: BonusDetailType.PERCENT, amount: 15 },
                wager: { type: BonusDetailType.MULTIPLIER, amount: 0.01 },
            },
        },
    };

    return depositInsurance[status];
};
