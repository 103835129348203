<template>
    <div class="badge-container" :class="classes">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { CustomBadgeSize, CustomBadgeThemes } from "@ui-kit/CustomBadge/config";
import { computed } from "vue";

interface IProps {
    theme?: CustomBadgeThemes;
    size?: CustomBadgeSize;
    uppercase?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
    theme: CustomBadgeThemes.primary,
    size: CustomBadgeSize.default,
});

const classes = computed(() => {
    return [
        `badge-container--theme-${props.theme}`,
        `badge-container--size-${props.size}`,
        {
            "badge-container--uppercase": props.uppercase,
        },
    ];
});
</script>

<style scoped lang="scss">
@import "~@theme/styles";
$themesBackground: (
    primary: $color-primary-5,
    secondary: $color-secondary-1,
    additional: $color-additional-1, // primary
    tertiary: $color-tertiary-1, // secondary
    tertiary3: $color-tertiary-3,
    tertiary5: $color-tertiary-5,
);

.badge-container {
    border-radius: $border-radius-M;
    text-align: center;
    color: $color-on-light-back;

    &--uppercase {
        text-transform: uppercase;
    }

    &--size {
        &-default {
            padding: 0 0.5rem;
            @include font-size--SmallCaption($font-weight--bold);
        }
        &-md {
            padding: 0.25rem 0.5rem;
            @include font-size--Caption2($font-weight--bold);
        }
        &-sm {
            width: 1rem;
            height: 1rem;
            @include font-size--SmallCaption($font-weight--bold);
        }
    }

    @each $name, $color in $themesBackground {
        &--theme-#{$name} {
            background: $color;
        }
    }
}
</style>
