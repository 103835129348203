import { onBeforeMount, onBeforeUnmount } from "vue";
import { ONBOARD_SCENARIOS } from "../../config/scenarios";
import { useOnboardScenarioRunner } from "../useOnboardRunner";
import { type OnboardEventBusTrigger, type OnboardScenario, TriggerType } from "@modules/Onboard/types";
import bus from "@bus";

export function useOnboardByEventBus() {
    const { tryRunScenario, clearScenario } = useOnboardScenarioRunner();

    const eventBusTriggerScenarios = ONBOARD_SCENARIOS.filter((scenario) => {
        return scenario.trigger.type === TriggerType.EVENT_BUS;
    }) as OnboardScenario<OnboardEventBusTrigger>[];

    const handlers: Array<[string, () => void]> = [];
    onBeforeMount(() => {
        eventBusTriggerScenarios.forEach((scenario) => {
            const { event, offEvent } = scenario.trigger;
            const onHandler = () => {
                tryRunScenario(scenario);
            };
            handlers.push([ event, onHandler ]);
            bus.$on(event, onHandler);

            if (offEvent) {
                const offHandler = () => {
                    clearScenario(scenario);
                };
                handlers.push([ offEvent, offHandler ]);
                bus.$on(offEvent, offHandler);
            }
        });
    });
    onBeforeUnmount(() => {
        handlers.forEach(([ name, handler ]) => {
            bus.$off(name, handler);
        });
    });
}
