<script setup lang="ts">

/*
https://www.fasttrack-solutions.com/en/resources/integration/channels/on-site-notifications-and-rich-inbox/front-end-implementation

DEMO MESSAGE

sid = "99999999-9999-9999-9999-999999999999-9999"

window.FasttrackCrm.demoMessage({
    Event: 'message',
    Title: 'Title',
    Message: 'Message body',
    CTAButtonLink: 'https://www.google.com',
    CTAButtonText: 'Primary button',
    ImageUrl: 'https://via.placeholder.com/900x380',
    CTAButton2Link: 'https://www.google.com',
    CTAButton2Text: 'Secondary button',
    Expires: '2020-05-05 10:00:00',
    DisplayType: 'push',
    PreviewText: 'Preview text',
    FooterText: 'Footer text',
});
*/
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { configAPI } from "@api";
import { isGamePage } from "@controllers/services/games/pageService";
import { useVerificationService } from "@controllers/services/user/verificationService";
import { useFasttrack } from "@modules/FastTrack/fasttrackStore";
import { useUserInfo } from "@store/userInfo";
import { useContextStore } from "@store/context";
import { useMultiLang } from "@store/multilang";
import config from "@theme/configs/config";
import FeImage from "@ui-kit/FeImage/index.vue";
import mailIcon from "./images/mail2.svg";

const i18n = useI18n();
const { isMobile } = storeToRefs(useContextStore());
const { userLanguage } = storeToRefs(useMultiLang());

const userInfoStore = useUserInfo();
const { cards, checkVerifyPassed } = useVerificationService();

const fastTrackStore = useFasttrack();
const { sid } = storeToRefs(fastTrackStore);

const scriptSrc = ref<string>();
const showButton = ref(false);

const headerHeight = computed(() => {
    if (!userInfoStore.isLogged || !userInfoStore.userInfo.id) {
        return "";
    }

    if (isMobile.value) {
        if (!userInfoStore.isVerifiedEmail) {
            return "7rem";
        }

        if (!checkVerifyPassed(cards.value)) {
            return "8rem";
        }
    } else if (!userInfoStore.isVerifiedEmail || !checkVerifyPassed(cards.value)) {
        return "8rem";
    }
});

const createScript = () => {
    const fastTrackCrmScript = document.createElement("script");
    fastTrackCrmScript.async = true;
    fastTrackCrmScript.type = "text/javascript";
    fastTrackCrmScript.src = scriptSrc.value ?? "";
    document.body.appendChild(fastTrackCrmScript);

    fastTrackCrmScript.onload = () => {
        if (window.FastTrackLoader) {
            return new window.FastTrackLoader();
        }
    };
};
const initFastTrack = () => {
    window.sid = sid.value;
    window.fasttrackbrand = config.fastTrackBrand;
    window.fasttrack = {
        integrationVersion: 1.1,
        autoInit: true,
        enableEventTracking: false,
        enablePendingRewards: false,
        enableCrmIntegration: true,
        locale: userLanguage.value,
        inbox: {
            enable: true,
            closeModalOnFogClick: true,
            badgeId: "#some-element-id",
            termsAboveButtons: false,
            contactEmail: i18n.t("FASTTRACK.CONTACT_EMAIL"),
        },
        translations: {
            validUntil: i18n.t("FASTTRACK.VALID_UNTIL"),
            questionsTop: i18n.t("FASTTRACK.QUESTIONS_TOP"),
            questionsShort: i18n.t("FASTTRACK.QUESTIONS_SHORT"),
            deleteMessagePrompt: i18n.t("FASTTRACK.DELETE_MESSAGE_PROMPT"),
            inboxEmpty: i18n.t("FASTTRACK.INBOX_EMPTY"),
            readMore: i18n.t("FASTTRACK.READ_MORE"),
            backToList: i18n.t("FASTTRACK.BACK_TO_LIST"),
            inboxHeader: i18n.t("FASTTRACK.INBOX_HEADER"),
            pendingBonusesEmpty: i18n.t("FASTTRACK.PENDING_BONUSES_EMPTY"),
            pendingBonusesTitle: i18n.t("FASTTRACK.PENDING_BONUSES_TITLE"),
            pendingBonusesSubTitle: i18n.t("FASTTRACK.PENDING_BONUSES_SUB_TITLE"),
            pendingBonusesExpiresIn: i18n.t("FASTTRACK.PENDING_BONUSES_EXPIRES_IN"),
            pendingBonusesExpires: i18n.t("FASTTRACK.PENDING_BONUSES_EXPIRES"),
            pendingBonusesCTAUnlockText: i18n.t("FASTTRACK.PENDING_BONUSES_CTA_UNLOCK_TEXT"),
            pendingBonusesCTAClaimFallbackText: i18n.t("FASTTRACK.PENDING_BONUSES_CTA_CLAIM_FALLBACK_TEXT"),
            pendingBonusesBackButtonText: i18n.t("FASTTRACK.PENDING_BONUSES_BACK_BUTTON_TEXT"),
            pendingBonusesTermsAndConditionsHeader: i18n.t("FASTTRACK.PENDING_BONUSES_TERMS_AND_CONDITIONS_HEADER"),
            pendingBonusesBonusUnlockedText: i18n.t("FASTTRACK.PENDING_BONUSES_BONUS_UNLOCKED_TEXT"),
            days: i18n.t("FASTTRACK.DAYS"),
            hours: i18n.t("FASTTRACK.HOURS"),
            minutes: i18n.t("FASTTRACK.MINUTES"),
        },
    };

    if (headerHeight.value) {
        document.documentElement?.style.setProperty("--header-height", headerHeight.value);
    }

    createScript();
};
const toggleInbox = () => {
    if (window.FasttrackCrm) {
        window.FasttrackCrm.toggleInbox();
    }
};

onMounted(async () => {
    await fastTrackStore.loadUserSid();
    const fastTrackConfig = await configAPI.getFastTrack();
    scriptSrc.value = fastTrackConfig?.scriptSrc;

    initFastTrack();
    showButton.value = Boolean(sid.value);
});
onBeforeUnmount(() => {
    if (window.FasttrackCrm) {
        window.FasttrackCrm.logout();
    }
});
</script>

<template>
    <div
        v-if="!isGamePage($route)"
        class="inbox-button"
        :class="{'show': showButton}"
        padding-s
        @click="toggleInbox"
    >
        <div class="inbox-button__icon">
            <FeImage :src="mailIcon" alt="mail-icon" />
            <span id="some-element-id" class="inbox-button__pin" />
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
