import { defineStore } from "pinia";
import { ref } from "vue";
import type {
    ILivespinsScheduledStream,
    ILivespinsStream,
} from "../types";

export const useLivespinsStore = defineStore("livespinsStore", () => {
    const streamsIsLoaded = ref(false);
    const streamsLive = ref<ILivespinsStream[]>([]);
    const streamsScheduled = ref<ILivespinsScheduledStream[]>([]);
    const authToken = ref<string | null>(null);

    function setStreams(data: [ILivespinsStream[], ILivespinsScheduledStream[]]) {
        const [ live, scheduled ] = data;

        streamsLive.value = live;
        streamsScheduled.value = scheduled;
        streamsIsLoaded.value = true;
    }
    function setAuthToken(token: string) {
        authToken.value = token;
    }
    function clearAuthToken() {
        authToken.value = null;
    }

    return {
        authToken,
        streamsIsLoaded,
        streamsLive,
        streamsScheduled,
        setStreams,
        setAuthToken,
        clearAuthToken,
    };
});
