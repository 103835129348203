<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { gcbCertificationDomains, type GCBCertificationDomain } from "@theme/configs/configDomains";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import iconGCB from "./img/icon-gcb.svg";

const domain = ref<GCBCertificationDomain | null>(null);
const GCB_URL = "https://cert.gcb.cw/certificate?id=";

onMounted(() => {
    const { hostname } = window.location;
    domain.value = gcbCertificationDomains.find((config) => {
        return config.domainName === hostname;
    }) || null;
});

const link = computed(() => {
    const { token } = domain.value || {};
    return token ? `${GCB_URL}${token}` : null;
});
</script>

<template>
    <component :is="link ? FeLink : 'div'" :to="link">
        <FeImage class="gcb-img" :src="iconGCB" alt="trust-icon" />
    </component>
</template>

<style scoped>
.gcb-img {
    width: 100%;
    height: auto;
}
</style>
