<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";

import { userAPI } from "@api";
import bus from "@bus";
import { REGISTRATION_EVENTS } from "@config/gtagEvents";
import { useI18n } from "vue-i18n";
import { gtag } from "@plugins/gtagPlugin";
import Toast from "@plugins/Toast";
import { useUserInfo } from "@store/userInfo";


const IS_SENT_MAIL = "IS_SENT_MAIL";

const userInfoStore = useUserInfo();
const i18n = useI18n();

const emailSent = ref<boolean>(false);
const timer = ref<undefined | ReturnType<typeof setTimeout>>();

const noticeText = computed<string>(() => {
    if (emailSent.value) {
        return i18n.t("HEADER_NOTICE.NOTICE_TEXT_RESEND");
    }
    return i18n.t("HEADER_NOTICE.NOTICE_TEXT");
});
const linkText = computed<string>(() => {
    if (emailSent.value) {
        return i18n.t("HEADER_NOTICE.LINK_TEXT_RESEND");
    }
    return i18n.t("HEADER_NOTICE.LINK_TEXT");
});

function setEmailSent(leftPeriod: number) {
    emailSent.value = true;
    const period: number = leftPeriod || 15 * 60 * 1000;

    timer.value = setTimeout(cleanEmailSent, period);

    if (!leftPeriod) {
        const expiresTime: Date = new Date(new Date().getTime() + period);
        localStorage.setItem(IS_SENT_MAIL, JSON.stringify(expiresTime));
    }
}

async function sentEmail() {
    await userAPI.verifyEmail(userInfoStore.userEmail);

    Toast.show({
        text: i18n.t("HEADER_NOTICE.CONFIRMATION_TOAST_TEXT", { email: userInfoStore.userEmail }),
    });

    if (!emailSent.value) {
        gtag("event", REGISTRATION_EVENTS.CONFIRM_EMAIL);
    }

    const leftPeriod = 0;

    setEmailSent(leftPeriod);
}

function cleanEmailSent() {
    emailSent.value = false;
    localStorage.removeItem(IS_SENT_MAIL);
}

onMounted(() => {
    const isSentRecently = localStorage.getItem(IS_SENT_MAIL);

    if (isSentRecently) {
        const expires = JSON.parse(isSentRecently);
        const validExpires = new Date(expires).getTime() > new Date().getTime();

        if (!validExpires) {
            cleanEmailSent();
        } else {
            const leftPeriod = new Date(expires).getTime() - new Date().getTime();

            setEmailSent(leftPeriod);
        }
    }

    bus.$on("user.logout", cleanEmailSent);
});

onUnmounted(() => {
    clearTimeout(timer.value);
    bus.$off("user.logout", cleanEmailSent);
});
</script>
<template>
    <div class="header-email-verify">
        {{ noticeText }}
        <FeLink @click="sentEmail">
            {{ linkText }}
        </FeLink>
    </div>
</template>
