import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import { useHead } from "@unhead/vue";

import { configAPI } from "@api";
import { useUserBalance } from "@store/userBalance";
import { useLiveTable } from "@store/liveTable";

export default defineComponent({
    name: "PragmaticLive",
    setup() {
        const { setLiveTable } = useLiveTable();
        const userBalanceStore = useUserBalance();

        const config = ref({
            casinoId: "",
            serverName: "",
            scriptSrc: "",
        });

        const connect = () => {
            if (typeof window === "undefined") {
                return;
            }
            const dga = window.dga;
            if (dga) {
                dga.connect(config.value.serverName);

                const sock = dga.websocket;

                if (sock) {
                    sock.onmessage = (response: MessageEvent) => {
                        const data = JSON.parse(response.data);
                        const tables: string[] = data?.tableKey;

                        if (tables) {
                            tables.forEach((tableId) => {
                                dga.subscribe(config.value.casinoId, tableId, userBalanceStore.userCurrency);
                            });
                        }

                        if (data?.tableId) {
                            setLiveTable(data);
                        }
                    };

                    sock.onopen = () => {
                        // Get tablesIds by casinoId
                        dga.available(config.value.casinoId);
                    };
                }
            }
        };
        const disconnect = () => {
            if (typeof window !== "undefined" && window.dga) {
                window.dga.disconnect();
            }
        };
        onMounted(async () => {
            const pConfig = await configAPI.getPragmaticLive();
            config.value = {
                ...config.value,
                ...pConfig,
            };

            if (config.value.scriptSrc) {
                useHead({
                    script: [
                        {
                            src: config.value.scriptSrc,
                            onload: () => {
                                connect();
                            },
                        },
                    ],
                });
            }
        });
        onBeforeUnmount(() => {
            disconnect();
        });
        return () => null;
    },
});
