<template>
    <picture>
        <source
            v-for="itemSourceElements in sourceElements"
            :key="itemSourceElements.media"
            :srcset="itemSourceElements.srcset"
            :media="itemSourceElements.media"
        />
        <img
            :src="fullSrc"
            :alt="alt"
            :loading="loading"
            :fetchpriority="fetchPriority"
            @load="onLoad"
        />
    </picture>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";
import { type GameImageQuery, usePictureSource } from "@ui-kit/FePicture/composables/usePictureSource";
import { TypeImage } from "@theme/configs/imgResize";

defineOptions({
    name: "FePicture",
});

interface IProps {
    src: string;
    type: TypeImage;
    alt: string;
    query?: GameImageQuery | undefined;
    isLazy?: boolean;
    fetchPriority?: "high" | "low" | "auto";
}

enum Loading {
    EAGER = "eager",
    LAZY = "lazy",
}

const props = withDefaults(defineProps<IProps>(), {
    isLazy: true,
});
const emit = defineEmits<{(e: "onLoad", value: boolean): void }>();
const { isMockerMode } = storeToRefs(useEnvironment());
const {
    imageSrc,
    sourceElements,
    setGameImageQueryParams,
} = usePictureSource(() => props.src, () => props.type, () => props.query);

const loading = computed<Loading>(() => {
    if (isMockerMode.value) {
        return Loading.EAGER;
    }

    return !props.isLazy ? Loading.EAGER : Loading.LAZY;
});

const fullSrc = computed(() => {
    if (props?.query) {
        return setGameImageQueryParams(props.src, props.query);
    }

    if (imageSrc.value) {
        return imageSrc.value;
    }

    return props.src;
});
function onLoad() {
    emit("onLoad", true);
}
</script>

<style scoped lang="scss">
object {
    pointer-events: none;
}
</style>
