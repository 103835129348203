import bus from "@bus";
import { RouteTransitionTrigger, type RouteTransitionCondition } from "@controllers/triggers/RouteTransitionTrigger";
import isPwaMode from "@helpers/isPwaMode";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { routeNames } from "@router/routeNames";
import { useCashbox } from "@store/cashbox";
import { useContextStore } from "@store/context";
import { useUserBalance } from "@store/userBalance";
import { useUserInfo } from "@store/userInfo";
import { onBeforeUnmount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getPWANextShowDelay, isPWADisabledByCookie, setPWADontShowAgain } from "./helpers/cookie";
import { loadTWAMode, saveTWAMode } from "./helpers/localStorage";

import installPWA from "@helpers/installPwaHelper";
import {
    CASHBOX_WITHDRAWAL_CLOSE_DELAY,
    MIN_USER_BALANCE,
} from "./config";
import { usePushPWA } from "./hooks/usePushPWA";
import { RegistrationStrategy } from "./RegistrationStrategy";
import { TriggerType } from "./types";
import { SocketEvent, websocketEmitter } from "@api/websockets";

export function usePWAStrategies() {
    const userInfoStore = useUserInfo();
    const userBalanceStore = useUserBalance();
    const cashboxStore = useCashbox();
    const contextStore = useContextStore();
    const { pushPWA, showPWA } = usePushPWA();
    const router = useRouter();

    const routeTransitionTrigger = RouteTransitionTrigger.use();

    let isDepositStrategyStarted = false;
    let stopCallback: (() => void) | undefined = undefined;

    const registrationStrategy = new RegistrationStrategy(() => {
        pushPWA(TriggerType.AFTER_REGISTRATION).then(() => {
            if (isPWADisabledByCookie()) {
                stopStrategy();
            }
        });
    });
    const startDepositStrategy = promiseMemoizer(async () => {
        if (isDepositStrategyStarted) {
            return;
        }
        const depositNumbers = await cashboxStore.loadUserDepositNumbers();
        if (depositNumbers === 0) {
            return;
        }
        isDepositStrategyStarted = true;

        const callback = promiseMemoizer(async () => {
            const isGamePage = router.currentRoute.value.name === routeNames.gameItem;
            const balanceCheck = userBalanceStore.balanceData.balance <= MIN_USER_BALANCE;
            if (balanceCheck && !isGamePage && getPWANextShowDelay() === 0) {
                await pushPWA(TriggerType.NAVIGATION);
                if (isPWADisabledByCookie()) {
                    stopStrategy();
                }
            }
        });

        const promotionsArray = [
            routeNames.promotionsCasinoCurrent,
            routeNames.promotionsCasinoActions,
            routeNames.promotionsCasinoTournaments,
            routeNames.promotionsLiveCurrent,
            routeNames.promotionsLiveActions,
            routeNames.promotionsLiveTournaments,
            routeNames.promotionsSportCurrent,
            routeNames.promotionsSportActions,
            routeNames.promotionsSportTournaments,
        ];

        const triggerConditions: RouteTransitionCondition = [
            {
                from: [ routeNames.gameItem ],
                localStorageKey: "route_trigger_pwa_strategy",
            },
            {
                from: promotionsArray,
            },
        ];
        routeTransitionTrigger.on(callback, triggerConditions);

        const cashboxWithdrawalCallback = () => {
            setTimeout(callback, CASHBOX_WITHDRAWAL_CLOSE_DELAY * 1000);
        };

        bus.$on("cashbox.withdrawal.close", cashboxWithdrawalCallback);

        stopCallback = () => {
            routeTransitionTrigger.off(callback);
            bus.$off("cashbox.withdrawal.close", cashboxWithdrawalCallback);
        };
    });

    function stopStrategy() {
        registrationStrategy.stop();
        isDepositStrategyStarted = false;
        if (stopCallback) {
            stopCallback();
            stopCallback = undefined;
        }
    }

    async function isDisabledCondition() {
        let twaMode = false;
        if (location.search.includes("mode=twa")) {
            saveTWAMode();
            twaMode = true;
        } else {
            twaMode = loadTWAMode();
        }

        const context = await contextStore.getContext();

        const isIframe = window.top !== window;
        const isPwa = isPwaMode();

        const contextCondition = isIframe || isPwa || !context.isMobile;
        const pwaDisabledConditions = [
            contextCondition && !twaMode,
            isPWADisabledByCookie(),
        ];

        return pwaDisabledConditions.includes(true);
    }

    async function initStrategy() {
        // start strategy
        const isLogged = await userInfoStore.isLoggedAsync();
        if (isLogged) {
            registrationStrategy.startStrategy();
            startDepositStrategy();
        }
    }

    const installPWAHandler = promiseMemoizer(async () => {
        const context = await contextStore.getContext();

        if (context.isMobile) {
            await showPWA(false, TriggerType.MANUAL);
        } else {
            installPWA();
        }
    });

    onMounted(async () => {
        if (!await isDisabledCondition()) {
            initStrategy();
            bus.$on("user.login", initStrategy);
            bus.$on("user.logout", stopStrategy);
            bus.$on("dont-show-pwa-again", setPWADontShowAgain);
            bus.$on("pwa.install", installPWAHandler);
            websocketEmitter.on(SocketEvent.BALANCE_CHANGED, startDepositStrategy);
        }
    });

    onBeforeUnmount(() => {
        bus.$off("user.login", initStrategy);
        bus.$off("user.logout", stopStrategy);
        bus.$off("dont-show-pwa-again", setPWADontShowAgain);
        bus.$off("pwa.install", installPWAHandler);
        websocketEmitter.off(SocketEvent.BALANCE_CHANGED, startDepositStrategy);

        stopStrategy();
        registrationStrategy.stop();
    });
}
