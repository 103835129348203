<script setup>
import { useUserBalanceServices, useUserStatusService } from "@controllers/services/user";
import { useCurrencyView } from "@controllers/services/currency-view";

const { currencyView } = useCurrencyView();
const { commonBalance } = useUserBalanceServices();
const { userVipStatus } = useUserStatusService();
</script>

<script>
import { mapState } from "pinia";
import { routeNames } from "@router/routeNames";
import { mapActions as piniaActions } from "pinia";

import Logo from "@components/Logo/Logo.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { iconsNavigation } from "@theme/configs/iconsNavigation";
import { iconsOutline } from "@theme/configs/iconsOutline";
import GiftButton from "@components/GiftButton/index.vue";
import EnterBtns from "@components/EnterBtns/index.vue";
import UserPicName from "@components/UserPicName/index.vue";
import DropdownBalanceNew from "@modules/Header/components/DropdownBalance.vue";
import DropdownNotifications from "@components/DropdownNotifications/index.vue";
import HeaderNotice from "@modules/Header/components/HeaderNotice.vue";
import HeaderNavMenu from "@components/HeaderNavMenu/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import Popup from "@components/Popup/index.vue";
import ChatDropdown from "@components/ChatDropdown/ChatDropdown.vue";
import ChatButton from "@components/ChatButton/index.vue";
import { CASHBOX_DASHBOARD_STEP, CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import bus from "@bus";
import { useLevels } from "@store/levels";
import { useUserNotice } from "@store/userNotice";
import { useUserInfo } from "@store/userInfo";
import { useUserBalance } from "@store/userBalance";
import { CustomBadgeThemes } from "@ui-kit/CustomBadge/config";
import CustomBadge from "@ui-kit/CustomBadge/CustomBadge.vue";
import { NotificationStatus } from "@api";
import { useContextStore } from "@store/context";

export default {
    name: "HeaderComponent",

    components: {
        Popup,
        FeIcon,
        EnterBtns,
        FeButton,
        GiftButton,
        Logo,
        FeLink,
        UserPicName,
        HeaderNavMenu,
        DropdownBalanceNew,
        DropdownNotifications,
        ChatDropdown,
        ChatButton,
        CustomBadge,
        HeaderNotice,
    },

    data() {
        return {
            CASHBOX_DASHBOARD_STEP,
            CASHBOX_DEPOSIT_STEP,
            FeButtonConfig,
            FeIconConfig,
            iconsOutline,
            iconsNavigation,
            routeNames,
            CustomBadgeThemes,
        };
    },

    computed: {
        ...mapState(useUserInfo, {
            userInfo: "userInfo",
            isLogged: "isLogged",
        }),
        ...mapState(useUserNotice, {
            userNotice: "userNotice",
            noticeNewCounter: "countNewNotification",
        }),
        ...mapState(useLevels, {
            userLevelInfo: "userLevelData",
        }),
        ...mapState(useContextStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useUserBalance, {
            userBalance: "balanceData",
        }),

        showUserBalance() {
            return this.userBalance.user_id;
        },
        fullLogo() {
            return !this.isMobile || (this.isMobile && !this.isLogged);
        },

        iconLevel() {
            return this.getLevelImageByIndex(this.userLevel);
        },
        userLevel() {
            return this.userLevelInfo?.level;
        },
        classes() {
            return {
                "header--login": this.isLogged,
                "header--logout": !this.isLogged,
            };
        },
        notice() {
            const notice = [ ...this.userNotice ];
            return notice.sort((current) => {
                return current.status !== NotificationStatus.NEW || current.next;
            });
        },
    },

    methods: {
        ...piniaActions(useLevels, {
            getLevelImageByIndex: "getLevelImageByIndex",
        }),
        openCashbox(step) {
            bus.$emit("open-cashbox", { step, location: "header" });
        },
        hideOpenedPopups(currentPopup) {
            if (currentPopup !== "popupNotifications" && this.$refs.popupNotifications) {
                this.$refs.popupNotifications.hideHandler();
            }
            if (currentPopup !== "popupChats" && this.$refs.popupChats) {
                this.$refs.popupChats.hideHandler();
            }
            if (currentPopup !== "popupBalance" && this.$refs.popupBalance) {
                this.$refs.popupBalance.hideHandler();
            }
        },

        openChat() {
            bus.$emit("chat.toggle");
        },
    },
};
</script>
<template>
    <div class="header" :class="classes">
        <HeaderNotice />
        <div class="header__wrapper">
            <div
                class="header__center"
                main-layout-width
                grid-full-width
                gap-s
            >
                <Logo class="header__logo" :full="fullLogo" />
                <HeaderNavMenu />
                <div v-if="isLogged" class="header__profile-new" gap-xs>
                    <div class="header__user" gap-xs>
                        <UserPicName
                            :linkto="{ name: routeNames.profileCabinet }"
                            :image="iconLevel"
                            class="header__user-name"
                        />
                        <Popup
                            ref="popupBalance"
                            width-auto
                            activator-fixed
                            bottom
                            right
                            offset-x
                        >
                            <template #activator="{ toggleHandler: toggleDropdownHandler, isShow }">
                                <div
                                    class="header__user-info"
                                    @click.stop="hideOpenedPopups('popupBalance')"
                                >
                                    <template v-if="showUserBalance">
                                        <div class="header__user-wrapper">
                                            <CustomBadge
                                                v-if="userVipStatus"
                                                :theme="CustomBadgeThemes.additional"
                                                uppercase
                                            >
                                                {{ $t("COMMON.VIP") }}
                                            </CustomBadge>
                                            <FeLink :to="{ name: routeNames.profileCabinet }" class="header__user-nickname">
                                                {{ userInfo.nick_name }}
                                            </FeLink>
                                        </div>

                                        <div
                                            class="header__user-balance"
                                            :class="{ 'header__user-balance--open': isShow }"
                                            @click="toggleDropdownHandler"
                                        >
                                            <span>{{ currencyView(commonBalance) }}</span>
                                            <FeIcon :icon="FeIconConfig.dropdownUp" class="header__user-balance-arrow" />
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template #default="{ hideHandler: hideDropdownBalance }">
                                <DropdownBalanceNew @close="hideDropdownBalance" />
                            </template>
                        </Popup>
                    </div>

                    <FeButton
                        class="header__cashbox"
                        :theme="FeButtonConfig.themes.PrimarySmall"
                        @click="openCashbox(CASHBOX_DEPOSIT_STEP)"
                    >
                        {{ $t("HEADER.CASHBOX") }}
                    </FeButton>

                    <FeButton
                        class="header__profile-button"
                        :theme="FeButtonConfig.themes.GhostSmall"
                        :icon="iconsNavigation.profile"
                        @click="$router.push({ name: routeNames.profile })"
                    />

                    <GiftButton />

                    <Popup
                        ref="popupNotifications"
                        class="header__notifications"
                        activator-fixed
                        left
                        offset-x
                        bottom
                        width-auto
                    >
                        <template #activator="{ isShow }">
                            <div @click="hideOpenedPopups('popupNotifications')">
                                <FeButton
                                    :class="{ 'active': isShow }"
                                    :theme="FeButtonConfig.themes.GhostSmall"
                                    :icon="iconsOutline.notificationsOff"
                                    data-test="notifications-button"
                                />
                                <div v-if="noticeNewCounter" class="header__notifications-counter">
                                    {{ noticeNewCounter }}
                                </div>
                            </div>
                        </template>

                        <DropdownNotifications :notifications="notice" />
                    </Popup>
                    <ChatButton
                        v-if="isMobile"
                        @click="openChat"
                    />
                    <ChatDropdown v-else />
                </div>
                <div v-else class="header__auth-new" gap-xs>
                    <EnterBtns
                        location="header"
                    />
                    <ChatButton
                        v-if="isMobile"
                        @click="openChat"
                    />
                    <ChatDropdown v-else />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" src="./style.scss"></style>
