<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useThemeStore } from "@store/theme";

interface TooltipProps {
    bottom?: boolean
    bottomLeft?: boolean
    left?: boolean
    right?: boolean
    top?: boolean
    topLeft?: boolean
    show?: boolean
    showOnHover?: boolean
    showOnClick?: boolean
}

interface TooltipEmits {
    (event: "removeRestrictions"): void;
}

defineOptions({
    name: "Tooltip",
});

const props = withDefaults(defineProps<TooltipProps>(), {
    top: true,
    showOnHover: true,
    showOnClick: false,
});

const emit = defineEmits<TooltipEmits>();

const { isThemeDark } = storeToRefs(useThemeStore());

const tooltipContent = ref<HTMLDivElement | null>(null);
const showByClick = ref(false);

const theme = computed(() => {
    return isThemeDark.value ? "theme-light" : "theme-dark";
});
const classes = computed(() => {
    const result: string[] = [];

    if (props.left) {
        result.push("tooltip--position-left");
    } else if (props.right) {
        result.push("tooltip--position-right");
    } else if (props.bottom) {
        result.push("tooltip--position-bottom");
    } else if (props.bottomLeft) {
        result.push("tooltip--position-bottom-left");
    } else if (props.topLeft) {
        result.push("tooltip--position-top-left");
    } else if (props.top) {
        result.push("tooltip--position-top");
    }

    if (props.show) {
        result.push("tooltip--show");
    }

    if (props.showOnClick && showByClick.value) {
        result.push("tooltip--show");
    }

    if (props.showOnHover) {
        result.push("tooltip--hover");
    }

    return result;
});

const showTooltip = () => {
    if (showByClick.value) {
        showByClick.value = false;
        emit("removeRestrictions");
    } else {
        showByClick.value = true;
    }
};

const hideTooltip = () => {
    showByClick.value = false;
};

const toCorrectAbroadAxisHorizontal = () => {
    if (!tooltipContent.value) {
        return;
    }

    const { x: offsetX, width } = tooltipContent.value.getBoundingClientRect();

    if (offsetX < 0) {
        tooltipContent.value.style.marginLeft = `${Math.abs(offsetX)}px`;
        return;
    }

    const diffAbroad = offsetX + width - document.body.offsetWidth;
    if (diffAbroad > 0) {
        tooltipContent.value.style.marginLeft = "0";
    }
};

onMounted(() => {
    toCorrectAbroadAxisHorizontal();
});
</script>

<template>
    <div
        v-click-outside="hideTooltip"
        class="tooltip"
        :class="classes"
        @click="showTooltip"
    >
        <div class="tooltip__activator">
            <slot name="activator" />
        </div>
        <div class="tooltip__body" :class="theme">
            <div ref="tooltipContent" class="tooltip__content">
                <slot />
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
