<script lang="ts" setup>
import bus from "@bus";
import { onMounted, onUnmounted } from "vue";
import { CASHBOX_DASHBOARD_STEP } from "@config/cashbox";
import { useActiveBonuses } from "@controllers/services/bonuses/activeBonus";

import UserBalance from "@components/UserBalance/UserBalanceNew.vue";
import { usePromoPage } from "@pages/PromoPage/composables/usePromoPage";
import { usePromo } from "@store/promo";
import { useUserInfo } from "@store/userInfo";
import FeButton from "@ui-kit/FeButton/index.vue";
import FeTitle from "@ui-kit/FeTitle/FeTitle.vue";
import { TitleSpacing } from "@ui-kit/FeTitle/config";
import BonusItemShort from "@components/Bonus/BonusItemShort/BonusItemShort.vue";
import { computed } from "vue";
import DropdownPromoWidget from "./DropdownPromoWidget.vue";
import FeScroll from "@ui-kit/FeScroll/FeScroll.vue";

const { activeBonuses } = useActiveBonuses();
const userInfoStore = useUserInfo();
const promoStore = usePromo();
const { isActive } = usePromoPage();

const SCREEN_WIDTH = 768;

defineEmits([ "close" ]);

function openCashbox(step: string) {
    bus.$emit("open-cashbox", { step, location: "header" });
}

const showPromoWidget = computed<boolean>(() => {
    return userInfoStore.isLogged && isActive.value && promoStore.isMembership;
});
onMounted(() => {
    if (window.innerWidth <= SCREEN_WIDTH) {
        document.body.style.overflow = "hidden";
    }
});

onUnmounted(() => {
    if (window.innerWidth <= SCREEN_WIDTH) {
        document.body.style.overflow = "";
    }
});
</script>

<template>
    <div class="dropdown-balance" gap-s padding-s>
        <FeScroll>
            <div class="dropdown-balance__wrapper gap-s">
                <UserBalance />
                <div>
                    <FeButton
                        full-width
                        data-test="cashbox-button--header"
                        @click="openCashbox(CASHBOX_DASHBOARD_STEP)"
                    >
                        {{ $t("PROFILE.BALANCE_CASHBOX") }}
                    </FeButton>
                </div>
                <template v-if="activeBonuses.length > 0">
                    <hr />
                    <FeTitle
                        :title="$t('GIFT.ACTIVE_BONUSES')"
                        title-classes="color-text-alt caption-1-bold"
                        tag="p"
                        :spacing="TitleSpacing.MD"
                    >
                        <div v-if="activeBonuses.length" class="gap-xs">
                            <BonusItemShort
                                v-for="gift in activeBonuses"
                                :key="gift.id"
                                :data="gift"
                                @click="$emit('close')"
                            />
                        </div>
                    </FeTitle>
                </template>
                <template v-if="showPromoWidget">
                    <hr />
                    <DropdownPromoWidget @click="$emit('close')" />
                </template>
            </div>
        </FeScroll>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.dropdown-balance {
    width: 100vw;
    background: $color-layer-alt-2;
    color: inherit;
    display: grid;
    flex-direction: column;
    border-radius: 0 0 $border-radius-M $border-radius-M;
    top: 0;
    position: relative;
    left: 0;
    box-sizing: border-box;

    @include media(S) {
        width: 25rem;
    }

    &__wrapper {
        max-height: 35rem;

        @include media(S) {
            max-height: 100%;
        }

        @media (max-height: 52rem) {
            max-height: calc(100vh - 20rem);
        }
    }

    &__carousel {
        --carousel-item-width: 17.5rem;
        --carousel-gap: 0.5rem;

        @include media(M) {
            --carousel-item-width: 20.25rem;
            --carousel-gap: 1rem;
        }
    }
}
</style>
