<script setup lang="ts">
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import cookie from "@front/core/controllers/CookieController";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import cookieImg from "./img/cookie.svg";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import InlineNotification from "@modules/InlineNotification/index.vue";
import { useEnvironment } from "@store/environment";

const COOKIES_KEY = "cookies_accepted";
const showCookiesAccept = ref(false);
const { isMockerMode } = storeToRefs(useEnvironment());

function hideCookie() {
    cookie.set(COOKIES_KEY, "true", { expires: 31536e3 });
    showCookiesAccept.value = false;
}
onMounted(() => {
    if (isMockerMode.value) {
        return;
    }
    setTimeout(() => {
        showCookiesAccept.value = !cookie.get(COOKIES_KEY);
    }, 0);
});

</script>
<template>
    <div
        v-if="showCookiesAccept"
        key="cookies"
        class="cookies-accepted"
        data-test="dropdown-balance__profile-btn"
    >
        <InlineNotification :icon="cookieImg">
            <template #icon>
                <FeImage class="cookies-accepted__icon" :src="cookieImg" alt="cookie icon" />
            </template>
            <div class="cookies-accepted__content" gap-xs>
                <i18n-t :keypath="'COOKIE_ACCEPT.TEXT_COMMON'" tag="span" scope="global">
                    <template #link>
                        <FeLink to="/privacy-policy">
                            {{ $t("COOKIE_ACCEPT.LINK_TEXT") }}
                        </FeLink>
                    </template>
                </i18n-t>
                <FeButton
                    :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                    full-width
                    data-test="dropdown-balance__profile-btn"
                    @click="hideCookie"
                >
                    {{ $t("COOKIE_ACCEPT.BUTTON_ACCEPT") }}
                </FeButton>
            </div>
        </InlineNotification>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.cookies-accepted {
    width: 100%;

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 1rem;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
    }

    :deep(.inline-notification__container) {
        width: fit-content;
    }

    &__content {
        grid-auto-flow: column;
        align-items: center;
    }

    a {
        font-weight: $font-weight--bold;
    }

    .fe-button {
        @include media(M) {
            width: 6rem;
        }
    }
}
</style>
