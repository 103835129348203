import {
    cashboxAPI,
    freshChatAPI,
    giftsAPI,
    TransactionType,
    userAPI,
    type ITransactionHistoryItemResource,
} from "@api";
import bus from "@bus";
import { CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import { USER_BONUS } from "@config/gtagEvents";
import { useCommonErrorPopup } from "@controllers/services/error/popups";
import { useParserContent } from "@helpers/ParserContent";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { useModalsStore } from "@store/modals";
import { useUserInfo } from "@store/userInfo";
import { computed, ref, type ComputedRef } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Toast from "@plugins/Toast";
import { routeNames } from "@router/routeNames";
import { useGifts } from "@store/gifts";
import { useGiftsStoreV2 } from "@store/giftsV2";
import config from "@theme/configs/config";
import { gtag } from "@plugins/gtagPlugin";
import { ACTIVE_BONUS_STATUSES } from "./config";
import { BonusStatus, BonusType, GiftStatus, type IBonusData } from "./types";
import { useEmailVerificationPopup } from "@modules/Profile/ProfileCabinet";
import {
    DEPOSIT_INSURANCE_TIMESTAMP,
    IS_DEPOSIT_INSURANCE_ERROR,
    IS_DEPOSIT_INSURANCE_REQUESTED,
} from "@controllers/services/bonuses/depositInsurance";
import { MODAL_DEPOSIT_INSURANCE } from "@config/modalNames";

interface IUseBonusActionServiceParams {
    bonusData: ComputedRef<IBonusData>;
}
export enum BonusActionType {
    VERIFY_EMAIL = "VERIFY_EMAIL",
    PLAY_GAME = "PLAY_GAME",
    DEPOSIT = "DEPOSIT",
    ACTIVATE = "ACTIVATE",
    INSURANCE = "INSURANCE",
}

const depositInsuranceVerifyPopup = () => {
    return import("@modules/Profile/ProfileBonuses/components/DepositInsuranceVerify.vue");
};
const depositInsuranceErrorPopup = () => {
    return import("@modules/Profile/ProfileBonuses/components/DepositInsuranceError.vue");
};

export function useBonusActionService(params: IUseBonusActionServiceParams) {
    const i18n = useI18n();
    const router = useRouter();
    const giftsStore = useGifts();
    const giftsStoreV2 = useGiftsStoreV2();
    const modalsStore = useModalsStore();
    const userInfoStore = useUserInfo();
    const { parseContent } = useParserContent();
    const { showCommonErrorPopup } = useCommonErrorPopup();
    const { showEmailVerificationPopup } = useEmailVerificationPopup();

    function playAction() {
        const { gameSlug, type, bonusStatus } = params.bonusData.value;
        if (gameSlug && bonusStatus !== BonusStatus.ACTIVE) {
            router.push({
                name: routeNames.gameItem,
                params: { name: gameSlug },
            });
        } else if (type === BonusType.CASINO) {
            router.push({
                name: routeNames.casinoCategory,
                params: {
                    slug: config.gameSlugs.bonusWagering,
                },
            });
        } else {
            router.push({
                name: routeNames.bettingPage,
            });
        }
    }

    const verifyEmail = promiseMemoizer(async () => {
        await showEmailVerificationPopup();
    });
    function depositAction() {
        giftsStore.setCurrentGift(params.bonusData.value.id);
        bus.$emit("open-cashbox", { location: "games", step: CASHBOX_DEPOSIT_STEP });
    }
    const activateAction = promiseMemoizer(async () => {
        const response = await giftsStore.activateGift(params.bonusData.value.id);

        if (response?.success === false && response.error) {
            showCommonErrorPopup();
        }
    });

    const actionType = computed<BonusActionType | undefined>(() => {
        const { bonusStatus, giftStatus, noDeposit, needEmailVerification, type } = params.bonusData.value;

        if (type === BonusType.INSURANCE) {
            return BonusActionType.INSURANCE;
        }
        if (!userInfoStore.isVerifiedEmail && needEmailVerification) {
            return BonusActionType.VERIFY_EMAIL;
        }
        if (bonusStatus === BonusStatus.ACTIVE || (giftStatus === GiftStatus.ACTIVATED && !bonusStatus)) {
            return BonusActionType.PLAY_GAME;
        }
        if (giftStatus === GiftStatus.RECEIVED) {
            if (noDeposit) {
                return BonusActionType.ACTIVATE;
            }
            return BonusActionType.DEPOSIT;
        }
    });

    const isDepositInsuranceRequested = ref(false);
    const isDepositInsuranceError = ref(false);
    const depositInsuranceAction = promiseMemoizer(async () => {
        const component = await depositInsuranceVerifyPopup();
        localStorage.setItem(IS_DEPOSIT_INSURANCE_REQUESTED, "false");
        localStorage.setItem(IS_DEPOSIT_INSURANCE_ERROR, "false");

        const getTimestampUTC = () => {
            const date = new Date();

            return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0) / 1000;
        };

        let payouts: ITransactionHistoryItemResource[] | null = null;
        const { data: deposits } = await cashboxAPI.loadTreasuryHistory({
            type: TransactionType.DEPOSIT,
            perPage: 30,
            dateFrom: new Date(getTimestampUTC() * 1000).toISOString().replace(/[.]\d+/, ""),
        });
        const lastSuccessDeposit = deposits?.find((deposit) => deposit.status === "success");
        const { emails, verification } = userInfoStore.userInfo;
        const isEmailVerified = Boolean(emails[0]?.is_verified);
        const isIdentityVerified = verification.verificationStatus === "approved";
        const isSuccessDepositToday = Boolean(lastSuccessDeposit);

        if (lastSuccessDeposit) {
            const dateFrom = new Date(lastSuccessDeposit.createdAt * 1000).toISOString().replace(/[.]\d+/, "");
            payouts = (await cashboxAPI.loadTreasuryHistory({ type: TransactionType.PAYOUT, dateFrom })).data;
        }
        const lastPayout = payouts?.[0];
        const isLastPayoutCanceled = lastPayout?.status === "canceled" || lastPayout?.status === "canceled_by_user";
        const hasNotCanceledPayouts = payouts?.filter(
            (payout) => payout.status !== "canceled" && payout.status !== "canceled_by_user",
        );

        let isLastTransaction = isSuccessDepositToday;
        if (lastPayout && Number(lastSuccessDeposit?.createdAt) < Number(lastPayout?.createdAt)) {
            isLastTransaction = isLastPayoutCanceled && !hasNotCanceledPayouts?.length;
        }

        if (!isEmailVerified || !isIdentityVerified || !isLastTransaction || !isSuccessDepositToday) {
            modalsStore.showModal({
                name: MODAL_DEPOSIT_INSURANCE,
                component: component.default,
                analyticsParams: {
                    additionalState: {
                        "email confirm": String(isEmailVerified),
                        "verification": String(isIdentityVerified),
                        "deps": String(isSuccessDepositToday),
                        "last transaction": String(isLastTransaction),
                    },
                },
                new: true,
                props: {
                    isEmailVerified,
                    isIdentityVerified,
                    isLastTransaction,
                    isSuccessDepositToday,
                },
            });
            return;
        }

        const restoreId = (await userAPI.loadFreshchatRestoreId(userInfoStore.getUserId)) || "";
        const { data } = await freshChatAPI.sendFreshChatMessage(userInfoStore.getUserId, restoreId);
        localStorage.setItem(DEPOSIT_INSURANCE_TIMESTAMP, String(new Date().getTime() + 600000));

        if (data?.conversation_id) {
            isDepositInsuranceRequested.value = true;
            localStorage.setItem(IS_DEPOSIT_INSURANCE_REQUESTED, "true");

            bus.$emit("insurance-bonus-requested");
            Toast.show({
                title: i18n.t("GIFT.INSURANCE_NOTIFICATION.TITLE"),
                text: i18n.t("GIFT.INSURANCE_NOTIFICATION.TEXT"),
            });
            gtag("event", USER_BONUS[BonusActionType.INSURANCE].REQUEST);
        } else {
            const errorPopup = await depositInsuranceErrorPopup();
            isDepositInsuranceError.value = true;
            localStorage.setItem(IS_DEPOSIT_INSURANCE_ERROR, "true");
            bus.$emit("insurance-bonus-requested");
            modalsStore.showModal({
                name: "modal-deposit-insurance-error",
                component: errorPopup.default,
                new: true,
            });
        }
    });

    interface IActionConfig {
        text: string;
        callback(): void | Promise<void>;
        type?: BonusActionType;
        isDepositInsuranceRequested?: boolean;
        isDepositInsuranceError?: boolean;
    }
    const actionConfig = computed<IActionConfig | null>(() => {
        switch (actionType.value) {
            case BonusActionType.VERIFY_EMAIL:
                return {
                    callback: verifyEmail,
                    text: i18n.t("GIFT.VERIFY_EMAIL_BUTTON"),
                };
            case BonusActionType.DEPOSIT:
                return {
                    callback: depositAction,
                    text: i18n.t("GIFT.DEPOSIT_BUTTON"),
                };
            case BonusActionType.ACTIVATE:
                return {
                    callback: activateAction,
                    text: i18n.t("GIFT.ACTIVATE_BUTTON"),
                };
            case BonusActionType.PLAY_GAME:
                return {
                    callback: playAction,
                    text: i18n.t("GIFT.PLAY_BUTTON"),
                };
            case BonusActionType.INSURANCE:
                return {
                    callback: depositInsuranceAction,
                    text: i18n.t("GIFT.MAKE_REQUEST"),
                    type: BonusActionType.INSURANCE,
                    isDepositInsuranceRequested: isDepositInsuranceRequested.value,
                    isDepositInsuranceError: isDepositInsuranceError.value,
                };
        }

        return null;
    });

    function showDeletedBonus(name: string) {
        Toast.show({
            title: i18n.t("GIFT.DELETED_BONUS_TITLE", { name }),
            text: i18n.t("GIFT.DELETED_BONUS_TEXT"),
        });
    }

    const isCancelable = computed(() => {
        const { bonusId, bonusStatus } = params.bonusData.value;
        return bonusId && bonusStatus && ACTIVE_BONUS_STATUSES.includes(bonusStatus);
    });
    async function cancelAction() {
        const { bonusId, title, localisationVariables } = params.bonusData.value;

        await giftsAPI.cancelBonus(bonusId as string);
        await giftsStoreV2.loadData();

        showDeletedBonus(parseContent(title, localisationVariables));
    }

    return {
        actionType,
        actionConfig,
        isCancelable,
        cancelAction,
    };
}
