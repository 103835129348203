import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import { routeNames } from "@router/routeNames";
import { useUserInfo } from "@store/userInfo";
import { isServer } from "@helpers/ssrHelpers";

export default function requestGuestHook(router: Router, pinia: Pinia) {
    const userInfoStore = useUserInfo(pinia);

    router.beforeEach((to, from, next) => {
        const requiresGuest = to.matched.some((record) => {
            return record.meta.requiresGuest;
        });
        if (requiresGuest && !isServer) {
            userInfoStore.isLoggedAsync().then((boolean) => {
                if (boolean) {
                    return router.push({ name: routeNames.main });
                }
            });
        }

        return next();
    });
}
