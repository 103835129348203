<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, type StyleValue } from "vue";
import { useI18n } from "vue-i18n";
import bus from "@bus";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { createFocusedElement, findElement } from "../helpers/domHelpers";
import { scrollToElement } from "../helpers/scrollHelpers";
import { useOnboardStore } from "../store/onboardStore";
import { useFloating, offset, autoUpdate, autoPlacement, arrow } from "@floating-ui/vue";
import type { OnboardScenarioItem } from "../types";

const OUTSIDE_CLICK_DELAY = 500;

type OnboardItemProps = {
    data: OnboardScenarioItem;
    step: number;
    stepsCount: number;
};
const props = defineProps<OnboardItemProps>();

const i18n = useI18n();

const onboardStore = useOnboardStore();
const rootRef = ref<HTMLDivElement>();
const floatingRef = ref<HTMLDivElement>();
const floatingArrowRef = ref<HTMLDivElement>();
const focusedElement = ref<HTMLElement>();
const mountedTime = ref<number>();

const { floatingStyles, middlewareData, placement } = useFloating<HTMLElement>(focusedElement, floatingRef, {
    middleware: [
        offset(16),
        autoPlacement(
            {
                allowedPlacements: [
                    "bottom",
                    "bottom-start",
                    "bottom-end",
                    "top",
                    "top-start",
                    "top-end",
                ],
            },
        ),
        arrow({
            element: floatingArrowRef,
            padding: 16,
        }),
    ],
    whileElementsMounted: autoUpdate,
});

const arrowStyles = computed(() => {
    const { x: arrowX = 0, y: arrowY = 0 } = middlewareData.value.arrow || {};
    const isTop = placement.value.includes("top");

    const stylesObj: StyleValue = {
        left: `${arrowX}px`,
    };
    if (isTop) {
        stylesObj.bottom = "-0.25rem";
    } else {
        stylesObj.top = "-0.25rem";
    }

    return stylesObj;
});

function outsideClickHandler() {
    if (mountedTime.value && mountedTime.value + OUTSIDE_CLICK_DELAY < Date.now()) {
        onboardStore.nextStep();
    }
}

onMounted(() => {
    bus.$on("onboard-outside-click", outsideClickHandler);
    const element = findElement(props.data.element);
    const root = rootRef.value;
    if (element && root) {
        focusedElement.value = createFocusedElement(root, element, props.data.extraClassName);
        scrollToElement(element);
        mountedTime.value = Date.now();
    } else {
        onboardStore.nextStep();
    }
});
onBeforeUnmount(() => {
    bus.$off("onboard-outside-click", outsideClickHandler);
});
</script>

<template>
    <div @click.stop>
        <div ref="rootRef" />
        <div
            ref="floatingRef"
            :style="floatingStyles"
            :class="[
                'onboard-item__message',
                { 'onboard-item__message-with-count': stepsCount > 1 }
            ]"
        >
            <div ref="floatingArrowRef" class="onboard-item__message-arrow" :style="arrowStyles" />
            <div class="onboard-item__message-text caption-2" v-html="i18n.t(data.message)" />
            <div class="onboard-item__message-action">
                <small v-if="stepsCount > 1" class="text-body">
                    {{ i18n.t("ONBOARDING.STEP_NUMBER", { current: step + 1, total: stepsCount }) }}
                </small>
                <FeButton
                    :full-width="stepsCount < 2"
                    :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                    @click.stop.capture="onboardStore.nextStep()"
                >
                    {{ i18n.t(data.buttonText || "ONBOARDING.ACTIONS.GOT_IT") }}
                </FeButton>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.onboard-item__message {
    display: grid;
    grid-gap: 0.5rem;
    background: $color-secondary-2;
    color: $color-on-dark-back;
    padding: 0.5rem 1rem;
    border-radius: $border-radius-XS;
    width: 15rem;

    &-with-count {
        grid-gap: 1rem;
        padding: 1rem;
    }

    &-arrow {
        background: $color-secondary-2;
        position: absolute;
        transform: rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
    }

    &-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
