import { BonusDetailKey, type IBonusDetail } from "./detailTypes";

export enum BonusType {
    CASINO = "casino",
    SPORT = "betting",
    INSURANCE = "insurance"
}

export enum GiftStatus {
    RECEIVED = "RECEIVED",
    ACTIVATED = "ACTIVATED",

    // history statuses
    USED = "USED", // not in case group bonus
    REMOVED = "REMOVED",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
}

export enum BonusStatus {
    WAITING = "WAITING",
    ACTIVE = "ACTIVE",

    // history statuses
    WAGER_DONE = "WAGER_DONE",
    LOST = "LOST",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
}

export enum BonusKind {
    CASHBACK = "CASHBACK",
    CASH = "CASH",
    XP = "XP",
    DEPOSIT_BONUS = "DEPOSIT_BONUS",
    GROUP = "GROUP",
    CUSTOM_GIFT = "CUSTOM_GIFT",
    UNIVERSAL_GAMING_FS = "UNIVERSAL_GAMING_FS",
    UNIVERSAL_GAMING_BONUS = "UNIVERSAL_GAMING_BONUS",
    ACTION_CURRENCY = "ACTION_CURRENCY",
    BETTING_FREEBET = "BETTING_FREEBET",
    BETTING_WAGERING = "BETTING_WAGERING",
    INSURANCE = "INSURANCE",
}

export interface IBonusData {
    id: string;
    bonusId?: string;
    type: BonusType;
    kind: BonusKind;
    giftStatus: GiftStatus
    bonusStatus?: BonusStatus;
    bonus?: number;
    wagering?: {
        value: number;
        limit: number;
    };
    localisationVariables: Record<string, unknown>;
    order?: number;
    expires?: number | Date;
    title: string;
    description?: string;
    image: string;
    noDeposit?: boolean; // can be activated without deposit
    gameSlug?: string;
    needEmailVerification: boolean;
    details: Partial<Record<BonusDetailKey, IBonusDetail>>;
}
