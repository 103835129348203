import type { IPromoPrizeResource } from "@api";
import { MODAL_PROMO_ALL_PRIZES } from "@config/modalNames";
import { PROMO_COINS } from "@controllers/services/promotions";
import { usePromoPage } from "@pages/PromoPage/composables/usePromoPage";
import { useModalsStore } from "@store/modals";
import { useUserInfo } from "@store/userInfo";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const promoAllPrizes = () => import("@pages/PromoPage/components/PromoAllPrizesModal.vue");

export function usePromoPagePrizes() {
    const userInfoStore = useUserInfo();
    const modalsStore = useModalsStore();
    const i18n = useI18n();
    const {
        currentPromo,
        activePromo,
        isActive,
        currentPromoCoinsUserBalance,
        isEnoughCoins,
    } = usePromoPage();

    const promoPrizes = computed(() => {
        if (userInfoStore.isLogged && isActive.value) {
            return [ ...currentPromoPrizes.value ].sort(
                (prizeA: IPromoPrizeResource, prizeB: IPromoPrizeResource) => {
                    return prizeB.leftExchanges > 0 ? 0 : -1;
                },
            );
        }

        return currentPromoPrizes.value;
    });

    const userExchangePrizeList = computed<IPromoPrizeResource[]>(() => {
        const higherPrizesList = exchangeAvailablePrizeList.value;
        const lastHigherPrizes = higherPrizesList.slice(-1)[0];
        const nextAvailablePrize = nextAvailablePrizeList.value[0];

        if (higherPrizesList.length > 0) {
            // Если самый дорогой подарок на обмен суперприз - отдавать два предыдущих самых дорогих подарка
            if (lastHigherPrizes === currentPromoPrimaryPrize.value) {
                return higherPrizesList.slice(-3, -1);
            }
            // Если следующий доступный подарок суперприз или суперприз уже был обменян -
            // отдавать два последних самых дорогих подарка
            if (!nextAvailablePrize || nextAvailablePrize?.id === currentPromoPrimaryPrize.value?.id) {
                return higherPrizesList.slice(-2);
            }

            //  Отдавать самый дорогой подарок на обмен
            return higherPrizesList.slice(-1);
        }
        return [];
    });

    const availablePrizeList = computed<IPromoPrizeResource[]>(() => {
        // Если в массиве на обмен два подарка - не отдавать следующие доступные подарки
        if (userExchangePrizeList.value.length >= 2) {
            return [];
        }
        // Если массив подарков на обмен пустой - добавить два первых следующих доступных подарка
        if (userExchangePrizeList.value.length === 0) {
            return nextAvailablePrizeList.value.slice(0, 2);
        }
        //  Если массив следующих доступных подарков не пустой и первый подарок не суперприз,
        //  отдавать первый следующий доступный подарок
        if (
            nextAvailablePrizeList.value.length > 0 &&
            nextAvailablePrizeList.value[0].id !== currentPromoPrimaryPrize.value?.id
        ) {
            return [ nextAvailablePrizeList.value[0] ];
        }

        return [];
    });

    const userPrizesList = computed<IPromoPrizeResource[]>(() => {
        const array = [ ...userExchangePrizeList.value, ...availablePrizeList.value ];

        if (currentPromoPrimaryPrize.value) {
            array.push(currentPromoPrimaryPrize.value);
        }
        return array;
    });

    async function showAllPrizes() {
        const module = await promoAllPrizes();

        modalsStore.showModal({
            new: true,
            name: MODAL_PROMO_ALL_PRIZES,
            component: module.default,
            props: {
                prizes: prizesListCompleted.value,
            },
        });
    }

    const prizesListCompletedView = computed<IPromoPrizeResource[]>(() => {
        return prizesListCompleted.value.slice(0, 3);
    });

    function sortPrizes(prizes: IPromoPrizeResource[]) {
        return [ ...prizes ].sort((prizeA: IPromoPrizeResource, prizeB: IPromoPrizeResource) => {
            return prizeA.price - prizeB.price;
        });
    }

    const currentPromoPrizes = computed<IPromoPrizeResource[]>(() => {
        if (currentPromo.value?.prizes) {
            return sortPrizes(currentPromo.value.prizes);
        }
        return [];
    });

    const activePromoPrizes = computed<IPromoPrizeResource[]>(() => {
        if (activePromo.value?.prizes) {
            return sortPrizes(activePromo.value.prizes);
        }
        return [];
    });

    const currentPromoPrimaryPrize = computed<IPromoPrizeResource | undefined>(() => {
        return currentPromoPrizes.value.slice(-1)[0];
    });

    const exchangeAvailablePrizeList = computed<IPromoPrizeResource[]>(() => {
        if (activePromo.value?.id) {
            return activePromoPrizes.value.filter((prize: IPromoPrizeResource) => {
                return prize.leftExchanges > 0 && isEnoughCoins(prize);
            });
        }
        return [];
    });

    const nextAvailablePrizeList = computed<IPromoPrizeResource[]>(() => {
        if (activePromo.value?.id) {
            return activePromoPrizes.value.filter((prize: IPromoPrizeResource) => {
                return prize.leftExchanges > 0 && currentPromoCoinsUserBalance.value < prize.price / PROMO_COINS;
            });
        }
        return [];
    });

    const prizesListCompleted = computed<IPromoPrizeResource[]>(() => {
        if (currentPromoPrizes.value.length) {
            return currentPromoPrizes.value
                .filter((prize: IPromoPrizeResource) => {
                    return prize.exchangeLimitPerUser - prize.leftExchanges > 0;
                })
                .sort((prizeA: IPromoPrizeResource, prizeB: IPromoPrizeResource) => {
                    const exchangeA = prizeA.exchangeLimitPerUser - prizeA.leftExchanges;
                    const exchangeB = prizeB.exchangeLimitPerUser - prizeB.leftExchanges;
                    return exchangeA - exchangeB;
                });
        }
        return [];
    });

    return {
        promoPrizes,
        userPrizesList,
        userExchangePrizeList,
        prizesListCompletedView,

        showAllPrizes,

        currentPromoPrizes,
        activePromoPrizes,
        currentPromoPrimaryPrize,
        exchangeAvailablePrizeList,
        nextAvailablePrizeList,
        prizesListCompleted,
    };
}
