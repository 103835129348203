import type { RouteLocationRaw } from "vue-router";
import BettingCashbackImage from "../images/calendar-task-betting-cashback.png";
import DoubleImage from "../images/calendar-task-double.png";
import FreebetImage from "../images/calendar-task-freebet.png";
import TournamentActivityImage from "../images/calendar-task-tournament-activity.png";
import RegularSportGiftImage from "../images/calendar-task-regular-sport-gift.png";
import { routeNames } from "@router/routeNames";

export type CalendarTaskConfig = {
    id: number;
    period: {
        from: Date;
        to: Date;
    };
    descriptionKey: string;
    titleKey: string;
    buttonKey: string;
    icon: string;
    link: RouteLocationRaw;
};

export enum CalendarTaskStatus {
    PAST = 1,
    CURRENT,
    FUTURE,
}

export type CalendarTaskData = {
    config: CalendarTaskConfig;
    status: CalendarTaskStatus;
};

export const CALENDAR_TASK_CONFIGS: CalendarTaskConfig[] = [
    {
        id: 1,
        period: {
            from: new Date("2024-12-03T00:00:00.000Z"),
            to: new Date("2024-12-04T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_1",
        titleKey: "TEXT_1",
        buttonKey: "BET_NOW",
        link: { name: routeNames.bettingPage },
        icon: FreebetImage,
    },
    {
        id: 2,
        period: {
            from: new Date("2024-12-04T00:00:00.000Z"),
            to: new Date("2024-12-06T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_2",
        titleKey: "TEXT_2",
        buttonKey: "MAKE_COMBO",
        link: { name: routeNames.bettingPage },
        icon: BettingCashbackImage,
    },
    {
        id: 3,
        period: {
            from: new Date("2024-12-06T00:00:00.000Z"),
            to: new Date("2024-12-09T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_3",
        titleKey: "TEXT_3",
        buttonKey: "DETAILS",
        link: { name: routeNames.bettingPage, query: { currentPage: "betRace" } },
        icon: TournamentActivityImage,
    },
    {
        id: 4,
        period: {
            from: new Date("2024-12-09T00:00:00.000Z"),
            to: new Date("2024-12-10T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_4",
        titleKey: "TEXT_4",
        buttonKey: "BET_NOW",
        link: { name: routeNames.bettingPage, query: { currentPage: "Upcoming", champId: "2903" } },
        icon: FreebetImage,
    },
    {
        id: 5,
        period: {
            from: new Date("2024-12-10T00:00:00.000Z"),
            to: new Date("2024-12-13T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_5",
        titleKey: "TEXT_5",
        buttonKey: "BET_NOW",
        link: { name: routeNames.bettingPage },
        icon: FreebetImage,
    },
    {
        id: 6,
        period: {
            from: new Date("2024-12-13T00:00:00.000Z"),
            to: new Date("2024-12-16T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_6",
        titleKey: "TEXT_6",
        buttonKey: "TRY_LUCK",
        link: { name: routeNames.bettingPage },
        icon: DoubleImage,
    },
    {
        id: 7,
        period: {
            from: new Date("2024-12-16T00:00:00.000Z"),
            to: new Date("2024-12-17T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_7",
        titleKey: "TEXT_7",
        buttonKey: "MAKE_BET",
        link: { name: routeNames.bettingPage },
        icon: FreebetImage,
    },
    {
        id: 8,
        period: {
            from: new Date("2024-12-17T00:00:00.000Z"),
            to: new Date("2024-12-18T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_8",
        titleKey: "TEXT_8",
        buttonKey: "DEPOSIT",
        link: { name: routeNames.profileBonusesSport },
        icon: RegularSportGiftImage,
    },
    {
        id: 9,
        period: {
            from: new Date("2024-12-18T00:00:00.000Z"),
            to: new Date("2024-12-20T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_9",
        titleKey: "TEXT_9",
        buttonKey: "BET_NOW",
        link: { name: routeNames.bettingPage, query: { currentPage: "Upcoming", champId: "998" } },
        icon: FreebetImage,
    },
    {
        id: 10,
        period: {
            from: new Date("2024-12-20T00:00:00.000Z"),
            to: new Date("2024-12-23T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_10",
        titleKey: "TEXT_10",
        buttonKey: "BET_NOW",
        link: { name: routeNames.bettingPage, query: { currentPage: "Upcoming", champId: "4485" } },
        icon: FreebetImage,
    },
    {
        id: 11,
        period: {
            from: new Date("2024-12-23T00:00:00.000Z"),
            to: new Date("2024-12-24T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_11",
        titleKey: "TEXT_11",
        buttonKey: "MAKE_BET",
        link: { name: routeNames.bettingPage },
        icon: FreebetImage,
    },
    {
        id: 12,
        period: {
            from: new Date("2024-12-24T00:00:00.000Z"),
            to: new Date("2024-12-25T00:00:00.000Z"),
        },
        descriptionKey: "TEXT_12",
        titleKey: "TEXT_12",
        buttonKey: "GET_BONUS",
        link: { name: routeNames.profilePromocode },
        icon: FreebetImage,
    },
];

export const CALENDAR_LOCALISATION_VARIABLES = {
    amount1: {
        USD: "30 USD",
        EUR: "30 EUR",
        AUD: "45 AUD",
        CAD: "45 CAD",
        NZD: "45 NZD",
        INR: "3 000 INR",
        BRL: "150 BRL",
    },
    amount2: {
        USD: "20 USD",
        EUR: "20 EUR",
        AUD: "30 AUD",
        CAD: "30 CAD",
        NZD: "30 NZD",
        INR: "2 000 INR",
        BRL: "100 BRL",
    },
    amount3: {
        USD: "10 USD",
        EUR: "10 EUR",
        AUD: "15 AUD",
        CAD: "15 CAD",
        NZD: "15 NZD",
        INR: "1 000 INR",
        BRL: "50 BRL",
    },
    amount4: {
        USD: "15 USD",
        EUR: "15 EUR",
        AUD: "25 AUD",
        CAD: "25 CAD",
        NZD: "25 NZD",
        INR: "1 500 INR",
        BRL: "75 BRL",
    },
    amount5:{
        USD: "25 USD",
        EUR: "25 EUR",
        AUD: "40 AUD",
        CAD: "40 CAD",
        NZD: "40 NZD",
        INR: "2 500 INR",
        BRL: "125 BRL",
    },
    amount6: {
        USD: "1700 USD",
        EUR: "1500 EUR",
        AUD: "2400 AUD",
        CAD: "2250 CAD",
        NZD: "2600 NZD",
        INR: "140 000 INR",
        BRL: "9000 BRL",
    },
    amount7: {
        USD: "200 USD",
        EUR: "200 EUR",
        AUD: "300 AUD",
        CAD: "300 CAD",
        NZD: "300 NZD",
        INR: "20 000 INR",
        BRL: "1000 BRL",
    },
};
