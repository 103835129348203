import { type IUserStrategiesResource, profileType, treasuryType, userAPI } from "@api";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUserStrategies = defineStore("userStrategies", () => {
    function baseUserStrategies(): IUserStrategiesResource {
        return {
            strategyProfile: {
                type: profileType.HARD,
            },
            strategyTreasury: {
                type: treasuryType.HARD,
            },
        };
    }
    const strategies = ref<IUserStrategiesResource>(baseUserStrategies());
    async function loadUserStrategies() {
        const data = await userAPI.loadUserStrategies();
        strategies.value = {
            ...strategies.value,
            ...data,
        };
    }

    const isSimpleFlow = computed<boolean>(() => {
        return strategies.value.strategyProfile.type === profileType.SIMPLE;
    });

    const isBrazilFlow = computed<boolean>(() => {
        return strategies.value.strategyProfile.type === profileType.BRAZIL;
    });

    function cleanUserStrategies() {
        strategies.value = baseUserStrategies();
    }

    return {
        strategies,
        loadUserStrategies,
        isSimpleFlow,
        isBrazilFlow,
        cleanUserStrategies,
    };
});
