export default [
    "AD", // Andorra / Андорра
    "AL", // Albania / Албания
    "AT", // Austria / Австрия
    "AU", // Australia / Австралия
    "BA", // Bosnia and Herzegovina / Босния и Герцеговина
    "BG", // Bulgaria / Болгария
    "CH", // Switzerland / Швейцария
    "CZ", // Czech Republic / Чехия
    "DE", // Germany / Германия
    "DK", // Denmark / Дания
    "EE", // Estonia / Эстония
    "FI", // Finland / Финляндия
    "GE", // Georgia / Грузия
    "HR", // Croatia / Хорватия
    "HU", // Hungary / Венгрия
    "IE", // Ireland / Ирландия
    "IO", // British Indian Ocean Territory / Британская территория в Индийском океане
    "IS", // Iceland / Исландия
    "JE", // Jersey / Джерси
    "LU", // Luxembourg / Люксембург
    "MD", // Moldova / Молдова
    "ME", // Montenegro / Черногория
    "MK", // North Macedonia / Северная Македония
    "NO", // Norway / Норвегия
    "PT", // Portugal / Португалия
    "RO", // Romania / Румыния
    "RS", // Serbia / Сербия
    "SE", // Sweden / Швеция
    "SI", // Slovenia / Словения
    "SK", // Slovakia / Словакия
    "BR", // Brazil / Бразилия
    "CA", // Canada / Канада
    "IN", // India / Индия
    "NZ", // New Zealand / Новая Зеландия
    "TR", // Turkey / Турция
];
