import { LOCALES } from "./constsLocales";

interface ILocalesByHost {
    [key: string]: string[];
}

export const ALLOWED_LOCALES_BY_HOST: ILocalesByHost = {
    "winspirit1.com": [ LOCALES.ENGLISH, LOCALES.AUSTRALIA_ENGLISH ],
    "winspirit.com": [ LOCALES.ENGLISH, LOCALES.CANADA_ENGLISH ],
    // for develop
    "localhost": [ LOCALES.ENGLISH, LOCALES.CANADA_ENGLISH ],
    "thor.development.wlabel.site": [ LOCALES.ENGLISH, LOCALES.CANADA_ENGLISH, LOCALES.AUSTRALIA_ENGLISH ],
};
