<script lang="ts" setup>
import { useParserContent } from "@helpers/ParserContent";
import { GLOBAL_EVENT_PROMO_SLUG } from "@modules/GlobalEvent";
import { usePromoPage } from "@pages/PromoPage/composables/usePromoPage";
import { usePromoPagePrizes } from "@pages/PromoPage/composables/usePromoPagePrizes";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import type { IPromoPrizeResource } from "@api";

import { PROMO_COINS } from "@controllers/services/promotions";
import { routeNames } from "@router/routeNames";
import { usePromo } from "@store/promo";

import PromoCoin from "@components/PromoCoin/PromoCoin.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
const i18n = useI18n();
const router = useRouter();
const promoStore = usePromo();
const { parseContent } = useParserContent();
const { exchangeAvailablePrizeList, nextAvailablePrizeList } = usePromoPagePrizes();
const { activePromoCoinsUserBalance } = usePromoPage();

const userCanExchange = computed<boolean>(() => {
    return exchangeAvailablePrizeList.value.length > 0;
});

const nextAvailablePrize = computed<IPromoPrizeResource>(() => {
    return nextAvailablePrizeList.value[0];
});

function goToPromo() {
    if (promoStore.activePromoData) {
        const slug = promoStore.activePromoData.slug;
        const name = slug === GLOBAL_EVENT_PROMO_SLUG ? routeNames.santasGiftShopPromoPage : routeNames.promoPage;

        router.push({
            name,
            params: { slug },
        });
    }
}

const promoTitle = computed(() => {
    return promoStore.activePromoData?.title || "";
});

const prizeText = computed(() => {
    return parseContent(nextAvailablePrize.value.title, nextAvailablePrize.value.gift.localisation?.variables);
});
</script>

<template>
    <div class="dropdown-promo color-text-alt">
        <p v-t="'PROMO.QUEST'" class="caption-1-bold" />
        <section class="section--light dropdown-promo__card" @click="goToPromo">
            <div class="dropdown-promo__section">
                <div class="caption-1">
                    {{ promoTitle }}
                </div>
                <PromoCoin
                    v-if="userCanExchange"
                    class="caption-1-bold"
                    :value="activePromoCoinsUserBalance"
                />
                <PromoCoin
                    v-else
                    class="caption-1-bold"
                    :value="activePromoCoinsUserBalance"
                    :to="nextAvailablePrize.price / PROMO_COINS"
                />
            </div>
            <div v-if="userCanExchange">
                <div class="caption-1">
                    <span>{{ i18n.t("PROMO.PROMO_USER_WIDGET_CAN_EXCHANGE_TEXT") }}&nbsp;</span>
                    <b class="caption-1-bold">
                        {{
                            i18n.t(
                                "PROMO.PROMO_USER_WIDGET_CAN_EXCHANGE_PRIZES",
                                exchangeAvailablePrizeList.length,
                            )
                        }}
                    </b>
                </div>
                <FeLink theme="secondary" class="caption-2-bold">
                    {{ i18n.t("PROMO.PROMO_PRIZE_BUTTON_EXCHANGE") }}
                </FeLink>
            </div>
            <div v-else>
                <div class="caption-1-bold color-text-alt">
                    {{ prizeText }}
                </div>
                <FeLink theme="secondary" class="caption-2-bold">
                    {{ i18n.t("PROMO.PROMO_USER_WIDGET_BUTTON_TO_PROMO") }}
                </FeLink>
            </div>
        </section>
    </div>
</template>

<style scoped lang="scss">
.dropdown-promo {
    display: grid;
    grid-gap: 1rem;
    cursor: pointer;

    &__section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__coin {
        width: 1rem;
        height: 1rem;
    }

    &__card {
        gap: 0.5rem;
        padding: 1rem;
    }
}
</style>
