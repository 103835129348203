import i18n from "@i18n";

const passwordRequiredLength = 6;

const ERRORS = {
    PHONE: {
        INVALID: "VALIDATION.PHONE.INVALID",
        FATAL: "VALIDATION.PHONE.FATAL",
    },
    EMAIL: {
        INVALID: "VALIDATION.EMAIL.INVALID",
        FATAL: "VALIDATION.EMAIL.FATAL",
    },
    SMS: {
        FATAL: "VALIDATION.SMS.FATAL",
        CHARS: "VALIDATION.SMS.CHARS",
        EMPTY: "VALIDATION.SMS.EMPTY",
        INVALID: "VALIDATION.SMS.INVALID",
    },
    PASSWORD: {
        WRONG_CHARTS: "VALIDATION.PASSWORD.WRONG_CHARTS",
        NOT_MATCH: "VALIDATION.PASSWORD.NOT_MATCH",
        LENGTH: "VALIDATION.PASSWORD.LENGTH",
        EMPTY: "VALIDATION.PASSWORD.EMPTY",
    },
    COUNTRY: {
        EMPTY: "VALIDATION.COUNTRY.EMPTY",
    },
    BIRTHDAY: {
        MIN: "VALIDATION.BIRTHDAY.MIN",
        MAX: "VALIDATION.BIRTHDAY.MAX",
    },
};

// eslint-disable-next-line @stylistic/js/max-len
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function emailRule(value?: string) {
    const { t } = i18n.instance || i18n.init();

    return emailRegEx.test(String(value).toLowerCase()) || t(ERRORS.EMAIL.INVALID);
}

function requiredRule(value: string) {
    return Boolean(value);
}

const smsRegEx = /^[0-9]*$/;

export function smsRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return requiredRule(value) || t(ERRORS.SMS.EMPTY);
}

export function smsCharsRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return smsRegEx.test(value.replace(" ", "")) || t(ERRORS.SMS.CHARS);
}

export function phoneRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return requiredRule(value) || t(ERRORS.PHONE.INVALID);
}

function minLengthRule(value: string, length: number) {
    return value.length >= length;
}

const passwordRegEx = /^[0-9a-zA-Z|@#$\^&="!№\';%:?()_+\-\/\,\.]*$/;

export function passwordCharRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return passwordRegEx.test(value) || t(ERRORS.PASSWORD.WRONG_CHARTS);
}

export function passwordRequireRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return requiredRule(value) || t(ERRORS.PASSWORD.EMPTY);
}

export function passwordMinLengthRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return minLengthRule(value, passwordRequiredLength) || t(ERRORS.PASSWORD.LENGTH, { count: passwordRequiredLength });
}

export function passwordsMatchRule(value1?: string, value2?: string) {
    const { t } = i18n.instance || i18n.init();

    return value1 === value2 || t(ERRORS.PASSWORD.NOT_MATCH);
}

export function countryRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    return requiredRule(value) || t(ERRORS.COUNTRY.EMPTY);
}

export function birthdayMinRule(value: string) {
    const { t } = i18n.instance || i18n.init();
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);

    return new Date(value) < new Date(minDate) || t(ERRORS.BIRTHDAY.MIN);
}

export function birthdayMaxRule(value: string) {
    const { t } = i18n.instance || i18n.init();

    if (value.length < 9) {
        return t(ERRORS.BIRTHDAY.MAX);
    }

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 118);

    return new Date(value) > new Date(maxDate) || t(ERRORS.BIRTHDAY.MAX);
}
