<template>
    <div id="main" class="main">
        <div style="min-height: 80vh">
            <Header />
            <HomeBanner v-if="isShowBanner" />
            <div class="body__wrapper" main-layout-width grid-full-width>
                <router-view class="body__wrapper-page" />
            </div>
        </div>
        <LocalTime v-if="!isMobile && !$route.meta.hideCasinoTime" />
        <FooterComponent />
        <AcceptedMessages
            v-if="!userInfoStore.isLogged"
            :show-cookies-accepted="isUserFromGDPRCountry"
            :show-popup-e-m-p="getFeatureFlags().empPopupEnabled"
        />
        <slot />
    </div>
</template>

<script setup lang="ts">
import { BannersPageService } from "@controllers/services/banners";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { Header } from "@modules/Header";
import HomeBanner from "@components/HomeBanner/index.vue";
import FooterComponent from "@modules/Footer/index.vue";
import LocalTime from "@components/LocalTime/LocalTime.vue";
import AcceptedMessages from "@modules/AcceptedMessages/index.vue";
import { useUserInfo } from "@store/userInfo";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import GDPRCountries from "@modules/CookiesAccepted/GDPRCountries";
import { useContextStore } from "@store/context";
import { useMultiLang } from "@store/multilang";

const userInfoStore = useUserInfo();
const route = useRoute();
const { isMobile } = storeToRefs(useContextStore());
const { userGeo } = storeToRefs(useMultiLang());

const isShowBanner = computed(() => {
    return route.name && BannersPageService.isShowOnPage(route.name as string);
});
const isUserFromGDPRCountry = computed(() => {
    return GDPRCountries.includes(userGeo.value);
});
</script>

<style lang="scss" src="./style.scss"></style>
