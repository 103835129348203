import log from "@front/core/controllers/LoggerController";
import type { PublicApiV1Response } from "@api/publicApi";
import { DEFAULT_PAGE_LIMIT } from "@theme/configs/constsGames";

export interface IPaginationData<T> {
    items: T[];
    currentPage: number;
    pending: boolean;
    isFullLoaded: boolean;
}

export type PaginationRequest<T, D> = (
    page: number,
    perPage: number,
    additionalData: D,
) => Promise<PublicApiV1Response<T[]> | undefined>;

export function usePaginationService<T, D>(request: PaginationRequest<T, D>, perPage = DEFAULT_PAGE_LIMIT) {
    function createPaginationData(): IPaginationData<T> {
        return {
            items: [],
            currentPage: 0,
            pending: false,
            isFullLoaded: false,
        };
    }

    async function loadCurrentPage(data: IPaginationData<T>, additionalData: D): Promise<IPaginationData<T>> {
        try {
            const response = await request(data.currentPage, perPage, additionalData);
            if (response?.data) {
                const { total } = response.pagination || {};
                const isFullLoaded = !total || total <= data.currentPage * perPage;

                return {
                    ...data,
                    isFullLoaded,
                    items: [ ...data.items, ...response.data ],
                };
            }
        } catch (err) {
            log.error("PAGINATION_LOAD_DATA_ERROR", err);
        }
        return {
            ...data,
            isFullLoaded: true,
        };
    }

    async function initLoad(data: IPaginationData<T>, additionalData: D): Promise<IPaginationData<T>> {
        if (data.currentPage === 0) {
            return await loadCurrentPage(
                {
                    ...data,
                    currentPage: 1,
                },
                additionalData,
            );
        }
        return data;
    }

    async function loadNext(data: IPaginationData<T>, additionalData: D): Promise<IPaginationData<T>> {
        if (!data.isFullLoaded) {
            return await loadCurrentPage(
                {
                    ...data,
                    currentPage: data.currentPage + 1,
                },
                additionalData,
            );
        }
        return data;
    }

    return {
        createPaginationData,
        initLoad,
        loadNext,
    };
}
