import banner from "@theme/configs/imgResize/banner";
import bannerHome from "@theme/configs/imgResize/bannerHome";
import bannerStories from "@theme/configs/imgResize/bannerStories";
import gameHomeThumb from "@theme/configs/imgResize/gameHomeThumb";
import gameRecent from "@theme/configs/imgResize/gameRecent";
import gameRowThumb from "@theme/configs/imgResize/gameRowThumb";
import gameThumb from "@theme/configs/imgResize/gameThumb";
import gameTournament from "@theme/configs/imgResize/gameTournament";
import { type IConfigForTypeImage } from "@theme/configs/imgResize/imageConfig";
import level from "@theme/configs/imgResize/level";
import promo from "@theme/configs/imgResize/promo";

export enum TypeImage {
    ACTIONS = "actions",
    TOURNAMENTS = "tournaments",
    LEVEL = "level",
    BANNER = "banner",
    BANNER_HOME = "bannerHome",
    BANNER_STORIES = "bannerStories",
    GAME_ROW_THUMB = "gameRowThumb",
    GAME_HOME_THUMB = "gameHomeThumb",
    GAME_THUMB = "gameThumb",
    GAME_RECENT = "gameRecent",
    GAME_TOURNAMENT = "gameTournament",
}

export interface IConfigResizeImg {
    [TypeImage.ACTIONS]: IConfigForTypeImage;
    [TypeImage.TOURNAMENTS]: IConfigForTypeImage;
    [TypeImage.LEVEL]: IConfigForTypeImage;
    [TypeImage.BANNER]: IConfigForTypeImage;
    [TypeImage.BANNER_HOME]: IConfigForTypeImage;
    [TypeImage.BANNER_STORIES]: IConfigForTypeImage;
    [TypeImage.GAME_ROW_THUMB]: IConfigForTypeImage;
    [TypeImage.GAME_HOME_THUMB]: IConfigForTypeImage;
    [TypeImage.GAME_THUMB]: IConfigForTypeImage;
    [TypeImage.GAME_RECENT]: IConfigForTypeImage;
    [TypeImage.GAME_TOURNAMENT]: IConfigForTypeImage;
}

const CONFIGS_BY_TYPE: IConfigResizeImg = {
    [TypeImage.ACTIONS]: promo,
    [TypeImage.TOURNAMENTS]: promo,
    [TypeImage.LEVEL]: level,
    [TypeImage.BANNER_HOME]: bannerHome,
    [TypeImage.BANNER]: banner,
    [TypeImage.BANNER_STORIES]: bannerStories,
    [TypeImage.GAME_ROW_THUMB]: gameRowThumb,
    [TypeImage.GAME_HOME_THUMB]: gameHomeThumb,
    [TypeImage.GAME_THUMB]: gameThumb,
    [TypeImage.GAME_RECENT]: gameRecent,
    [TypeImage.GAME_TOURNAMENT]: gameTournament,
};

export function getConfigImg(type: TypeImage): IConfigForTypeImage {
    return CONFIGS_BY_TYPE[type];
}
