import type { IUserBalanceBonusesWagering } from "@api";
import bus from "@bus";
import { MODAL_CASHBOX_DEPOSIT, MODAL_DEPOSIT_BONUS_BALANCE_INFO } from "@config/modalNames";
import { useModalsStore } from "@store/modals";
import { onBeforeUnmount, onMounted } from "vue";

import { useBonusAfterDepositStore } from "./stateStore";
import { SocketEvent, websocketEmitter } from "@api/websockets";

const DEPOSIT_CONFIRMED_REASON = "treasury.deposit.confirmed" as const;

interface IBalanceChangedSocketData {
    reason: string | typeof DEPOSIT_CONFIRMED_REASON;
    bonusesWagering?: {
        sport?: IUserBalanceBonusesWagering | null;
        casino?: IUserBalanceBonusesWagering | null;
    };
}

function depositBonusBalanceInfoPopup() {
    return import("@modules/Popups/DepositBonusBalanceInfo/DepositBonusBalanceInfo.vue");
}

export function useCashboxBonusInfoPopupStrategy() {
    const stateStore = useBonusAfterDepositStore();
    const modalsStore = useModalsStore();

    async function showBonusInfoPopup() {
        const module = await depositBonusBalanceInfoPopup();
        modalsStore.showModal({
            name: MODAL_DEPOSIT_BONUS_BALANCE_INFO,
            component: module.default,
            new: true,
        });
    }

    function checkDepositPopupOpened() {
        return modalsStore.modals.some((modal) => {
            return modal.name === MODAL_CASHBOX_DEPOSIT;
        });
    }
    function balanceChangedSocket(data: IBalanceChangedSocketData) {
        if (data.reason === DEPOSIT_CONFIRMED_REASON && (data.bonusesWagering?.casino || data.bonusesWagering?.sport)) {
            if (checkDepositPopupOpened()) {
                stateStore.setUserHasBonusBalanceBeforeDeposit();
            } else {
                showBonusInfoPopup();
            }
        }
    }
    function depositPopupClosed() {
        if (stateStore.userHasBonusBalanceBeforeDeposit) {
            stateStore.setUserHasBonusBalanceBeforeDeposit(false);
            showBonusInfoPopup();
        }
    }

    onMounted(() => {
        websocketEmitter.on(SocketEvent.BALANCE_CHANGED, balanceChangedSocket);
        bus.$on("cashbox.deposit.close", depositPopupClosed);
    });
    onBeforeUnmount(() => {
        websocketEmitter.off(SocketEvent.BALANCE_CHANGED, balanceChangedSocket);
        bus.$off("cashbox.deposit.close", depositPopupClosed);
    });
}
