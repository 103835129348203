<template>
    <transition name="fe-notice">
        <div v-if="noticeText" :class="noticeClass">
            <div class="fe-notice__body">
                <div class="fe-notice__text" v-html="noticeText" />
            </div>
            <FeLink
                v-if="showButton"
                class="fe-notice__link"
                :to="notice.button.link"
                :target="blank ? '_blank' : undefined"
            >
                <FeButton :theme="config.buttons.Notice.button">
                    {{ buttonName }}
                </FeButton>
            </FeLink>
            <FeIcon
                :icon="FeIconConfig.close"
                data-test="notice-cancel"
                class="fe-notice__cancel"
                @click="userNoticeStore.deleteUserNotification(notice.id)"
            />
        </div>
    </transition>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";

import type { IUserNotificationResource } from "@api";
import config from "@theme/configs/config";
import { FeIconConfig } from "@theme/configs/icons";
import { useNoticeParserContent } from "@controllers/services/notifications/parseNotificationService";
import { useUserNotice } from "@store/userNotice";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import FeButton from "@ui-kit/FeButton/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";

interface IProps {
    notice: IUserNotificationResource;
    newStyle?: boolean;
    blank?: boolean;
}
const props = defineProps<IProps>();

const i18n = useI18n();
const userNoticeStore = useUserNotice();

const { parseNotice } = useNoticeParserContent();

const noticeText = computed(() => {
    return parseNotice(props.notice).message;
});

const buttonName = computed(() => {
    switch (props.notice.code) {
        case "USER_LEVEL_CHANGED":
            return i18n.t("NOTIFICATION_BACK.USER_LEVEL_CHANGED_BUTTON");
        case "TOURNAMENT_PHASE_STARTED":
            return i18n.t("NOTIFICATION_BACK.TOURNAMENT_PHASE_STARTED_BUTTON");
        case "TOURNAMENT_USER_PRIZE_DISTRIBUTE":
            return i18n.t("NOTIFICATION_BACK.TOURNAMENT_USER_PRIZE_DISTRIBUTE_BUTTON");
    }

    return props.notice.button.name;
});

const showButton = computed(() => {
    return props.notice.button?.link && props.notice.button?.name;
});

const noticeClass = computed(() => {
    return {
        "fe-notice": true,
        "fe-notice--text-only": !showButton.value,
        "fe-notice--new": props.newStyle,
    };
});

</script>

<style lang="scss" src="./style.scss"></style>
