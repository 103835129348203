<script setup lang="ts">
import { useI18n } from "vue-i18n";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import makeberryLogo from "./makeberry-logo.svg";
import winspiritLogo from "./winspirit-logo.png";
import winspiritLogoRetina from "./winspirit-logo@2x.png";

type PartnerBlock = {
    id: string,
    title: string
    link: string
    image: string
    alt: string
    width: string
    height: string
    imageRetina?: string
    rel?: string
}

const i18n = useI18n();
const partners: PartnerBlock[] = [
    {
        id: "winspirit",
        title: i18n.t("FOOTER.PARTNER_BLOCK.WINSPIRIT_TITLE"),
        link: "https://winspiritaffiliates.com",
        image: winspiritLogo,
        imageRetina: winspiritLogoRetina,
        alt: "winspirit-affiliates",
        width: "120",
        height: "48",
        rel: "noindex nofollow",
    },
    {
        id: "makeberry",
        title: i18n.t("FOOTER.PARTNER_BLOCK.MAKEBERRY_TITLE"),
        link: "https://affiliate.makeberry.com/",
        image: makeberryLogo,
        alt: "makeberry-affiliates",
        width: "120",
        height: "48",
        rel: "noindex nofollow",
    },
];
</script>

<template>
    <section class="partner">
        <div v-for="partner in partners" :key="partner.id" class="partner__item">
            <div class="caption-2">
                {{ partner.title }}
            </div>
            <FeLink :to="partner.link" :rel="partner.rel">
                <FeImage
                    :src="partner.image"
                    :alt="partner.alt"
                    :src-retina="partner.imageRetina"
                    :width="partner?.width"
                    :height="partner?.height"
                />
            </FeLink>
        </div>
    </section>
</template>

<style scoped lang="scss">
@import "~@theme/styles/index";

.partner {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    color: $color-text-body;

    @include media(M) {
        gap: 3rem;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
}

.partner__item {
    position: relative;
    display: flex;
    gap: 1rem;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
    width: 100%;

    @include media(M) {
        gap: 0.5rem;
        width: auto;
    }

    &::before {
        background-color: $color-tertiary-3;
        content: '';
        position: absolute;
        top: -0.5rem;
        left: 0;
        right: 0;
        height: 1px;
    }

    &:first-of-type::before {
        display: none;
    }

    @include media(M) {
        &::before {
            top: 50%;
            left: -1.5rem;
            right: auto;
            height: 2rem;
            width: 1px;
            transform: translateY(-50%);
        }
    }
}
</style>
