import { useUserInfo } from "@store/userInfo";
import { useUserStrategies } from "@store/userStrategies";

function isPersonalDataSaved() {
    const userInfoStore = useUserInfo();
    return userInfoStore.isPersonalDataSaved;
}
function isAddressDataSaved() {
    const userInfoStore = useUserInfo();
    return userInfoStore.isAddressDataSaved;
}

function isBrazilFlow() {
    const userStrategiesStore = useUserStrategies();
    return userStrategiesStore.isBrazilFlow;
}

function isBrazilDataSaved() {
    const userInfoStore = useUserInfo();
    return userInfoStore.isBrazilDataSaved;
}

function isUserDataSaved() {
    return isBrazilFlow() ?
        isBrazilDataSaved() :
        isPersonalDataSaved() && isAddressDataSaved();
}


export {
    isPersonalDataSaved,
    isAddressDataSaved,
    isBrazilFlow,
    isBrazilDataSaved,
    isUserDataSaved,
};
