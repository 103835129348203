import initSentry from "@controllers/SentryController";
import log from "@front/core/controllers/LoggerController";
import metriks from "@front/core/controllers/MetriksController/index.js";
import { useServerStore } from "@store/server";
import { useUserInfo } from "@store/userInfo";
import "intersection-observer";
import { VueRecaptchaPlugin } from "vue-recaptcha";
import Vue3Mask from "vue3-mask";
import Vue3OutsideEvents from "vue3-outside-events";
import Vue3TouchEvents from "vue3-touch-events";
import { generateApp } from "./app";
import { useContextStore } from "@store/context";
import { getBaseUrl } from "@helpers/baseUrlHelper";

log.init({}, (obj: Record<string, string>) => {
    if (obj.level_name === "ERROR") {
        // eslint-disable-next-line no-console
        console.error(obj);
    }

    fetch("/log", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(obj),
    });
});
window.addEventListener("load", async () => {
    await fetch("/performance", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(window.performance.timing),
    });

    if (window.navigator?.connection) {
        const { rtt, downlink, effectiveType } = window.navigator.connection;
        await fetch("/network-performance", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
                rtt,
                downlink,
                effectiveType,
            }),
        });
    }
});

(async () => {
    const { app, router, pinia } = await generateApp({
        baseUrl: getBaseUrl(window.location.pathname),
        piniaInitialState: window.__pinia,
    });

    window.front_api = {
        router,
        page: "main",
    };

    const serverStore = useServerStore(pinia);
    const userInfoStore = useUserInfo(pinia);
    const contextStore = useContextStore(pinia);
    userInfoStore.isLoggedAsync().then(() => {
        // @ts-expect-error lack of types definition in MetriksController
        metriks.init(serverStore.metricsConfig, {
            router,
            user: userInfoStore.userInfo,
            isAuth: userInfoStore.isLogged,
            isBot: contextStore.isBot,
            valdemoro: serverStore.valdemoroConfig,
        });
    });

    // @ts-expect-error incorrect type in module
    app.use(Vue3Mask);
    // @ts-expect-error incorrect type in module
    app.use(Vue3TouchEvents);
    app.use(Vue3OutsideEvents);
    if (serverStore.captchaState && serverStore.captchaKey) {
        app.use(VueRecaptchaPlugin, {
            v2SiteKey: serverStore.captchaKey,
        });
    }

    if (!DEV) {
        initSentry(app, router, pinia);
    }
    router.isReady().then(() => app.mount("#app"));
})();
