import { performanceAPI, type IGameResource } from "@api";
import { DigitainFrameType } from "@controllers/services/performance/config";
import { isServer } from "@helpers/ssrHelpers";
import { useUserInfo } from "@store/userInfo";

export function useObserveGameLoadTime() {
    const userInfoStore = useUserInfo();

    function observeGameLoadTime(game: IGameResource, time: number) {
        if (isServer && !userInfoStore.isLogged) {
            return;
        }

        const {
            effectiveType = "other",
        } = window.navigator?.connection || {};

        performanceAPI.gameStartObserve({
            country: userInfoStore.userInfo.chosen_country,
            userId: userInfoStore.userInfo.user_id,
            provider: game.gameProvider,
            gameName: game.name,
            gameId: game.id,
            value: time,
            connectionType: effectiveType as string,
        });
    }

    function observeBettingLoadTime(game: DigitainFrameType, time: number) {
        if (isServer && !userInfoStore.isLogged) {
            return;
        }

        const {
            effectiveType = "other",
        } = window.navigator?.connection || {};

        performanceAPI.gameStartObserve({
            country: userInfoStore.userInfo.chosen_country,
            userId: userInfoStore.userInfo.user_id,
            gameName: game,
            value: time,
            connectionType: effectiveType as string,
        });
    }

    return {
        observeGameLoadTime,
        observeBettingLoadTime,
    };
}
