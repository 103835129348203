import { routeNames } from "@router/routeNames";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { AuthType, type OnboardScenario, OnboardScenarioConditionType, PlatformType, TriggerType } from "../types";

export const ONBOARD_SCENARIOS: OnboardScenario[] = [
    {
        name: "faq_page_auth",
        authType: AuthType.AUTH_ONLY,
        disabled: getFeatureFlags().enableStoriesVerification,
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.faqPage },
        },
        items: [
            {
                element: "[data-test='stories-bonuses'] .carousel-scroll__list .carousel-scroll__item:nth-child(1)",
                message: "ONBOARDING.MESSAGES.FAQ_STORIES",
            },
        ],
    },
    {
        name: "faq_page_auth_v2_1",
        authType: AuthType.AUTH_ONLY,
        disabled: !getFeatureFlags().enableStoriesVerification,
        conditions: [
            {
                type: OnboardScenarioConditionType.SAW_STORIES,
                stories: [ "faq_page_auth" ],
            },
        ],
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.faqPage },
        },
        items: [
            {
                element: "[data-test='stories-verification'] .carousel-scroll__list .carousel-scroll__item:nth-child(1)",
                message: "ONBOARDING.MESSAGES.FAQ_STORIES_VERIFICATION_ONLY",
            },
        ],
    },
    {
        name: "faq_page_auth_v2_2",
        authType: AuthType.AUTH_ONLY,
        disabled: !getFeatureFlags().enableStoriesVerification,
        conditions: [
            {
                type: OnboardScenarioConditionType.NOT_SAW_STORIES,
                stories: [ "faq_page_auth" ],
            },
        ],
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.faqPage },
        },
        items: [
            {
                element: "[data-test='stories-bonuses'] .carousel-scroll__list .carousel-scroll__item:nth-child(1)",
                message: "ONBOARDING.MESSAGES.FAQ_STORIES_BONUSES",
            },
            {
                element: "[data-test='stories-verification'] .carousel-scroll__list .carousel-scroll__item:nth-child(1)",
                message: "ONBOARDING.MESSAGES.FAQ_STORIES_VERIFICATION",
            },
        ],
    },
    {
        name: "verification_page_auth",
        authType: AuthType.AUTH_ONLY,
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.verificationPage },
        },
        items: [
            {
                element: ".carousel.stories .carousel-scroll__list .carousel-scroll__item:nth-child(1)",
                message: "ONBOARDING.MESSAGES.PROFILE_BONUSES_STORIES",
            },
        ],
    },
    {
        name: "profile_bonuses_page_auth",
        authType: AuthType.AUTH_ONLY,
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.profileBonuses },
        },
        items: [
            {
                element: ".carousel-scroll .story",
                message: "ONBOARDING.MESSAGES.PROFILE_BONUSES_STORIES",
            },
        ],
    },
    {
        name: "profile_verification_page_auth",
        authType: AuthType.AUTH_ONLY,
        disabled: !getFeatureFlags().enableStoriesVerification,
        delay: 1000,
        trigger: {
            type: TriggerType.ROUTE,
            route: { name: routeNames.profileVerification },
        },
        items: [
            {
                element: ".carousel-scroll .story",
                message: "ONBOARDING.MESSAGES.PROFILE_BONUSES_STORIES",
            },
        ],
    },
    {
        name: "sidebar_global_event",
        disabled: !getFeatureFlags().enableGlobalEvent,
        delay: 1000,
        trigger: {
            type: TriggerType.EVENT_BUS,
            event: "sidebar.open",
            offEvent: "sidebar.close",
        },
        items: [
            {
                element: "[data-test='sidebar-global-event']",
                message: "ONBOARDING.MESSAGES.GLOBAL_EVENT",
                extraClassName: "onboarding-sidebar-element",
            },
        ],
        platformType: PlatformType.MOBILE_ONLY,
    },
];
