import type { Pinia } from "pinia";
import { type ShowModalData, useModalsStore } from "@store/modals";

let pinia: Pinia | undefined = undefined;

export default {
    init(piniaInstance: Pinia) {
        pinia = piniaInstance;
    },

    /**
     * Displays the given modal.
     *
     * @deprecated This method is deprecated. Use `modalsStore.showModal()` directly instead.
     *
     * @param {Modal} modal - The name of the modal to be displayed.
     */
    show(modal: ShowModalData) {
        if (pinia) {
            const modalsStore = useModalsStore(pinia);
            modalsStore.showModal(modal);
        }
    },

    /**
     * @deprecated
     *
     * Use modalsStore directly instead.
     *
     * @param {string} name - The name of the modal to be closed.
     *
     * @returns {void}
     */
    close(name: string) {
        if (pinia) {
            const modalsStore = useModalsStore(pinia);
            modalsStore.closeModal(name);
        }
    },
};
