import type { RouteLocationRaw } from "vue-router";

export enum TriggerType {
    ROUTE = "route",
    EVENT_BUS = "eventBus",
}

export type OnboardRouteTrigger = {
    type: TriggerType.ROUTE;
    route: RouteLocationRaw;
};
export type OnboardEventBusTrigger = {
    type: TriggerType.EVENT_BUS;
    event: string;
    offEvent?: string;
};

export type OnboardTrigger = OnboardRouteTrigger | OnboardEventBusTrigger;

export type SelectorOrElementGetter = string | (() => HTMLElement | null);

export enum AuthType {
    ALL,
    AUTH_ONLY,
    GUEST_ONLY,
}

export enum PlatformType {
    ALL,
    MOBILE_ONLY,
    DESKTOP_ONLY,
}

export type OnboardScenarioItem = {
    message: string;
    buttonText?: string;
    extraClassName?: string;
    element: SelectorOrElementGetter;
};

export enum OnboardScenarioConditionType {
    SAW_STORIES = "SAW_STORIES",
    NOT_SAW_STORIES = "NOT_SAW_STORIES",
}
export type OnboardScenarioConditionSawStories = {
    type: OnboardScenarioConditionType.SAW_STORIES;
    stories: string[];
};
export type OnboardScenarioConditionNotSawStories = {
    type: OnboardScenarioConditionType.NOT_SAW_STORIES;
    stories: string[];
};
export type OnboardScenarioCondition = OnboardScenarioConditionSawStories | OnboardScenarioConditionNotSawStories;

export type OnboardScenario<T extends OnboardTrigger = OnboardTrigger> = {
    name: string;
    disabled?: boolean | (() => boolean);
    conditions?: OnboardScenarioCondition[];
    trigger: T;
    authType?: AuthType;
    platformType?: PlatformType;
    delay?: number;
    timeout?: number;
    items: OnboardScenarioItem[];
};
