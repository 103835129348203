import { serverAPI, type IPhoneCode, type IPhoneCodeList } from "@api";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCountriesData = defineStore("countriesData", () => {
    const phoneCodeLoaded = ref(false);

    const phoneCodes = ref<IPhoneCodeList>({
        au: {
            code: "au",
            dialCode: 61,
            name: "Australia",
            example: "4 1234-5678",
        },
    });

    async function loadCountriesData(): Promise<IPhoneCodeList> {
        if (phoneCodeLoaded.value) {
            return phoneCodes.value;
        }
        const data = await serverAPI.loadCountriesData();

        if (data) {
            phoneCodes.value = data;
        }
        phoneCodeLoaded.value = true;
        return phoneCodes.value;
    }

    const getCountries = computed<IPhoneCode[]>(() => {
        return Object.values(phoneCodes.value);
    });

    return {
        phoneCodeLoaded,
        phoneCodes,
        loadCountriesData,
        getCountries,
    };
});
