import type { Pinia } from "pinia";
import type { Router } from "vue-router";
import type { IStaticPagesItemResource } from "@api";
import { routeNames } from "@router/routeNames";
import { useStaticPagesStore } from "@store/staticPages";

const StaticPage = () => import("@modules/StaticPage/StaticPage.vue");
const StaticPageGames = () => import("@modules/StaticPageGames/StaticPageGames.vue");

export function generateStaticRoutes(
    routerData: IStaticPagesItemResource[] | Record<string, unknown> = {},
    router?: Router,
    pinia?: Pinia,
) {
    if (routerData && router) {
        let staticPagesData: IStaticPagesItemResource[] | undefined = undefined;
        const staticPagesStore = useStaticPagesStore(pinia);
        if (Array.isArray(routerData)) {
            staticPagesData = routerData;
        } else if (routerData.staticPages) {
            staticPagesData = routerData.staticPages as IStaticPagesItemResource[];
        } else {
            staticPagesData = staticPagesStore.staticPages;
        }

        staticPagesData.forEach((staticPage) => {
            if (!staticPage.slug || router.hasRoute(staticPage.slug)) {
                return;
            }

            router.addRoute(routeNames.base, {
                path: staticPage.slug,
                name: staticPage.slug,
                component: StaticPage,
                meta: {
                    staticPageGames: true,
                },
            });
            router.addRoute(routeNames.base, {
                path: `${staticPage.slug}/games`,
                name: `${staticPage.slug}Games`,
                props: { slug: staticPage.slug },
                component: StaticPageGames,
                meta: {
                    staticPageGames: true,
                    slug: staticPage.slug,
                },
            });
            router.addRoute(routeNames.base, {
                path: `${staticPage.slug}/games/:page`,
                name: `${staticPage.slug}GamesByPage`,
                props: { slug: staticPage.slug },
                component: StaticPageGames,
                meta: {
                    staticPageGames: true,
                    slug: staticPage.slug,
                },
            });
        });
    }
}
