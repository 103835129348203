<template>
    <div class="iti-flag-wrapper">
        <div class="iti-flag" :class="{[code]: code, 'disabled': disabled}" />
    </div>
</template>

<script>
export default {
    props: {
        code: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
