import { useEnvironment } from "@store/environment";
import { getConfigImg, TypeImage } from "@theme/configs/imgResize";
import {
    IMG_QUERY_DIMENSION,
    IMG_QUERY_DPR,
    BreakPoints,
    type ConfigImgType,
    type IConfigForTypeImage,
    type IConfigImgCompostela,
} from "@theme/configs/imgResize/imageConfig";
import { computed, toValue, type MaybeRefOrGetter } from "vue";

export type GameImageQuery = Record<TypeImageQuery, string | string[]>;

export enum TypeImageQuery {
    BADGES = "badges",
    GRADIENTS = "gradients",
    PROVIDER = "providers",
    LABELS = "labels",
}

export function usePictureSource(
    src: MaybeRefOrGetter<string>,
    type: MaybeRefOrGetter<TypeImage>,
    query: MaybeRefOrGetter<GameImageQuery | undefined>,
) {
    const envStore = useEnvironment();
    const configImgByType = computed<IConfigForTypeImage>(() => getConfigImg(toValue(type)));
    const isCompostelaType = computed(() => [
        TypeImage.BANNER_HOME,
        TypeImage.BANNER_STORIES,
        TypeImage.GAME_THUMB,
        TypeImage.GAME_ROW_THUMB,
        TypeImage.GAME_HOME_THUMB,
        TypeImage.GAME_RECENT,
        TypeImage.GAME_TOURNAMENT,
    ].includes(toValue(type)));

    const isGameThumb = computed(() => [
        TypeImage.GAME_THUMB,
        TypeImage.GAME_ROW_THUMB,
        TypeImage.GAME_HOME_THUMB,
        TypeImage.GAME_RECENT,
        TypeImage.GAME_TOURNAMENT,
    ].includes(toValue(type)));

    function setQueryParams(url: string, queryParams: ConfigImgType): string {
        const params = new URLSearchParams();

        Object.entries(queryParams).forEach(([ key, value ]) => {
            if (value !== undefined) {
                params.append(key, value.toString());
            }
        });

        return `${url}?${params.toString()}`;
    }

    function setGameImageQueryParams(url: string, queryParams: ConfigImgType | GameImageQuery): string {
        const params = new URLSearchParams();

        Object.entries(queryParams).forEach(([ key, value ]) => {
            if (value !== undefined) {
                params.append(key, value.toString());
            }
        });

        const queryValue = toValue(query);

        if (queryValue) {
            Object.entries(queryValue).forEach(([ key, value ]) => {
                if (value !== undefined) {
                    params.append(key, value.toString());
                }
            });
        }

        return `${url}?${params.toString()}`;
    }

    function getQueryForResize(breakPoint: BreakPoints, dpr = 1): ConfigImgType {
        const config = configImgByType.value.sizeByBreakPoint[breakPoint];

        return {
            ...config,
            [IMG_QUERY_DPR]: dpr * (isCompostelaType.value ? 0.5 : 1),
        };
    }

    function getGameImageQueryForResize(breakPoint: BreakPoints, dpr = 1): ConfigImgType {
        const config = configImgByType.value.sizeByBreakPoint[breakPoint];

        const dimension = (config as IConfigImgCompostela)[IMG_QUERY_DIMENSION];

        return {
            ...config,
            [IMG_QUERY_DIMENSION]: dimension ? Number(dimension) * dpr : dimension,
        };
    }

    function getImageUrl(breakpoint: BreakPoints, dpr = 1) {
        const resizeQuery = getQueryForResize(breakpoint, dpr);
        const pathPrefix = isCompostelaType.value ? toValue(src) : `/fe-resize/${toValue(type)}${toValue(src)}`;

        return setQueryParams(pathPrefix, resizeQuery);
    }

    function getGameImageUrl(breakpoint: BreakPoints, dpr = 1) {
        const resizeQuery = getGameImageQueryForResize(breakpoint, dpr);

        return setGameImageQueryParams(toValue(src), resizeQuery);
    }

    function setSrcset(viewport: string) {
        if (envStore.isMockerMode) {
            return `${toValue(src)} 1x, ${toValue(src)} 2x`;
        }
        const breakPoint = (Object.keys(configImgByType.value.sizeByBreakPoint) as unknown as BreakPoints[])
            .find((item) => Number(item) >= Number(viewport));

        if (breakPoint) {
            if (isGameThumb.value) {
                const urlImg = getGameImageUrl(breakPoint);
                const urlImgRetina = getGameImageUrl(breakPoint, 2);
                return `${urlImg} 1x, ${urlImgRetina} 2x`;
            }
            const urlImg = getImageUrl(breakPoint);
            const urlImgRetina = getImageUrl(breakPoint, 2);
            return `${urlImg} 1x, ${urlImgRetina} 2x`;
        }
    }

    const sourceElements = computed(() => Object.keys(configImgByType.value.sizeByBreakPoint)
        .sort((a, b) => Number(b) - Number(a))
        .map((breakpoint) => ({
            srcset: setSrcset(breakpoint),
            media: `(min-width: ${breakpoint}px)`,
        })));

    const imageSrc = computed(() => {
        const breakPoints = Object.keys(configImgByType.value.sizeByBreakPoint)
            .sort((a, b) => Number(a) - Number(b));
        const breakPoint = Number(breakPoints[0]);

        if (isGameThumb.value) {
            return getGameImageUrl(breakPoint);
        }

        return getImageUrl(breakPoint);
    });

    return {
        imageSrc,
        sourceElements,
        setGameImageQueryParams,
    };
}
