type CanonicalUrls = {
    [domain in string]?: {
        [path in string]?: string
    }
}

export const CUSTOM_CANONICAL_URLS_BY_HOST: CanonicalUrls = {
    "winspirit1.com": {
        // value must be empty string
        "/casino": "",
    },
};
