export enum PerformanceKey {
    GAME_START = "game_start",
    BETTING_START = "betting_start",
    ESPORT_START = "esport_start",
}

export enum DigitainFrameType {
    BETTING_MOBILE = "betting_mobile",
    BETTING_DESKTOP = "betting_desktop",
    ESPORT = "esport",
}
