<script setup lang="ts">
import { componentConfigByTheme, PromoBannerThemes } from "@components/PromoBanner/config";
import { BonusType } from "@controllers/services/bonuses/types";
import { computed } from "vue";
import image from "./images/welcome-badge-sport.png";
import imageRetina from "./images/welcome-badge-sport@2x.png";
import { BETTING_AMOUNT_MAP_BANNER } from "@config/promoBanner";
import { usePromoBanner } from "@controllers/services/promo-banner";
import { useI18n } from "vue-i18n";

interface IProps {
    theme?: PromoBannerThemes;
}
const props = withDefaults(defineProps<IProps>(), {
    theme: PromoBannerThemes.primary,
});

const i18n = useI18n();

const { bannerAmounts } = usePromoBanner();
const data = computed(() => {
    return {
        image,
        imageRetina,
        type: BonusType.SPORT,
        title: i18n.t("MODAL_THANKS.WELCOME_BONUS_BETTING_TITLE", bannerAmounts(BETTING_AMOUNT_MAP_BANNER)),
        caption: i18n.t("MODAL_THANKS.WELCOME_BONUS_BETTING_TEXT"),
    };
});
const theme = computed(() => {
    if (props.theme === PromoBannerThemes.secondary) {
        return PromoBannerThemes.secondary;
    }
    return PromoBannerThemes.primary;
});
</script>

<template>
    <component :is="componentConfigByTheme[theme]" :data="data">
        <slot />
    </component>
</template>
