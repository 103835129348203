<script setup lang="ts">
import FeText, * as FeTextConfig from "@ui-kit/FeText/index.vue";
import { FeTooltipPosition, FeTooltipThemes } from "./config";
import { computed } from "vue";

interface FeTooltipProps {
    theme?: FeTooltipThemes
    fullWidth?: boolean
    position?: FeTooltipPosition
    showOnHover?: boolean
    show?: boolean
}

defineOptions({
    name: "FeTooltip",
});

const props = withDefaults(defineProps<FeTooltipProps>(), {
    fullWidth: false,
    showOnHover: true,
    show: false,
    position: FeTooltipPosition.top,
    theme: FeTooltipThemes.danger,
});

const classes = computed(() => {
    return {
        [`fe-tooltip--${ props.theme }`]: props.theme,
        [`fe-tooltip--position-${ props.position }`]: props.position,
        "fe-tooltip--hover": props.showOnHover,
        "fe-tooltip--show": props.show,
        "fe-tooltip--full-width": props.fullWidth,
    };
});
</script>

<template>
    <div class="fe-tooltip" :class="classes">
        <div class="fe-tooltip__activator">
            <slot name="activator" />
        </div>
        <div class="fe-tooltip__body">
            <div class="fe-tooltip__content">
                <FeText
                    :theme="FeTextConfig.themes.White"
                    :size="FeTextConfig.size.Small"
                >
                    <slot />
                </FeText>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
