import type { SelectorOrElementGetter } from "../types";

export function findElement(element: SelectorOrElementGetter) {
    return typeof element === "string"
        ? document.querySelector(element) as HTMLElement
        : element();
}

export function createFocusedElement(parent: HTMLElement, reference: HTMLElement, extraClassName?: string) {
    const rect = reference.getBoundingClientRect();
    const copy = reference.cloneNode(true) as HTMLElement;
    const container = document.createElement("div");

    container.style.pointerEvents = "none";
    container.style.position = "absolute";
    container.style.top = `${rect.top + window.scrollY}px`;
    container.style.left = `${rect.left}px`;

    copy.style.boxSizing = "border-box";
    copy.style.width = `${rect.width}px`;
    copy.style.height = `${rect.height}px`;

    if (extraClassName) {
        container.className = extraClassName;
    }

    container.append(copy);
    parent.append(container);
    return container;
}
