import type { IDisableLimitDTO, ILimitResource, IManageLimitDTO } from "@api";
import { limitsAPI } from "@api";
import { LIMITS_SUBTYPE_ORDER, LimitType } from "@controllers/services/limits";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { defineStore } from "pinia";
import { ref } from "vue";

function sortLimitsBySubtype <T extends LimitType = LimitType>(limits: Array<ILimitResource<T>>) {
    return [ ...limits ].sort((limitA, limitB) => {
        return LIMITS_SUBTYPE_ORDER[limitA.subType] - LIMITS_SUBTYPE_ORDER[limitB.subType];
    });
}

interface ILimitsByType {
    [LimitType.DEPOSIT]: Array<ILimitResource<LimitType.DEPOSIT>>;
    [LimitType.LOSS]: Array<ILimitResource<LimitType.LOSS>>;
}

export const useLimitsStore = defineStore("limits", () => {
    const limits = ref<ILimitsByType>({
        [LimitType.DEPOSIT]: [],
        [LimitType.LOSS]: [],
    });
    const selfExclusionLimit = ref<ILimitResource<LimitType.SELF_EXCLUSION>>();

    async function loadLimits() {
        await Promise.all([
            loadLossLimits(),
            loadDepositLimits(),
            loadSelfExclusionLimit(),
        ]);
    }
    async function loadLimitsByType(type: LimitType) {
        if (type === LimitType.DEPOSIT) {
            await loadDepositLimits();
        } else if (type === LimitType.LOSS) {
            await loadLossLimits();
        }
    }
    const loadDepositLimits = promiseMemoizer(async () => {
        limits.value[LimitType.DEPOSIT] = sortLimitsBySubtype(await limitsAPI.getActiveLimits(LimitType.DEPOSIT));
    });
    const loadLossLimits = promiseMemoizer(async () => {
        limits.value[LimitType.LOSS] = sortLimitsBySubtype(await limitsAPI.getActiveLimits(LimitType.LOSS));
    });
    const loadSelfExclusionLimit = promiseMemoizer(async () => {
        const [ limit ] = await limitsAPI.getActiveLimits(LimitType.SELF_EXCLUSION);
        if (limit) {
            selfExclusionLimit.value = limit;
        }
    });

    async function createLimit(params: IManageLimitDTO) {
        const { data, error } = await limitsAPI.createLimit(params);
        if (data) {
            loadLimitsByType(params.type);
            return;
        }
        throw error;
    }
    async function updateLimit(params: IManageLimitDTO) {
        const { data, error } = await limitsAPI.updateLimit(params);
        if (data) {
            loadLimitsByType(params.type);
            return;
        }
        throw error;
    }
    async function disableLimit(params: IDisableLimitDTO) {
        const { data, error } = await limitsAPI.disableLimit(params);
        if (data) {
            loadLimitsByType(params.type);
            return;
        }
        throw error;
    }

    return {
        limits,
        selfExclusionLimit,
        loadLimitsByType,
        loadSelfExclusionLimit,

        loadLimits,
        createLimit,
        updateLimit,
        disableLimit,
    };
});
