import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";

enum LivespinsMessageType {
    GAME_INIT = "LIVESPINS.game-play-events.game-init",
    GAME_STARTED = "LIVESPINS.game-play-events.game-started",
}
type LivespinsGameInitMessage = {
    type: LivespinsMessageType.GAME_INIT;
}
type LivespinsGameStartedMessage = {
    type: LivespinsMessageType.GAME_STARTED;
    value?: {
        sessionId?: string;
    };
}
type LivespinsMessage = LivespinsGameInitMessage | LivespinsGameStartedMessage;

enum LivespinsTrackerDataType {
    LOBBY = "lobby",
    STREAM = "stream",
}
type LivespinsTrackerStream = {
    type: LivespinsTrackerDataType.STREAM;
    sessionId: string;
}
type LivespinsTrackerLobby = {
    type: LivespinsTrackerDataType.LOBBY;
}
export type LivespinsTrackerData = LivespinsTrackerLobby | LivespinsTrackerStream;

export function useLivespinsGameStreamTracker() {
    const router = useRouter();
    const streamState = ref<LivespinsTrackerData | null>(null);

    function messageHandler(event: MessageEvent) {
        const data = event.data as LivespinsMessage;
        if (data?.type === LivespinsMessageType.GAME_INIT) {
            streamState.value = {
                type: LivespinsTrackerDataType.LOBBY,
            };
        } else if (data?.type === LivespinsMessageType.GAME_STARTED && data.value?.sessionId) {
            streamState.value = {
                type: LivespinsTrackerDataType.STREAM,
                sessionId: data.value.sessionId,
            };
        }
    }

    watch(streamState, (value) => {
        if (value) {
            router.replace({
                name: routeNames.gameLivespins,
                params: {
                    sessionId: value.type === LivespinsTrackerDataType.STREAM
                        ? value.sessionId
                        : "",
                },
            });
        }
    });

    onMounted(() => {
        const route = router.currentRoute.value;
        if (route.name === routeNames.gameLivespins) {
            const sessionId = route.params.sessionId;
            if (sessionId && typeof sessionId === "string") {
                streamState.value = {
                    type: LivespinsTrackerDataType.STREAM,
                    sessionId: sessionId,
                };
            } else {
                streamState.value = {
                    type: LivespinsTrackerDataType.LOBBY,
                };
            }
            window.addEventListener("message", messageHandler);
        }
    });
    onBeforeUnmount(() => {
        window.removeEventListener("message", messageHandler);
    });
}
