<template>
    <div class="dropdown-notifications">
        <FeScroll
            v-if="notifications.length"
        >
            <div class="dropdown-notifications__notice">
                <FeNotice
                    v-for="(item, index) in notifications"
                    :key="item.id"
                    :notice="item"
                    new-style
                    :class="{ 'fe-notice--last-child': index + 1 === notifications.length }"
                />
            </div>
        </FeScroll>
        <div
            v-else
            class="dropdown-notifications__empty"
            padding-s
            v-html="$t('HEADER.EMPTY_NOTIFICATIONS')"
        />
    </div>
</template>

<script>
import FeNotice from "@ui-kit/FeNotice/index.vue";
import FeScroll from "@ui-kit/FeScroll/FeScroll.vue";

export default {
    name: "DropdownNotifications",

    components: {
        FeNotice,
        FeScroll,
    },

    props: {
        notifications: {
            type: Array,
            defautl: [],
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
