<script setup lang="ts">
import type { FieldRef, IPhoneFieldProps, ServerInputType } from "@ui-kit/FeFields/types";
import { computed, onMounted, onServerPrefetch, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useBaseField } from "@ui-kit/FeFields/useBaseField";
import { useUserInfo } from "@store/userInfo";
import { useCountriesData } from "@store/countriesData";
import { ServerInput } from "@mixins/ServerInput";
import { getCountryByPhone } from "@mixins/phoneMixin";

import CountriesField from "@ui-kit/FeFields/Countries.vue";
import FieldValidator from "@components/FieldValidator/FieldValidator.vue";
import { FeTooltip, FeTooltipPosition } from "@ui-kit/FeTooltip";
import FeInput from "@ui-kit/FeInput/FeInput.vue";
import { FeIconConfig } from "@theme/configs/icons";
import FeIcon from "@ui-kit/FeIcon/index.vue";

interface IPhoneFieldEmits {
    (event: "focus", value: FocusEvent): void
    (event: "input", payload: Record<string, string>): void
    (event: "update:isValid", value: boolean | number): void
}

const codeListWithExtraNumber: Record<string, number> = { nz: 1, de: 1, at: 2 };
const countryPhonePrefixes: Record<string, string[]> = {
    au: [ "0" ],
    de: [ "0", "49" ],
};
defineOptions({
    name: "PhoneField",
});

const props = withDefaults(defineProps<IPhoneFieldProps>(), {
    phoneValue: "",
    positionTooltip: FeTooltipPosition.bottom,
    isValid: false,
    autofocus: true,
    required: false,
});

const emit = defineEmits<IPhoneFieldEmits>();

const i18n = useI18n();
const userInfoStore = useUserInfo();
const phoneCodeStore = useCountriesData();
const countryByPhone = getCountryByPhone(userInfoStore.userPhone);

const fieldRef = ref<FieldRef>(null);
const { rules, inputValue } = useBaseField(props, fieldRef.value);

const currentValue = ref("");
const phoneMask = ref<string>();
const dialCode = ref<string | null>(null);
const example = ref<string | null>(null);
const country = ref<ServerInputType>(null);

const guessCountryCode = computed(() => {
    const phone = inputValue.value.replace(/[^0-9]/g, "");

    return getCountryByPhone(phone);
});
const countryCode = computed(() => {
    let _country = null;
    const iso2 = guessCountryCode.value;
    _country = iso2 && phoneCodeStore.phoneCodes[iso2];
    if (_country) {
        return _country;
    }
    return null;
});
const isLocalPhone = computed(() => {
    if (!countryCode.value?.code) {
        return false;
    }

    const countryCodes = countryPhonePrefixes[countryCode.value.code];

    if (!countryCodes) {
        return false;
    }

    return countryCodes.some((code) => currentValue.value.startsWith(code));
});

const countryCodeValidLength = computed(() => {
    if (!countryCode.value) {
        return 0;
    }
    const phone = countryCode.value?.example.replace(/\D/g, "");
    if (isLocalPhone.value) {
        const prefix = countryPhonePrefixes[countryCode.value?.code].find((code) => {
            return currentValue.value.startsWith(code);
        });

        if (prefix) {
            return phone.length + prefix.length;
        }
    }

    return phone.length;
});
const isValidPhone = computed(() => {
    if (currentValue.value) {
        const phone = currentValue.value.replace(/[^0-9]/g, "");

        let extendedMask: number | boolean = false;
        const phoneLength = phone.length;
        if (countryCode.value) {
            extendedMask = codeListWithExtraNumber[countryCode.value.code] &&
                (phoneLength === countryCodeValidLength.value ||
                    phoneLength >= (countryCodeValidLength.value - codeListWithExtraNumber[countryCode.value.code]));
        }

        return phoneLength === countryCodeValidLength.value || extendedMask;
    }

    return false;
});

const placeholderExample = computed(() => {
    return dialCode.value ? example.value?.replace(/\d/g, "X") : props.placeholder;
});

const setPhoneMask = (arg?: string) => {
    if (!arg) {
        return;
    }

    const _country = arg.toLowerCase();
    const countryExample = phoneCodeStore.phoneCodes[_country]?.example;

    example.value = countryExample || "";

    if (isLocalPhone.value && countryCode.value?.code) {
        const prefix = countryPhonePrefixes[countryCode.value.code].find((code) => currentValue.value.startsWith(code));
        example.value = `${countryExample}${prefix}`;
    }

    dialCode.value = String(phoneCodeStore.phoneCodes[_country]?.dialCode) || "";

    const phone = example.value;

    phoneMask.value = phone?.replace(/[0-9]/g, "#");
};
const inputChange = (value: string) => {
    let newValue = value;

    if (isLocalPhone.value && countryCode.value?.code) {
        const prefix = countryPhonePrefixes[countryCode.value.code].find((code) => currentValue.value.startsWith(code));
        if (prefix) {
            newValue = value.replace(new RegExp(`^${prefix}`), "");
        }
    }

    if (newValue) {
        const number = `${dialCode.value}${newValue}`.replace(/\D/g, "");
        currentValue.value = value;

        emit("input", {
            type: "phone",
            value: number,
        });

        inputValue.value = number;

        emit("update:isValid", isValidPhone.value);
    }
};
const onFocusHandler = (event: FocusEvent) => emit("focus", event);
const initData = async() => {
    await phoneCodeStore.loadCountriesData();

    const _country = inputValue.value ? getCountryByPhone(inputValue.value) : userInfoStore.userCountryCode;

    if (_country) {
        dialCode.value = String(phoneCodeStore.phoneCodes[_country.toLowerCase()]?.dialCode) || "";

        setPhoneMask(_country);
    }

    currentValue.value = "";

    if (inputValue.value) {
        if (dialCode.value) {
            currentValue.value = inputValue.value.replace(dialCode.value, "");
        } else {
            currentValue.value = inputValue.value;
        }
    } else {
        inputValue.value = String(dialCode.value);
    }
};

watch(() => isValidPhone.value, () => emit("update:isValid", isValidPhone.value));
watch(() => isLocalPhone.value, () => setPhoneMask(countryCode.value?.code));
watch(() => guessCountryCode.value, (newCountry, oldCountry) => {
    if (newCountry && newCountry !== oldCountry) {
        setPhoneMask(newCountry);
    }
});
watch(() => country.value?.value, (newCountry, oldCountry) => {
    if (newCountry && newCountry !== oldCountry) {
        setPhoneMask(newCountry);
    }
});

onServerPrefetch(async() => {
    await initData();
});

onMounted(async() => {
    country.value = new ServerInput(countryByPhone && countryByPhone.toUpperCase() || userInfoStore.userCountryCode, []);
    if (!currentValue.value) {
        await initData();
    }
});
</script>

<template>
    <div class="phone-field">
        <CountriesField
            v-if="country"
            :input="country"
            :disabled="disabled"
            dial-code-mode
            :label="dialCode ? i18n.t('REGISTRATION.LABEL_DIAL_CODE_COUNTRY') : i18n.t('REGISTRATION.LABEL_COUNTRY')"
        />
        <FieldValidator
            ref="fieldRef"
            :name="label"
            :value="currentValue"
            :rules="rules"
            manual-validate
        >
            <template #default="{ valid, message, showError }">
                <FeTooltip
                    :position="positionTooltip"
                    :show-on-hover="false"
                    :show="showError"
                >
                    <template #activator>
                        <FeInput
                            v-bind="$attrs"
                            ref="input"
                            v-model="currentValue"
                            :error="!valid"
                            :mask="phoneMask"
                            :disabled="disabled"
                            :data-test="dataTest"
                            class="input__input"
                            type="tel"
                            :name="`phone-${fieldRef?.fieldName}`"
                            :placeholder="placeholderExample"
                            autocorrect="off"
                            autocapitalize="none"
                            :spellcheck="false"
                            :autofocus="autofocus"
                            inputmode="phone"
                            :label="label"
                            :required="required"
                            @update:model-value="inputChange"
                            @focus="onFocusHandler"
                        >
                            <template #right>
                                <FeIcon
                                    v-if="isValidPhone"
                                    class="phone-field__icon"
                                    :icon="FeIconConfig.check"
                                />
                            </template>
                        </FeInput>
                    </template>
                    {{ message }}
                </FeTooltip>
            </template>
        </FieldValidator>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.phone-field {
    display: grid;
    grid-template-columns: 8rem auto;
    grid-gap: 1rem;

    &__icon {
        width: 1.5rem;
        color: $color-primary-2;
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 3rem;
    }

    100% {
        stroke-dashoffset: 0
    }
}
</style>
