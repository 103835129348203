import { BonusActionType } from "@controllers/services/bonuses/actionService";

const REGISTRATION_EVENTS = {
    CLICK: "sign_up_click",
    OPEN: "sign_up_popup_view",
    CLOSE: "sign_up_close_popup",
    ACCOUNT_INFO: "sign_up_account_info",
    ERROR: "sign_up_error",
    INFO: {
        PERSONAL: "sign_up_personal_info",
        ADDRESS: "sign_up_address_info",
        SIMPLIFIED: "sign_up_simplified_info",
    },
    SUCCESS: {
        SING_UP: "sign_up_success",
        PERSONAL: "sign_up_2_step_success",
        ADDRESS: "sign_up_3_step_success",
        SIMPLIFIED: "sign_up_3_step_success",
    },
    CONFIRM: "sign_up_confirm_registration",
    CONFIRM_EMAIL: "confirm_button_header",
    LEAVE_NOW_MODAL_SING_UP: "sign_up_leave_popup",
};
const LOGIN_EVENTS = {
    CLICK: "log_in_click",
    OPEN: "log_in_popup_view",
    FILL_FORM: "log_in_fill_form",
    FORGOT_PASSWORD: "log_in_forgot_pass",
    SUBMIT: "log_in_form_button",
    ERROR: "log_in_error",
    SUCCESS: "log_in_success",
    RESTORE_PASSWORD: "restore_password",
};
const BANNER = {
    VIEW: "view_promotion",
    SELECT: "select_promotion",
};
const CASHBOX_EVENTS = {
    DEPOSIT: {
        OPEN: "deposit_popup_view",
        CLOSE: "deposit_close_popup",
        CLICK: "deposit_click",
        ERROR: "deposit_error",
        PAYMENT: "deposit_payment_method",
        AMOUNT: "deposit_amount",
        SELECT_GIFT: "deposit_select_gift",
        CONFIRM: "deposit_confirm_button_click",
    },
    WITHDRAW: {
        OPEN: "withdraw_popup_view",
        CLOSE: "withdraw_close_popup",
        CLICK: "withdraw_click",
        ERROR: "withdraw_error",
        PAYMENT: "withdraw_payment_method",
        AMOUNT: "withdraw_amount",
        CONFIRM: "withdraw_confirm_button_click",
    },
    DASHBOARD: {
        OPEN: "cashbox_popup_view",
        CLOSE: "cashbox_close_popup",
        CLICK: "cashbox_click",
    },
};
const BROWSER_INFORMER = {
    OPEN: "browser_informer_popup",
    CLOSE: "browser_informer_popup_close",
    COPY: "browser_informer_popup_copy",
    COPY_CLOSE: "browser_informer_popup_copy_close",
    PWA: "browser_informer_popup_pwa",
    PWA_CLOSE: "browser_informer_popup_pwa_close",
    INSTALL: "browser_informer_popup_install",
    INSTALL_CLOSE: "browser_informer_popup_install_close",
};
const WELCOME_BONUS = {
    OPEN: "welcome_{number}_dep_extra_bonus_popup",
    CLOSE: "welcome_{number}_dep_extra_bonus_close_popup",
    PROFILE_BONUS: "open_profile_bonus",
};
const USER_BONUS = {
    [BonusActionType.INSURANCE]: {
        REQUEST: "make request",
    },
};
function getWelcomeBonusEvent(name, number = 1) {
    return name.replace("{number}", number);
}
export {
    REGISTRATION_EVENTS,
    LOGIN_EVENTS,
    BANNER,
    CASHBOX_EVENTS,
    BROWSER_INFORMER,
    WELCOME_BONUS,
    USER_BONUS,
    getWelcomeBonusEvent,
};
