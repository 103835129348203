import { promiseMemoizer } from "@helpers/promiseHelpers";
import { SURVEY_SCRIPT_SRC } from "./config";

export enum ISurveyWidgetType {
    DEFAULT = "default",
    COMPACT = "compact",
}

export interface ISurveyWidgetInitParams {
    type: ISurveyWidgetType;
    delay: number;
    include_unsubscribe: boolean;
    survey_id: string;
    survey_collector_id: string,
    contact: {
        first_name: string;
        last_name: string;
        work_email: string;
        phone_number?: string;
    };
}

export interface ISurveyWidget {
    init(params: ISurveyWidgetInitParams): void;
}

declare const SurveyWidget: ISurveyWidget;

export const getSurveyWidgetAsync = promiseMemoizer<ISurveyWidget>(() => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = SURVEY_SCRIPT_SRC;
        script.async = true;
        script.onload = () => {
            resolve(SurveyWidget);
        };
        document.body.appendChild(script);
    });
});
