import { VerificationsStatus } from "@api";
import { useI18n } from "vue-i18n";
import { useUserInfo } from "@store/userInfo";
import { FeIconConfig } from "@theme/configs/icons";
import { v4 as uuid } from "uuid";
import { computed } from "vue";

export interface IVerificationCard {
    id: string;
    icon: string;
    status: VerificationsStatus;
    title: string;
    description?: string;
}
export enum VerificationCardType {
    IDENTITY = "Identity",
    PAYMENT = "Payment",
    ADDRESS = "Address"
}

enum VerificationCardConfig {
    IDENTITY = "IDENTITY",
    PAYMENT = "PAYMENT",
    ADDRESS = "ADDRESS",
}

interface IStatusDescription {
    description: string;
}

interface ICardStatusConfig {
    [VerificationsStatus.INITIAL]: IStatusDescription;
    [VerificationsStatus.PENDING]: IStatusDescription;
    [VerificationsStatus.APPROVED]: IStatusDescription;
    [VerificationsStatus.RECHECK]: IStatusDescription;
    [VerificationsStatus.REJECTED]: IStatusDescription;
}

interface ICardConfig {
    [VerificationCardType.IDENTITY]: ICardStatusConfig;
    [VerificationCardType.PAYMENT]: ICardStatusConfig;
    [VerificationCardType.ADDRESS]?: ICardStatusConfig;
}

export function useVerificationService() {
    const i18n = useI18n();
    const userInfoStore = useUserInfo();

    function createStatusConfig(type: VerificationCardConfig): ICardStatusConfig {
        return {
            [VerificationsStatus.INITIAL]: {
                description: i18n.t(`VERIFICATION.${type}.DESCRIPTION_STATUS_INITIAL`),
            },
            [VerificationsStatus.PENDING]: {
                description: i18n.t(`VERIFICATION.${type}.DESCRIPTION_STATUS_PENDING`),
            },
            [VerificationsStatus.APPROVED]: {
                description: i18n.t(`VERIFICATION.${type}.DESCRIPTION_STATUS_APPROVED`),
            },
            [VerificationsStatus.RECHECK]: {
                description: i18n.t(`VERIFICATION.${type}.DESCRIPTION_STATUS_RECHECK`),
            },
            [VerificationsStatus.REJECTED]: {
                description: i18n.t(`VERIFICATION.${type}.DESCRIPTION_STATUS_REJECTED`),
            },
        };
    }

    const cardConfig = computed<ICardConfig>(() => ({
        [VerificationCardType.IDENTITY]: createStatusConfig(VerificationCardConfig.IDENTITY),
        [VerificationCardType.PAYMENT]: createStatusConfig(VerificationCardConfig.PAYMENT),
    }));
    function computeStatus(type: VerificationCardType): VerificationsStatus {
        if (type === VerificationCardType.IDENTITY) {
            if (userInfoStore.userInfo.verification.verificationStatus === VerificationsStatus.IN_PROGRESS) {
                return VerificationsStatus.INITIAL;
            }
            return userInfoStore.userInfo.verification.verificationStatus;
        }

        if (type !== VerificationCardType.PAYMENT) {
            return VerificationsStatus.INITIAL;
        }

        const payments = userInfoStore.userInfo.verification?.paymentMethods || [];
        if (payments.length === 0) {
            return VerificationsStatus.INITIAL;
        }

        if (payments.every((pay) => pay.verificationStatus === VerificationsStatus.APPROVED)) {
            return VerificationsStatus.APPROVED;
        }
        if (payments.some((pay) => pay.verificationStatus === VerificationsStatus.PENDING)) {
            return VerificationsStatus.PENDING;
        }
        if (payments.some((pay) => pay.verificationStatus === VerificationsStatus.RECHECK ||
            pay.verificationStatus === VerificationsStatus.REJECTED)) {
            return VerificationsStatus.REJECTED;
        }
        return VerificationsStatus.INITIAL;
    }

    function generateCardItem(type: VerificationCardType): IVerificationCard {
        let icon = FeIconConfig.user;
        let title = i18n.t("VERIFICATION.IDENTITY.TITLE");

        if (type === VerificationCardType.PAYMENT) {
            icon = FeIconConfig.paymentCard;
            title = i18n.t("VERIFICATION.PAYMENT.TITLE");
        }

        const status = computeStatus(type);
        // @ts-expect-error Property [VerificationsStatus.IN_PROGRESS] does not exist on type ICardStatusConfig
        const description = cardConfig.value[type]?.[status]?.description;

        return {
            id: uuid(),
            icon,
            status,
            title,
            description,
        };
    }

    function generateCardList(): IVerificationCard[] {
        return [ VerificationCardType.IDENTITY, VerificationCardType.PAYMENT ].map((type) => generateCardItem(type));
    }

    function checkVerifyPassed(cards: IVerificationCard[]): boolean {
        const identityCard = cards[0];
        const paymentsCard = cards[1];

        if (identityCard.status !== VerificationsStatus.APPROVED) {
            return false;
        }

        return userInfoStore.userInfo.verification?.paymentMethods?.length === 0 ||
            paymentsCard.status === VerificationsStatus.APPROVED;
    }

    return {
        cards: computed(() => generateCardList()),
        paymentMethods: computed(() => userInfoStore.userInfo.verification?.paymentMethods),
        checkVerifyPassed,
    };
}
