import type { RouteLocationNormalized } from "vue-router";
import type { Pinia } from "pinia";
import log from "@front/core/controllers/LoggerController";
import { useServerStore } from "@store/server";

type ResultData = {
    success: boolean;
    result?: unknown;
    error?: unknown;
};

const toResultObject = async(promise?: Promise<unknown>): Promise<ResultData> => {
    const _promise = promise ? promise : new Promise<void>((resolve) => resolve());

    try {
        const result = await _promise;
        return { success: true, result };
    } catch (error) {
        return { success: false, error };
    }
};

export const promiseAll = async(promises: Array<Promise<unknown>>) => {
    return Promise.all(promises.map(toResultObject))
        .then((values) => {
            for (let i = 0; i < values.length; ++i) {
                if (!values[i].success) {
                    log.error("PROMISE_ERROR", values[i].error);
                }
            }
        })
        .catch((error) => log.error("PROMISE_ERROR", error));
};

export const loadStartData = (route: RouteLocationNormalized, pinia?: Pinia) => {
    const { fetchServerData } = useServerStore(pinia);

    return fetchServerData(route);
};
