<script setup lang="ts">
import { BonusType } from "@controllers/services/bonuses/types";
import { routeNames } from "@router/routeNames";
import { ref } from "vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { usePromoBanner } from "@controllers/services/promo-banner";
import { useRouter } from "vue-router";

interface IProps {
    showCloseIcon?: boolean;
    data: {
        image: string;
        imageRetina?: string;
        title: string;
        caption: string;
        type: BonusType;
    };
}
const props = withDefaults(defineProps<IProps>(), {
    showCloseIcon: false,
});

interface IEmits {
    (e: "close"): void;
    (e: "select"): void;
}
const emit = defineEmits<IEmits>();

const router = useRouter();
const hidden = ref(false);
const { setCurrentPromoBanner } = usePromoBanner();

function closeBanner() {
    emit("close");
    hidden.value = true;
}

function clickHandler() {
    if (props.showCloseIcon) {
        setCurrentPromoBanner(props.data.type);
        router.push({ name: routeNames.registration });
        hidden.value = true;
    } else {
        emit("select");
    }
}
</script>

<template>
    <div
        v-if="!hidden"
        class="promo-banner"
        :class="{'promo-banner--betting': data.type === BonusType.SPORT}"
        @click="clickHandler"
    >
        <FeImage :src="data.image" :src-retina="data.imageRetina" :alt="data.title" />
        <div class="promo-banner__body">
            <div class="promo-banner__subtitle">
                {{ data.caption }}
            </div>
            <div class="promo-banner__title" v-html="data.title" />
        </div>
        <FeIcon
            v-if="showCloseIcon"
            class="promo-banner__close"
            :icon="FeIconConfig.close"
            @click.stop="closeBanner"
        />
        <slot />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.promo-banner {
    display: flex;
    position: relative;
    padding: 1rem;
    align-items: center;
    background: $color-gradient-4;
    width: 100%;
    max-width: 30rem;
    box-sizing: border-box;
    color: $color-text-alt;
    border-radius: $border-radius-S;
    overflow: hidden;
    @include font-size--Body($font-weight--bold);

    &--betting {
        background: $color-gradient-1;
    }

    @include media(XS) {
        min-width: 20rem;
    }

    @include media(S) {
        min-width: 21rem;
    }

    &__body {
        z-index: 1;
    }

    &__subtitle {
        @include font-size--Caption2;
        color: $color-additional-1;
    }


    img {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 0.5rem;
    }

    .auth & {
        @media screen and (max-height: 720px) {
            display: none;
        }
    }

    &__close {
        margin-left: auto;
        min-width: 1.5rem;
        padding-left: 0.5rem;
    }
}

</style>
