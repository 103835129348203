export enum LivespinsType {
    LIVE = "Stream",
    SCHEDULED = "ScheduledStream",
}
export const livespinsBaseData = {
    slug: "livespins",
    title: "Livespins",
    name: "livespins",
};
export interface IPlayURLParameters {
    authToken: string;
    backURL?: string;
    language?: string;
    sessionId: string;
    shareURL?: string;
    streamEndRedirect?: boolean;
    tenantId?: string;
}
export interface ILivespinsStream {
    accountId: string;
    "content-language": string;
    currentGame: string;
    currentGameTitle: string;
    description: string;
    gameStreamId: string;
    isSingleStream: boolean;
    isTesting: boolean;
    language: string;
    locale: string;
    maxPlayers: unknown;
    metadata: string;
    name: string;
    order: number;
    sessionId: string;
    startTimestamp: number;
    streamEventAccentColor: string | null;
    streamEventImage: string | null;
    streamerId: string;
    streamerImage: string | null;
    streamerNameImage: string | null;
    streamerNickname: string;
    streamerStreamId: string;
    thumbnail: string;
    totalPlayersInBetBehind: number;
    type: LivespinsType;
    viewers: number;
}

export interface ILivespinsScheduledStream {
    "content-language": string;
    date: string;
    id: string;
    language: string;
    locale: string;
    metadata: string;
    scheduledStartTime: number;
    streamEventAccentColor: string | null;
    streamEventImage: string | null;
    streamer: string;
    streamerId: string;
    streamerImage: string | null;
    streamerNameImage: string | null;
    streamerNickname: string;
    time: string;
    type: LivespinsType;
}

export interface ILivespinsServerConfig {
    api: string;
    ui?: string;
}
export interface ILivespinsStaticConfigurationParameters {
    serverConfig?: ILivespinsServerConfig;
    tenant: string;
}
export interface ILivespinsVariableConfigurationParameters {
    debug?: boolean;
    language?: string;
}
export interface ILivespinsConfiguration {
    getDebug(): boolean;
    getLanguage(): string;
    getServerConfig(): ILivespinsServerConfig;
    getTenant(): string;
}
export interface ILivespinsConvertors {
    toLobbyURL(lobbyURLParameters: Omit<IPlayURLParameters, "sessionId"> & { openStream?: unknown }): string;
    toPlayURL(playURLParameters: IPlayURLParameters): string;
}
export interface ILivespinsClient {
    getScheduledStreams(): ILivespinsScheduledStream[];
    getStreams(): ILivespinsStream[];
    onUpdate(updateCallback: () => unknown): CallableFunction;
}
export interface ILivespinsSDK {
    config: ILivespinsConfiguration;
    convert: ILivespinsConvertors;
    version: string;
    documentation: string;
    Client(): Promise<ILivespinsClient>;
    disconnect(): Promise<void>;
    isConnected(): boolean;
    reconnect(): Promise<void>;
    updateConfig(configParams: ILivespinsVariableConfigurationParameters): void;
}

export interface ILivespinsCard {
    id: string;
    thumbnail: string | null;
    streamerNickname: string;
    gameTitle: string | null;
    type: LivespinsType;
    language: string;
    slug: string | null;
    startAt: number | null;
    sessionId: string | null;
    streamerImage: string | null;
    streamerNameImage: string | null;
}
