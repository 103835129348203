import { useModalsStore } from "@store/modals";
import { computed, type Ref } from "vue";
import type { ILimitResource } from "@api";
import { useLimitsFeatureService } from "./featureService";
import { LimitStatus, LimitSubtype, type LimitType } from "./types";
import { useLimitsStore } from "@store/limits";

export const LIMITS_WAITING_DISABLE_STATUSES = [
    LimitStatus.PROLONGATION_DISABLE,
    LimitStatus.WAITING_DISABLE,
];

export const LIMITS_WAITING_CHANGES_STATUSES = [
    LimitStatus.PROLONGATION_UPDATED,
    LimitStatus.WAITING_CHANGES,
    LimitStatus.WAITING_ACTIVATION,
];

export function useLimitItemStatusService(limit: Ref<ILimitResource>) {
    const isWaitingChanges = computed(() => {
        return LIMITS_WAITING_CHANGES_STATUSES.includes(limit.value.status);
    });
    const isWaitingDisable = computed(() => {
        return LIMITS_WAITING_DISABLE_STATUSES.includes(limit.value.status);
    });

    return {
        isWaitingChanges,
        isWaitingDisable,
    };
}

export function useLimitStatusService(type: LimitType) {
    const limitsStore = useLimitsStore();
    const { isLimitsEnabled } = useLimitsFeatureService();
    const gameLossLimitPopup = () => import("@modules/Popups/GameLossLimit/GameLossLimitPopup.vue");
    const modalsStore = useModalsStore();

    const existedLimits = computed(() => {
        // @ts-expect-error Element implicitly has an any type because expression of type LimitType can't be used to index type
        return limitsStore.limits[type].map(({ subType }) => subType);
    });

    const isCreateAvailable = computed(() => {
        return existedLimits.value.length < Object.keys(LimitSubtype).length;
    });

    const isLimitReached = computed(() => {
        // @ts-expect-error Element implicitly has an any type because expression of type LimitType can't be used to index type
        return limitsStore.limits[type].some((limit) => {
            return limit.used === limit.limit;
        });
    });

    async function checkIsLimitReached() {
        if (!isLimitsEnabled.value) {
            return false;
        }
        await limitsStore.loadLimitsByType(type);
        return isLimitReached.value;
    }

    async function showLossLimitPopup() {
        const module = await gameLossLimitPopup();
        modalsStore.showModal({
            component: module.default,
            name: "loss-limit",
            new: true,
        });
    }

    return {
        isCreateAvailable,
        isLimitReached,

        checkIsLimitReached,
        showLossLimitPopup,
    };
}
