import type { I18nGlobal } from "./types";

type UpdateUserLocale = ({ lang }: { lang: string }) => void

export async function loadLanguageAsync(lang: string, i18n: I18nGlobal, updateUserLocale: UpdateUserLocale = () => {}) {
    if (i18n.messages.value?.[lang]) {
        return Promise.resolve(() => updateUserLocale({ lang }));
    }

    // Если локализация ещё не была загружена
    return import(/* webpackChunkName: "lang-[request]" */ `@i18n/messages/${lang}.json`)
        .then((messages) => {
            i18n.setLocaleMessage(lang, messages.default);
            return updateUserLocale({ lang });
        });
}
