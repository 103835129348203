import log from "@front/core/controllers/LoggerController";

export const SCENARIO_LOCAL_STORAGE_KEY = "shown-onboarding-scenario";

export function checkScenarioShown(name: string) {
    const data = localStorage.getItem(SCENARIO_LOCAL_STORAGE_KEY);
    if (data) {
        try {
            const shownScenarios = JSON.parse(data) as string[];
            return shownScenarios.includes(name);
        } catch {
            log.error("check onboarding scenario shown parse error");
            localStorage.removeItem(SCENARIO_LOCAL_STORAGE_KEY);
        }
    }
    return false;
}

export function setScenarioShown(name: string) {
    let shownScenarios: string[] = [];
    const data = localStorage.getItem(SCENARIO_LOCAL_STORAGE_KEY);
    if (data) {
        try {
            const savedScenarios = JSON.parse(data) as string[];
            if (Array.isArray(savedScenarios)) {
                shownScenarios = savedScenarios;
                if (shownScenarios.includes(name)) {
                    return;
                }
            }
        } catch {
            log.error("set onboarding scenario parse error");
        }
    }

    localStorage.setItem(SCENARIO_LOCAL_STORAGE_KEY, JSON.stringify([ ...shownScenarios, name ]));
}
