import type { IStaticPagesItemResource } from "@api";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMultiLang } from "@store/multilang";
import { useStaticPagesStore } from "@store/staticPages";
import config from "@theme/configs/config";
import { ALLOWED_LOCALES_BY_HOST } from "@theme/configs/blogConfigs";

export interface IVisibleStaticPagesItemResource extends IStaticPagesItemResource {
    target?: string;
}

export function useVisibleStaticPages() {
    const staticPagesStore = useStaticPagesStore();
    const multiLangStore = useMultiLang();

    const i18n = useI18n();

    const hostname = ref("");
    onMounted(() => {
        hostname.value = window.location.hostname;
    });

    const visibleStaticPages = computed(() => {
        const pages: IVisibleStaticPagesItemResource[] = staticPagesStore.staticPages.filter(
            (page: IStaticPagesItemResource) => {
                return !page.hidden;
            },
        );

        pages.push({
            hidden: false,
            id: "generated_bonus_static_page",
            slug: "bonus",
            title: i18n.t("BONUS_PAGE.ABOUT_BONUSES"),
            url: config.links.bonusPage,
            pageType: "static",
        });

        pages.push({
            hidden: false,
            id: "generated_vip_static_page",
            slug: "vip",
            title: i18n.t("VIP.TITLE"),
            url: "/vip",
            pageType: "static",
        });

        pages.push({
            hidden: false,
            id: "benefits-of-crypto",
            slug: "benefits-of-crypto",
            title: i18n.t("FOOTER.LINKS.CRYPTO"),
            url: "/benefits-of-crypto",
            pageType: "static",
        });

        if (ALLOWED_LOCALES_BY_HOST[hostname.value]?.includes(multiLangStore.userLocale)) {
            pages.push({
                hidden: false,
                id: "generated_blog_static_page",
                slug: "blog",
                title: i18n.t("FOOTER.LINKS.BLOG"),
                url: "/blog",
                pageType: "static",
                target: "_blank",
            });
        }

        return pages;
    });

    return {
        visibleStaticPages,
    };
}
