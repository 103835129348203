import Modal from "@modal";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { cashboxAPI } from "@api";
import { useUserInfo } from "@store/userInfo";

const emailMessageModal = () => import("@modules/Popups/Verification/emailModal.vue");
const verificationKYC = () => import("@modules/Popups/Verification/index.vue");

export default async function showVerificationModal() {
    if (getFeatureFlags().kyc) {
        const userInfoStore = useUserInfo();

        const flow = await cashboxAPI.loadUserPayoutFlow();
        Modal.close("modal-cashbox-withdraw");

        if (flow === "hard" && !userInfoStore.verificationStatusVerified) {
            const module = await verificationKYC();

            Modal.show({
                name: "modal-verification",
                component: module.default,
                new: true,
            });
        } else if (!userInfoStore.isVerifiedEmail) {
            const module = await emailMessageModal();

            Modal.show({
                name: "email-verify",
                component: module.default,
                new: true,
            });
        }
    }
}
