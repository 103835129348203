import { computed, watch } from "vue";
import { blockScroll, unblockScroll } from "../helpers/scrollHelpers";
import { useOnboardStore } from "../store/onboardStore";

export function useOnboardScrollBlocker() {
    const onboardStore = useOnboardStore();
    const isActive = computed(() => {
        return Boolean(onboardStore.currentScenario?.isActive && onboardStore.currentScenarioItem);
    });

    watch(isActive, (value) => {
        if (value) {
            blockScroll();
        } else {
            unblockScroll();
        }
    });

    if (isActive.value) {
        blockScroll();
    }
}
