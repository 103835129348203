import codes from "./countryCodes";

export function getCountryByPhone(phone: string) {
    if (!phone) {
        return null;
    }
    const phoneLength = phone.length;
    for (let i = phoneLength; i > 0; i--) {
        const phoneCode = phone.slice(0, i);
        const countryCode = codes[phoneCode];
        if (countryCode) {
            if (countryCode === "us") {
                return "ca";
            }
            return countryCode;
        }
    }
    return null;
}
