import type { Router } from "vue-router";
import type { Pinia } from "pinia";
import type { I18nGlobal } from "@i18n/types";
import { loadLanguageAsync } from "@i18n/loadLanguageAsync";
import { useMultiLang } from "@store/multilang";

export default function languageHook(router: Router, pinia: Pinia, i18n: I18nGlobal) {
    router.beforeEach(async (_to, _from, next) => {
        const multiLangStore = useMultiLang(pinia);
        try {
            await loadLanguageAsync(multiLangStore.userLocale, i18n);
            next();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    });
}
