<script setup lang="ts">
import { routeNames } from "@router/routeNames";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { iconsNavigation } from "@theme/configs/iconsNavigation";
import activeMenu from "@helpers/activeMenu";
import bus from "@bus";
import { useUserNotice } from "@store/userNotice";
import { useUserInfo } from "@store/userInfo";
import EnterBtns from "@components/EnterBtns/index.vue";
import PreRegTooltip from "@components/PreRegTooltip/index.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import { CASHBOX_DEPOSIT_STEP } from "@config/cashbox";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const userInfoStore = useUserInfo();
const userNoticeStore = useUserNotice();
const i18n = useI18n();

const list = computed(() => {
    return [
        {
            title: i18n.t("HEADER.CASINO"),
            icon: iconsNavigation.pokies,
            routeName: routeNames.casinoPage,
            dataTest: "bottom-menu-casino",
        },
        {
            title: i18n.t("HEADER.LIVE_SHORT"),
            icon: iconsNavigation.table,
            routeName: routeNames.livePage,
            dataTest: "bottom-menu-live",
        },
        {
            title: i18n.t("HEADER.BETS"),
            icon: iconsNavigation.bet,
            routeName: routeNames.bettingPage,
            dataTest: "bottom-menu-betting",
        },
        {
            title: i18n.t("HEADER.MORE"),
            icon: iconsNavigation.more,
            action: () => {
                openSideBar();
            },
            dataTest: "bottom-menu-more",
            counter: userNoticeStore.countNewNotification,
        },
    ];
});

function openSideBar() {
    bus.$emit("sidebar.open");
}

const noLoginView = ref(false);
const loginPending = ref(true);
onMounted(async () => {
    noLoginView.value = !await userInfoStore.isLoggedAsync();
    loginPending.value = false;
});
watch(() => userInfoStore.isLogged, (value) => {
    if (!loginPending.value) {
        noLoginView.value = !value;
    }
});

function openCashbox() {
    bus.$emit("open-cashbox", { location: "bottom-menu", step: CASHBOX_DEPOSIT_STEP });
}
</script>

<template>
    <div
        class="bottom-menu bottom-menu-new"
        :class="{ 'bottom-menu--no-login': noLoginView }"
    >
        <template v-if="!loginPending">
            <div class="bottom-menu-nav">
                <template v-for="item in list" :key="item.title">
                    <!-- @vue-expect-error incorrect types -->
                    <template v-if="!item.hide">
                        <div class="fe-relative" :class="item.routeName ? `bottom-menu-nav-item--${item.routeName}` : ''">
                            <!-- @vue-expect-error incorrect types -->
                            <div
                                v-if="item.action"
                                class="bottom-menu-nav-item"
                                :class="{ active: item.active }"
                                :data-test="item.dataTest"
                                @click.stop="item.action"
                            >
                                <FeIcon :icon="item.icon" />
                                {{ item.title }}
                                <span v-if="item.counter" class="bottom-menu-nav-item-counter">
                                    {{ item.counter }}
                                </span>
                            </div>
                            <!-- @vue-expect-error incorrect types -->
                            <FeLink
                                v-else
                                :to="{ name: item.routeName, params: item.routeParams || {} }"
                                :class="{ active: item.active }"
                            >
                                <!-- @vue-expect-error incorrect types -->
                                <template #default="{ navigate, isExactActive }">
                                    <div
                                        class="bottom-menu-nav-item"
                                        :class="activeMenu(item, isExactActive, $router.currentRoute)"
                                        @click="navigate"
                                    >
                                        <FeIcon :icon="item.icon" />
                                        {{ item.title }}
                                        <!-- @vue-expect-error incorrect types -->
                                        <span
                                            v-if="item.counter"
                                            class="bottom-menu-nav-item-counter"
                                            :class="{ active: item.active }"
                                        >
                                            <!-- @vue-expect-error incorrect types -->
                                            {{ item.counter }}
                                        </span>
                                    </div>
                                </template>
                            </FeLink>
                        </div>
                    </template>
                </template>
                <FeButton
                    v-if="userInfoStore.isLogged"
                    class="bottom-menu-nav-btn-cashbox"
                    :theme="FeButtonConfig.themes.PrimarySmall"
                    @click="openCashbox"
                >
                    {{ i18n.t("HEADER.CASHBOX") }}
                </FeButton>
            </div>
            <template v-if="!userInfoStore.isLogged">
                <EnterBtns
                    class="bottom-menu-enter-bnts"
                    location="bottomMenu"
                    gap-s
                />
                <PreRegTooltip />
            </template>
        </template>
    </div>
</template>

<style lang="scss" scoped>
@import "~@theme/styles";

.bottom-menu {
    height: 3.5rem;
    background: $header--background;
    backdrop-filter: blur(1.5rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100vw;
    padding-bottom: env(safe-area-inset-bottom);

    @include media(L) {
        display: none;
    }

    &--no-login {
        height: 7rem;
        align-content: flex-start;
        border-radius: $border-radius-M $border-radius-M 0 0;

        .bottom-menu-enter-bnts {
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &-nav {
        display: grid;
        grid-auto-flow: column;
        width: 100%;
        grid-gap: 0.25rem;
        justify-items: center;
        align-items: center;
        padding: 0.5rem 0;

        a {
            @include menu-hover;
        }

        &-item {

            @include font-size--SmallCaption($weight: $font-weight--bold);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            flex-grow: 1;
            cursor: pointer;
            width: 3rem;


            &-counter {
                @include pin;
                right: 0;
                top: 0;
                position: absolute;

                &.active {
                    @include pin-pulse;
                }
            }

            &--Betting {
                display: none;

                @include media(XS) {
                    display: block;
                }
            }
        }


    }
}

</style>
