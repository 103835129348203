import { defineStore } from "pinia";
import { ref } from "vue";

export const useLiveTable = defineStore("liveTable", () => {
    const liveTables = ref<Record<string, unknown>[]>([]);

    function setLiveTable(table: Record<string, unknown>) {
        if (liveTables.value.length) {
            liveTables.value = liveTables.value.filter((elem) => {
                return elem.tableId !== table.tableId;
            });
        }
        liveTables.value.push(table);
    }

    function getLiveTableById(id: string) {
        return liveTables.value.find((table) => {
            return table.tableId === id;
        });
    }

    return {
        liveTables,
        setLiveTable,
        getLiveTableById,
    };
});
