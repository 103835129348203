<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useFormatDate } from "@helpers/formatDate";
import { useEnvironment } from "@store/environment";

const { isMockerMode } = storeToRefs(useEnvironment());
const { timeUTC } = useFormatDate();

const date = ref(timeUTC());
const timerId = ref<ReturnType<typeof setTimeout>>();

const updateDate = () => {
    if (isMockerMode.value) {
        date.value = timeUTC(new Date("2000"));
        return;
    }

    timerId.value = setTimeout(() => {
        date.value = timeUTC();
        updateDate();
    }, 15000);
};

onMounted(() => {
    updateDate();
});

onBeforeUnmount(() => {
    clearTimeout(timerId.value);
});
</script>
<template>
    <div
        main-layout-width
        grid-full-width
        class="local-time local-time--sticky"
    >
        <div class="caption-2 local-time__content">
            {{ $t("CALENDAR.DATE_DISPLAY", { date }) }}
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.local-time {
    &--sticky{
        position: sticky;
        bottom: 0;
        right: 0;
        z-index: 9999;
        margin: -2rem auto 0;
        padding-top: 1rem;
        pointer-events: none;
    }
    &__content {
        color: $color-text-alt;
        background: $color-tertiary-5;
        backdrop-filter: blur(10px);
        width: max-content;
        padding: .5rem 1rem;
        border-radius: 1rem 1rem 0 0;
        margin-left: auto;
    }
}
</style>
