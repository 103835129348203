import { Currency } from "@api/resources/commonResources";
import type { AmountMap } from "@config/promoBanner";
import { currencyView } from "@controllers/services/currency-view";
import { useUserBalance } from "@store/userBalance";
import { BonusType } from "../bonuses/types";
const PROMO_BANNER_NAME_KEY = "currentPromoBanner";

export function usePromoBanner() {
    const userBalanceStore = useUserBalance();
    const userCurrency: Currency = userBalanceStore.userCurrency;

    function setCurrentPromoBanner(type: BonusType) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(PROMO_BANNER_NAME_KEY, type);
        }
    }

    function getCurrentPromoBanner(): string | null {
        if (typeof localStorage !== "undefined") {
            return localStorage.getItem(PROMO_BANNER_NAME_KEY);
        }
        return null;
    }

    function cleanCurrentPromoBanner(): void {
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem(PROMO_BANNER_NAME_KEY);
        }
    }

    function bannerAmounts(amountObj: AmountMap): Record<string, string> {
        const currencyArray = amountObj[userCurrency];

        if (!currencyArray) {
            return {};
        }

        return currencyArray.reduce<Record<string, string>>((acc, amount, index) => {
            const key = index === 0 ? "amount" : `amount${index}`;
            acc[key] = currencyView(amount);
            return acc;
        }, {});
    }

    return {
        setCurrentPromoBanner,
        getCurrentPromoBanner,
        cleanCurrentPromoBanner,
        bannerAmounts,
    };
}
