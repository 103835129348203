import { type App, watch } from "vue";
import type { Router } from "vue-router";
import * as Sentry from "@sentry/vue";
import type { Pinia } from "pinia";
import { useEnvironment } from "@store/environment";
import { useUserStatusService, USER_REGULAR_STATUS } from "@controllers/services/user";

export default function initSentry(app: App, router: Router, pinia: Pinia) {
    const envStore = useEnvironment(pinia);
    const { userVipStatus } = useUserStatusService(pinia);

    Sentry.init({
        app,
        dsn: "https://16fc91a1d15c17d5bfcf213a4631eb41@o1057380.ingest.sentry.io/4505782051274752",
        environment: envStore.environment,
        release: `fe-thor@${envStore.version}`,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.captureConsoleIntegration({
                levels: [ "error" ],
            }),
        ],
        tracePropagationTargets: [
            "localhost",
            "^https:\/\/.*winspirit.*\/json-api",
            "^https:\/\/.*winspirit.*\/public-api",
            "^https:\/\/.*winspirit.*\/api",
        ],
        tracesSampleRate: 1.0,
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
    });

    watch(userVipStatus, () => {
        Sentry.setTag("user_vip_status", userVipStatus.value || USER_REGULAR_STATUS);
        Sentry.setTag("user_is_vip", String(Boolean(userVipStatus.value)));
    });
}
