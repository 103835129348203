import type { Pinia } from "pinia";
import { useMultiLang } from "@store/multilang";

export function useMultiLangFetchService(pinia?: Pinia) {
    useMultiLang(pinia);

    function loadMultiLang() {
        return Promise.resolve();
    }

    return { loadMultiLang };
}
